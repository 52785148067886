import {
    useWriteContract,
    useWalletClient,
    useWaitForTransactionReceipt
} from "wagmi";

import {
    // _no,
    _notEmpty
} from "../utils";

import {
    ERROR_RWA_CLAIM_POSITION,
    ERROR_RWA_CLAIM_POSITION_WRITE
} from "../utils/config";

export const useMintPosition = ({
    config,
}) => {

    const { data: signer } = useWalletClient();
    const { chainId, positionNFTContract  } = config;

    const enabled = _notEmpty(
        positionNFTContract?.address
    );

    const {
        error,
        status,
        data: hash,
        writeContract,
        isPending,
    } = useWriteContract();

    const mintPosition = async ({
        onConfirmation,
        // onClaimComplete,
        // onClaimingRewards,
        onError
    }) => {
        try {

            onConfirmation?.();

            writeContract({
                chainId,
                abi: positionNFTContract?.abi,
                address: positionNFTContract?.address,
                signerOrProvider: signer,
                // gas: gasLimitFormatted,
                functionName: "mintPosition",
                query: {
                    enabled,
                },
                onError: (error) => {
                    console.error(
                        ERROR_RWA_CLAIM_POSITION_WRITE,
                        error
                    );
                }
            });

            return;

        } catch (error) {

            console.error(
                ERROR_RWA_CLAIM_POSITION,
                error
            );

            onError?.(error);
            return false;
        }
    };

    const {
        isError: hasError,
        isLoading: isConfirming,
        isSuccess: isConfirmed
    } = useWaitForTransactionReceipt({
        hash,
    });

    return {
        hash,
        error,
        status,
        hasError,
        isPending,
        isConfirming,
        isConfirmed,
        mintPosition,
        isLoading: isConfirming,
    };
};
