import React from 'react';
import { useState } from 'react';
import cn from 'classnames';
import Details from './Details';
import styles from "./TransactionRow.module.sass";
import useConfig from "../../../../customHooks/useConfig";
import useWiseReferralData from "../../../../customHooksWise/useWiseReferralData";
import LinearBar from "../../../../components/LinearBar";
import Modal from "../../../../components/Modal";

import {
    formatAPY,
    shortenAddress,
    daysAgoToTitle,
    numberWithCommasForFutureDays,
} from "../../../../utils/";
import Checkbox from '../../../../components/Checkbox';

const TransactionRow = ({
    index,
    referralId,
    address,
    wiseCost,
    currentWiseDay,
    handleSelect,
    selected,
    doRefetch,
    setDoRefetch
}) => {

    const config = useConfig();

    const [visibleModal, setVisibleModal] = useState(false);

    // TODO: import isFetching and see what can be done
	const { stakeData, referralData } = useWiseReferralData({
        config,
        address,
        referralId,
        wiseCost,
        currentWiseDay,
        doRefetch
    });

    const daysAgo = daysAgoToTitle(currentWiseDay - stakeData.startDay + 1);

    let referralColor = "#1bc943";

    if (stakeData && stakeData.closedPrematurely) {
        referralColor = "#f4772e";
    }

    const stakeStatus = () => {
        if (stakeData.matureStake && !stakeData.closedStake) {
            return "Matured Stake";
        }
        if (stakeData.onGoingStake && !stakeData.pendingStake) {
            return "Stake Is Ongoing";
        }
        if (stakeData.pendingStake) {
            return "Stake Is Pending";
        }
        if (stakeData.closedStake) {
            return "Stake Is Closed";
        }
        if (stakeData.closedPrematurely) {
            return "Terminated Stake";
        }
    }

    // can only  be Active or Inactive
    /*
    const referralStatus = () => {
        if (stakeData.matureStake && !stakeData.closedStake) {
            return "Active";
        }
        if (stakeData.onGoingStake && !stakeData.pendingStake) {
            return "Active";
        }
        if (stakeData.pendingStake) {
            return "Active";
        }
        if (stakeData.closedStake) {
            return "Inactive";
        }
    }
    */

    const staked = stakeData.stakedAmountFormatted;

    let progress = stakeData.stakeProgress;
    if (progress <= 0) progress = 0.001;

    if (!progress)  {
        return <div className={cn(styles.row)} key={index}></div>;
    }
    // @TODO... loading}

    const fullySaturated = stakeData && parseInt(stakeData.stakeProgress) === 100;
    const hasRewards = referralData && parseInt(referralData.referralInterest) > 0;
    const reward = parseFloat(referralData.referralInterestValue);

    return (
        <div className={cn(
            styles.row,
            {
                [styles.active]: selected.includes(referralId),
            }
            )}
            key={index}
        >
            <div className={styles.col}>
                <div>
                    <Checkbox
                        value={selected.includes(referralId)}
                        onChange={() => {
                        handleSelect(referralId, reward);
                    }
                    }></Checkbox>
                </div>
            </div>
            <div
                onClick={() => handleSelect(referralId, reward)}
                className={styles.col}
            >
                <div>
                    <div className={styles.product}>
                        {daysAgo}
                    </div>
                <div
                    className={styles.invoice}>{stakeData.pendingStake ? 'On' : 'On'} {stakeData && stakeData.opensDayDate}
                </div>
                </div>
            </div>
            <div
                onClick={() => handleSelect(referralId, reward)}
                className={styles.col}
            >
                <div className={styles.label}>Stake Progress</div>
                <div className={styles.liner}
                    style={{
                        filter: stakeData && (stakeData.closedStake || fullySaturated) ? "grayscale(0.5)" : "none",
                        opacity: stakeData && (stakeData.closedStake || fullySaturated) ? 0.2 : 1
                    }}
                >
                    <LinearBar
                        height={8}
                        width={"100"}
                        backColor="#181818"
                        customFill={referralColor}
                        limit={progress}
                        limitColor="#181818"
                    />
                </div>
                <div>
                    <div className={styles.invoice}>
                        <div className={styles.static}>
                            <span>{parseFloat(progress).toFixed(2)}% Expired</span>
                            <span>{stakeData && stakeData.lockDaysFormatted} Total</span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                onClick={() => handleSelect(referralId, reward)}
                className={styles.col}
            >
                <div className={styles.label}>Stake Status</div>
                {stakeData.matureStake && !stakeData.closedStake && (
                    <>
                        <div className={styles.product}>Expired</div>
                        <div className={styles.invoice}>On {stakeData && stakeData.finalDayDate}</div>
                    </>
                )}
                {stakeData.onGoingStake && !stakeData.pendingStake && (
                    <>
                        <div className={styles.product}>{stakeData && numberWithCommasForFutureDays(stakeData.daysLeft, "In ")}</div>
                        <div className={styles.invoice}>On {stakeData && stakeData.finalDayDate}</div>
                    </>

                )}
                {stakeData.pendingStake && (
                    <>
                        <div className={styles.product}>Starts Tomorrow</div>
                        <div className={styles.invoice}>Till {stakeData && stakeData.finalDayDate}</div>
                    </>

                )}
                {stakeData.closedStake && (
                    <>
                        <div className={styles.product}>{
                            stakeData && stakeData.closedPrematurely
                                ? "Terminated"
                                : "Expired"
                        }</div>
                        <div className={styles.invoice}>On {stakeData && stakeData.closeDayDate}</div>
                    </>
                )}
            </div>
            {/*<div className={styles.col}>
                <div className={styles.label}>Referral Status</div>
                <div
                    className={cn(
                        "status-green-dark",
                        styles.status
                    )}
                >
                    {referralStatus()}
                </div>
            </div>*/}
            <div
                onClick={() => handleSelect(referralId, reward)}
                className={styles.col}>
                <div className={styles.product}>{shortenAddress(referralData.staker)}</div>
                <div className={styles.invoice}>{stakeStatus()}</div>
            </div>
            <div
                onClick={() => handleSelect(referralId, reward)}
                className={styles.col}
            >
                <div className={styles.label}>Stake Amount</div>
                <div className={styles.product}>{stakeData && staked}</div>
                <div className={styles.invoice}>{stakeData && stakeData.stakedAmountInUSDWithPrecision}</div>
            </div>
            <div
                onClick={() => handleSelect(referralId, reward)}
                className={styles.col}
            >
                <div className={styles.label}>Your Rewards</div>
                <div
                    className={cn(
                        styles.product,
                        {
                            [styles.hasrewards]: hasRewards,
                            [styles.norewards]: !hasRewards,
                            [styles.lastreward]: hasRewards && stakeData && stakeData.closedPrematurely
                        }
                    )}
                >{referralData && referralData.referralInterestFormatted}</div>
                <div className={styles.invoice}>+{referralData && referralData.referralAmountInUSD} USD</div>
            </div>
            <div className={styles.col}>
                <button
                    onClick={() => setVisibleModal(true)}
                    className={cn(
                        "button-small",
                        "button-stroke",
                        styles.button
                    )}
                >
                    Check Details
                </button>
            </div>
            <Modal
                outerClassName={styles.outer}
                visible={visibleModal}
                onClose={() => setVisibleModal(false)}
            >
                <Details
                    onClose={() => {
                        setVisibleModal(false);
                    }}
                    setDoRefetch={setDoRefetch}
                    referralId={referralId}
                    stakeId={stakeData.stakeId}
                    stakeData={stakeData}
                    referralData={referralData}
                    currentWiseDay={currentWiseDay}
                    currentAPY={formatAPY(stakeData)}
                />
            </Modal>
            {/*<div className={styles.col}>
                <div className={styles.label}>Amount</div>
                {index % 2 > 0 ? (
                    <div>+${numberWithCommas(10.00)}</div>
                ) : (
                    <div className={styles.negative}>
                        -${numberWithCommas(12.12)}
                    </div>
                )}
            </div>*/}
        </div>
    );
};

export default TransactionRow;