import cn from 'classnames';
import Modal from "../Modal";
import KadoWidget from '../KadoWidget';
import styles from './KadoWidgetModal.module.sass';

const KadoWidgetModal = ({
    visible,
    onClose
}) => {

    return (
        <Modal
            outerClassName={styles.kadoWidgetModal}
            visible={visible}
            onClose={onClose}
            >
            <div className={cn("title-green", styles.title)}>
                Account Funding
            </div>
            <KadoWidget />
        </Modal>
    )
}

export default KadoWidgetModal;