import {
    useWriteContract,
    useWalletClient,
    useWaitForTransactionReceipt
} from "wagmi";

const useTransferNft = ({
    contract,
    chainId
}) => {

    const { data: signer } = useWalletClient();

    const {
        error,
        data: hash,
        writeContract,
        isPending,
    } = useWriteContract();

    const transferNft = ({
        onConfirmation,
        fromAddress,
        toAddress,
        tokenId,
    }) => {
        onConfirmation?.();

        const args = [
            fromAddress,
            toAddress,
            tokenId
        ];

        writeContract(
            {
                chainId,
                abi: contract?.abi,
                address: contract?.address,
                signerOrProvider: signer,
                // gas: gasLimitFormatted,
                functionName: "safeTransferFrom",
                args: args,
                query: {
                    enabled: tokenId && toAddress,
                }
            }
        );
    };

    const {
        isError: hasError,
        error: errorConfirming,
        isLoading: isConfirming,
        isSuccess: isConfirmed
    } = useWaitForTransactionReceipt({
        hash,
    });

    return {
        hash,
        error,
        hasError,
        isPending,
        isConfirming,
        isConfirmed,
        transferNft,
        errorConfirming,
        isLoading: isConfirming,
    };
};

export default useTransferNft;