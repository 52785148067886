import { useEffect } from "react";
import { useState } from "react";
import {
    useReadContract,
    // useBlockNumber
} from "wagmi";
import { _notEmpty } from "../utils";
import { useQueryClient } from '@tanstack/react-query'
import { appLocalStorage } from "../utils/localstorage";
import useWalletBalance from "./useWalletBalance";

const usePositionNFT = ({
    config,
    userAddress,
    isNftId = false
}) => {

    const { chainId, positionNFTContract } = config;

    const { walletBalance } = useWalletBalance({
        chainId,
        userAddress
    });

    const [doRefetch, setDoRefetch] = useState(false);

    const toggleRefetch = () => {
        setDoRefetch(!doRefetch);
    }

    const functionName = isNftId
        ? "ownerOf"
        : "walletOfOwner";

    const enabled = _notEmpty(userAddress)
        && _notEmpty(chainId)
        && _notEmpty(positionNFTContract?.address);

    const queryClient = useQueryClient();

    /*
    const { data: blockNumber } = useBlockNumber({
        watch: true
    });
    */

    const preferredNftId = appLocalStorage.preferredNFT.get();

    const {
        data: allNfttPositions,
        isFetching,
        queryKey,
    } = useReadContract({
        chainId,
        address: positionNFTContract?.address,
        abi: positionNFTContract?.abi,
        functionName,
        args: [
            userAddress
        ],
        query: {
            enabled
        }
    });

    const {
        data: reservedNftId,
        queryKey: reservedKey,
    } = useReadContract({
        chainId,
        address: positionNFTContract?.address,
        abi: positionNFTContract?.abi,
        functionName: "reserved",
        args: [
            userAddress
        ],
        query: {
            enabled: !!userAddress
        }
    });

    // console.log(allNfttPositions, 'allNfttPositions');

    useEffect(() => {

        queryClient.invalidateQueries({
            queryKey
        });

        queryClient.invalidateQueries({
            reservedKey
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        userAddress,
        walletBalance,
        // blockNumber,
        queryClient,
        chainId,
        doRefetch,
    ]);

    // console.log(allNfttPositions, 'allNfttPositions')
    // console.log(isFetching, 'isFetching')

    let selectedPos = !!allNfttPositions && !!allNfttPositions[0]
        ? parseInt(allNfttPositions[0])
        // : 10000000;
        : -1;

    const intArray = allNfttPositions?.map((pos) => parseInt(pos));

    if (!!intArray && intArray.includes(preferredNftId)) {
        selectedPos = preferredNftId;
    }

    // console.log(selectedPos, 'selectedPos');
    // console.log(preferredNftId, 'preferredNftId');
    // console.log(allNfttPositions, 'allNfttPositions');

    return {
        toggleRefetch,
        result: selectedPos,
        isFetching: isFetching,
        allNfttPositions,
        reservedNftId
    };
};

export default usePositionNFT;
