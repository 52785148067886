// import React, { useState } from "react";
import React from "react";
import styles from "./Market.module.sass";
// import cn from "classnames";
// import Checkbox from "../../../../components/Checkbox";
// import Icon from "../../../../components/Icon";
import Row from "./Row";

const Market = ({
    // items,
    address,
    positionCount,
    config,
    setTotalByUser,
    onChildValueChange
}) => {

    // const [chooseAll, setСhooseAll] = useState(false);
    // const [selectedFilters, setSelectedFilters] = useState([]);

    /*
    const handleChange = (id) => {
        if (selectedFilters.includes(id)) {
            setSelectedFilters(selectedFilters.filter((x) => x !== id));
        } else {
            setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
        }
    };
    */

    return (
        <div className={styles.market}>
            <div className={styles.table}>
                <div className={styles.row}>
                    <div className={styles.col}>
                        {/*<Checkbox
                            className={styles.checkbox}
                            value={chooseAll}
                            onChange={() => setСhooseAll(!chooseAll)}
                        />*/}
                    </div>
                    <div className={styles.col}>Your Positions</div>
                    <div className={styles.col}>Deposited</div>
                    <div className={styles.col}>Fixed APY</div>
                    <div className={styles.col}>Earned Rewards</div>
                    <div className={styles.col}>Distribution</div>
                    <div className={styles.col}>Available Rewards</div>
                    <div className={styles.col}>Actions</div>
                </div>
                {!!parseInt(positionCount) && [...Array(parseInt(positionCount))].map((x, index) => (
                    <Row
                        // item={x}
                        id={`child${index}`}
                        onChildValueChange={onChildValueChange}
                        setTotalByUser={setTotalByUser}
                        config={config}
                        address={address}
                        index={index}
                        key={index}
                        // up={items.length - index <= 2}
                        // value={selectedFilters.includes(x.id)}
                        // onChange={() => handleChange(x.id)}
                    />
                ))}
            </div>
            {/*<div className={styles.foot}>
                <button className={styles.arrow}>
                    <Icon name="arrow-left" size="20" />
                </button>
                <button className={styles.arrow}>
                    <Icon name="arrow-right" size="20" />
                </button>
            </div>*/}
        </div>
    );
};

export default Market;
