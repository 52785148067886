import { useEffect, useMemo, useState } from "react";
import { getAbiItem } from 'viem';
// import { ERROR_TRANSACTION } from "../utils/config";
import { getSupportedTokens } from "../ethers/tokens";
import { _defaultArray, _no, generateEventSignature } from "../utils";
import { INFURA_API_KEY } from "../utils/config";
import { ethers } from "ethers";

const MAX_TRANSACTIONS = 100;

export const useGetTransactions = ({
    config,
    tokenAddresses = [],
    skip
}) => {
    const {
        wiseLendingContract,
        aaveHubContract,
        deploymentBlock,
        chainId,
        availablePools,
    } = config;

    const infuraProvider = useMemo(() => {

        if (_no(chainId) || _no(INFURA_API_KEY)) {
            return null;
        }

        return new ethers.providers.InfuraProvider(
            chainId,
            INFURA_API_KEY
        );
        // eslint-disable-next-line no-use-before-define
    }, [chainId])

    const [isFetching, setIsFetching] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [transactions, setTransactions] = useState([]);

    const eventNames = useMemo(() => [
        "FundsDeposited",
        "FundsWithdrawn",
        "FundsWithdrawnOnBehalf",
        "FundsBorrowed",
        "FundsBorrowedOnBehalf",
        "FundsReturned"
    ], []);

    const tokens = useMemo(
        () => {
            const supportedTokens = getSupportedTokens(
                chainId,
                availablePools
            );

            return supportedTokens;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [chainId]
    );

    const processLogs = async (logs) => {
        const filteredLogs = logs.filter((log) => {
                const matchTokens = tokenAddresses.some((tokenAddress) => {
                    return tokenAddress.toLowerCase() === log.args.token.toLowerCase();
                });
                return _no(tokenAddresses?.length) || matchTokens;
            }).sort((logA, logB) => {
                return Number(logB.args.timestamp) - Number(logA.args.timestamp);
            }).slice(
                0,
                MAX_TRANSACTIONS
            );

        const uniqueTransactionHashes = [
            ...new Set(
                filteredLogs.map((log) => {
                    return log.transactionHash
                })
            )
        ];

        const receipts = await Promise.all(
            uniqueTransactionHashes.map((hash) => {
                return infuraProvider.getTransactionReceipt(
                    hash
                );
            })
        );

        const receiptMap = new Map(
            receipts.map((receipt) => [
                receipt.transactionHash,
                receipt
            ])
        );

        return filteredLogs.map(log => {
            const receipt = receiptMap.get(
                log.transactionHash
            );

            const {
                sender,
                amount,
                totalPayment,
                timestamp,
                token,
                borrower
            } = log.args;

            const senderAddress = borrower ?? sender;
            const tokenAmount = totalPayment ?? amount;

            let label;
            if (log.eventName === "FundsDeposited") {
                label = "Lend";
            } else if (log.eventName === "FundsWithdrawn"
                || log.eventName === "FundsWithdrawnOnBehalf") {
                label = "Redeem";
            } else if (log.eventName === "FundsBorrowed"
                || log.eventName === "FundsBorrowedOnBehalf") {
                label = "Borrow";
            } else if (log.eventName === "FundsReturned") {
                label = "Repaid";
            }

            let userAccount;
            let tokenData;

            if (senderAddress?.toLowerCase() === aaveHubContract.address?.toLowerCase()) {
                userAccount = receipt.from;
                tokenData = tokens.find((supportedToken) => {
                    return supportedToken.aaveAddress?.toLowerCase() === token?.toLowerCase()
                        && supportedToken.isAavePool
                });
            } else {
                userAccount = senderAddress;
                tokenData = tokens.find((supportedToken) => {
                    return supportedToken.address.toLowerCase() === token?.toLowerCase()
                        && _no(supportedToken.isAavePool)
                });
            }
            return {
                from: receipt.from,
                transactionHash: log.transactionHash,
                timestamp,
                token: tokenData,
                amount: tokenAmount,
                userAccount,
                label
            };

        });
    }

    const fetchHistoricalLogs = async ({
        isRefetch = false
    }) => {
        setIsFetching(true && _no(isRefetch));
        try {
            const contract = new ethers.Contract(
                wiseLendingContract.address,
                wiseLendingContract.abi,
                infuraProvider
            );

            const latestBlock = await infuraProvider.getBlockNumber();
            const fromBlock = ethers.BigNumber.from(deploymentBlock).gt(latestBlock)
                ? latestBlock
                : deploymentBlock;

            const topicsFilter = eventNames.map(eventName => {
                const eventAbi = getAbiItem({
                    abi: _defaultArray(wiseLendingContract?.abi),
                    name: eventName,
                    type: "event"
                });
                return ethers.utils.id(
                    generateEventSignature(
                        eventAbi
                    )
                );
            });

            const logs = await infuraProvider.getLogs({
                address: wiseLendingContract.address,
                topics: [topicsFilter],
                fromBlock: ethers.utils.hexValue(fromBlock),
                toBlock: "latest"
            });

            const parsedLogs = logs.map(log => {
                const eventLog = contract.interface.parseLog(log);
                return {
                    ...log,
                    eventName: eventLog.name,
                    args: eventLog.args
                };
            });

            const newTransactions = await processLogs(
                parsedLogs
            );

            setTransactions(
                newTransactions
            );
        } catch (error) {
            console.error("Error fetching historical logs:", error);
        } finally {
            setIsFetching(false);
        }
    }

    const isInvalid = _no(chainId)
        || _no(wiseLendingContract)
        || _no(deploymentBlock)
        || _no(infuraProvider)
        || skip;

    const refetch = async () => {
        if (isInvalid || isRefetching) return;

        try {
            setIsRefetching(true);
            await fetchHistoricalLogs({
                isRefetch: true
            });
        } finally {
            setIsRefetching(false);
        }
    }

    useEffect(() => {
        if (isInvalid) return;

        fetchHistoricalLogs({
            isRefetch: false
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId, deploymentBlock, skip, wiseLendingContract, infuraProvider]);

    return {
        transactions,
        isFetching,
        refetch,
        isRefetching,
    };
};
