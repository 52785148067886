import React, { useState } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
// import Checkbox from "../../../components/Checkbox";
// import Loader from "../../../components/Loader";
import Tooltip from "../../../components/Tooltip";
import Row from "./Row";

// data
import { customers } from "../../../mocks/customers";
import TooltipGlobal from "../../../components/TooltipGlobal";
import Loader from "../../../components/Loader";


const Table = ({
    className,
    referees,
    activeTable,
    setActiveTable,
    loading
}) => {

    // const [chooseAll, setСhooseAll] = useState(false);
    const [activeId, setActiveId] = useState(
        customers[0].id
    );

    const [selectedFilters, setSelectedFilters] = useState([]);

    const handleChange = (id) => {
        if (selectedFilters.includes(id)) {
            setSelectedFilters(selectedFilters.filter((x) => x !== id));
        } else {
            setSelectedFilters((selectedFilters) => [
                ...selectedFilters,
                id
            ]);
        }
    };

    const list = referees?.length
        ? referees
        : [];

    return (
        <div className={cn(styles.wrapper, className)}>
            <div className={cn(styles.table)}>
                <div className={cn(styles.row, { [styles.active]: activeTable })}>
                    <div className={styles.col}>
                        {/*<Checkbox
                            className={styles.checkbox}
                            value={chooseAll}
                            onChange={() => setСhooseAll(!chooseAll)}
                        />*/}
                    </div>
                    <div className={styles.col}>Referee Address</div>
                    {/*<div className={styles.col}>Email</div>*/}
                    <div className={styles.col}>
                        Points Rate
                        <Tooltip
                            className={cn(
                                styles.tooltip,
                            )}
                            title={'amount of referral points you earn per second'}
                            icon="info-stroke"
                            place="right"
                        />
                    </div>
                    <div className={styles.col}>
                        ETH Referred
                        {/*<Tooltip
                            className={cn(
                                styles.tooltip,
                            )}
                            title={'currently added value by the referee'}
                            icon="info-stroke"
                            place="right"
                        />*/}
                    </div>
                    <div className={styles.col}>
                        Stable Referred
                        {/*<Tooltip
                            className={cn(
                                styles.tooltip,
                            )}
                            title={'currently added value by the referee'}
                            icon="info-stroke"
                            place="right"
                        />*/}
                    </div>
                    <div className={styles.col}>
                        Points
                        <Tooltip
                            className={cn(
                                styles.tooltip,
                            )}
                            title={'amount of referral points earned so far'}
                            icon="info-stroke"
                            place="right"
                        />
                    </div>
                </div>
                {!loading && list?.map((x, index) => (
                    <Row
                        item={x}
                        key={index}
                        activeTable={activeTable}
                        setActiveTable={setActiveTable}
                        activeId={activeId}
                        setActiveId={setActiveId}
                        value={selectedFilters.includes(x.id)}
                        onChange={() => handleChange(x.id)}
                    />
                ))}
            </div>
            {loading && (
                <div className={styles.placeholder}>
                    <Loader className={styles.placeholderContent} />
                </div>
            )}
            <TooltipGlobal />
        </div>
    );
};

export default Table;
