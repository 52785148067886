import React, { useState } from "react";
import styles from "./Positions.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Market from "./Market";
import Table from "./Table";
import useConfig from "../../../customHooks/useConfig";
import usePositionNFT from "../../../customHooks/usePositionNFT";
import { useAccount } from "wagmi";
import { MAIN_COLOR_A, MAIN_COLOR_B } from "../../../utils/config";

// data
import { traffic } from "../../../mocks/traffic";
import { viewers } from "../../../mocks/viewers";
import { market } from "../../../mocks/market";
import { showToast } from "../../../utils";

const indicatorsTraffic = [
    {
        title: "Aave",
        color: "#FFBC99",
    },
    {
        title: "Compound",
        color: "#fff191",
    },
    {
        title: "Curve",
        color: MAIN_COLOR_A,
    },
    {
        title: "Fluid",
        color: MAIN_COLOR_B,
    },
    {
        title: "Others",
        color: "#333333",
    },
];

const indicatorsViewers = [
    {
        title: "Lending",
        color: MAIN_COLOR_A,
    },
    {
        title: "Borrowing",
        color: "#f99191",
    },
];

const Positions = () => {

    const navigation = [
        "Create",
        "Merge",
        "Import",
        "Transfer"
    ];

    const [activeTab, setActiveTab] = useState(navigation[0]);
    // const [search, setSearch] = useState("");

    const { address: userAddress } = useAccount();

    const config = useConfig();
    const {
        allNfttPositions,
        reservedNftId,
        toggleRefetch
    } = usePositionNFT({
        config,
        userAddress,
        isNftId: false
    });

    return (
        <Card
            className={styles.card}
            title="Position Manager"
            classTitle={cn("title-green", styles.title)}
            classCardHead={styles.head}
            head={
                <>
                    {/*<Form
						className={styles.form}
						value={search}
						setValue={setSearch}
						onSubmit={() => handleSubmit()}
						placeholder="Search product"
						type="text"
						name="search"
						icon="search"
					/>*/}
                    <div className={styles.control}>
                        <button className={cn("button-stroke button-small", styles.button)}>
                            Deleted
                        </button>
                        <button className={cn("button-stroke button-small", styles.button)}>
                            Set status
                        </button>
                        <div className={styles.counter}>3 selected</div>
                    </div>
                    <div className={cn(styles.nav, "tablet-hide")}>
                        {navigation.map((x, index) => (
                            <button
                                className={cn(styles.link, {
                                    [styles.active]: x === activeTab,
                                })}
                                onClick={() => {
                                    if (index < 3) {
                                        setActiveTab(x);
                                    }
                                    else {
                                        showToast(
                                            "This feature will be available in next release...",
                                            {
                                                type: "info",
                                            }
                                        );
                                    }
                                }}
                                key={index}
                            >
                                {x}
                            </button>
                        ))}
                    </div>
                    <div className={cn(styles.dropdown, "tablet-show")}>
                        <Dropdown
                            classDropdownHead={styles.dropdownHead}
                            value={activeTab}
                            setValue={setActiveTab}
                            options={navigation}
                            small
                        />
                    </div>
                </>
            }
        >
            <div className={styles.products}>
                <div className={styles.wrapper}>
                    {activeTab === navigation[0] && <Market
                        items={market}
                        config={config}
                        toggleRefetch={toggleRefetch}
                        allNfttPositions={allNfttPositions}
                        reservedNftId={reservedNftId}
                    />}
                    {activeTab === navigation[1] && (
                        <Table
                            allNfttPositions={allNfttPositions}
                            title="Traffic source"
                            items={traffic}
                            legend={indicatorsViewers}
                        />
                    )}
                    {activeTab === navigation[2] && (
                        <Table title="Viewers" items={viewers} legend={indicatorsTraffic} />
                    )}
                </div>
            </div>
        </Card>
    );
};

export default Positions;
