import { useState, useEffect } from "react";
import { useQueryClient } from '@tanstack/react-query'
import { useReadContracts, useReadContract, useBlockNumber } from "wagmi";
import { formatUnits, getAbiItem } from "viem";

import {
    APY_DECIMALS,
    DEFAULT_DECIMALS,
    // ERROR_OVERALL_BALANCE,
    // ERROR_OVERALL_NET_APY,
    ETH_TOKEN_NAME,
    // POWER_USER_ADDRESS,
    // ERROR_OVERALL_BORROW,
    // ERROR_OVERALL_BORROW_APY,
    // ERROR_OVERALL_LENDING_APY,
    // ERROR_OVERALL_LIMIT,
    // ERROR_OVERALL_SUPPLY,
    SAFE_lIMIT_PERCENTAGE
} from "../utils/config";

import {
    _notEmpty,
    _isNotEmptyNftId,
    // _no,
    _defaultArray,
    scientificToDecimal,
    // fetchPointsData
} from "../utils";
import { useSelector } from "react-redux";
// import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";

const summaryDefault = {
    percentageCap: SAFE_lIMIT_PERCENTAGE
};

const useLendingSummary = ({
    config,
    nftId,
    userAccount
}) => {

    const [summary, setSummary] = useState(
        summaryDefault
    );
    const [successDataFetching, setSuccessDataFetching] = useState(
        false
    );

    const { pricingFeeds } = useSelector(
        (state) => state.token
    );

    const tokenPriceData = pricingFeeds.find(
        item => item.name === ETH_TOKEN_NAME
    );

    const tokenPrice = tokenPriceData?.price ?? 0;

    const getWiseSecurityABI = (name) => {
        return [
            getAbiItem({
                abi: _defaultArray(wiseSecurityContract?.abi),
                name,
                type: "function"
            })
        ];
    };

    const {
        chainId,
        wiseSecurityContract,
        APYViewerContract
    } = config;

    const enabled = _isNotEmptyNftId(nftId)
        && _notEmpty(chainId)
        && _notEmpty(wiseSecurityContract?.address)
        && _notEmpty(APYViewerContract?.address);

    const contracts = [
        {
            chainId,
            address: wiseSecurityContract?.address,
            abi: getWiseSecurityABI("overallETHCollateralsBare"),
            functionName: "overallETHCollateralsBare",
            args: [
                nftId
            ],
        },
        {
            chainId,
            address: wiseSecurityContract?.address,
            abi: getWiseSecurityABI("overallETHBorrow"),
            functionName: "overallETHBorrow",
            args: [
                nftId
            ],
        },
        {
            chainId,
            address: wiseSecurityContract?.address,
            abi: getWiseSecurityABI("overallETHCollateralsWeighted"),
            functionName: "overallETHCollateralsWeighted",
            args: [
                nftId
            ],
        },
        {
            chainId,
            address: wiseSecurityContract?.address,
            abi: getWiseSecurityABI("overallLendingAPY"),
            functionName: "overallLendingAPY",
            args: [
                nftId
            ],
        },
        {
            chainId,
            address: wiseSecurityContract?.address,
            abi: getWiseSecurityABI("overallBorrowAPY"),
            functionName: "overallBorrowAPY",
            args: [
                nftId
            ],
        }
    ];

    const { data, isFetching, queryKey } = useReadContracts({
        allowFailure: true,
        query: {
            enabled
        },
        contracts
    });

    useEffect(() => {

        if (data) {

            const [
                supplyResult,
                borrowResult,
                limitResult,
                lendingRateResult,
                borrowRateResult
            ] = data;

            let supplyFormatted = "0";
            const supply = supplyResult?.result;
            if (supply) {
                supplyFormatted = scientificToDecimal(
                    formatUnits(
                        supply,
                        DEFAULT_DECIMALS
                    ) * tokenPrice
                );
                if (parseInt(supplyFormatted) === 0) {
                    supplyFormatted = 0.00;
                }
            }

            let borrowFormatted = "0";
            const borrow = borrowResult?.result;
            if (borrow) {
                borrowFormatted = scientificToDecimal(
                    formatUnits(
                        borrow,
                        DEFAULT_DECIMALS
                    ) * tokenPrice
                );
            }

            let safeLimit;
            let limitFormatted = "0";
            const limit = limitResult.result;
            if (limit) {
                limitFormatted = formatUnits(
                    limit,
                    DEFAULT_DECIMALS
                ) * tokenPrice;

                safeLimit = limitFormatted * summary.percentageCap / 100;
            }

            let lendingRateFormatted = "0";
            const lendingRate = lendingRateResult?.result;
            if (lendingRate) {
                lendingRateFormatted = formatUnits(
                    lendingRate,
                    APY_DECIMALS
                );
            }

            let borrowRateFormatted = "0";
            const borrowRate = borrowRateResult?.result;
            if (borrowRate) {
                borrowRateFormatted = formatUnits(
                    borrowRate,
                    APY_DECIMALS
                );
            }

            setSummary((prev) => {
                return {
                    ...prev,
                    supply,
                    supplyFormatted,
                    borrow,
                    borrowFormatted,
                    limit,
                    limitFormatted,
                    safeLimit,
                    lendingRate,
                    lendingRateFormatted,
                    borrowRate,
                    borrowRateFormatted
                };
            });

            if (data) {

                const overallETHCollateralsBare = data[0]?.result || 0;
                const overallETHBorrow = data[1]?.result || 0;
                const overallETHCollateralsWeighted = data[2]?.result || 0;
                const overallLendingAPY = data[3]?.result || 0;
                const overallBorrowAPY = data[4]?.result || 0;

                const overallETHCollateralsBareFormatted = formatUnits(
                    overallETHCollateralsBare,
                    DEFAULT_DECIMALS
                );
                const overallETHBorrowFormatted = formatUnits(
                    overallETHBorrow,
                    DEFAULT_DECIMALS
                );
                const overallETHCollateralsWeightedFormatted = formatUnits(
                    overallETHCollateralsWeighted,
                    DEFAULT_DECIMALS
                );
                const overallLendingAPYFormatted = formatUnits(
                    overallLendingAPY || 0,
                    APY_DECIMALS
                );
                const overallBorrowAPYFormatted = formatUnits(
                    overallBorrowAPY,
                    APY_DECIMALS
                );

                const overallLimit = scientificToDecimal(
                    overallETHCollateralsBareFormatted
                );
                const overallSupply = scientificToDecimal(
                    overallETHCollateralsWeightedFormatted
                );
                const overallBorrow = scientificToDecimal(
                    overallETHBorrowFormatted
                );

                /*
                const overallLendingAPYFormatted = scientificToDecimal(
                    overallLendingAPYFormatted
                );
                */

                setSummary((prev) => {
                    return {
                        ...prev,
                        overallLimit,
                        overallSupply,
                        overallBorrow,
                        overallLendingAPYFormatted,
                        overallBorrowAPYFormatted
                    };
                });

                setSuccessDataFetching(true);
            }

        } else {
            setSummary((prev) => {
                return {
                    ...prev,
                    overallLimit: 0,
                    overallSupply: 0,
                    overallBorrow: 0,
                    overallLendingAPYFormatted: "0",
                    overallBorrowAPYFormatted: "0"
                };
            });

            setSuccessDataFetching(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const queryClient = useQueryClient();
    const { data: blockNumber } = useBlockNumber({
        watch: true
    });

    const { data: overAllNetAPY, queryKey: keyB } = useReadContract({
        chainId,
        address: APYViewerContract?.address,
        abi: APYViewerContract?.abi,
        functionName: "overallNetAPYs",
        args: [
            nftId
        ],
        query: {
            enabled
        },
    });

    useEffect(() => {
        // console.log(overAllNetAPY, 'overAllNetAPY');
        if (overAllNetAPY) {
            const data = overAllNetAPY;
            const borrowAPY = data[0];
            const lendingAPY = data[1];
            const overallNetAPY = data[2];
            const isNegativeApy = data[5];

            let borrowAPYFormatted = "0";
            let lendingAPYFormatted = "0";
            let overallNetAPYFormatted = "0";

            if (borrowAPY) {
                borrowAPYFormatted = formatUnits(
                    borrowAPY,
                    APY_DECIMALS
                );
            }

            if (lendingAPY) {
                lendingAPYFormatted = formatUnits(
                    lendingAPY,
                    APY_DECIMALS
                );
            }

            if (overallNetAPY) {
                overallNetAPYFormatted = formatUnits(
                    overallNetAPY,
                    APY_DECIMALS
                );
            }
            setSummary((prev) => {
                return {
                    ...prev,
                    borrowAPY,
                    borrowAPYFormatted,
                    lendingAPY,
                    lendingAPYFormatted,
                    overallNetAPY,
                    overallNetAPYFormatted,
                    isNegativeApy
                };
            });
        } else {
            setSummary((prev) => {
                return {
                    ...prev,
                    borrowAPY: 0,
                    borrowAPYFormatted: "0",
                    lendingAPY: 0,
                    lendingAPYFormatted: "0",
                    overallNetAPY: 0,
                    overallNetAPYFormatted: "0",
                    isNegativeApy: false
                };
            });
        }
    }, [overAllNetAPY]);

    const summaryData = {
        ...summary,
        overAllNetAPY
    };

    useEffect(() => {
        queryClient.invalidateQueries({
            queryKey
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        userAccount,
        blockNumber,
        queryClient,
        chainId
    ]);

    useEffect(() => {
        queryClient.invalidateQueries({
            keyB
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        userAccount,
        blockNumber,
        queryClient,
        chainId
    ]);

    // console.log(overAllNetAPY, 'overAllNetAPY');

    /*
    const fetchPoints = async (address) => {
        const objResult = await fetchPointsData(address);

        setSummary((prev) => {
            return {
                ...prev,
                points: objResult,
            };
        });
    };
    */

    // console.log(summaryData, 'summaryData');

    return {
        ...summaryData,
        isFetching,
        successDataFetching: enabled
            ? successDataFetching
            : true
    };
};

export default useLendingSummary;
