import React from "react";
// import cn from "classnames";
import styles from "./TooltipGlobal.module.sass";
// import Icon from "../Icon";
import ReactTooltip from "react-tooltip";

const TooltipGlobal = () => {
    return (
        <ReactTooltip
            className={styles.tooltipWpapper}
            effect="solid"
            border={true}
            borderColor="rgba(255, 255, 255, 0.2)"
            backgroundColor="#272B30"
            textColor="#F7F7F7"
            html={true}
        />
    );
};

export default TooltipGlobal;
