import React from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../Icon";

const Control = ({ className, icon, customIcon }) => {

    const actions = [
        {
            customIcon: customIcon,
            icon: icon,
            action: () => {
                // setVisibleModalProduct(true)
            },
        },
    ];

    return (
        <>
            <div className={cn(styles.control, className)}>
                {actions.map((x, index) => (
                    <button className={styles.button} key={index} onClick={x.action}>
                        {x.customIcon ? (
                            <div className={cn(styles.custom)}>
                                <img
                                    src={x.customIcon}
                                    alt="Icon"
                                />
                            </div>
                        ) : (
                            <><Icon name={x.icon} size="20" /></>
                        )}
                    </button>
                ))}
            </div>
            {/*<ModalProduct
                visible={visibleModalProduct}
                onClose={() => setVisibleModalProduct(false)}
            />*/}
        </>
    );
};

export default Control;
