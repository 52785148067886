import { Scrollbar as ReactScrollbar } from "react-scrollbars-custom";
import cn from "classnames";
import styles from "./Scrollbar.module.sass";

const Scrollbar = ({
    children,
    className,
    isPage = false,
}) => {
    return (
        <ReactScrollbar
            className={cn(
                className,
                styles.scroll,
                {
                    [styles.page]: isPage,
                }
            )}
            thumbYProps={{
                renderer: props => {
                    const { key, elementRef, ...restProps } = props;
                    return (
                        <div
                            key={key}
                            {...restProps}
                            ref={elementRef}
                            className={styles.thumbVertical}
                        />
                    );
                },
            }}
            thumbXProps={{
                renderer: props => {
                    const { key, elementRef, ...restProps } = props;
                    return (
                        <div
                            key={key}
                            {...restProps}
                            ref={elementRef}
                            className={styles.thumbHorizontal}
                        />
                    );
                },
            }}
            trackYProps={{
                renderer: props => {
                    const { key, elementRef, ...restProps } = props;
                    return (
                        <div
                            key={key}
                            {...restProps}
                            ref={elementRef}
                            className={cn(
                                styles.trackVertical,
                                {
                                    [styles.componentVertical]: !isPage,
                                }
                            )}
                        />
                    );
                },
            }}
            trackXProps={{
                renderer: props => {
                    const { key, elementRef, ...restProps } = props;
                    return (
                        <div
                            key={key}
                            {...restProps}
                            ref={elementRef}
                            className={cn(
                                styles.trackVertical,
                                {
                                    [styles.componentVertical]: !isPage,
                                }
                            )}
                        />
                    );
                },
            }}
            wrapperProps={{
                renderer: props => {
                    const { key, elementRef, ...restProps } = props;
                    return (
                        <div
                            key={key}
                            {...restProps}
                            ref={elementRef}
                            className={cn(styles.wrapper)}
                        />
                    );
                },
            }}
            contentProps={{
                renderer: props => {
                    const { key, elementRef, ...restProps } = props;
                    return (
                        <div
                            key={key}
                            {...restProps}
                            ref={elementRef}
                            className={cn(
                                styles.content,
                                {
                                    [styles.componentContent]: !isPage,
                                }
                            )}
                        />
                    );
                },
            }}
        >
            {children}
        </ReactScrollbar>
    );
};

export default Scrollbar;
