import React from "react";
import cn from "classnames";
import styles from "./Tooltip.module.sass";
import Icon from "../Icon";

const Tooltip = ({
    className,
    title,
    place,
    icon = undefined,
    children = undefined
}) => {
    return (
        <div className={cn(styles.tooltip, className)}>
            <span data-tip={title} data-place={place}>
                {icon && <Icon size="20" name={icon} />}
                {children}
            </span>
        </div>
    );
};

export default Tooltip;
