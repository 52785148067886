import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Modal from "../../../components/Modal";
import Details from "./Details";
import { formatMoney } from "../../../utils";

const Row = ({
    item,
    rank,
    isCurrent
}) => {
    const DEFAULT_IMAGE = '/images/content/shadow-owl.jpg';

    const [visibleModal, setVisibleModal] = useState(false);

    return (
        <>
            <div
                className={cn({
                    [styles.row]: true,
                    [styles.current]: isCurrent,
                    [styles.inactive]: !parseFloat(
                        item.pointsRateFormatted
                    )
                })}
            // onClick={() => setVisibleModal(true)}
            >
                <div className={styles.col}>
                    <div
                        className={
                            cn({
                                [styles.rank]: true,
                                [styles.new]: rank < 3,
                                [styles.progress]: rank > 2,
                                [styles.gray]: rank > 9
                            })}
                    >
                        #{rank + 1}
                        {/*<Icon name="diamond" size="16" />*/}
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.user}>
                        <div className={styles.avatar}>
                            <img src={item.avatar || DEFAULT_IMAGE} alt="Avatar" />
                        </div>
                        {item.address}
                    </div>
                </div>
                <div className={styles.col}>
                    {formatMoney(
                        item.contributedAmount,
                        true
                    )}
                </div>
                <div className={styles.col}>{item.pointsRateFormatted}</div>
                <div className={styles.col}>
                    {formatMoney(
                        item.totalPoints
                    )}
                </div>
            </div>
            <Modal
                outerClassName={styles.outer}
                visible={visibleModal}
                onClose={() => setVisibleModal(false)}
            >
                <Details item={item} />
            </Modal>
        </>
    );
};

export default Row;
