import React from "react";
import cn from "classnames";
// import { useAccount } from "wagmi";

import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Avatar from "../../../components/Avatar";
import Item from "../Item";
// import { useAccount } from "wagmi";
// import Editor from "../../../components/Editor";

import { showToast } from "../../../utils";

import styles from "./ProfileInformation.module.sass";

const ProfileInformation = ({ className, address }) => {

    // const [content, setContent] = useState();

    return (
        <Item
            className={cn(styles.card, className)}
            title="Profile information"
            classTitle="title-green"
        >
            <div className={styles.profile}>
            <div className={styles.avatar}>
                    <Avatar address={address} className={styles.avatar} />
                    <img src="/images/content/shadow-owl.jpg" alt="Avatar" />
                    <button className={cn(
                        styles.remove,
                        {
                            [styles.disabled]: true
                        }
                    )}
                    >
                        <Icon name="close" />
                    </button>
                </div>
                <div className={styles.file}>
                    <button
                        // disabled={true}
                        onClick={() => {
                            showToast(
                                "Available in the next release...",
                                {
                                    type: "info"
                                }
                            )
                        }}
                        className={cn("button", styles.button, styles.disabled)}
                        type="button"
                    >
                        <Icon name="promotion" size="24" />
                        <span>Custom NFT Profile</span>
                    </button>
                </div>
                <button
                    onClick={() => {
                        showToast(
                            "Available in the next release...",
                            {
                                type: "info"
                            }
                        )
                    }}
                    className={cn(
                        "button-stroke",
                        styles.button,
                        styles.disabled
                    )}>
                    Use ENS Profile
                </button>
            </div>
            <div className={styles.fieldset}>
                <TextInput
                    className={styles.field}
                    label="Connected Wallet Address"
                    name="display-name"
                    type="text"
                    disabled={true}
                    value={address}
                    // tooltip="Maximum 100 characters. No HTML or emoji allowed"
                    required
                />
                <TextInput
                    className={styles.field}
                    label="Provided Email Address"
                    name="email"
                    disabled={true}
                    type="email"
                    // tooltip="Maximum 100 characters. No HTML or emoji allowed"
                    required
                />
                {/*<TextInput
                    className={styles.field}
                    label="Location"
                    name="location"
                    type="text"
                    tooltip="Maximum 100 characters. No HTML or emoji allowed"
                    required
                />
                <Editor
                    state={content}
                    onChange={setContent}
                    classEditor={styles.editor}
                    label="Bio"
                    tooltip="Description"
                />*/}
            </div>
            <br/>
            <button
                className={cn("button-stroke", styles.button)}
                onClick={() => {
                    showToast(
                        "Available in the next release...",
                        {
                            type: "info"
                        }
                    )
                }}
            >
                Save Profile Information
            </button>
        </Item>
    );
};

export default ProfileInformation;
