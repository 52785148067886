import cn from "classnames";
import React, { useState } from "react";
import Card from "../../../components/Card";
import styles from "./RWAVaults.module.sass";
import { useSelector } from "react-redux";
import Row from "./Row";
import Market from "./Market";
import { market } from "../../../mocks/market";

import useConfig from "../../../customHooks/useConfig";
import useRWAPositionNFT from "../../../customHooks/useRWAPositionNFT";

const RWAVaults = () => {

    const [totalByUser, setTotalByUser] = useState(0);
    const [childValues, setChildValues] = useState({})
    const [refetchTrigger, setRefetchTrigger] = useState(false);

    const {
        address,
        // nftId
    } = useSelector(
        (state) => state.settings
    );

    const toggleRefetch = () => {
        setRefetchTrigger(!refetchTrigger);
    }

    const handleChildValueChange = (childId, newValue) => {
        setChildValues(prev => ({
            ...prev,
            [childId]: newValue
        }))
    }

    const config = useConfig();
    const {
        chainId
    } = config;

    const rwaData = [
        {
            id: 'rwa-farm',
            source: "World Mobile Vault",
            description: "USDC Air Nodes RWA",
            image: "/images/rwa/worldmobile.jpg",
            image2x: "/images/rwa/worldmobile.jpg",
            badge: "/images/icons/usdc.svg",
            capacity: "5,112,500 USDC",
            APY: 20,
            lockTime: 30,
            position: 0,
            defaultToken: "USDC",
            active: true
        }
    ];

    const rwaPosition = useRWAPositionNFT({
        config,
        userAddress: address,
        refetchTrigger
    });

    let sum = 0;

    for (let i = 0; i < rwaPosition.nftPositionCount; i++) {
        const num = parseFloat(childValues[`child${i}`]) || 0;
        sum += num;
    }

    return (
        <>
        <Card
            className={cn(styles.card)}
            title={`Centralized Positions (${rwaPosition.nftPositionCount})`}
            classTitle="title-green"
        >
            <div className={styles.wrapper}>
                <div className={styles.table}>
                    <div className={cn(styles.row)}>
                        <div className={styles.col}></div>
                        <div className={styles.col}>Yield Source</div>
                        <div className={styles.col}>Vault Capacity</div>
                        <div className={styles.col}>Fixed APY
                            {/* <Tooltip
                                className={styles.tooltip}
                                title={"Variable APY based on supply and demand"}
                                place="right"
                            > */}
                            {/* <b className={styles.tilt}>Fixed APY</b> */}
                            {/* </Tooltip> */}
                        </div>
                        <div className={styles.col}>Reward Cycle</div>
                        <div className={styles.col}>Your Deposits</div>
                        <div className={styles.col}>Actions</div>
                    </div>
                    {rwaData.map((x, index) => {
                        /*
                        const extraApy =
                            tokenList
                                ?.find(token => token.poolName === x.poolName)
                                ?.extraApy;
                        */
                        return (
                            <Row
                                sum={sum}
                                totalByUser={totalByUser}
                                totalDepositedData={rwaPosition.totalDepositedData}
                                config={config}
                                chainId={chainId}
                                item={x}
                                toggleRefetch={toggleRefetch}
                                key={`rwa_${index}`}
                            // extraApy={extraApy}
                            />
                        );
                    })}
                </div>
            </div>
        </Card>
        {rwaPosition.nftPositionCount > 0 && (
            <Card>
                <Market
                    toggleRefetch={toggleRefetch}
                    onChildValueChange={handleChildValueChange}
                    setTotalByUser={setTotalByUser}
                    address={address}
                    positionCount={rwaPosition.nftPositionCount}
                    items={market}
                    config={config}
                >
                </Market>
            </Card>
        )}
        </>
    );
};

export default RWAVaults;
