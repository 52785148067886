import React, { useEffect, useState } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import OpenFarmDetails from "../OpenFarmDetails";
import ViewFarmDetails from "../ViewFarmDetails";
import { useAccount } from "wagmi";
import {
    _isNotEmptyNftId,
    _no,
    _notEmpty,
    computeUSDAmount,
    formatMoney,
    // formatMoney,
    formatUSD,
    removeNonNumeric,
    showToast
} from "../../../../utils";
// import { useFarmingContext } from "../../../../screens/Farming/context";
import { POOL_NAMES } from "../../../../ethers/tokens";
import { useSelector } from "react-redux";
import Modal from "../../../Modal";

const generateDetails = (token) => {
    return {
        parameters: [
            {
                title: "Available Funds",
                content: `0.000 ${token}`,
            },
            /*{
                title: "Minimum Borrow",
                content: `0.00 ${token}`,
            },*/
            {
                title: "Borrow Amount",
                content: `0.000 ${token}`,
            },
            {
                title: "Position Amount",
                content: `0.000 ${token}`,
            },
            {
                title: "Estimated APY",
                content: "12.34%",
                tooltip: "NET APY",
                isAPY: true,
                isGreen: true,
                data: [
                    {
                        title: "Borrow APY",
                        content: "12.34%",
                    },
                    {
                        title: "Source APY",
                        content: "12.34%",
                        isGreen: true,
                    }
                ]
            },
            {
                title: "Estimated Rewards",
                tooltip: "Current Net Interest",
                content: `0.000 ${token}`,
            },
            {
                title: "Position Opening Fee",
                price: `0.000 ${token}`,
            },
            /*
            {
                title: "Opening Transaction Cost",
                price: 0.123,
            },
            */
        ],
        details: [
            {
                title: "Initial Amount",
                content: `3.000 ${token}`,
            },
            {
                title: "Leverage Loop Level",
                content: "2x",
            },
            {
                title: "Borrow Amount",
                content: `4.000 ${token}`,
            },
            {
                title: "Current APY",
                content: "12.34%",
                downloadedStatus: true,
                downloadedValue: true,
            },
            {
                title: "Accumulated Rewards",
                content: `0.000 ${token}`,
            },
            {
                title: "Closing Transaction Cost",
                tooltip: "Description Price",
                price: 0.123,
            },
        ],
    };
};

const Control = ({
    item,
    token,
    amountDaysValue,
    isActive,
    nftId,
    isFetching,
    doRefetch,
    positionData,
    tokenUtilization,
    availableFunds,
    tokenBalance,
    setToken
}) => {

    const { isConnected } = useAccount();
    // const farmingContext = useFarmingContext();

    const { pricingFeeds } = useSelector(
        (state) => state.token
    );

    const tokenPriceData = pricingFeeds.find(
        item => item.name === token.name
    );

    const buttonText = isActive === false
        ? "Coming Soon"
        : "Loading...";

    const [modalType, setModalType] = useState();

    const notReady = isConnected && isFetching && _no(nftId);
    const showDisabledButton = notReady || _no(isActive);

    const borrowAmount = positionData.amountFormatted
        * positionData.leverageFormatted;

    const hasPosition = _isNotEmptyNftId(
        nftId
    );

    if (positionData.reward < 0) {
        positionData.reward = 0;
    }

    // TODO: to be deleted
    // const rewardFormatted = !!positionData.reward
    //     ? `${positionData.reward.toFixed(2)} ${token.name}`
    //     : `0.00 ${token.name}`;

    /*
    const getAmountDisplayValue = (value) => {
        return formatMoney({
            value,
            isDollar: false,
            allowNegative: false,
            precision: 2
        });
    }*/

    const getPriceDescription = (price) => {
        const value = _no([POOL_NAMES.USDC, POOL_NAMES.USDT].includes(token.poolName))
            ? computeUSDAmount(
                tokenPriceData?.decimals,
                removeNonNumeric(price || 0),
                tokenPriceData?.price
            )
            : null;

        return _notEmpty(parseFloat(value))
            ? `(${formatUSD(value)})`
            : null;
    }


    const farmDetails = [
        {
            title: "Initial Deposit",
            content: `${formatMoney(positionData.amountFormatted)} ${token.name}`,
            contentDescription: getPriceDescription(positionData.amountFormatted),
        },
        {
            title: "Leverage Loop Level",
            content: `${parseInt(positionData.leverageFormatted)}x`,
        },
        {
            title: "Borrow Amount",
            content: `${formatMoney(borrowAmount)} ${token.name}`,
            contentDescription: getPriceDescription(borrowAmount),
        },
        {
            title: "Estimated APY",
            tooltip: "NET APY",
            content: `${positionData.estimatedAPYValue}%`,
            isAPY: true,
            isGreen: true,
            data: []
        },
        {
            title: "Expected Payout",
            // TODO: to be deleted
            // content: `${rewardFormatted}`,
            // contentDescription: getPriceDescription(positionData.reward),
            tooltip: "After Closing Position"
            // content: `${formatMoney(positionData.reward)} ETH`,
            // content: `0.00 ETH`,
        },
        /*{
            title: "Closing Transaction Cost",
            price: 0.123,
        },*/
    ];

    const data = {
        ...item,
        ...generateDetails(token.name),
        details: farmDetails
    };

    const showOpen = _no(hasPosition) || _no(isConnected);

    const handleCloseModal = () => setModalType(null);

    const handleOpenModal = (modal) => {
        setModalType(modal);
    };

    useEffect(() => {
        const handleRowClick = () => {
            if (!showOpen) {
                return handleOpenModal("view-position");
            } else if (showOpen) {
                return handleOpenModal("open-position");
            }
        };

        window.addEventListener(
            "farmRowClick" + item.id,
            handleRowClick
        );

        return () => {
            window.removeEventListener(
                "farmRowClick" + item.id,
                handleRowClick
            );
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.product, handleOpenModal]);

    return (
        <>
            {showDisabledButton && (
                <div className={styles.buttonHolder}>
                    <button className={cn(
                        "button",
                        styles.button,
                        {
                            disabled: true,
                            [styles.disabled]: true
                        },
                    )}>
                        {buttonText}
                    </button>
                </div>
            )}
            {_no(showDisabledButton) && _no(showOpen) && (
                <div onClick={() => { handleOpenModal("view-position"); }} className={styles.buttonHolder}>
                    <button className={cn("button-stroke", styles.button)}>
                        View Position
                    </button>
                </div>
            )}
            {_no(showDisabledButton) && showOpen && (
                <div
                    onClick={() => {
                        if (amountDaysValue !== 'Expired') {
                            handleOpenModal("open-position")
                        } else {
                            showToast("This farm already expired. Try on Ethereum!", {
                                type: "info"
                            });
                        }
                    }}
                    className={styles.buttonHolder}
                >
                    <button className={cn("button", styles.button)}>
                    {amountDaysValue === `Expired` ? "Expired" : "Open Position"}
                    </button>
                </div>
            )}
            <Modal
                outerClassName={cn({
                    [styles.openOuter]: modalType === "open-position",
                    [styles.viewOuter]: modalType === "view-position"
                })}
                visible={_notEmpty(modalType)}
                onClose={handleCloseModal}
            >
                {
                    modalType === "open-position" && (
                        <OpenFarmDetails
                            item={data}
                            availableFunds={availableFunds}
                            // tokenUtilization={tokenUtilization}
                            onClose={handleCloseModal}
                            token={tokenBalance}
                            tokenBalance={tokenBalance}
                            onConnect={() => handleOpenModal({
                                modal: "connect"
                            })}
                            onDone={handleCloseModal}
                            doRefetch={doRefetch}
                            setToken={setToken}
                        />
                    )
                }
                {
                    modalType === "view-position" && (
                        <ViewFarmDetails
                            nftId={nftId}
                            item={data}
                            token={token}
                            onClose={handleCloseModal}
                            onConnect={() => handleOpenModal({
                                modal: "connect"
                            })}
                            onDone={handleCloseModal}
                            doRefetch={doRefetch}
                            amount={positionData.amount}
                        />
                    )
                }
            </Modal>
        </>
    );
};

export default Control;
