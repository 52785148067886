import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Market.module.sass";
import Loader from "../../../../components/Loader";
import { appLocalStorage } from "../../../../utils/localstorage";
import { setNftId } from "../../../../redux/settingsSlice";
// import cn from "classnames";
// import { useSupplyToken } from "../../../../customHooks/useSupplyToken";
import Icon from "../../../../components/Icon";
import Row from "./Row";
import { showToast } from "../../../../utils";
import { useMintPosition } from "../../../../customHooks/useMintPosition";
// import useConfig from "../../../../customHooks/useConfig";

const Market = ({
    items,
    allNfttPositions,
    reservedNftId,
    config,
    toggleRefetch,
}) => {

    // const [chooseAll, setСhooseAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [buttonText, setButtonText] = useState("Create New Position");

    const selectedFilters = [];
    // const [selectedFilters, setSelectedFilters] = useState([]);

    const dispatch = useDispatch();

    const {
        nftId,
    } = useSelector(
        (state) => state.settings
    );

    const handleChange = (id) => {
        dispatch(
            setNftId(id)
        );

        appLocalStorage.preferredNFT.update(
            id
        );

        showToast(
            `Position #${id} selected. Go back to dashboard.`,
            {
                type: "success",
            }
        )
    };

    const mintPosition = useMintPosition({
        config,
    });

    const handleCreatePosition = () => {

        if (reservedNftId > 0) {

            showToast("Required: latest position must be minted", {
                type: "info",
            });

            return;
        }

        /*
        if (allNfttPositions.length > 4) {
            showToast("You can't have more than 5 positions...", {
                type: "info",
            });
            return;
        }
        */

        // check that latest is above 0
        /*
        if (latest <= 0 && allNfttPositions.length > 0) {
            showToast("Must have borrowed balance on latest position", {
                type: "info",
            });
            return;
        }
        */

        mintPosition.mintPosition({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonText(
                    "Confirm In Wallet"
                );
            }
        });
    }

    useEffect(() => {

        if (mintPosition.error && isLoading) {
            setIsLoading(false);
            setButtonText(`Create New Position`);
        }

        if (mintPosition.isConfirming && isLoading) {
            setButtonText(`Creating Position...`);
        }

        if (mintPosition.isConfirmed && isLoading) {

            setIsLoading(false);
            const message = "Position created successfully!";

            showToast(message, {
                type: "success",
            });

            setButtonText(
                `Create New Position`
            );

            setIsLoading(false);

            toggleRefetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mintPosition]);

    return (
        <div className={styles.market}>
            <div className={styles.table}>
                <div className={styles.row}>
                    <div className={styles.col}>
                    </div>
                    <div className={styles.col}>Position Id</div>
                    <div className={styles.col}>Status</div>
                    <div className={styles.col}>Net APY</div>
                    <div className={styles.col}>Lending</div>
                    <div className={styles.col}>Borrowing</div>
                    <div className={styles.col}>Borrow Limit Used</div>
                    <div className={styles.col}>Minted</div>
                </div>
                {/*{items.map((x, index) => (
                    <Row
                        item={x}
                        key={index}
                        up={items.length - index <= 2}
                        value={selectedFilters.includes(x.id)}
                        onChange={() => handleChange(x.id)}
                    />
                ))}*/}
                {allNfttPositions && allNfttPositions.map((x, index) => (
                    <Row
                        item={parseInt(x)}
                        key={index}
                        toggleRefetch={toggleRefetch}
                        reservedNftId={reservedNftId}
                        active={parseInt(nftId) === parseInt(x)}
                        up={items.length - index <= 2}
                        value={selectedFilters.includes(parseInt(x))}
                        onChanger={() => handleChange(parseInt(x))}
                    />
                ))}
            </div>
            <div className={styles.foot}>
                <div className={styles.switcher}>
                    {/*<Switch
                        className={styles.switch}
                        value={0}
                        onChange={() => {}}
                    />
                    Close modal on position switch*/}
                    {/*<Checkbox
                        content={"Close modal on position switch"}
                        value="-"
                        onChange={() => {
                            console.log('0000');
                        }}
                        className={styles.checkbox}
                        checked={false}
                    >
                        Select All
                    </Checkbox>*/}
                </div>
                <button
                    onClick={() => {
                        handleCreatePosition();
                    }}
                    disabled={isLoading}
                    className={cn(
                        "button-stroke",
                        {
                            disabled: isLoading,
                        }
                    )}
                >
                        { isLoading ? (
                            <Loader className={styles.loader} />
                        ): (
                            <Icon name="plus" />
                        )}
                        {buttonText}
                </button>
            </div>
        </div>
    );
};

export default Market;
