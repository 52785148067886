import 'react-loading-skeleton/dist/skeleton.css';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./styles/app.sass";
import { useState } from "react";
import Page from "./components/Page";
// import AffiliateCenter from "./screens/AffiliateCenter";
// import Comments from "./screens/Comments";
import CustomerList from "./screens/CustomerList";
import Customers from "./screens/Customers";
import Drafts from "./screens/Drafts";
import Earning from "./screens/Earning";
// import ExploreContributors from "./screens/ExploreContributors";
import Farming from "./screens/Farming";
import Vaults from "./screens/Vaults";
import Home from "./screens/Home";
// import IconList from "./screens/IconList";
import LeadBoards from "./screens/LeadBoards";
import NewProduct from "./screens/NewProduct";
import Notification from "./screens/Notification";
// import PageList from "./screens/PageList";
// import PointsCenter from "./screens/PointsCenter";
import PoolCenter from "./screens/PoolCenter";
import ProductsDashboard from "./screens/ProductsDashboard";
import Profile from "./screens/Profile";
import Audits from "./screens/Audits";
import Promote from "./screens/Promote";
// import Questions from "./screens/Questions";
// import Refunds from "./screens/Refunds";
import Released from "./screens/Released";
import Scheduled from "./screens/Scheduled";
import Settings from "./screens/Settings";
import Statements from "./screens/Statements";
import Staking from "./screens/Staking";
import Stats from "./screens/Stats";
import Referrals from "./screens/Referrals";
// import UpgradeToPro from "./screens/UpgradeToPro";
import Withdrawals from "./screens/Withdrawals";
import { useLocalStorage } from "./customHooks/useLocalStorage";
// import Archive from "./screens/Home/Archive";
import JoinNetwork from "./screens/PointsCenter/JoinNetwork";
import { HOME_TITLE } from "./utils/config";

function App() {

    useLocalStorage();

    const [expand, setExpand] = useState(false);
    const hostname = window.location.hostname;

    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path="/"
                    render={() => {
                        if (hostname === "lend.wise.one") {
                            return (
                                <Page expand={expand} setExpand={setExpand} title={HOME_TITLE} icon="lightning">
                                    <Home expand={expand} />
                                </Page>
                            );
                        } else if (hostname === "stake.wise.one") {
                            return (
                                <Page expand={expand} setExpand={setExpand} title="Wise Staking Hub" icon="schedule">
                                    <Staking />
                                </Page>
                            );
                        } else if (hostname === "wise.one") {
                            return (
                                <Page wide title="Wise Home" icon="home" expand={expand} setExpand={setExpand}>
                                    <Profile />
                                </Page>
                            );
                        } else if (hostname === "localhost") {
                            return (
                                <Page wide title="Wise Home" icon="home" expand={expand} setExpand={setExpand}>
                                    <Profile />
                                </Page>
                            );
                        } else {
                            // Some default fallback if none of the above matches
                            return (
                                <Page wide title="Wise Home" icon="home" expand={expand} setExpand={setExpand}>
                                    <Profile />
                                </Page>
                            );
                        }
                    }}
                />
                <Route
                    exact
                    path="/home"
                    render={() => (
                        <Page
                            wide
                            title="Wise Home"
                            icon="home"
                            expand={expand}
                            setExpand={setExpand}
                        >
                            <Profile />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/lending"
                    render={() => (
                        <Page expand={expand} setExpand={setExpand} title={HOME_TITLE} icon="lightning">
                            <Home expand={expand} />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/lending/hub"
                    render={() => (
                        <Page
                            expand={expand}
                            setExpand={setExpand}
                            title={HOME_TITLE}
                            icon="lightning"
                        >
                            <Home expand={expand} />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/staking"
                    render={() => (
                        <Page
                            expand={expand}
                            setExpand={setExpand}
                            title={"Wise Staking Hub"}
                            icon="schedule"
                        >
                            <Staking />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/staking/hub"
                    render={() => (
                        <Page
                            expand={expand}
                            setExpand={setExpand}
                            title={"Wise Staking Hub"}
                            icon="schedule"
                        >
                            <Staking />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/staking/ref"
                    render={() => (
                        <Page
                            expand={expand}
                            setExpand={setExpand}
                            title={"Wise Staking Referrals"}
                            icon="schedule"
                        >
                            <Referrals />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/staking/info"
                    render={() => (
                        <Page
                            title={"Wise Staking Statistics"}
                            icon="schedule"
                            expand={expand}
                            setExpand={setExpand}
                        >
                            <Stats />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/products/dashboard"
                    render={() => (
                        <Page
                            title="Points Center"
                            expand={expand}
                            setExpand={setExpand}
                        >
                            <ProductsDashboard />
                        </Page>
                    )}
                />
                {/*<Route
                    exact
                    path="/points-v1"
                    render={() => (
                        <Page title="Points Center" icon="diamond">
                            <PointsCenter />
                        </Page>
                    )}
                />
                */}
                <Route
                    exact
                    path="/lending/points"
                    render={() => (
                        <Page
                            title="Wise Lending Leaderboards"
                            icon="lightning"
                            expand={expand}
                            setExpand={setExpand}
                        >
                            <LeadBoards />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/r/:address"
                    render={() => (
                        <Page
                            expand={expand}
                            setExpand={setExpand}
                            title="New Referral Invitation"
                            mobileTitle="Referral Invitation"
                            icon="share"
                        >
                            <JoinNetwork />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/products/add"
                    render={() => (
                        <Page
                            expand={expand}
                            setExpand={setExpand}
                            title="New page"
                        >
                            <NewProduct />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/products/drafts"
                    render={() => (
                        <Page
                            expand={expand}
                            setExpand={setExpand}
                            title="Drafts"
                        >
                            <Drafts />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/products/released"
                    render={() => (
                        <Page
                            expand={expand}
                            setExpand={setExpand}
                            title="Released"
                        >
                            <Released />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/products/scheduled"
                    render={() => (
                        <Page
                            title="Scheduled"
                            expand={expand}
                            setExpand={setExpand}
                        >
                            <Scheduled />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/vaults"
                    render={() => (
                        <Page
                            expand={expand}
                            setExpand={setExpand}
                            title="Wise Vaults"
                            icon="shopping-bag"
                        >
                            <Vaults />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/lending/farms"
                    render={() => (
                        <Page
                            title="Wise Lending Farms"
                            icon="lightning"
                            expand={expand}
                            setExpand={setExpand}
                        >
                            <Farming />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/customers/overview"
                    render={() => (
                        <Page
                            title="Analytics"
                            expand={expand}
                            setExpand={setExpand}
                        >
                            <Customers />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/customers/customer-list"
                    render={() => (
                        <Page
                            title="List View"
                            expand={expand}
                            setExpand={setExpand}
                        >
                            <CustomerList />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/"
                    render={() => (
                        <Page wide
                            title="Wise Home"
                            icon="home"
                            expand={expand}
                            setExpand={setExpand}
                        >
                            <Profile />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/audits"
                    render={() => (
                        <Page wide
                            title="Wise Audits"
                            customIcon="/images/icons/security.svg"
                            expand={expand}
                            setExpand={setExpand}
                        >
                            <Audits />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/lending/details"
                    render={() => (
                        <Page
                            title="Wise Lending Statistics"
                            icon="lightning"
                            expand={expand}
                            setExpand={setExpand}
                        >
                            <Earning />
                        </Page>
                    )}
                />
                {/*<Route
                    exact
                    path="/liquidations"
                    render={() => (
                        <Page title="Liquidation">
                            <Refunds />
                        </Page>
                    )}
                />*/}
                <Route
                    exact
                    path="/withdrawals"
                    render={() => (
                        <Page
                            title="Withdrawals"
                            expand={expand}
                            setExpand={setExpand}
                        >
                            <Withdrawals />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/statements"
                    render={() => (
                        <Page
                            title="Statements"
                            expand={expand}
                            setExpand={setExpand}
                        >
                            <Statements />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/promote"
                    render={() => (
                        <Page
                            title="LiquidNFTs Details"
                            icon="promotion"
                            expand={expand}
                            setExpand={setExpand}
                        >
                            <Promote />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/notification"
                    render={() => (
                        <Page
                            title="Notification"
                            expand={expand}
                            setExpand={setExpand}
                        >
                            <Notification />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/settings"
                    render={() => (
                        <Page
                            title="Dashboard Settings"
                            icon="setting"
                            expand={expand}
                            setExpand={setExpand}
                        >
                            <Settings />
                        </Page>
                    )}
                />
                {/*<Route
                    exact
                    path="/about-power-farms"
                    render={() => (
                        <Page title="About Power Farms">
                            <UpgradeToPro />
                        </Page>
                    )}
                />*/}
                {/*<Route
                    exact
                    path="/questions"
                    render={() => (
                        <Page title="Frequent Questions">
                            <Questions />
                        </Page>
                    )}
                />*/}
                {/*<Route
                    exact
                    path="/explore-contributors"
                    render={() => (
                        <Page title="Explore Contributors">
                            <ExploreContributors />
                        </Page>
                    )}
                />*/}
                {/*<Route
                    exact
                    path="/affiliate-center"
                    render={() => (
                        <Page title="Affiliate center">
                            <AffiliateCenter />
                        </Page>
                    )}
                />*/}
                <Route
                    exact
                    path="/lending/pool/:pool"
                    render={() => (
                        <Page
                            title="Lending Pool Details"
                            expand={expand}
                            setExpand={setExpand}
                        >
                            <PoolCenter />
                        </Page>
                    )}
                />
                {/*
                <Route exact path="/pagelist" component={PageList} />
                <Route exact path="/iconlist" component={IconList} />*/}
            </Switch>
        </Router>
    );
}

export default App;
