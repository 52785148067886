import React, { useCallback, useEffect } from "react";
// import cn from "classnames";
// import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
// import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./ModalStaking.module.sass";
// import Icon from "../Icon";
// import Product from "./Product";
import Staking from "../../screens/Staking";

const ModalStaking = ({ visible, onClose }) => {

    const escFunction = useCallback(
        (e) => {
            if (e.keyCode === 27) {
                onClose();
            }
        },
        [onClose]
    );

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    return createPortal(
        visible && (
            <div id="modal-product" className={styles.modal}>
                <div className={styles.outer}>
                    <Staking fullScreen={true} onClose={onClose} />
                </div>
            </div>
        ),
        document.body
    );
};

export default ModalStaking;
