import React, { useState, useEffect } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import dayjs from "dayjs";
import SendNFT from "../../../../AffiliateCenter/SendNFT";
// import Checkbox from "../../../../../components/Checkbox";
// import Icon from "../../../../../components/Icon";
// import Balance from "../../../../../components/Balance";
// import ModalProduct from "../../../../../components/ModalProduct";
// import Control from "../../Control";
import useRWAPositionDetails from "../../../../../customHooks/useRWAPositionDetails";
import { formatUnits } from "ethers/lib/utils";
import { formatMoney, showToast } from "../../../../../utils";
// import { numberWithCommas } from "../../../../../utils";
import { useRWAClaimRewards } from "../../../../../customHooks/useRWAClaimRewards";
import { CONFIRM_TEXT } from "../../../../../utils/config";
import Loader from "../../../../../components/Loader";
import Modal from "../../../../../components/Modal";

const Row = ({
    // item,
    // value,
    // onChange,
    // up,
    index,
    address,
    config,
    setTotalByUser,
    id,
    onChildValueChange,
}) => {

    const [visibleModalSend, setVisibleModalSend] = useState(false);
    // const [visibleActions, setVisibleActions] = useState(false);
    // const [visibleModalProduct, setVisibleModalProduct] = useState(false);
    const [deposited, setDeposited] = useState(0);
    const [interestEarned, setInterestEarned] = useState(0);
    const [daysUntilPayout, setDaysUntilPayout] = useState("-");
    // const [currentUser, setCurrentUser] = useState(address);
    const [isLoading, setIsLoading] = useState(false);
    const [buttonText, setButtonText] = useState("Collect Rewards");

    const positionDetails = useRWAPositionDetails({
        config: config,
        positionIndex: index,
        userAddress: address
    });

    const rwaPosition = useRWAClaimRewards({
        config: config,
        nftId: positionDetails.rwaNFTPosition,
    });

    const USDC_DECIMALS = 6;

    const getInterestEarned = ({
        principal,
        apr = 0.20,
        startTimestamp,
        currentTimestamp,
        annualHours = 365 * 24, // Default: 8760 hours in a year
    }) => {
        if (!startTimestamp || !currentTimestamp) return 0;

        // Ensure timestamps are in seconds
        if (startTimestamp > 1e12) startTimestamp = Math.floor(startTimestamp / 1000);
        if (currentTimestamp > 1e12) currentTimestamp = Math.floor(currentTimestamp / 1000);

        // Convert timestamps to date objects
        const startDate = dayjs.unix(startTimestamp);
        const currentDate = dayjs.unix(currentTimestamp);

        // Calculate elapsed time in seconds
        const secondsDifference = currentDate.diff(startDate, "second");

        // Convert annual interest into per-second interest
        const annualInterest = principal * apr;
        const interestPerSecond = annualInterest / (annualHours * 3600); // 8760 hours * 3600 seconds

        // Calculate earned interest so far
        if (secondsDifference <= 3600) return 0.001;
        return interestPerSecond * secondsDifference;
    };


    const daysUntilSinglePayout = (initialTimestampSeconds) => {
        const nowSeconds = Math.floor(Date.now() / 1000);
        const SECONDS_PER_DAY = 24 * 60 * 60;
        const SECONDS_IN_30_DAYS = 30 * SECONDS_PER_DAY;

        const payoutTimestamp = initialTimestampSeconds + SECONDS_IN_30_DAYS;
        const diffSeconds = payoutTimestamp - nowSeconds;
        const daysLeft = Math.ceil(diffSeconds / SECONDS_PER_DAY);

        // Return at least 0 so we don't show negative days
        return daysLeft > 0 ? daysLeft : 0;
    }

    const availableRewards = formatMoney(
        positionDetails ? formatUnits(
            positionDetails
            && positionDetails.rwaRewardsData
            && positionDetails.rwaRewardsData.rewards,
            USDC_DECIMALS
        ) : 0
    );

    useEffect(() => {

        const hasData = positionDetails && positionDetails.rwaPositionData;

        if (hasData) {

            const deposit = formatUnits(
                positionDetails && positionDetails?.rwaPositionData[0],
                USDC_DECIMALS
            );

            setDeposited(
                deposit
            );

            setInterestEarned(
                getInterestEarned({
                    principal: deposit,
                    startTimestamp: parseInt(positionDetails.rwaPositionData[1]),
                    currentTimestamp: Date.now() / 1000
                })
            );

            let d = daysUntilSinglePayout(
                parseInt(
                    positionDetails.rwaPositionData[1]
                )
            );

            if (d === 0 && availableRewards > 0) {
                setDaysUntilPayout(
                    "Available"
                );
            } else {
                setDaysUntilPayout(
                    `In ${d} Days`
                );
            }

            if (d === 1) {
                setDaysUntilPayout(
                    `In ${d} Day`
                );
            }

            onChildValueChange(
                id,
                deposit
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        positionDetails.rwaPositionData
    ]);

    const collectRewards = () => {


        const d = daysUntilSinglePayout(
            parseInt(positionDetails.rwaPositionData[1])
        );

        if (d > 0) {
            const daysOrDay = d === 1 ? "day" : "days";
            showToast(
                `You can collect rewards after ${d} more ${daysOrDay}...`,
                {
                    type: "info",
                }
            );

            return;
        }

        rwaPosition.claimRewards({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonText(CONFIRM_TEXT);
            },
        });
    }

    useEffect(() => {

        if (rwaPosition.hasError && isLoading) {
            setIsLoading(false);
            setButtonText("Collect Rewards");
            showToast(
                "User rejected the request.",
                {
                    type: "info",
                }
            );
        }

        if (rwaPosition.error  && isLoading) {
            setIsLoading(false);
            setButtonText("Collect Rewards");
            showToast(
                "User rejected the request.",
                {
                    type: "info",
                }
            );
        }

        if (rwaPosition.isConfirming && isLoading) {
            setButtonText("Collecting...");
        }

        if (rwaPosition.isConfirmed && isLoading) {
            setIsLoading(false);
            setButtonText("Collect Rewards");
            showToast(
                "Rewards collected successfully!",
                {
                    type: "success",
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rwaPosition]);

    return (
        <>
            <div className={styles.row}
                // onMouseLeave={() => setVisibleActions(false)}
            >
                <div className={styles.col}>
                </div>
                <div className={styles.col}>
                    <div
                        className={styles.item}
                        // onClick={() => setVisibleModalProduct(true)}
                    >
                        <div className={styles.details}>
                            <div className={styles.product}>Position #{positionDetails.rwaNFTPosition}</div>
                            <div className={styles.wrap}>
                                <div className={styles.category}>World Mobile Vault</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>{formatMoney(deposited)} USDC</div>
                <div className={styles.col}>
                    <div className={styles.label}>Position APY</div>
                    <div className={cn("status-green-dark", styles.status)}>20.00%</div>
                </div>
                <div className={styles.col}>
                    <div className={styles.label}>Earned Rewards</div>
                    <div className={styles.sales}>
                        <div className={styles.number}>{formatMoney(interestEarned)} USDC</div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.label}>Distribution</div>
                    <div className={styles.box}>
                        <div className={styles.number}>{daysUntilPayout}</div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.label}>Available Rewards</div>
                    <div className={styles.sales}>
                        <div className={styles.number}>{availableRewards} USDC</div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.label}>Action</div>
                    <div className={styles.holders}>
                    <button
                        className={cn(
                            "buttons",
                            "button-stroke",
                            styles.buttons,
                            styles.button,
                            {
                                disabled: isLoading,
                                [styles.disabled]: isLoading
                                // disabled: true,
                                // [styles.disabled]: true
                            }
                        )}
                        onClick={() => collectRewards()}
                    >
                        {buttonText === "Collecting..." && (
                            <Loader className={styles.loader}></Loader>
                        )}
                        {buttonText}
                    </button>
                    <button
                        className={cn(
                            "buttons",
                            "button-stroke",
                            styles.buttons,
                            styles.button,
                            {
                                // disabled: isDeprecated,
                                // [styles.disabled]: isDeprecated
                                // disabled: true,
                                // [styles.disabled]: true
                            }
                        )}
                        onClick={() => setVisibleModalSend(true)}
                    >
                        Send
                        {/*<Icon name="exit"></Icon>*/}
                    </button>
                    </div>
                </div>
            </div>
            <Modal
                outerClassName={cn(styles.sender)}
                visible={visibleModalSend}
                onClose={() => {
                    setVisibleModalSend(false);
                    // doRefetch && doRefetch();
                }}
            >
                <SendNFT
                    onClose={() => {
                        setVisibleModalSend(false);
                        // doRefetch && doRefetch();
                    }}
                    linkBase={config.linkBase}
                    contract={config.ForwardingDepositUSDCContract}
                    nftId={positionDetails.rwaNFTPosition}
                    // stakeId={stakeId}
                    chainId={config.chainId}
                    // stakeData={stakeData}
                    // doRefetchStakes={doRefetch}
                    title={`Send Vault Position`}
                />
            </Modal>
            {/*<ModalProduct
                visible={visibleModalProduct}
                onClose={() => setVisibleModalProduct(false)}
            />*/}
        </>
    );
};

export default Row;
