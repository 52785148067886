import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useAccount } from "wagmi";
import { parseUnits } from "viem";
import { usePrivy } from '@privy-io/react-auth';
import Icon from "../../../../../components/Icon";
import LinearBar from "../../../../../components/LinearBar";
import Loader from "../../../../../components/Loader";
import { useTokenApproval } from "../../../../../customHooks/useTokenApproval";
import { useBorrowToken } from "../../../../../customHooks/useBorrowToken";
import {
    _no,
    _notEmpty,
    removeNonNumeric,
    formatMoneyDashed,
    formatMoney,
    formatDecimals,
    toPrecision,
    computeUSDAmount,
    showToast,
    toZeroIfExponential,
    toZeroIfBelowOne,
    toZeroIfSuperSmall,
    floorToDecimals,
} from "../../../../../utils";
import {
    DONE_TEXT,
    FETCHING_DATA,
    TRY_AGAIN_TEXT,
    CONFIRM_TEXT,
    APPROVING_TEXT,
    BORROWING_TEXT,
    BORROW_FAILED_MESSAGE,
    BORROW_SUCCESS,
    APPROVE_TEXT,
    INSUFFICIENT_COST,
    WISELENDING_TEXT,
} from "../../../../../utils/config";
import styles from "./Borrow.module.sass";
import SuccessPanel from "../../../../../components/Modal/SuccessPanel";
import TransactionCost from "../../../../../components/TransactionCost";
import TooltipGlobal from "../../../../../components/TooltipGlobal";
import TokenAmountInput from "../../../../../components/TokenAmountInput";
// import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAPYPredict } from "../../../../../customHooks/useAPYPredict";

const FUNCTION_NAME = "Borrow";

const Borrow = ({
    config,
    nftId,
    token,
    summary,
    tokenPriceData,
    walletBalance,
    onSupply,
    onDone,
    onConnect
}) => {

    const DEFAULT_APRROVE_TEXT = APPROVE_TEXT(
        WISELENDING_TEXT
    );

    const {
        login,
    } = usePrivy();

    const DEFAULT_TEXT = `${FUNCTION_NAME} ${token.name}`;

    // const { open } = useWeb3Modal();
    const { isConnected } = useAccount();
    const { linkBase } = config;

    const [amount, setAmount] = useState("");

    const [buttonApproveText, setButtonApproveText] = useState(
        DEFAULT_APRROVE_TEXT
    );

    const [buttonText, setButtonText] = useState(
        DEFAULT_TEXT
    );

    const [isApproved, setIsApproved] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [successfullBorrow, setSuccessfullBorrow] = useState();
    const [finishBorrow, setFinishBorrow] = useState(false);
    const [transactionHashLink, setTransactionHashLink] = useState("");

    const tokenPrice = tokenPriceData?.price || 0;

    const convertedAmount = removeNonNumeric(
        amount || 0
    );

    const formattedAmount = formatMoneyDashed(
        amount
    );

    const amountInUSD = computeUSDAmount(
        token.decimals,
        convertedAmount,
        tokenPrice
    );

    let maxLimit = parseFloat(summary.limitFormatted);
    let summaryBorrowLimit = parseFloat(summary.borrowFormatted);

    const borrowAmount = parseUnits(
        convertedAmount,
        token.decimals
    );

    const tokenApproval = useTokenApproval({
        config,
        nftId,
        token,
        amount: borrowAmount
    });

    const isFetchingData = _no(summary.limitFormatted)
        || _no(tokenPrice)
        || tokenApproval.isFetching;

    const BORROW_SUCCESS_MESSAGE = `${BORROW_SUCCESS} ${formattedAmount} ${token.name}`;

    const preApproved = token.isAavePool && _no(finishBorrow)
        ? isApproved
        : true;

    useEffect(() => {
        setIsApproved(
            tokenApproval.isApproved
        );
    }, [tokenApproval]);

    const showSupplyButton = isConnected
        && parseFloat(token.borrowBalanceFormatted) === 0
        && _no(summary.safeLimit)
        && _no(isFetchingData)
        && _no(finishBorrow);

    const showApproval = amount
        && _no(preApproved)
        && _no(isFetchingData)
        && _no(showSupplyButton);

    const borrowToken = useBorrowToken({
        config,
        nftId,
        token,
        amount: borrowAmount
    });

    const predictedAPY = useAPYPredict({
        config,
        token,
        amount: borrowAmount
    });

    const isDefault = buttonText.includes(
        FUNCTION_NAME
    ) && buttonText.includes(
        token.name
    );

    const handleBorrow = () => {

        if (buttonText === DONE_TEXT) {
            onDone && onDone();
            return;
        };

        if (buttonText === TRY_AGAIN_TEXT) {
            setFinishBorrow(false);
            setTransactionHashLink("");
            setButtonText(
                DEFAULT_TEXT
            );
        };

        if (_no(isDefault)) return;

        borrowToken.borrow({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonText(
                    CONFIRM_TEXT
                );
            }
        });
    };

    useEffect(() => {

        if (borrowToken.hasError && isLoading) {
            setIsLoading(false);
            handleError(borrowToken.hasError);
            setTransactionHashLink("");

            const amountInDecimal = toPrecision(
                convertedAmount
            );

            const amountInFloat = parseFloat(
                removeNonNumeric(
                    amountInDecimal
                )
            );

            const textPrefix = convertedAmount > amountInFloat
                ? "~"
                : "";

            const amountText = _notEmpty(amountInFloat)
                ? `${textPrefix}${amountInDecimal} `
                : "";

            setButtonText(
                `${FUNCTION_NAME} ${amountText} ${token.name}`
            );
        }

        if (borrowToken.error && isLoading) {

            setIsLoading(false);

            showToast(
                `Transaction cancelled by user in wallet.`,
                {
                    type: "warning"
                }
            );

            setTransactionHashLink("");

            const amountInDecimal = toPrecision(
                convertedAmount
            );

            const amountInFloat = parseFloat(
                removeNonNumeric(
                    amountInDecimal
                )
            );

            // @TODO: get rid of duplicate
            const textPrefix = convertedAmount > amountInFloat
                ? "~"
                : "";

            const amountText = _notEmpty(amountInFloat)
                ? `${textPrefix}${amountInDecimal} `
                : "";

            setButtonText(
                `${FUNCTION_NAME} ${amountText} ${token.name}`
            );
        }

        if (borrowToken.isConfirming && _no(transactionHashLink)) {
            setIsLoading(true);
            setButtonText(
                BORROWING_TEXT
            );
            setTransactionHashLink(
                `${linkBase}/tx/${borrowToken.hash}`
            );
        }

        if (borrowToken.isConfirmed && isLoading) {

            setIsLoading(false);
            const transactionLink = `${linkBase}/tx/${borrowToken.hash}`;

            setTransactionHashLink(
                transactionLink
            )

            setFinishBorrow(true);

            /*
            const toastContent = toastNotification.generateLinkContent(
                transactionLink,
                `${BORROW_SUCCESS_MESSAGE} ${CLICK_TO_VIEW}`
            );
            */

            /*
            toastNotification.showToast(
                borrowed.status,
                toastContent
            );
            */

            showToast(
                `${BORROW_SUCCESS_MESSAGE}`,
                {
                    type: "success"
                }
            );

            setSuccessfullBorrow(
                true
            );

            setButtonText(
                DONE_TEXT
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [borrowToken]);

    const handleApprove = () => {

        if (buttonApproveText !== DEFAULT_APRROVE_TEXT) {
            return;
        }

        tokenApproval.approve({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonApproveText(
                    CONFIRM_TEXT
                );
            },
        });
    };

    useEffect(() => {

        if (tokenApproval.hasError && isLoading && buttonApproveText === APPROVING_TEXT) {
            setIsLoading(false);
            setButtonApproveText(
                DEFAULT_APRROVE_TEXT
            );
            handleError(
                tokenApproval.hasError
            );
        }

        if (tokenApproval.error && isLoading) {
            setIsLoading(false);
            setButtonApproveText(
                DEFAULT_APRROVE_TEXT
            );
            handleError(
                tokenApproval.error
            );
        }

        if (tokenApproval.isConfirming && isLoading) {
            setButtonApproveText(
                APPROVING_TEXT
            );
        }

        if (tokenApproval.isConfirmed && buttonApproveText === APPROVING_TEXT) {
            setIsLoading(false);
            setButtonApproveText(
                DONE_TEXT
            );

            const message = `Approved - You can borrow tokens now`;

            setIsApproved(true);

            showToast(message, {
                type: "success",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenApproval]);


    const handleError = (err) => {
        showToast(
            err.message,
            {
                type: "error"
            }
        );
    };

    let newBorrowUsedInUSD = parseFloat(toZeroIfExponential(summary.borrowFormatted)) + parseFloat(amountInUSD);

    let maxBorrowDisplay = toZeroIfSuperSmall(
        token.borrowBalanceFormatted
    );

    const borrowMaxInUSD = computeUSDAmount(
        token.decimals,
        token.borrowBalanceFormatted,
        tokenPrice
    );

    if (borrowMaxInUSD < 1) {
        maxBorrowDisplay = 0;
    }

    const handleQuickSelect = (option) => {
        let amount = option === "max" && parseFloat(token.borrowBalanceFormatted) > 0
            ? floorToDecimals(token.borrowBalanceFormatted, 6)
            : "";

        if (borrowMaxInUSD < 1) {
            return;
        }

        setAmount(
            amount
        );
    };

    useEffect(() => {
        if (isDefault) {
            const amountInDecimal = toPrecision(convertedAmount);
            const amountInFloat = parseFloat(
                removeNonNumeric(
                    amountInDecimal
                )
            );
            const textPrefix = convertedAmount > amountInFloat
                ? "~"
                : "";

            const amountText = _notEmpty(amountInFloat)
                ? `${textPrefix}${amountInDecimal} `
                : "";

            setButtonText(
                `${FUNCTION_NAME} ${amountText} ${token.name}`
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [convertedAmount]);

    const {
        estimatedGasUSD,
        estimatesGasFormatted
    } = borrowToken;

    const isTokenInsufficient = parseFloat(estimatesGasFormatted) > walletBalance
        && _no(isLoading)
        && _no(finishBorrow);

    const isInsufficient = parseFloat(convertedAmount) > parseFloat(token.borrowBalanceFormatted)
        && _no(isLoading)
        && _no(finishBorrow);

    let safeLimit = parseFloat(summary.safeLimit);
    if (borrowMaxInUSD < 1) {
        safeLimit = 0;
    }

    const showBorrowButton = isConnected && _no(showSupplyButton);
    const exceedSafeLimit = parseFloat(amountInUSD) > parseFloat(safeLimit);
    const borrowUsed = ((parseFloat(amountInUSD) + summaryBorrowLimit) / parseFloat(maxLimit)) * 100;

    const disableApprove = isInsufficient
        || isLoading
        || isTokenInsufficient;

    const disabledSubmit = _no(amount)
        || _no(preApproved)
        || parseFloat(amount) === 0.00
        || isInsufficient
        || isTokenInsufficient
        || isLoading
        || exceedSafeLimit
        || isFetchingData;

    let borrowButtonText = buttonText;

    if (buttonText !== DONE_TEXT) {
        if (_no(amount)) {
            borrowButtonText = `Enter ${token.name} amount`;
        } else if (isInsufficient) {
            borrowButtonText = ` Borrow Limit Exceeded`;
        } else if (isTokenInsufficient) {
            borrowButtonText = INSUFFICIENT_COST;
        } else if (exceedSafeLimit) {
            borrowButtonText = `Borrow Limit Exceeded`;
        }
    }

    // console.log(borrowMaxInUSD, 'borrowMaxInUSD');
    // console.log(toZeroIfExponential(summary.borrowFormatted), 'parseFloat(summary.borrowFormatted)');
    // console.log(parseFloat(amountInUSD), 'parseFloat(amountInUSD)');


    return (
        <>
            <div>
                {finishBorrow &&
                    <div className={styles.successWrapper}>
                        <SuccessPanel />
                        <div className={styles.text}>
                            {successfullBorrow
                                ? BORROW_SUCCESS_MESSAGE
                                : BORROW_FAILED_MESSAGE
                            }
                        </div>
                    </div>
                }
                {_no(finishBorrow) &&
                    <>
                        <div className={cn("title-blue", styles.title)}>
                            {FUNCTION_NAME} {token.name}
                        </div>
                        <div className={styles.wrapperTitle}>
                            Borrow Amount
                            {/*<Icon name="info" size="14" />*/}
                        </div>
                        <TokenAmountInput
                            token={token}
                            disabled={isLoading}>
                            <TokenAmountInput.Input
                                amount={amount}
                                onChange={setAmount}
                                showClearButton={amount && _no(isLoading)}
                                onClearButtonClick={() => handleQuickSelect("clear")}
                            />
                            <TokenAmountInput.Details
                                amountInUsd={amountInUSD}
                                balance={maxBorrowDisplay}
                                onClick={() => handleQuickSelect("max")}>
                                Max {FUNCTION_NAME}
                            </TokenAmountInput.Details>
                        </TokenAmountInput>
                        <div className={styles.wrapper}>
                            <div className={styles.row}>
                                <div className={cn(
                                    styles.col,
                                    styles.item,
                                    // styles.mbAPY
                                )}>
                                    <div className={styles.label}>Borrow APY</div>
                                    <div className={"status-purple-dark"}>{
                                        formatDecimals(
                                            predictedAPY.borrowPredictedAPY || token.borrowRateFormatted
                                        )
                                    }%</div>
                                </div>
                            </div>
                            <div className={cn(
                                styles.row,
                                styles.mbAPY
                            )}>
                                <div className={cn(
                                    styles.col,
                                    styles.item
                                )}>
                                    <div className={styles.label}>Available Funds</div>
                                    <div className={styles.value}>{toPrecision(token.poolFormatted)} {token.name}</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.wrapperTitle}>
                            Borrowing Impact
                        </div>
                        {!showSupplyButton && (
                        <div className={styles.wrapper}>
                            <div className={cn(
                                styles.row,
                                styles.mbAPY
                            )}>
                                <div className={cn(styles.col, styles.item)}>
                                    <div className={styles.label}>Borrow Limit</div>
                                    <div className={styles.value}>${formatMoney(toZeroIfBelowOne(summary.limitFormatted))}</div>
                                </div>
                            </div>
                            <div className={cn(
                                styles.row,
                                styles.mbAPY
                            )}>
                                <div className={cn(styles.col, styles.item, styles.icongo)}>
                                    <div className={styles.label}>Borrow Balance</div>
                                    <div className={styles.value}>
                                    {formatMoney(
                                        toZeroIfBelowOne(summary.borrowFormatted),
                                        true
                                    )}
                                    <Icon className={styles.icongo} name="arrow-right" size="20" />
                                    {formatMoney(
                                        toZeroIfExponential(newBorrowUsedInUSD),
                                        true
                                    )}
                                    </div>
                                </div>
                            </div>
                            <div className={cn(
                                styles.row,
                                styles.borrowLine,
                                styles.mbAPY
                            )}>
                                <LinearBar
                                    height={7}
                                    width={borrowUsed}
                                    backColor="#31363C"
                                    fill="#00b0aa"
                                    limit={summary.percentageCap}
                                    limitColor="#FF6A55"
                                />
                            </div>
                        </div>
                        )}
                        {!showSupplyButton && (
                        <TransactionCost
                            nftId={nftId}
                            isAave={token.isAavePool}
                            showApproval={showApproval}
                            value={showApproval ? tokenApproval.estimatedGasUSD : estimatedGasUSD}
                            amount={amount}
                        />
                        )}
                    </>
                }
                <div className={styles.foot}>
                    {showSupplyButton &&
                        <>
                            <div className={styles.error} >
                                You need to lend funds first
                            </div>
                            <button
                                className={cn(
                                    "button-small",
                                    "button-stroke",
                                    "button-transparent",
                                    styles.button
                                )}
                                onClick={onSupply}
                            >
                                <span>Open {token.name} Lending</span>
                            </button>
                        </>
                    }
                    {showBorrowButton &&
                        <>
                            {showApproval && (
                                <button
                                    className={cn(
                                        "button-small",
                                        styles.button,
                                        styles.approve,
                                        {
                                            [styles.active]: amount,
                                            disabled: disableApprove
                                        }
                                    )}
                                    onClick={handleApprove}
                                >
                                    {buttonApproveText === APPROVING_TEXT && <Loader className={styles.loader} />}
                                    <span>{buttonApproveText}</span>
                                    {/* <Icon name="info" size="20" /> */}
                                </button>
                            )}
                            <button
                                className={cn(
                                    "button-small",
                                    styles.button,
                                    {
                                        disabled: disabledSubmit && buttonText !== DONE_TEXT,
                                        "button-stroke": showApproval,
                                        [styles.stroke]: showApproval
                                    }
                                )}
                                onClick={handleBorrow}
                            >
                                {(buttonText === BORROWING_TEXT || isFetchingData)
                                    && <Loader className={styles.loader} />}
                                <span>
                                    {isFetchingData
                                        ? FETCHING_DATA
                                        : borrowButtonText
                                    }
                                </span>
                            </button>
                            {transactionHashLink &&
                                <a
                                    className={cn(
                                        "button-small",
                                        "button-stroke",
                                        "button-transparent",
                                        styles.button
                                    )}
                                    href={transactionHashLink}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    View Transaction Details
                                </a>
                            }
                        </>
                    }
                    {_no(isConnected) &&
                        <button
                            className={cn(
                                "button-small",
                                styles.button
                            )}
                            onClick={() => {
                                // open()
                                login();
                            }}
                        >
                            <Icon name="profile-circle" size="20" />
                            <span>Connect Account</span>
                        </button>
                    }
                </div >
            </div >
            <TooltipGlobal />
        </>
    );
};

export default Borrow;
