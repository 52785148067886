// import { useState } from "react";
import { useReadContract } from "wagmi";

const useWiseStakeNft = ({
    config,
    nftId,
}) => {

    const { chainId, wiseNftStakes } = config;

    const { data: stakeId, isFetching, refetch: refetchStakeNft } = useReadContract({
        chainId,
        address: wiseNftStakes?.address,
        abi: wiseNftStakes?.abi,
        functionName: "NFTStake",
        args: [
            nftId
        ],
        query: {
            enabled: nftId,
        }
    });

    return {
        refetchStakeNft,
        stakeId: stakeId,
        isFetching: isFetching
    };
};

export default useWiseStakeNft;
