import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import App from "./App";
import { arbitrum, mainnet } from "wagmi/chains";
import { http } from "wagmi";
// import { WagmiProvider } from "wagmi";
import { store } from "./redux/store";
import { Buffer } from "buffer";
import Scrollbar from "./components/Scrollbar";
import { Crisp } from "crisp-sdk-web";
import { StrictMode } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PrivyProvider } from '@privy-io/react-auth';
import { createConfig } from '@privy-io/wagmi';
import { WagmiProvider } from '@privy-io/wagmi';
import { ToastContainer, Bounce } from "react-toastify";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"

if (typeof window !== "undefined") {
    window.Buffer = Buffer;
}

const token = "3dc96a65-2f4b-48dc-8a56-062b2aae0eb4";
Crisp.configure(token);

const config = createConfig({
    chains: [
        mainnet,
        arbitrum
    ],
    transports: {
        [mainnet.id]: http("https://mainnet.infura.io/v3/1bffd3c056ee47f4ad9c4e004b6f1853"),
        [arbitrum.id]: http("https://arbitrum-mainnet.infura.io/v3/1bffd3c056ee47f4ad9c4e004b6f1853"),
    },
});

// Create a QueryClient instance
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <StrictMode>
        <QueryClientProvider client={queryClient}>
        <PrivyProvider
            appId="cm5t9ognj0d2ipcarm9001xxt"
            config={{
                "loginMethods": [
                    "wallet",
                    "email",
                    "google",
                    "apple",
                    "discord",
                    "github",
                    // "sms",
                ],
                "fundingMethodConfig": {
                    "moonpay": {
                    "useSandbox": true
                    }
                },
                "embeddedWallets": {
                    "createOnLogin": "users-without-wallets",
                    "requireUserPasswordOnCreate": false,
                    "showWalletUIs": true
                },
                "mfa": {
                    "noPromptOnMfaRequired": false
                },
                // Customize Privy's appearance in your app
                "appearance": {
                    "theme": "#222224",
                    "accentColor": "#00b0aa",
                    "logo": "https://www.wise.one/images/networks/wiseone-simple.jpg",
                    "walletChainType": "ethereum-only"
                },
                // Create embedded wallets for users who don't have a wallet
            }}
            >
            <WagmiProvider config={config}>
                <Provider store={store}>
                    <ToastProvider newestOnTop={true} placement="bottom-right">
                        <SkeletonTheme baseColor="#1A1D1F" highlightColor="#444">
                            <Scrollbar isPage={true}>
                                <ToastContainer
                                    position="top-center"
                                    autoClose={5000}
                                    className="desaturated"
                                    closeButton={false}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick={false}
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable={false}
                                    pauseOnHover
                                    theme="dark"
                                    transition={Bounce}
                                />
                                <App />
                                <Analytics/>
                                <SpeedInsights/>
                            </Scrollbar>
                        </SkeletonTheme>
                    </ToastProvider>
                </Provider>
            </WagmiProvider>
            </PrivyProvider>
        </QueryClientProvider>
    </StrictMode>
);
