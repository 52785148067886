import { useEffect } from "react";
import { useReadContract, useBlockNumber } from "wagmi";
import { useQueryClient } from '@tanstack/react-query'

/*
import {
    DEFAULT_DECIMALS,
    ERROR_NFTIDS,
    ERROR_TRANSACTION
} from "../utils/config";
 */

// import { formatUnits, getAbiItem } from "viem";

const useGetFarmPosition = ({
    address,
    chainId,
    // refetch,
    farm
}) => {

    const queryClient = useQueryClient();
    const { data: blockNumber } = useBlockNumber({
        watch: true
    });

    /*
    if (farm?.contractConfig?.address === "0xB94D3bAA5C5cb154cCe5C59aBeA077317516030B") {
        if (address === "0xC167b6c615ccABf9465C8Ad1f0B3b5854C555673") {
            farm.contractConfig.address = "0x929e41cfDE855bfB43d4e19F5000187D7B127788"
        }
        if (address === "0x1826c38dfe6e37F47ae7f2229C5187cDc13B4D40") {
            farm.contractConfig.address = "0x929e41cfDE855bfB43d4e19F5000187D7B127788"
        }
    }*/

    const { data: farmNftId, isLoading: loading, queryKey } = useReadContract({
        chainId,
        abi: farm?.contractConfig?.abi,
        address: farm?.contractConfig?.address,
        functionName: "reservedKeys",
        args: [
            address
        ],
        allowFailure: true,
        // watch: false,
        query: {
            enabled: true,
        },
    });

    useEffect(() => {
        queryClient.invalidateQueries({
            queryKey
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // userAccount,
        blockNumber,
        queryClient,
        chainId
    ]);

    return {
        isFetching: loading,
        farmNftId
    };
};

export default useGetFarmPosition;
