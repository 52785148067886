import {
    useWriteContract,
    useWalletClient,
    useWaitForTransactionReceipt
} from "wagmi";

export const useScrapeInterest = ({
    config,
    stakeId,
}) => {

    const { data: signer } = useWalletClient();
    const { chainId, WiseTokenContract: contract } = config;

    const {
        error,
        data: hash,
        writeContract,
        // writeContractAsync,
        isPending,
    } = useWriteContract();

    const scrapeInterest = ({
        onConfirmation,
        // onError
    }) => {
        onConfirmation?.();

        const args = [
            stakeId,
            0
        ];

        writeContract({
            chainId,
            abi: contract?.abi,
            address: contract?.address,
            signerOrProvider: signer,
            // gas: gasLimitFormatted,
            functionName: "scrapeInterest",
            args: args,
            query: {
                enabled: stakeId,
            }
        });
    };

    const {
        isError: hasError,
        isLoading: isConfirming,
        isSuccess: isConfirmed
    } = useWaitForTransactionReceipt({
        hash,
    });

    return {
        hash,
        error,
        hasError,
        isPending,
        isConfirming,
        isConfirmed,
        scrapeInterest,
        isLoading: isConfirming,
    };
};
