import React, { useState } from "react";
import cn from "classnames";
import { _no } from "../../utils";
import styles from "./Product.module.sass";
import Control from "./Control";
// import Icon from "../Icon";
import Checkbox from "../Checkbox";
import { Link } from "react-router-dom";

const Product = ({
    className,
    item,
    value,
    onChange,
    released,
    withoutСheckbox,
}) => {
    const [visible, setVisible] = useState(false);

    const handleClick = () => {
        onChange();
        setVisible(!visible);
    };

    return (
        <div
            className={cn(styles.product, className, {
                [styles.active]: visible,
                [styles.dimmed]: item.active === false
            })}
        >
            {item.active && !item.externalLink && (
                <Link onClick={() => {
                    document.getElementById('inner').scrollIntoView();
                }} to={`${item.active ? item.link : '#'}`} className={styles.link}>
                    <div className={styles.preview}>
                        {_no(withoutСheckbox) && (
                            <Checkbox
                                className={styles.checkbox}
                                classCheckboxTick={styles.checkboxTick}
                                value={value}
                                onChange={() => handleClick()}
                            />
                        )}
                        {item.active && (item.icon || item.customIcon) && (
                            <Control
                                icon={item.icon}
                                customIcon={item.customIcon}
                                className={styles.control}
                            />
                        )}
                        <img srcSet={`${item.image2x} 2x`} src={item.image} alt="Product" />
                    </div>
                    <div className={styles.line}>
                        <div className={styles.title}>
                            {item.product}
                        </div>
                        {(item.price > 0 || item.forced || item.active) ? (
                            <div className={styles.empty}>{item.price}</div>
                        ) : (
                            <div className={styles.empty}>Next Release</div>
                        )}
                    </div>
                </Link>
            )}
            {item.active && item.externalLink && (
                <a href={item.externalLink} target="_blank" rel="noreferrer" className={styles.link}>
                    <div className={styles.preview}>
                        {_no(withoutСheckbox) && (
                            <Checkbox
                                className={styles.checkbox}
                                classCheckboxTick={styles.checkboxTick}
                                value={value}
                                onChange={() => handleClick()}
                            />
                        )}
                        {item.active && item.icon && (
                            <Control icon={item.icon} className={styles.control} />
                        )}
                        <img srcSet={`${item.image2x} 2x`} src={item.image} alt="Product" />
                    </div>
                    <div className={styles.line}>
                        <div className={styles.title}>
                            {item.product}
                        </div>
                        {(item.price > 0 || item.forced || item.active) ? (
                            <div className={styles.empty}>{item.price}</div>
                        ) : (
                            <div className={styles.empty}>Next Release</div>
                        )}
                    </div>
                </a>
            )}
            {!item.active && (
                <>
                <div className={
                    cn(
                        styles.preview,
                        styles.disabled
                    )}>
                    <img srcSet={`${item.image2x} 2x`} src={item.image} alt="Product" />
                </div>
                <div className={styles.line}>
                    <div className={styles.title}>
                        {item.product}
                    </div>
                    {(item.price > 0 || item.forced || item.active) ? (
                        <div className={styles.empty}>{item.price}</div>
                    ) : (
                        <div className={styles.empty}>Next Release</div>
                    )}
                </div>
                </>
            )}
        </div>
    );
};

export default Product;
