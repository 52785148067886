// import React, { useState } from "react";
import React from "react";
import cn from "classnames";
import styles from "./NavSwitch.module.sass";
import Item from "./Item";
import TooltipGlobal from "../TooltipGlobal";

const NavSwitch = ({
    nav,
    selectedValue,
    className,
}) => {

    const navInitialIndex = nav.findIndex(
        item => item.value === selectedValue
    );

    const activeIndex = selectedValue
        ? navInitialIndex
        : 0;

    /*
    const [activeIndex, setActiveIndex] = useState(selectedValue
        ? navInitialIndex
        : 0
    );
    */

    return (
        <div className={styles.nav}>
            {nav.map((x, index) => (
                <Item
                    disabled={x.disabled ?? false}
                    className={cn(
                        styles.item,
                        className,
                        {
                            [styles.disabled]: x.disabled,
                            [styles.active]: index === activeIndex,
                        }
                    )}
                    key={index}
                    onActive={() => {
                        // setActiveIndex(index);
                    }}
                    item={x}
                />
            ))}
            <TooltipGlobal />
        </div>
    );
};

export default NavSwitch;
