import React, { useState } from "react";
import cn from "classnames";
import styles from "./Settings.module.sass";
import TooltipGlobal from "../../components/TooltipGlobal";
// import Dropdown from "../../components/Dropdown";
import ProfileInformation from "./ProfileInformation";
// import Login from "./Login";
import Notifications from "./Notifications";
import { useAccount } from "wagmi";
// import Payment from "./Payment";

const Settings = ({preSelectIndex}) => {

    const { address } = useAccount();

    const navigation = [
        {
            title: "Interface Preferences",
            action: () => {
                // scrollToNotifications.current.scrollIntoView({ behavior: "smooth" }),
            }
        },
        /*{
            title: "Account",
            action: () =>
                // scrollToLogin.current.scrollIntoView({ behavior: "smooth" }),
        },*/
        /*{
            title: "Payment",
            action: () =>
                scrollToPayment.current.scrollIntoView({ behavior: "smooth" }),
        },*/
    ];

    if (address) {
        navigation.push({
            title: "Profile Infomration",
            action: () => {
                // scrollToProfile.current.scrollIntoView({ behavior: "smooth" })
            },
        });
    }

    const options = [];
    navigation.map((x) => options.push(x.title));

    const [activeIndex, setActiveIndex] = useState(preSelectIndex || 0);
    // const scrollToProfile = useRef(null);
    // const scrollToLogin = useRef(null);
    // const scrollToNotifications = useRef(null);
    // const scrollToPayment = useRef(null);

    const handleClick = (x, index) => {
        setActiveIndex(index);
        // x.action();
    };
    return (
        <>
            <div className={styles.settings}>
                <div className={styles.menu}>
                    {navigation.map((x, index) => (
                        <button
                            className={cn(styles.button, {
                                [styles.active]: activeIndex === index,
                            })}
                            key={index}
                            onClick={() => handleClick(x, index)}
                        >
                            {x.title}
                        </button>
                    ))}
                </div>
                <div className={styles.wrapper}>
                    <div className={styles.list}>
                        {activeIndex === 1 && address && (
                            <div
                                className={cn(styles.item, {
                                    [styles.active]: activeIndex === 1,
                                })}
                            >
                                <div className={styles.anchor}></div>
                                <ProfileInformation address={address} />
                            </div>
                        )}
                        {/*<div
                            className={cn(styles.item, {
                                [styles.active]: activeTab === options[1],
                            })}
                        >
                            <div className={styles.anchor} ref={scrollToLogin}></div>
                            <Login />
                        </div>*/}
                        {activeIndex === 0 && (
                            <div
                                className={cn(styles.item, {
                                    [styles.active]: activeIndex === 0,
                                })}
                            >
                                <div className={styles.anchor}></div>
                                <Notifications />
                            </div>
                        )}
                        {/*<div
                            className={cn(styles.item, {
                                [styles.active]: activeTab === options[3],
                            })}
                        >
                            <div className={styles.anchor} ref={scrollToPayment}></div>
                            <Payment />
                        </div>*/}
                    </div>
                    {/*<button className={cn("button", styles.button)}>Save</button>*/}
                </div>
            </div>
            <TooltipGlobal />
        </>
    );
};

export default Settings;
