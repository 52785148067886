import { useEffect, useState } from "react";
import { useBalance, useBlockNumber } from "wagmi";
import { useQueryClient } from '@tanstack/react-query'
import { formatUnits } from "viem";

import {
    DEFAULT_DECIMALS,
    ERROR_BALANCE_WALLET,
} from "../utils/config";

import { _getNotETHValue, _notEmpty } from "../utils";

const useWalletBalance = ({
    chainId,
    userAccount,
    token = null
}) => {

    const [walletBalance, setWalletBalance] = useState(0);

    const { data, error, isError, isLoading, queryKey } = useBalance({
        chainId,
        address: userAccount,
        token: _getNotETHValue(
            token?.name,
            token?.address
        ),
        query: {
            enabled: _notEmpty(chainId) && userAccount
        }
    });

    const queryClient = useQueryClient();
    const { data: blockNumber } = useBlockNumber({ watch: true })

    useEffect(() => {
        queryClient.invalidateQueries({
            queryKey
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        blockNumber,
        queryClient
    ]);

    const handleSetWalletBalance = (data) => {
        setWalletBalance(
            formatUnits(
                data.value,
                DEFAULT_DECIMALS
            )
        );
    }

    const handleSetError = (error) => {
        console.error(
            ERROR_BALANCE_WALLET,
            error
        );
    }

    useEffect(() => {
        if (data) {
            handleSetWalletBalance(data);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            handleSetError(error);
        }
    }, [error]);

    return { isError, walletBalance, isFetching: isLoading };
};

export default useWalletBalance;
