import { useMemo } from "react";
import { useReadContract } from "wagmi";

/**
 * Custom hook for fetching and paginating stake data with filtering
 * @param {Object} options - Hook options
 * @param {Object} options.config - Contract configuration
 * @param {string} options.address - User wallet address
 * @param {number} options.perPage - Items per page
 * @param {number} options.page - Current page (0-indexed)
 * @param {string} options.filter - Filter type: 'all', 'transferable', or 'scrapable'
 */
const useWiseStakes = ({
    config,
    address,
    perPage,
    page,
    filter = "all", // Default filter is "all"
}) => {
    const { chainId, WiseTokenContract, wiseNftStakes } = config;

    // Determine if we should fetch NFT stakes based on filter
    const shouldFetchNfts = filter === "all" || filter === "transferable";

    // Determine if we should fetch regular stakes based on filter
    const shouldFetchRegular = filter === "all" || filter === "scrapable";

    // Fetch regular stake count
    const { data: stakeCount, isFetching: fetchingCount, refetch: refetchStakeCount } = useReadContract({
        chainId,
        address: WiseTokenContract?.address,
        abi: WiseTokenContract?.abi,
        functionName: "stakeCount",
        args: [address],
        query: {
            enabled: !!address && shouldFetchRegular,
            onError: (error) => {
                console.log(error, 'error')
            }
        },
    });

    // Fetch NFT stake count
    const { data: nftCount, isFetching: fetchingNftCount, refetch: refetchNftCount } = useReadContract({
        chainId,
        address: wiseNftStakes?.address,
        abi: wiseNftStakes?.abi,
        functionName: "balanceOf",
        args: [address],
        query: {
            enabled: !!address && shouldFetchNfts,
            onError: (error) => {
                console.log(error, "error");
            }
        },
    });

    // Parse the counts and ensure they're treated as numbers
    const parsedNftCount = (parseInt(nftCount) || 0) * (shouldFetchNfts ? 1 : 0);
    const parsedStakeCount = (parseInt(stakeCount) || 0) * (shouldFetchRegular ? 1 : 0);
    const totalItems = parsedNftCount + parsedStakeCount;
    const totalPages = Math.ceil(totalItems / perPage);

    // Fetch all NFT stakes
    const { data: nftStakes, isFetching: fetchingNftStakes, refetch: refetchNftStakes } = useReadContract({
        chainId,
        address: wiseNftStakes?.address,
        abi: wiseNftStakes?.abi,
        functionName: "walletOfOwner",
        args: [address],
        query: {
            enabled: !!address && shouldFetchNfts,
            onError: (error) => {
                console.log(error, "error");
            }
        },
    });

    // Calculate pagination for mixed stake types
    const paginationParams = useMemo(() => {
        // For transferable filter (NFT stakes only)
        if (filter === "transferable") {
            return {
                nftStartIndex: page * perPage,
                nftEndIndex: Math.min((page + 1) * perPage, parsedNftCount),
                regularStartIndex: 0,
                regularToFetch: 0
            };
        }

        // For scrapable filter (regular stakes only)
        if (filter === "scrapable") {
            return {
                nftStartIndex: 0,
                nftEndIndex: 0,
                regularStartIndex: page * perPage,
                regularToFetch: perPage
            };
        }

        // For "all" filter - we need to handle the mixed case
        // First, determine what should be on the current page

        // Calculate total items that should be shown across all previous pages
        const itemsBeforePage = page * perPage;

        // If all items before this page are less than NFT count, we'll show some NFTs on this page
        if (itemsBeforePage < parsedNftCount) {
            // Some NFTs will be on this page
            const nftStartIndex = itemsBeforePage;
            const nftEndIndex = Math.min((page + 1) * perPage, parsedNftCount);
            const nftsOnThisPage = nftEndIndex - nftStartIndex;

            // If we have room for regular stakes on this page
            const regularToFetch = Math.min(perPage - nftsOnThisPage, parsedStakeCount);
            const regularStartIndex = 0;

            return {
                nftStartIndex,
                nftEndIndex,
                regularStartIndex,
                regularToFetch
            };
        } else {
            // All NFTs are on previous pages, only regular stakes on this page
            const regularStartIndex = itemsBeforePage - parsedNftCount;
            const regularToFetch = Math.min(perPage, parsedStakeCount - regularStartIndex);

            return {
                nftStartIndex: 0,
                nftEndIndex: 0,
                regularStartIndex,
                regularToFetch: Math.max(0, regularToFetch)
            };
        }
    }, [filter, page, perPage, parsedNftCount, parsedStakeCount]);

    // Fetch regular stakes with calculated pagination
    const { data: regularStakes, isFetching: fetchingStakes, refetch: refetchStakes } = useReadContract({
        chainId,
        address: WiseTokenContract?.address,
        abi: WiseTokenContract?.abi,
        functionName: "stakesPagination",
        args: [
            address,
            paginationParams.regularStartIndex,
            paginationParams.regularToFetch
        ],
        query: {
            enabled: !!address && paginationParams.regularToFetch > 0 && shouldFetchRegular,
            onError: (error) => {
                console.log(error, "error")
            }
        },
    });

    // Combine NFT stakes and regular stakes for the current page
    const finalStakes = useMemo(() => {
        // Initialize with empty array
        let result = [];

        // Add NFT stakes if needed
        if (shouldFetchNfts && nftStakes && paginationParams.nftStartIndex < paginationParams.nftEndIndex) {
            result = [
                ...result,
                ...nftStakes.slice(paginationParams.nftStartIndex, paginationParams.nftEndIndex)
            ];
        }

        // Add regular stakes if any
        if (shouldFetchRegular && regularStakes && regularStakes.length > 0) {
            result = [...result, ...regularStakes];
        }

        // Ensure we don't return more than perPage items
        return result.slice(0, perPage);
    }, [
        shouldFetchNfts,
        shouldFetchRegular,
        nftStakes,
        regularStakes,
        paginationParams.nftStartIndex,
        paginationParams.nftEndIndex,
        perPage
    ]);

    // For debugging - can be removed in production
    /*
    console.log({
        page,
        perPage,
        parsedNftCount,
        parsedStakeCount,
        totalItems,
        paginationParams,
        finalStakesLength: finalStakes.length
    });
    */

    return {
        refetchStakes,
        refetchStakeCount,
        refetchNftStakes,
        refetchNftCount,
        stakes: finalStakes,
        nftCount: parsedNftCount,
        stakeCount: parsedStakeCount,
        totalItems,
        totalPages,
        isFetching:
            (shouldFetchRegular && fetchingCount) ||
            (shouldFetchRegular && fetchingStakes) ||
            (shouldFetchNfts && fetchingNftCount) ||
            (shouldFetchNfts && fetchingNftStakes),
    };
};

export default useWiseStakes;