import React from "react";
import styles from "./Parameter.module.sass";
import cn from "classnames";
// import Tooltip from "../../../../components/TooltipGlobal";

const Parameter = ({ item, isMature }) => {

    return (
        <div className={styles.parameter}>
            <div className={styles.title}>
                {item.title}
                {/*item.tooltip && (
                    <Tooltip
                        className={styles.tooltip}
                        title={item.tooltip}
                        icon="info-stroke"
                        place="top"
                    />
                )*/}
            </div>
            {item.content && (
                <div className={cn(
                    styles.content,
                    {
                        [styles.red]: parseInt(item.content) < 0,
                        [styles.green]: item?.content?.includes(`+`),
                    }
                )}>
                    {item.content}
                </div>
            )}
            {item.downloadedStatus &&
                ((isMature === true) ? (
                    <div className={styles.yes}>{item.downloadedStatus}</div>
                ) : (
                    <div className={styles.no}>{item.downloadedStatus}</div>
                ))}
            {item.price && (
                <div className={styles.price}>${item.price.toFixed(2)}</div>
            )}
        </div>
    );
};

export default Parameter;
