import { parseUnits } from "viem";
import {
    arbitrum,
    mainnet
    // goerli
} from "viem/chains";
import {
    APYViewerABIv11,
    AaveHubABIArbv11,
    AaveHubABIv10,
    ForwardingDepositUSDCABI,
    LPViewerABIv11,
    LRTfiPowerFarmApiRoutingABIv11,
    LRTfiPowerFarmApiRoutingStableABIv11,
    PositionNFTABIArbv11,
    PositionNFTABIv10,
    WiseOracleHubABI,
    WiseRewardsABI,
    aaveUSDCPendlePowerManagerABIv11,
    pendlePowerManagerABIv11,
    wiseLendingABIArbv11,
    wiseLendingABIv10,
    wiseSecurityABIArbv11,
    wiseSecurityABIv10,
    WiseNftABI,
    WISE_TOKEN_ABI,
    // APYViewerABIv10,
    // wstETHManagerABIv10,
    wstETHManagerABIv11,
} from './abis';

const MAINNET_CONTRACTS = [
    {
        version: 3,
        chainId: mainnet.id,
        linkBase: 'https://etherscan.io',
        wiseLendingContract: {
            address: "0x78190e4c7C7B2c2C3b0562F1f155a1FC2F5160CA",
            abi: wiseLendingABIv10
        },
        aaveHubContract: {
            address: "0x5b2E35d9dEB2962D05A5C7E91939169656DCd1Cd",
            abi: AaveHubABIv10
        },
        wiseSecurityContract: {
            address: "0x8EB1B69fB74C6019C16f43ae93F0fAD7CCB9A59d",
            abi: wiseSecurityABIv10
        },
        positionNFTContract: {
            address: "0xbEc57D7bA1FEf3f2352cD529c3B40cf2C4999529",
            abi: PositionNFTABIv10
        },
        LPViewerContract: {
            address: "0x929e41cfDE855bfB43d4e19F5000187D7B127788",
            abi: LPViewerABIv11
        },
        wstETHManagerContract: {
            address: "0xaA4Aef3De98A0336928e949A8Be405C1175Ac1Be",
            abi: wstETHManagerABIv11
        },
        pendlePowerManagerContract: {
            address: "0xbC7f75Dcce2947f2D6e4622a0E09185567E6e74f",
            abi: pendlePowerManagerABIv11
        },
        EthenaApiRoutingUsdc: {
            address: "0xE766d33CCf9dc14765Dc0DdE7Bc4DA27e62c0670",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        EthenaApiRoutingUsdc2: {
            address: "0x56F292Cd75De83D43e7A8D8D5E21677220c3289E",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        EthenaApiRoutingUsdc3: {
            address: "0x090dc39AC03A2a18a7804107bF8577aF0661Cd12",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        EthenaApiRoutingUsdt3: {
            address: "0x0591354a80e20c33539a03aF2F4a945830991920",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        EthenaApiRoutingDai3: {
            address: "0x37152209C2CfaCb93c8f26f4CBFd7b156834193B",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        WiseTokenContract: {
            address: "0x66a0f676479Cee1d7373f3DC2e2952778BfF5bd6",
            abi: WISE_TOKEN_ABI
        },
        EthenaApiRoutingUsdt: {
            address: "0x6730dc1E66d788e19fE7aD8935BeFD869aDD84d2",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        EthenaApiRoutingUsdt2: {
            address: "0x923446F62eDa47e12C50f4815De8042072440077",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        EthenaApiRoutingDai: {
            address: "0x705c632d2f36B49f2c819DC24c27308F1a0aFBEc",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        EthenaApiLidoRoutingETH: {
            address: "0xca56eE2Ab23006847ca0A05fEc891Ed17157c1a4",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        wiseRewards: {
            address: "0xC6cbfd25390Aa9AE331fCed429482Ac4B76Ee28c",
            abi: WiseRewardsABI
        },
        wiseNftStakes: {
            address: "0xf863A603501fcD30F56DfFCb6700A25eFFEFBe94",
            abi: WiseNftABI
        },
        APYViewerContract: {
            address: "0x505bf1bE04d9E1e2B6782e8B2Bf6922f21491850",
            abi: APYViewerABIv11
        },
        ForwardingDepositUSDCContract: {
            address: "0x062475feaac57A69be3afFd83b7Ea5b5b71ec3Ed",
            abi: ForwardingDepositUSDCABI
        },
        deploymentBlock: parseUnits(
            "19720035",
            0
        ),
        availablePools: [
            "ETH",
            "DAI",
            "USDC",
            "USDT",
            // "wstETH",
            // "sDAI",
            "WBTC",
            // "LINK"
        ],
        isTestMode: false,
        isDeprecated: false
    }
];

const ARBITRUM_CONTRACTS = [
    {
        version: 1,
        chainId: arbitrum.id,
        linkBase: 'https://arbiscan.io',
        wiseLendingContract: {
            address: "0x9034a49587bD2c1Af27598E0f04F30Db66C87Ebf",
            abi: wiseLendingABIArbv11
        },
        aaveHubContract: {
            address: "0x4A56DCd67E66102E6a877dE8BF2E903Df5E18978",
            abi: AaveHubABIArbv11
        },
        wiseSecurityContract: {
            address: "0x67dae107eCF474F0D5B7d8aD45490608a5AdbE2A",
            abi: wiseSecurityABIArbv11
        },
        positionNFTContract: {
            address: "0x6Cd8C86f5253F49c06FE913182E14DAD313298F9",
            abi: PositionNFTABIArbv11
        },
        wstETHManagerContract: {
            address: "0xaA4Aef3De98A0336928e949A8Be405C1175Ac1Be",
            abi: wstETHManagerABIv11
        },
        aaveUSDCPendlePowerManagerContract: {
            address: "0x14e1D0cae44CdFe985ecF2CF95C46aD016DAD5b5",
            // address: "0xAE6396577Fa2596cd71E822c85EA615FbD74F879", // old farm
            abi: aaveUSDCPendlePowerManagerABIv11
        },
        aaveUSDCDaiPendlePowerManagerContract: {
            address: "0x7cab0ad1c4f9d7ba367b439f51687c06ab9238d2",
            abi: aaveUSDCPendlePowerManagerABIv11
        },
        aaveUSDCUsdtPendlePowerManagerContract: {
            address: "0x0121e237Dc0e32d95104F099F354338567e05206",
            abi: aaveUSDCPendlePowerManagerABIv11
        },
        LRTfiPowerFarmContract: {
            address: "0x7A6a0E24136AfB14be7e184983D447af7AAa14d2",
            abi: pendlePowerManagerABIv11
        },
        LRTfiPowerFarmApiRoutingContract: {
            address: "0xDB9Af861aae3Dd6BeC86f5512d704AFc621722d5",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        LPViewerContract: {
            address: "0x60DB81b6D754b11eDaa49623bC0E4e498Cde3414",
            abi: LPViewerABIv11
        },
        LRTfiPowerFarmApiRoutingContract2: {
            address: "0x0cf0e21b094e73c3e12b2f838f40905d473289e5",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        LRTfiPowerFarmApiRoutingContractFixed: {
            address: "0xc0FedEeaA7291F30025D76b4000c3E23aFE4C82F",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        LRTfiPowerFarmApiRoutingContract2025: {
            address: "0x929e41cfDE855bfB43d4e19F5000187D7B127788",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        LRTfiPowerFarmApiRoutingContractOld: {
            address: "0xf432Dcdc0B1D33926c84541C1a24d4bEc1e5b353",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        LRTfiPowerFarmApiRoutingContract3: {
            address: "0x0bEd3E9422262C160Da4dD951DF9fE04509145e9",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        LRTfiPowerFarmApiRoutingContractUSDCFixed: {
            address: "0xcbE5F4E8a112F25C2F902714e3cBB7955F19Bb36",
            abi: LRTfiPowerFarmApiRoutingStableABIv11
        },
        LRTfiPowerFarmApiRoutingContractUSDCFixedNovember: {
            address: "0x3a421b2ed19c4fd69fca885d8485368ed1a7d074",
            abi: LRTfiPowerFarmApiRoutingStableABIv11
        },
        LRTfiPowerFarmApiRoutingContractUSDT: {
            address: "0xe3Cc473A0f540BaA31536F81f57D8F9feE14B31d",
            abi: LRTfiPowerFarmApiRoutingStableABIv11
        },
        LRTfiPowerFarmApiRoutingContractUSDTFixed: {
            address: "0x23849116B35258BF160D11D792B77691111E25AC",
            abi: LRTfiPowerFarmApiRoutingStableABIv11
        },
        LRTfiPowerFarmApiRoutingContractUSDTFixedNovember: {
            address: "0xc917fba41b5247ef115d4490e9b50feb37e28cb7",
            abi: LRTfiPowerFarmApiRoutingStableABIv11
        },
        LRTfiPowerFarmApiRoutingContractDAI: {
            address: "0xdd0Dc4056d027aab4A9e09C52b007d5682C24b2d",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        LRTfiPowerFarmApiRoutingContractDAIFixed: {
            address: "0xEf172D348b44dFf321Fb440e97A076971E915ddA",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        LRTfiPowerFarmApiRoutingContractDAIFixedNovember: {
            address: "0x3b3cf231d32a489c8a4652ca86e6abb11ff4cc7a",
            abi: LRTfiPowerFarmApiRoutingABIv11
        },
        APYViewerContract: {
            address: "0x83f3048267c939abfae1d054df10f0e1a86c2a8b",
            abi: APYViewerABIv11
        },
        KelpPowerFarmContract: {
            address: "0x68980206f8Cba9130101FC531b842fAEd71cD3F4",
            abi: pendlePowerManagerABIv11
        },
        WiseOracleHubContract: {
            address: "0x5E93f02b629EfdCf889e544126167709206b92a8",
            abi: WiseOracleHubABI
        },
        ForwardingDepositUSDCContract: {
            address: "0x062475feaac57A69be3afFd83b7Ea5b5b71ec3Ed",
            abi: ForwardingDepositUSDCABI
        },
        deploymentBlock: parseUnits(
            "184718510",
            0
        ),
        availablePools: [
            "ETH-AAVE",
            "DAI-AAVE",
            // "DAI",
            // "USDC",
            "USDC-AAVE",
            "USDT-AAVE",
            "wstETH",
        ],
        isTestMode: false,
        isDeprecated: false
    }
];

export const getContracts = (chainId, version) => {
    const contracts = {
        // [goerli.id]: TESTNET_CONTRACTS,
        [mainnet.id]: MAINNET_CONTRACTS,
        [arbitrum.id]: ARBITRUM_CONTRACTS
    };

    return contracts[chainId].find((item) => {
        return item.version === version;
    });
};

const CONTRACT_INPUTS = {
    "needCollateralState": {
        /*[goerli.id]: {
            // 8: true,
            9: false,
            10: false
        },
        */
        [mainnet.id]: {
            1: true,
            2: false,
            3: false,
        },
        [arbitrum.id]: {
            1: false,
        }
    }
};

export const getContractInputs = ({ name, chainId, version }) => {
    return CONTRACT_INPUTS[name][chainId][version];
};

const CONTRACT_METHODS = {
    "aaveTokenApproval": {
        /*[goerli.id]: {
            8: "getApproved",
        },
        */
        [mainnet.id]: {
            1: "getApproved",
            3: "getApprovedSoft",
        },
        [arbitrum.id]: {
            1: "getApprovedSoft",
        }
    }
};

export const getContractMethod = ({ name, chainId, version }) => {
    return CONTRACT_METHODS[name][chainId][version];
};

export const getLendingContract = (config, isAavePool) => {
    const {
        wiseLendingContract,
        aaveHubContract
    } = config;

    if (isAavePool) {
        return aaveHubContract;
    }

    return wiseLendingContract;
};

export const getSecurityContract = (config, isAavePool) => {
    const {
        wiseSecurityContract,
        aaveHubContract
    } = config;

    if (isAavePool) {
        return aaveHubContract;
    }

    return wiseSecurityContract;
};
