import React from "react";
// import cn from "classnames";
import styles from "./Promote.module.sass";
import TooltipGlobal from "../../components/TooltipGlobal";
import Overview from "./Overview";
import RecentPost from "./RecentPost";

const Promote = () => {
    return (
        <>
            <div className={styles.section}>
                <Overview className={styles.card} />
                <RecentPost />
            </div>
            <TooltipGlobal />
        </>
    );
};

export default Promote;
