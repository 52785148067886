import cn from "classnames";
import React, { useMemo } from "react";
// import { Link } from "react-router-dom";
import useConfig from "../../customHooks/useConfig";
import { _no } from "../../utils";
import { DEFI_LLAMA_BASE_LINK, DEFI_LLAMA_POOL_IDS } from "../../utils/config";
import Card from "../Card";
import Icon from "../Icon";
import styles from "./DataSource.module.sass";

const requests = [
    {
        content:
            `Additional statistic are also available on <strong>Defi Llama</strong> portal. Click the button below to check.`,
        icon: "plus-circle",
        fill: "#FF6A55",
        color: "#FFE7E4",
    },
];

const DataSource = ({ className, tokenName, title, classTitle }) => {

    const { chainId } = useConfig();

    const defiLLamaLink = useMemo(() => {
        return `${DEFI_LLAMA_BASE_LINK}/yields/pool/${DEFI_LLAMA_POOL_IDS[chainId][tokenName]}`;
    }, [tokenName, chainId]);

    return (
        <Card
            className={cn(styles.card, className)}
            title={title}
            classTitle={classTitle}
        >
            <div className={styles.section}>
                <div className={styles.list}>
                    {requests.map((x, index) => (
                        <div className={styles.item} key={index}>
                            <div className={styles.icon} style={{ backgroundColor: x.color }}>
                                <Icon name={x.icon} size="24" fill={x.fill} />
                            </div>
                            <div
                                className={styles.content}
                                dangerouslySetInnerHTML={{ __html: x.content }}
                            ></div>
                        </div>
                    ))}
                </div>
                <a
                    className={
                        cn("button-stroke",
                            styles.button,
                            {
                                disabled: _no(defiLLamaLink),
                            }
                        )
                    }
                    href={defiLLamaLink}
                    target="_blank"
                    rel="noreferrer"
                >
                    Check Defi Llama
                </a>
            </div>
        </Card>
    );
};

export default DataSource;
