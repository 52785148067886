import { useEffect } from "react";
import { useReadContract, useBlockNumber } from "wagmi";
import { useQueryClient } from '@tanstack/react-query'

const useGetFarmPositionData = ({
    chainId,
    farm,
    farmNftId
}) => {

    const queryClient = useQueryClient();
    const { data: blockNumber } = useBlockNumber({
        watch: true
    });

    const { data, isLoading, queryKey } = useReadContract({
        chainId,
        abi: farm?.contractConfig?.abi,
        address: farm?.contractConfig?.address,
        functionName: "farmData",
        args: [
            farmNftId
        ],
        allowFailure: true,
        // watch: false,
        query: {
            enabled: true,
        },
    });

    useEffect(() => {
        queryClient.invalidateQueries({
            queryKey
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // userAccount,
        blockNumber,
        queryClient,
        chainId
    ]);

    return {
        isFetching: isLoading,
        data
    };
};

export default useGetFarmPositionData;
