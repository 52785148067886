import React, { useState, useEffect } from "react";
import cn from "classnames";
import { formatUnits, parseUnits } from "viem";
import { fromHex } from "viem";
import { useAccount } from "wagmi";
import Icon from "../../../../../components/Icon";
import Loader from "../../../../../components/Loader";
import { useTokenAllowance } from "../../../../../customHooks/useTokenAllowance";
import { useRepayToken } from "../../../../../customHooks/useRepayToken";
import {
    removeNonNumeric,
    _no,
    formatMoneyDashed,
    formatMoney,
    toPrecision,
    _notEmpty,
    // displayWithLoader,
    // formatDecimals,
    computeUSDAmount,
    showToast,
    toZeroIfBelowOne
} from "../../../../../utils";
import {
    DONE_TEXT,
    FETCHING_DATA,
    TRY_AGAIN_TEXT,
    CONFIRM_TEXT,
    APPROVING_TEXT,
    REPAYING_TEXT,
    REPAY_FAILED_MESSAGE,
    REPAY_SUCCESS,
    // CLICK_TO_VIEW,
    APPROVE_TEXT,
    INSUFFICIENT_COST,
    ETH_TOKEN_NAME,
    SWING_PERCENT
} from "../../../../../utils/config";
import styles from "./Repay.module.sass";
import SuccessPanel from "../../../../../components/Modal/SuccessPanel";
import TransactionCost from "../../../../../components/TransactionCost";
import TooltipGlobal from "../../../../../components/TooltipGlobal";
import TokenAmountInput from "../../../../../components/TokenAmountInput";
import { useSelector } from "react-redux";
// import { useWeb3Modal } from "@web3modal/wagmi/react";

const FUNCTION_NAME = "Repay";

const Repay = ({
    config,
    nftId,
    token,
    tokenPriceData,
    summary,
    onConnect,
    onDone,
    walletBalance
}) => {

    const DEFAULT_APRROVE_TEXT = APPROVE_TEXT(token.name);
    const DEFAULT_TEXT = `${FUNCTION_NAME} ${token.name}`;

    const {
        isConnected,
    } = useAccount();
    // const { open } = useWeb3Modal();

    const address = useSelector(
        (state) => state.settings.address
    );

    const { linkBase } = config;

    const [amount, setAmount] = useState("");

    const [buttonApproveText, setButtonApproveText] = useState(
        DEFAULT_APRROVE_TEXT
    );

    const [buttonText, setButtonText] = useState(
        DEFAULT_TEXT
    );

    const [isLoading, setIsLoading] = useState(false);
    const [successfullRepay, setSuccessfullRepay] = useState();
    const [finishRepay, setFinishRepay] = useState(false);
    const [transactionHashLink, setTransactionHashLink] = useState("");
    const [allowance, setAllowance] = useState(0);

    const tokenPrice = tokenPriceData?.price || 0;

    const convertedAmount = removeNonNumeric(
        amount || 0
    );

    const repayAmount = parseUnits(
        convertedAmount,
        token.decimals
    );

    const tokenAllowance = useTokenAllowance({
        config,
        token,
        userAccount: address,
        amount: repayAmount,
    });

    const isFetchingData = _no(token.repayBalanceFormatted)
        || _no(tokenPrice)
        // || tokenAllowance.isFetching;

    const preApprovedDefault = token.needsApproval && _no(finishRepay)
        ? allowance && allowance >= repayAmount
        : true;

    const [preApproved, setPreApproved] = useState(
        preApprovedDefault
    );

    const formattedAmount = formatMoneyDashed(
        amount
    );

    const amountInUSD = computeUSDAmount(
        token.decimals,
        convertedAmount,
        tokenPrice
    );

    const maxLimit = parseFloat(
        summary.limitFormatted
    );

    const summaryBorrowLimit = parseFloat(
        summary.borrowFormatted
    );

    /*
    const borrowUsed = summaryBorrowLimit
        / maxLimit
        * 100;
    */

    let newBorrowUsed = (summaryBorrowLimit - parseFloat(amountInUSD))
        / parseFloat(maxLimit)
        * 100;

    if (newBorrowUsed < 0) {
        newBorrowUsed = 0;
    }

    const REPAY_SUCCESS_MESSAGE = `${REPAY_SUCCESS} ${formattedAmount} ${token.name}`;

    const isMax = parseFloat(convertedAmount) >= parseFloat(token.repayBalanceFormatted) * SWING_PERCENT;

    // console.log(isMax, 'isMax');

    let showApproval = amount
        && buttonText !== DONE_TEXT
        && buttonText !== REPAYING_TEXT
        && _no(preApproved)
        && _no(isFetchingData);

    const repayToken = useRepayToken({
        config,
        nftId,
        token,
        isMax,
        showApproval,
        amount: repayAmount,
    });

    useEffect(() => {

        if (repayToken.hasError && isLoading) {
            setIsLoading(false);
            handleError(
                `${REPAY_FAILED_MESSAGE}`,
                {
                    type: "warning",
                }
            );
            setTransactionHashLink("");

            const amountInDecimal = toPrecision(
                convertedAmount
            );

            const amountInFloat = parseFloat(
                removeNonNumeric(
                    amountInDecimal
                )
            );

            // @TODO: get rid of duplicate
            const textPrefix = convertedAmount > amountInFloat
                ? "~"
                : "";

            const amountText = _notEmpty(amountInFloat)
                ? `${textPrefix}${amountInDecimal} `
                : "";

            setButtonText(
                `${FUNCTION_NAME} ${amountText} ${token.name}`
            );
        }

        if (repayToken.error && isLoading) {
            setIsLoading(false);
            handleError(
                repayToken.error
            );
            setTransactionHashLink("");

            const amountInDecimal = toPrecision(
                convertedAmount
            );

            const amountInFloat = parseFloat(
                removeNonNumeric(
                    amountInDecimal
                )
            );

            // @TODO: get rid of duplicate
            const textPrefix = convertedAmount > amountInFloat
                ? "~"
                : "";

            const amountText = _notEmpty(amountInFloat)
                ? `${textPrefix}${amountInDecimal} `
                : "";

            setButtonText(
                `${FUNCTION_NAME} ${amountText} ${token.name}`
            );
        }

        if (repayToken.isConfirming && isLoading && !transactionHashLink) {
            setButtonText(
                REPAYING_TEXT
            );

            setTransactionHashLink(
                `${linkBase}/tx/${repayToken.hash}`
            );
        }

        if (repayToken.isConfirmed && isLoading) {

            setIsLoading(false);
            setFinishRepay(true);

            // const transactionLink = `${linkBase}/tx/${repayToken.hash}`;

            /*
            const message = repaid.status
                ? REPAY_SUCCESS_MESSAGE
                : REPAY_FAILED_MESSAGE;

            const toastContent = toastNotification.generateLinkContent(
                transactionLink,
                `${message} ${CLICK_TO_VIEW}`
            );

            toastNotification.showToast(
                repaid.status,
                toastContent
            );*/

            showToast(
                REPAY_SUCCESS_MESSAGE,
                {
                    type: "success",
                }
            );

            setSuccessfullRepay(
                true
            );

            setButtonText(
                DONE_TEXT
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [repayToken]);

    const isDefault = buttonText.includes(FUNCTION_NAME)
        && buttonText.includes(token.name);

    const handleRepay = () => {

        if (buttonText === DONE_TEXT) {
            onDone && onDone();
            return;
        };

        if (buttonText === TRY_AGAIN_TEXT) {
            setFinishRepay(false);
            setTransactionHashLink("");
            setButtonText(
                DEFAULT_TEXT
            );
        };

        if (_no(isDefault)) return;

        repayToken.repay({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonText(
                    CONFIRM_TEXT
                );
            },
        });
    };

    const handleApprove = () => {

        tokenAllowance.approve({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonApproveText(
                    CONFIRM_TEXT
                );
            }
        });
    };

    useEffect(() => {
        if (tokenAllowance.allowance) {
            setAllowance(
                tokenAllowance.allowance
            );

            if (tokenAllowance.allowance >= repayAmount) {
                setPreApproved(true);
            }

            if (tokenAllowance.allowance < repayAmount) {
                setPreApproved(false);
            }
        }
    }, [tokenAllowance.allowance, repayAmount]);

    useEffect(() => {

        if (tokenAllowance.hasError && isLoading) {
            setIsLoading(false);
            handleError(
                tokenAllowance.error
            );
            // setTransactionHash("");
            setButtonApproveText(
                DEFAULT_APRROVE_TEXT
            );
        }

        if (tokenAllowance.error && isLoading) {
            setIsLoading(false);
            handleError(
                tokenAllowance.error
            );
            // setTransactionHash("");
            setButtonApproveText(
                DEFAULT_APRROVE_TEXT
            );
        }

        if (tokenAllowance.isConfirming && isLoading) {
            setButtonApproveText(
                APPROVING_TEXT
            );
        }

        if (tokenAllowance.isConfirmed && buttonApproveText === APPROVING_TEXT) {

            setIsLoading(false);

            const { logs } = tokenAllowance.transactionData;

            const spendingCap = fromHex(
                logs[0].data,
                "bigint"
            );

            setAllowance(
                spendingCap
            );

            if (spendingCap >= repayAmount) {
                setPreApproved(true);
            }

            const spendingCapAmount = formatUnits(
                spendingCap,
                token.decimals
            );

            const formattedSpendingCapAmount = formatMoneyDashed(
                spendingCapAmount
            );

            showToast(
                `Approved tokens ${formattedSpendingCapAmount} ${token.name} - you can repay now`,
                {
                    type: "success",
                }
            );

            setButtonApproveText(
                DEFAULT_APRROVE_TEXT
            );


            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenAllowance]);

    const handleError = (err) => {
        showToast(
            err.message,
            {
                type: "warning",
            }
        );
    };

    let maximumRepay = parseFloat(token.balanceFormatted) < parseFloat(token.repayBalanceFormatted)
        ? token.balanceFormatted
        : token.repayBalanceFormatted;

    const maximimRepayInUSD = computeUSDAmount(
        token.decimals,
        maximumRepay,
        tokenPrice
    );

    if (parseFloat(maximimRepayInUSD) < 1) {
        maximumRepay = 0;
    }

    const handleQuickSelect = (option) => {

        const aboveZero = parseFloat(maximumRepay) > 0;

        const amount = option === "max" && aboveZero
            ? maximumRepay
            : "";

        setAmount(
            amount
        );
    };

    useEffect(() => {

            const amountInDecimal = toPrecision(
                convertedAmount
            );

            const amountInFloat = parseFloat(
                removeNonNumeric(
                    amountInDecimal
                )
            );

            // @TODO: get rid of duplicate
            const textPrefix = convertedAmount > amountInFloat
                ? "~"
                : "";

            const amountText = _notEmpty(amountInFloat)
                ? `${textPrefix}${amountInDecimal} `
                : "";

            setButtonText(
                `${FUNCTION_NAME} ${amountText} ${token.name}`
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [convertedAmount]);

    const {
        estimatedGasUSD,
        estimatesGasFormatted
    } = repayToken;

    const ethTokenAmount = token.name === ETH_TOKEN_NAME
        ? convertedAmount
        : 0;

    const totalTokenAmount = parseFloat(ethTokenAmount) + parseFloat(estimatesGasFormatted);
    const isTokenInsufficient = totalTokenAmount > walletBalance
        && _no(isLoading)
        && _no(finishRepay);

    const isInsufficient = parseFloat(convertedAmount) > parseFloat(token.repayBalanceFormatted)
        && _no(isLoading)
        && _no(finishRepay);

    const isInsufficientBalance = parseFloat(convertedAmount) > parseFloat(token.balanceFormatted)
        && _no(isLoading)
        && _no(finishRepay);

    const disableApprove = isInsufficient
        || isLoading
        || isTokenInsufficient
        || isInsufficientBalance;

    const disabledSubmit = isLoading
        || _no(amount)
        || parseFloat(amount) === 0.00
        || _no(preApproved)
        || isInsufficient
        || isTokenInsufficient
        || isInsufficientBalance
        || isFetchingData;

    let repayButtonText = buttonText;

    if (_no(amount)) {
        repayButtonText = `Enter ${token.name} amount`;
    } else if (isInsufficient) {
        repayButtonText = `Exceeded ${FUNCTION_NAME} Limit`;
    } else if (isTokenInsufficient) {
        repayButtonText = INSUFFICIENT_COST;
    } else if (isInsufficientBalance) {
        repayButtonText = `Insufficient ${token.name} Balance`;
    }

    let newLimit = parseFloat(summary.borrowFormatted) - parseFloat(amountInUSD);

    const showMoreThan = parseFloat(token.repayBalanceFormatted) > parseFloat(token.balanceFormatted)
        && formatMoney(token.balanceFormatted) === formatMoney(token.repayBalanceFormatted);

    const debtInUSD = computeUSDAmount(
        token.decimals,
        token.repayBalanceFormatted,
        tokenPrice
    );

    return (
        <>
            <div>
                {finishRepay &&
                    <div className={styles.successWrapper}>
                        <SuccessPanel />
                        {/* <div className={cn(
                        styles.icon,
                        {
                            [styles.failed]: _no(successfullRepay)
                        }
                    )}>
                        <Icon
                            name={successfullRepay
                                ? "check-circle"
                                : "close-circle"
                            }
                            size="50"
                        />
                    </div> */}
                        <div className={styles.text}>
                            {successfullRepay
                                ? REPAY_SUCCESS_MESSAGE
                                : REPAY_FAILED_MESSAGE
                            }
                        </div>
                    </div>
                }
                {_no(finishRepay) &&
                    <>
                        <div className={cn("title-green", styles.title)}>
                            {FUNCTION_NAME} {token.name}
                        </div>
                        <div className={styles.wrapperTitle}>
                            Repay Amount
                        </div>
                        <TokenAmountInput
                            token={token}
                            disabled={isLoading}>
                            <TokenAmountInput.Input
                                amount={amount}
                                onChange={setAmount}
                                showClearButton={amount && _no(isLoading)}
                                onClearButtonClick={() => handleQuickSelect("clear")}
                            />
                            <TokenAmountInput.Details
                                amountInUsd={amountInUSD}
                                balance={maximumRepay}
                                onClick={() => handleQuickSelect("max")}>
                                Max {FUNCTION_NAME}
                            </TokenAmountInput.Details>
                        </TokenAmountInput>
                        <div className={styles.wrapper}>
                            <div className={styles.row}>
                                <div className={cn(styles.col, styles.item)}>
                                    <div className={styles.label}>Your Debt</div>
                                    <div className={styles.value}>
                                        {showMoreThan === true
                                            ? ">"
                                            : ""
                                        }
                                        {formatMoney(debtInUSD < 1 ? 0 : token.repayBalanceFormatted)} {token.name}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={cn(styles.col, styles.item)}>
                                    <div className={styles.label}>Wallet Balance</div>
                                    <div className={styles.value}>
                                        {formatMoney(token.balanceFormatted)} {token.name}
                                    </div>
                                </div>
                            </div>
                            {/*<div className={styles.row}>
                            <div className={cn(styles.col, styles.item)}>
                                <div className={styles.label}>Repay Cycle</div>
                                <div className={styles.value}>{token.repayCycle}</div>
                            </div>
                        </div>*/}
                        </div>
                        <div className={styles.wrapperTitle}>
                            Repay Impact
                        </div>
                        <div className={styles.wrapper}>
                            <div className={styles.row}>
                                <div className={cn(styles.col, styles.item)}>
                                    <div className={styles.label}>Borrow Limit</div>
                                    <div className={styles.value}>
                                        {formatMoney(
                                            toZeroIfBelowOne(summary.limitFormatted),
                                            true
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={cn(styles.col, styles.item)}>
                                    <div className={styles.label}>Borrow Balance</div>
                                    <div className={cn(
                                        styles.value,
                                        styles.newLimit
                                    )}>
                                        {formatMoney(
                                            toZeroIfBelowOne(summary.borrowFormatted),
                                            true
                                        )}
                                        <Icon name="arrow-right" size="20" />
                                        {formatMoney(
                                            toZeroIfBelowOne(newLimit),
                                            true
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TransactionCost
                            nftId={nftId}
                            isAave={token.isAavePool}
                            showApproval={showApproval}
                            value={showApproval ? tokenAllowance.estimatedGasUSD : estimatedGasUSD}
                            amount={amount}
                        />
                    </>
                }
                <div className={styles.foot}>
                    {isConnected && (
                        <>
                            {showApproval && (
                                <button
                                    className={cn(
                                        "button-small",
                                        styles.button,
                                        styles.approve,
                                        {
                                            [styles.active]: amount,
                                            disabled: disableApprove
                                        }
                                    )}
                                    onClick={handleApprove}
                                >
                                    {buttonApproveText === APPROVING_TEXT && <Loader className={styles.loader} />}
                                    <span>{buttonApproveText}</span>
                                    {/* <Icon name="info" size="20" /> */}
                                </button>
                            )}
                            <button
                                className={cn(
                                    "button-small",
                                    styles.button,
                                    {
                                        disabled: disabledSubmit && buttonText !== DONE_TEXT,
                                        "button-stroke": showApproval
                                    }
                                )}
                                onClick={handleRepay}
                            >
                                {(buttonText === REPAYING_TEXT || isFetchingData)
                                    && <Loader className={styles.loader} />}
                                <span>
                                    {isFetchingData
                                        ? FETCHING_DATA
                                        : repayButtonText
                                    }
                                </span>
                            </button>
                            {transactionHashLink &&
                                <a
                                    className={cn(
                                        "button-small",
                                        "button-stroke",
                                        styles.button
                                    )}
                                    href={transactionHashLink}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    View Transaction Details
                                </a>
                            }
                        </>
                    )}
                    {_no(isConnected) &&
                        <button
                            className={cn(
                                "button-small",
                                styles.button
                            )}
                            onClick={() => {
                                // open()
                            }}
                        >
                            <span>Connect Wallet</span>
                            <Icon name="info" size="20" />
                        </button>
                    }
                </div>
            </div >
            <TooltipGlobal />
        </>
    );
};

export default Repay;
