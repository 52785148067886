import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import { useParams } from 'react-router-dom';
import Icon from "../Icon";
import Header from "../Header";
import Sidebar from "../Sidebar";
import styles from "./Page.module.sass";
import NetAPY from "./NetAPY";
import PositionManager from "./PositionManager";
import StateUpdater from "./StateUpdater";
import SwitchNetwork from "./SwitchNetwork";
import { _no } from "../../utils";
import { useNetworkStatus } from "../../customHooks/useNetworkStatus";
import { HOME_TITLE } from "../../utils/config";

const Page = ({
    wide,
    children,
    title,
    mobileTitle,
    icon,
    customIcon,
    expand,
    setExpand
}) => {

    const params = useParams();

    const [visible, setVisible] = useState(false);
    const isOffline = useNetworkStatus();

    if (isOffline) {
        return <div>You are offline. Please check your connection.</div>
    }

    return (
        <>
            <StateUpdater title={title} params={params} />
            <SwitchNetwork />
            <div className={cn(styles.page, {
                [styles.expand]: expand
            })}>
                <div style={{top: "-100px", position: "absolute"}} id="inner"></div>
                <Sidebar
                    className={cn(styles.sidebar, { [styles.visible]: visible })}
                    onClose={() => setVisible(false)}
                    expand={expand}
                    onExpand={(expand) => setExpand(expand)}
                />
                <Header expand={expand} onOpen={() => setVisible(true)} />
                <div className={styles.inner}>
                    <div
                        className={cn(styles.container, {
                            [styles.wide]: wide,
                        })}
                    >
                        <div className={styles.titleContainer}>
                            <div>
                                {(title || mobileTitle) &&
                                    <div className={cn("h4", styles.title)}>
                                        {icon && <Icon name={icon} size="32" />}
                                        {
                                            customIcon && <img className={styles.custom} src={customIcon} alt="icon" />
                                        }
                                        {title && (
                                            <span className={cn(styles.titleText, {
                                                [styles.visible]: _no(mobileTitle)
                                            })}>
                                                {title}
                                            </span>
                                        )}
                                        {mobileTitle && (
                                            <span className={styles.titleMobileText}>
                                                {mobileTitle}
                                            </span>
                                        )}
                                    </div>
                                }
                            </div>
                            {title && title === HOME_TITLE && (
                                <div className={styles.netApyContainer}>
                                    <PositionManager title={title} />
                                    <NetAPY title={title} />
                                </div>
                            )}
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default withRouter(Page);
