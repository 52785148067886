import { formatMoneyDashed, formatUSD } from "../../utils";
import cn from "classnames";
import styles from "./TokenAmountInput.module.sass";
import NumberInput from "../NumberInput";
import Icon from "../Icon";
import Logo from "../Logo";
import { createContext, useContext } from "react";
import {
    DEFAULT_DECIMALS,
    DEFAULT_TOKEN_ICON,
    DEFAULT_TOKEN_NAME
} from "../../utils/config";

const ERROR = "useTokenAmountInputContext should be used within TokenAmountInput";

const TokenAmountInputContext = createContext(
    undefined
);

const useTokenAmountInputContext = () => {

    const context = useContext(
        TokenAmountInputContext
    );

    if (!context) {
        throw new Error(
            ERROR
        );
    }

    return context;
};

const InputRow = ({
    amount,
    onChange,
    placeholder = "0.00",
    integerOnly = false,
    icon,
    showClearButton,
    onClearButtonClick,
}) => {

    const { token, disabled } = useTokenAmountInputContext();
    let decimals = token?.decimals ?? DEFAULT_DECIMALS;
    if (integerOnly) {
        decimals = 0;
    }
    return (<div className={styles.row}>
        <div className={styles.col}>
            <NumberInput
                className={styles.textfield}
                name="amount"
                placeholder={placeholder}
                value={amount}
                onChange={onChange}
                disabled={disabled}
                decimalPlaces={decimals}
            />
        </div>
        <div className={styles.col}>
            <button
                className={cn(
                    styles.reset,
                    {
                        [styles.active]: showClearButton
                    }
                )}
                onClick={onClearButtonClick}
            >
                <Icon name="close" size="14" />
            </button>
        </div>
        <div className={styles.col}>
            <div className={styles.token}>
                {token?.customIcon && <div className={styles.myicon}>
                    <Icon name={token?.customIcon} size="23" />
                </div>}
                <Logo
                    className={cn(
                        styles.currency,
                        {
                            [styles.customwise]: token?.customWise
                        }
                    )}
                    logo={token?.icon ?? DEFAULT_TOKEN_ICON}
                    subLogo={token?.sub}
                    width={32}
                    height={32}
                />
                <div className={styles.name}>{token?.name ?? DEFAULT_TOKEN_NAME}</div>
            </div>
        </div>
    </div>);
};

const Details = ({
    amountInUsd,
    balance,
    onClick,
    children,
}) => {
    const { disabled, token } = useTokenAmountInputContext();
    return (
        <div className={styles.row}>
            <div className={styles.col}>
                <div className={styles.preview}>
                    {formatUSD(amountInUsd)}
                </div>
            </div>
            <div className={cn(styles.col, styles.balance)}>
                <div>
                    {children}
                </div>
                <button
                    className={styles.max}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {formatMoneyDashed(balance)} {token?.name ?? DEFAULT_TOKEN_NAME}
                </button>
            </div>
        </div>
    );
};

const TokenAmountInput = ({
    disabled,
    token,
    tokenBalanceFormatted,
    children
}) => {
    return (
        <TokenAmountInputContext.Provider
            value={{
                token,
                tokenBalanceFormatted,
                disabled
            }}>
            <div className={styles.wrapper}>
                {children}
            </div>
        </TokenAmountInputContext.Provider>
    );
};

TokenAmountInput.Input = InputRow;
TokenAmountInput.Details = Details;

export default TokenAmountInput;
