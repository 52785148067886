import { useReadContract } from "wagmi";
import { useEffect } from "react";
import { formatUnits } from "viem";
import { useQueryClient } from '@tanstack/react-query'
import useWiseStakeData from "./useWiseStakeData";

import {
    toPrecision,
} from "../utils";

const useWiseReferralData = ({
    config,
    address,
    referralId,
    wiseCost,
    currentWiseDay,
    doRefetch,
}) => {

    const { chainId, WiseTokenContract } = config;

    const { data: referral, isFetchingReferralData, queryKey  } = useReadContract({
        chainId,
        address: WiseTokenContract?.address,
        abi: WiseTokenContract?.abi,
        functionName: "checkReferralsByID",
        args: [
            address,
            referralId
        ],
        query: {
            enabled: !!address && !!referralId && !!chainId,
        }
    });

    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.invalidateQueries({
            queryKey
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // userAccount,
        doRefetch
    ]);

    const DEFAULT_DECIMALS = 18;

    const { stakeData, isFetching: isFetchingStakeData } = useWiseStakeData({
        config,
        address: referral && referral[0],
        stakeId: referral && referral[1],
        currentWiseDay: currentWiseDay,
        wiseCost: wiseCost
    });

    let referralInterest = referral
        ? referral[3]
        : 0;

    if (referral && referral[4] === false) {
        referralInterest = 0;
    }

    let precison = 2;

    const value = referral ? formatUnits(
        referralInterest,
        DEFAULT_DECIMALS
    ) : 0;

    if (value > 999) {
        precison = 0;
    }

    const referralInterestFormatted = referral ? toPrecision(
        value,
        precison
    ) : 0;

    const referralAmountInUSD = referral ? toPrecision(
        wiseCost *
        value,
        2
    ) : "0.00";

    const referralFormatted = {
        staker: referral && referral[0],
        stakeID: referral && referral[1],
        referrerShares: referral && referral[2],
        referralInterest: referralInterest,
        referralInterestValue: value,
        referralInterestFormatted: `+${referralInterestFormatted} WISE`,
        referralAmountInUSD: referralAmountInUSD,
        isActiveReferral: referral && referral[4],
        isActiveStake: referral && referral[5],
        isMatureStake: referral && referral[6],
        isEndedStake: referral && referral[7],
    }

    return {
        stakeData: stakeData,
        referralData: referralFormatted,
        isFetching: isFetchingReferralData || isFetchingStakeData
    };
};

export default useWiseReferralData;
