import React from "react";
import styles from "./Row.module.sass";
import Checkbox from "../../../../../components/Checkbox";
import cn from "classnames";
import { MAIN_COLOR_A, MAIN_COLOR_B } from "../../../../../utils/config";

import useLendingSummary from "../../../../../customHooks/useLendingSummary";
import useConfig from "../../../../../customHooks/useConfig";
import { useAccount } from "wagmi";

import {
    toPrecision,
    formatMoney,
} from "../../../../../utils";

const Row = ({ active, item, value, onChange, up, position }) => {

    // const [visibleActions, setVisibleActions] = useState(false);
    // const [visibleModalProduct, setVisibleModalProduct] = useState(false);

    const config = useConfig();
    const { address } = useAccount();

    const summary = useLendingSummary({
        config,
        nftId: item,
        userAccount: address,
    });

    const totalAmount = summary && parseInt(summary?.supplyFormatted) + parseInt(summary?.borrowFormatted);
    const supplyPercent = summary && ((parseInt(summary?.supplyFormatted) / totalAmount) * 100);
    const borrowPercent = summary && ((parseInt(summary?.borrowFormatted) / totalAmount) * 100);

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Checkbox
                        className={styles.checkbox}
                        value={value}
                        key={item}
                        onChange={onChange}
                    />
                </div>
                <div className={styles.col}>
                    <div
                        className={styles.item}
                        // onClick={() => setVisibleModalProduct(true)}
                    >
                        <div className={styles.details}>
                            <div className={styles.product}>Position #{item}</div>
                            <div className={styles.date}>
                                Wise Lending
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    {active ? (
                        <div className={cn("status-green", styles.status)}>Active</div>
                    ) : (
                        <div className={cn("status-yellow", styles.status)}>Select</div>
                    )}
                </div>
                <div className={styles.col}>
                    <div className={styles.white}>
                        {summary && toPrecision(summary?.overallNetAPYFormatted)}%
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.line} style={{ width: "100%" }}>
                        <div
                            className={styles.box}
                            style={{ backgroundColor: MAIN_COLOR_A, width: `${supplyPercent}%` }}
                        >
                            <div className={styles.tooltip}>
                                <div className={styles.subtitle}>Lending</div>
                                <div className={styles.legend}>
                                    <div
                                        className={styles.color}
                                        style={{ backgroundColor: MAIN_COLOR_A }}
                                    ></div>
                                    <div className={styles.counter}>
                                        ${summary && formatMoney(summary?.supplyFormatted)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={styles.box}
                            style={{ backgroundColor: MAIN_COLOR_B, width: `${borrowPercent}%` }}
                        >
                            <div className={styles.tooltip}>
                                <div className={styles.subtitle}>Borrowing</div>
                                <div className={styles.legend}>
                                    <div
                                        className={styles.color}
                                        style={{ backgroundColor: MAIN_COLOR_B }}
                                    ></div>
                                    <div className={styles.counter}>
                                        ${summary && formatMoney(parseInt(summary?.borrowFormatted))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        { !borrowPercent && !supplyPercent && (
                            <div
                                className={styles.box}
                                style={{ backgroundColor: "gray", width: `100%` }}
                            >
                                <div className={styles.tooltip}>
                                    <div className={styles.subtitle}>No Funds</div>
                                    <div className={styles.legend}>
                                        <div
                                            className={styles.color}
                                            style={{ backgroundColor: "gray" }}
                                        ></div>
                                        <div className={styles.counter}>
                                            $0.00
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Row;
