import Tooltip from "../Tooltip";
import TooltipGlobal from "../TooltipGlobal";
import styles from "./Comment.module.sass";
import cn from "classnames";

const Comment = ({
    avatar,
    author,
    // time,
    comment,
    className,
    isAnswer,
    children,
    compact,
    tooltip,
    authorClassName
}) => {
    return (
        <>
            <div className={cn(
                styles.box,
                {
                    [styles.answer]: isAnswer,
                    [styles.compact]: compact
                },
                className)}
            >
                <div className={styles.avatar}>
                    <img src={avatar} alt="Avatar" />
                </div>
                <div className={styles.details}>
                    <div className={styles.line}>
                        <Tooltip
                            className={styles.tooltip}
                            title={tooltip}
                            place="top"
                        >
                            <div className={cn(styles.author, authorClassName)}>
                                {author}
                            </div>
                        </Tooltip>
                    </div>
                    <div className={styles.comment}>
                        {comment}
                    </div>
                    {children}
                </div>
            </div>
            <TooltipGlobal />
        </>
    )
}

export default Comment;