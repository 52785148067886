import React, { useState } from "react";
import styles from "./Products.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Market from "./Market";
import Table from "./Table";
import { MAIN_COLOR_A, MAIN_COLOR_B } from "../../../utils/config";

// data
import { traffic } from "../../../mocks/traffic";
import { viewers } from "../../../mocks/viewers";
import { market } from "../../../mocks/market";

const indicatorsTraffic = [
	{
		title: "Market",
		color: "#FFBC99",
	},
	{
		title: "Social media",
		color: "#f99191",
	},
	{
		title: "Direct",
		color: MAIN_COLOR_A,
	},
	{
		title: "UI8",
		color: MAIN_COLOR_B,
	},
	{
		title: "Others",
		color: "#FFD88D",
	},
];

const indicatorsViewers = [
	{
		title: "Followers",
		color: MAIN_COLOR_A,
	},
	{
		title: "Others",
		color: "#f99191",
	},
];

const Products = () => {

	const navigation = [
		"Manage Positions",
		"Merge Positions",
		"Split Positions"
	];

	const [activeTab, setActiveTab] = useState(navigation[0]);

	return (
		<Card
			className={styles.card}
			title="Manager"
			classTitle={cn("title-purple", styles.title)}
			classCardHead={styles.head}
			head={
				<>
					{/*<Form
						className={styles.form}
						value={search}
						setValue={setSearch}
						onSubmit={() => handleSubmit()}
						placeholder="Search product"
						type="text"
						name="search"
						icon="search"
					/>*/}
					<div className={styles.control}>
						<button className={cn("button-stroke button-small", styles.button)}>
							Deleted
						</button>
						<button className={cn("button-stroke button-small", styles.button)}>
							Set status
						</button>
						<div className={styles.counter}>3 selected</div>
					</div>
					<div className={cn(styles.nav, "tablet-hide")}>
						{navigation.map((x, index) => (
							<button
								className={cn(styles.link, {
									[styles.active]: x === activeTab,
								})}
								onClick={() => setActiveTab(x)}
								key={index}
							>
								{x}
							</button>
						))}
					</div>
					<div className={cn(styles.dropdown, "tablet-show")}>
						<Dropdown
							classDropdownHead={styles.dropdownHead}
							value={activeTab}
							setValue={setActiveTab}
							options={navigation}
							small
						/>
					</div>
				</>
			}
		>
			<div className={styles.products}>
				<div className={styles.wrapper}>
					{activeTab === navigation[0] && <Market items={market} />}
					{activeTab === navigation[1] && (
						<Table
							title="Traffic source"
							items={traffic}
							legend={indicatorsTraffic}
						/>
					)}
					{activeTab === navigation[2] && (
						<Table title="Viewers" items={viewers} legend={indicatorsViewers} />
					)}
				</div>
			</div>
		</Card>
	);
};

export default Products;
