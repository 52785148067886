import React from "react";
import styles from "./SupportedTokens.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import { useSelector } from "react-redux";
import { formatMoneyDashed } from "../../../utils";
import Icon from "../../../components/Icon";

const SupportedTokens = ({ className }) => {

    const pricingFeeds = useSelector(
        (state) => state.token.pricingFeeds
    );

    return (
        <Card
            className={cn(styles.card, className)}
            title="Token USD Prices"
            classTitle="title-blue"
        >
            <div className={styles.items}>
                {pricingFeeds.map((x, index) => (
                    <div
                        onClick={() => window.open(x.priceFeedLink, "_blank")}
                        className={styles.item} key={index}>
                        <img className={styles.currency} src={x.icon} alt="Currency" />
                        <div className={styles.title}>{x.title}</div>
                        <div className={styles.price}>
                            {formatMoneyDashed(x.price)}
                        </div>
                        <div className={styles.link}>
                            <Icon name="link" size="24" />
                        </div>
                    </div>
                ))}
            </div>
        </Card>
    );
};

export default SupportedTokens;
