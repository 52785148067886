import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { formatUnits, parseUnits } from "viem";

import {
    useAccount
} from "wagmi";

import Icon from "../../../../../components/Icon";
import Loader from "../../../../../components/Loader";
import Switch from "../../../../../components/Switch";
import TooltipGlobal from "../../../../../components/TooltipGlobal";
import TransactionCost from "../../../../../components/TransactionCost";
import { useTokenAllowance } from "../../../../../customHooks/useTokenAllowance";
import { useSupplyToken } from "../../../../../customHooks/useSupplyToken";
import { usePrivy } from '@privy-io/react-auth';

import {
    removeNonNumeric,
    _no,
    formatMoneyDashed,
    formatMoney,
    formatDecimals,
    _notEmpty,
    toPrecision,
    _booleanToText,
    computeUSDAmount,
    showToast,
    toZeroIfBelowOne,
} from "../../../../../utils";

import { fromHex } from "viem";

import {
    DONE_TEXT,
    FETCHING_DATA,
    TRY_AGAIN_TEXT,
    CONFIRM_TEXT,
    APPROVING_TEXT,
    SUPPLYING_TEXT,
    SUPPLY_SUCCESS,
    SUPPLY_FAILED_MESSAGE,
    // CLICK_TO_VIEW,
    APPROVE_TEXT,
    MODES,
    INSUFFICIENT_COST,
    ETH_TOKEN_NAME,
    RESET_APPROVAL_TEXT,
    NEED_RESET_TOKENS,
    APY_DECIMALS
    // switchMode
} from "../../../../../utils/config";

import styles from "./Supply.module.sass";
import Tooltip from "../../../../../components/Tooltip";
import SuccessPanel from "../../../../../components/Modal/SuccessPanel";
import TokenAmountInput from "../../../../../components/TokenAmountInput";
import { useSelector } from "react-redux";
// import { useWeb3Modal } from "@web3modal/wagmi/react";
// import { arbitrum } from "viem/chains";
// import { POOL_NAMES } from "../../../../../ethers/tokens";

const FUNCTION_NAME = "Lend";

/*
const EARN_POINTS_DETAILS = [
    {
        details: 'Up To 2x Etherfi Points + Wise Points',
        icon: '/images/icons/etherfi.webp',
        style: `pointC`,
        iconTooltip: 'Up To 2x Etherfi Points + Wise Points',
        poolVisibility: [
            POOL_NAMES.ETH,
        ]
    },
    {
        details: 'Up To 20x Sats + Wise Points',
        icon: '/images/icons/ethena.webp',
        style: `pointA`,
        iconTooltip: 'Up To 20x Sats + Wise Points',
        poolVisibility: [
            POOL_NAMES.USDC,
            POOL_NAMES.USDT,
            POOL_NAMES.DAI
        ]
    }
];
*/

const Supply = ({
    config,
    nftId,
    tokenPriceData,
    token,
    summary,
    walletBalance,
    onConnect,
    onDone,
    // extraApyLabel,
    // ethApyTooltip
}) => {

    const {
        login,
    } = usePrivy();

    const DEFAULT_APPRROVE_TEXT = APPROVE_TEXT(
        token.name
    );

    const RESET_APPROVE_TEXT = RESET_APPROVAL_TEXT(
        token.name
    );

    const DEFAULT_TEXT = `${FUNCTION_NAME} ${token.name}`;

    const { isConnected, chainId } = useAccount();

    const needReset = NEED_RESET_TOKENS.includes(
        token.name
    ) && chainId === 1;

    const getDefaultApprovalText = (allowance) => {
        if (needReset) {
            return allowance === 0n
                ? DEFAULT_APPRROVE_TEXT
                : RESET_APPROVE_TEXT;
        }

        return DEFAULT_APPRROVE_TEXT;
    };

    // const { open } = useWeb3Modal();
    const { linkBase } = config;

    const address = useSelector(
        (state) => state.settings.address
    );

    /*const { chainId } = useSelector(
        (state) => state.token
    );
    */

    const [cachedInput, setCachedInput] = useState(null);
    const [amount, setAmount] = useState("");
    const convertedAmount = removeNonNumeric(
        amount || 0
    );

    const [buttonApproveText, setButtonApproveText] = useState(
        DEFAULT_APPRROVE_TEXT
    );

    const mode = MODES.expert;
    const isCollateral = true;

    const [isPrivate, setIsPrivate] = useState(false);
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [isLoading, setIsLoading] = useState(false);
    const [successfullSupply, setSuccessfullSupply] = useState();
    const [finishSupply, setFinishSupply] = useState(false);
    const [transactionHashLink, setTransactionHashLink] = useState("");
    const [allowance, setAllowance] = useState(0);

    const tokenPrice = tokenPriceData?.price || 0;

    const supplyAmount = parseUnits(
        convertedAmount,
        token.decimals
    );

    const isReset = buttonApproveText === RESET_APPROVE_TEXT;

    const tokenAllowance = useTokenAllowance({
        config,
        token,
        userAccount: address,
        isReset,
        amount: supplyAmount,
    });

    const isFetchingData = _no(tokenPrice);
        // || tokenAllowance.isFetching;

    const preApprovedDefault = token.needsApproval && _no(finishSupply)
        ? allowance && allowance >= supplyAmount
        : true;

    const [preApproved, setPreApproved] = useState(
        preApprovedDefault
    );

    const formattedAmount = formatMoneyDashed(
        amount
    );

    const SUPPLY_SUCCESS_MESSAGE = `${SUPPLY_SUCCESS} ${formattedAmount} ${token.name}`;

    const pureAmountInUSD = computeUSDAmount(
        token.decimals,
        convertedAmount,
        tokenPrice
    );

    const amountInUSD = pureAmountInUSD
        * token.poolCollateralFactor;

    let newLimit = parseFloat(summary.limitFormatted)
        + (
            isCollateral
                ? amountInUSD
                : 0
        );

    let showApproval = amount
        && buttonText !== SUPPLYING_TEXT
        && buttonText !== DONE_TEXT
        && _no(preApproved)
        && _no(tokenAllowance.isFetching)
        && _no(isFetchingData);

    const supplyToken = useSupplyToken({
        config,
        nftId,
        token,
        isPrivate,
        showApproval,
        collateralState: isCollateral,
        amount: supplyAmount
    });

    const supplyAPY = amount && supplyToken.simulationAPY ?
        formatUnits(
            supplyToken.simulationAPY,
            APY_DECIMALS
        )
        : token.supplyRateFormatted;

    const isDefault =
        buttonText.includes(FUNCTION_NAME) &&
        buttonText.includes(token.name);

    const changePrivateSupply = () => {
        setIsPrivate(
            !isPrivate
        );
    };

    const handleSupply = () => {
        if (buttonText === DONE_TEXT) {
            onDone && onDone();
            return;
        };

        if (buttonText === TRY_AGAIN_TEXT) {
            setFinishSupply(false);
            setTransactionHashLink("");
            setButtonText(
                DEFAULT_TEXT
            );
        };

        if (_no(isDefault)) return;

        supplyToken.supply({
            chainId,
            onConfirmation: () => {
                setIsLoading(true);
                setButtonText(
                    CONFIRM_TEXT
                );
            }
        });
    };

    useEffect(() => {

        if (supplyToken.hasError && isLoading) {
            setIsLoading(false);
            setTransactionHashLink("");

            showToast(
                'Transaction reverted, please try again later.',
                {
                    type: "warning",
                }
            );

            const amountInDecimal = toPrecision(
                convertedAmount
            );

            const amountInFloat = parseFloat(
                removeNonNumeric(
                    amountInDecimal
                )
            );

            // @TODO: get rid of duplicate
            const textPrefix = convertedAmount > amountInFloat
                ? "~"
                : "";

            const amountText = _notEmpty(amountInFloat)
                ? `${textPrefix}${amountInDecimal} `
                : "";

            setButtonText(
                `${FUNCTION_NAME} ${amountText} ${token.name}`
            );
        }

        if (supplyToken.error && isLoading) {
            setIsLoading(false);
            setTransactionHashLink("");

            showToast(
                `Transaction cancelled by user in wallet.`,
                {
                    type: "warning",
                }
            );

            const amountInDecimal = toPrecision(
                convertedAmount
            );

            const amountInFloat = parseFloat(
                removeNonNumeric(
                    amountInDecimal
                )
            );

            // @TODO: get rid of duplicate
            const textPrefix = convertedAmount > amountInFloat
                ? "~"
                : "";

            const amountText = _notEmpty(amountInFloat)
                ? `${textPrefix}${amountInDecimal} `
                : "";

            setButtonText(
                `${FUNCTION_NAME} ${amountText} ${token.name}`
            );
        }

        if (supplyToken.isConfirming && !transactionHashLink && !finishSupply) {
            setButtonText(SUPPLYING_TEXT);
            setTransactionHashLink(
                `${linkBase}/tx/${supplyToken.hash}`
            );
        }

        if (supplyToken.isConfirmed && isLoading) {

            setIsLoading(false);
            setFinishSupply(true);

            // const transactionLink = `${linkBase}/tx/${supplyToken.hash}`;

            const message = supplyToken.isConfirmed
                ? SUPPLY_SUCCESS_MESSAGE
                : SUPPLY_FAILED_MESSAGE;

            /*
            const toastContent = toastNotification.generateLinkContent(
                transactionLink,
                `${message} ${CLICK_TO_VIEW}`
            );
            */

            /*
            toastNotification.showToast(
                supplyToken.isConfirmed,
                toastContent
            );
            */

            showToast(message, {
                type: "success",
            });

            setSuccessfullSupply(
                supplyToken.isConfirmed
            );

            setButtonText(
                supplyToken.isConfirmed
                    ? DONE_TEXT
                    : TRY_AGAIN_TEXT
            );

            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supplyToken]);

    const handleApprove = () => {
        tokenAllowance.approve({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonApproveText(
                    CONFIRM_TEXT
                );
            }
        });
    };

    useEffect(() => {
        if (tokenAllowance.allowance) {
            setAllowance(
                tokenAllowance.allowance
            );

            if (tokenAllowance.allowance >= supplyAmount) {
                setPreApproved(true);
            }

            if (tokenAllowance.allowance < supplyAmount) {
                setPreApproved(false);
            }
        }
    }, [tokenAllowance.allowance, supplyAmount]);

    useEffect(() => {

        if (tokenAllowance.error && isLoading) {
            setIsLoading(false);
            handleError(
                tokenAllowance.error
            );
            // setTransactionHash("");
            setButtonApproveText(
                getDefaultApprovalText(allowance)
            );
        }

        if (tokenAllowance.isConfirming && isLoading) {
            setButtonApproveText(
                APPROVING_TEXT
            );
        }

        if (tokenAllowance.isConfirmed && buttonApproveText === APPROVING_TEXT) {

            setIsLoading(false);

            const { logs } = tokenAllowance.transactionData;

            const spendingCap = fromHex(
                logs[0].data,
                "bigint"
            );

            setAllowance(
                spendingCap
            );

            if (spendingCap >= supplyAmount) {
                setPreApproved(true);
            }

            const spendingCapAmount = formatUnits(
                spendingCap,
                token.decimals
            );

            const formattedSpendingCapAmount = formatMoneyDashed(
                spendingCapAmount
            );

            const successMessage = spendingCap === 0n
                ? `Successfully reset ${token.name} approval`
                : `Approved ${formattedSpendingCapAmount} ${token.name} tokens - You can lend now`;

            if (isReset) {
                setCachedInput(amount);
            }

            showToast(successMessage, {
                type: "success",
            });

            setButtonApproveText(
                DEFAULT_APPRROVE_TEXT
            );

            setIsLoading(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenAllowance]);

    const handleError = (err) => {

        const error = err?.message || err;

        showToast(
            error
        );
    };

    const handleQuickSelect = (option) => {

        const aboveZero = parseFloat(token.balanceFormatted) > 0;
        const amount = option === "max" && aboveZero
            ? formatDecimals(token.balanceFormatted)
            : "";

        setAmount(
            amount
        );
    };

    useEffect(() => {
        if (isDefault) {

            const amountInDecimal = toPrecision(
                convertedAmount
            );

            const amountInFloat = parseFloat(
                removeNonNumeric(
                    amountInDecimal
                )
            );

            const textPrefix = convertedAmount > amountInFloat
                ? "~"
                : "";

            const amountText = `${textPrefix}${amountInDecimal}`;

            setButtonText(
                `${FUNCTION_NAME} ${amountText} ${token.name}`
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [convertedAmount]);

    useEffect(() => {
        setButtonApproveText(
            getDefaultApprovalText(
                allowance
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowance, amount]);

    useEffect(() => {
        if (_notEmpty(cachedInput) && _notEmpty(amount)) {
            setAmount(null);
        } else if (_no(amount) && _notEmpty(cachedInput)) {
            setAmount(cachedInput);
            setCachedInput(null);
        }
    }, [cachedInput, amount]);

    const {
        estimatedGasUSD,
        estimatesGasFormatted
    } = supplyToken;

    const ethTokenAmount = token.name === ETH_TOKEN_NAME
        ? convertedAmount
        : 0;

    const totalTokenAmount = parseFloat(ethTokenAmount) + parseFloat(estimatesGasFormatted);
    const isTokenInsufficient = totalTokenAmount > walletBalance
        && _no(isLoading)
        && (_no(finishSupply) || _no(preApproved));

    const isInsufficient = parseFloat(convertedAmount) > parseFloat(token.balanceFormatted)
        && _no(isLoading)
        && (_no(finishSupply) || _no(preApproved));

    const disableApprove = isInsufficient
        || isLoading
        || isTokenInsufficient;

    const disabledSubmit =  _no(amount)
        || parseFloat(amount) === 0.00
        || _no(preApproved)
        || isInsufficient
        || isTokenInsufficient
        || isLoading
        || isFetchingData;

    let supplyButtonText = buttonText;

    if (_no(amount)) {
        supplyButtonText = `Enter ${token.name} amount`;
    } else if (isInsufficient) {
        supplyButtonText = `Insufficient ${token.name} Balance`;
    } else if (isTokenInsufficient) {
        supplyButtonText = INSUFFICIENT_COST;
    }


    /*
    const EarnPoints = useMemo(() => {
        const earnPointsDetails = token.poolName && EARN_POINTS_DETAILS
            .find(earnPointsDetails => earnPointsDetails
                .poolVisibility
                .includes(token.poolName)
            );

        const hasEarnPointsDetails = _notEmpty(earnPointsDetails);

        const isArbitrumNetwork = chainId === arbitrum.id;

        const hasPoolVisibility =
            !earnPointsDetails?.poolVisibility
            || earnPointsDetails.poolVisibility.includes(token.poolName);

        const shouldShowEarnPoints =
            isArbitrumNetwork &&
            hasEarnPointsDetails &&
            hasPoolVisibility;

        if (!shouldShowEarnPoints) return <></>;

        return (
            <>
                <div className={styles.wrapperTitle}>
                    <div>
                        Earn Points
                    </div>
                </div>
                <div className={styles.wrapper}>
                    <div className={cn(
                        styles.col,
                        styles.item
                    )}>
                        <div className={styles.label}>
                            {earnPointsDetails.details}
                        </div>
                        <div className={cn(
                            styles.value,
                            styles.newLimit
                        )}>
                            <Tooltip
                                className={styles.tooltip}
                                title={earnPointsDetails.iconTooltip}
                                place="right"
                            >
                                <img alt="wise" className={cn(styles.wise)} src="images/wise.png"></img>
                                {earnPointsDetails.style === 'pointA' ? (
                                    <img alt="etherfi" className={cn(styles.pointA)} src={earnPointsDetails.icon}></img>
                                ) : (
                                    <img alt="etherfi" className={cn(styles.pointC)} src={earnPointsDetails.icon}></img>
                                )}
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </>
        );
    }, [chainId, token]);
    */

    const supplyAPYBG = useMemo(() => {
        if (supplyToken.isCalculatingSimulationAPY) {
            return "status-green-dark";
        } else {
            return isPrivate || token.canBorrow === false
                ? "status-green"
                : "status-green-dark";
        }

    }, [
        supplyToken.isCalculatingSimulationAPY,
        isPrivate,
        token.canBorrow
    ]);

    return (
        <>
            <div>
                {finishSupply &&
                    <div className={styles.successWrapper}>
                        <SuccessPanel />
                        <div className={styles.text}>
                            {successfullSupply
                                ? SUPPLY_SUCCESS_MESSAGE
                                : SUPPLY_FAILED_MESSAGE
                            }
                        </div>
                    </div>
                }
                {_no(finishSupply) &&
                    <>
                        <div className={cn("title-green", styles.title)}>
                            {FUNCTION_NAME} {token.name}
                        </div>
                        <div className={styles.wrapperTitle}>
                            <div>
                                Lending Amount
                            </div>
                        </div>
                        <TokenAmountInput
                            token={token}
                            disabled={isLoading}>
                            <TokenAmountInput.Input
                                amount={amount}
                                onChange={setAmount}
                                showClearButton={amount && _no(isLoading)}
                                onClearButtonClick={() => handleQuickSelect("clear")}
                            />
                            <TokenAmountInput.Details
                                amountInUsd={pureAmountInUSD}
                                balance={token.balanceFormatted}
                                onClick={() => handleQuickSelect("max")}>
                                Max {FUNCTION_NAME}
                            </TokenAmountInput.Details>
                        </TokenAmountInput>
                        {_no(token.isAavePool) &&
                            <>
                                {token.canBorrow ? (
                                    <div className={styles.wrapper}>
                                        <div className={cn(
                                            styles.row,
                                            styles.mbAPY
                                        )}>
                                            <div className={cn(
                                                styles.col,
                                                styles.item
                                            )}>
                                                <div className={cn(
                                                    styles.label,
                                                    // styles.gray
                                                )}>
                                                    Private Supply
                                                    <Tooltip
                                                        className={styles.tooltip}
                                                        title="Isolated from borrowing pool."
                                                        icon="info-stroke"
                                                        place="right"
                                                    />
                                                </div>
                                                <div className={styles.value}>
                                                    <div className={styles.collateral}>
                                                        {mode === MODES.expert
                                                            ?
                                                            <Switch
                                                                className={styles.switch}
                                                                value={isPrivate}
                                                                onChange={changePrivateSupply}
                                                            />
                                                            :
                                                            _booleanToText(isPrivate)
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={styles.wrapper}>
                                        <div className={cn(
                                            styles.row,
                                            styles.mbAPY
                                        )}>
                                            <div className={cn(
                                                styles.col,
                                                styles.item
                                            )}>
                                                <div className={cn(
                                                    styles.label,
                                                    // styles.gray
                                                )}>
                                                    Supplied only as collateral
                                                    <Tooltip
                                                        className={styles.tooltip}
                                                        title="Not available for borrowing."
                                                        icon="info-stroke"
                                                        place="right"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        }
                        {/*EarnPoints*/}
                        <div className={styles.wrapperTitle}>
                            <div>
                                Lending Impact
                            </div>
                        </div>
                        <div className={styles.wrapper}>
                            <div className={cn(
                                styles.row,
                                // styles.mbAPY
                            )}>
                                <div className={cn(
                                    styles.col,
                                    styles.item
                                )}>
                                    <div className={styles.label}>
                                        Lending APY
                                    </div>
                                    <div className={styles.flex}>
                                        <span className={
                                            cn(
                                                supplyAPYBG,
                                                styles.supplyAPY
                                            )
                                        }
                                        >
                                            {   // 1 === 1
                                                supplyToken.isCalculatingSimulationAPY
                                                ?
                                                <>
                                                    <Loader className={styles.loader} />
                                                    {""}
                                                </>
                                                :
                                                <>
                                                    {
                                                        isPrivate || token.canBorrow === false
                                                            ? "N / A"
                                                            :
                                                            `${formatDecimals(
                                                                supplyAPY
                                                            )}%`
                                                    }

                                                </>
                                            }
                                        </span>
                                        {/*token.name !== "wstETH" && chainId !== mainnet.id && !!extraApyLabel
                                            && (
                                                <>
                                                    <span>&nbsp;</span>
                                                    <Tooltip
                                                        className={styles.tooltip}
                                                        title={ethApyTooltip}
                                                        place="right"
                                                    >
                                                        <span className={
                                                            isPrivate || token.canBorrow === false
                                                                ? "status-purple"
                                                                : "status-purple-dark"
                                                        }
                                                        >
                                                            +{extraApyLabel}
                                                        </span>
                                                    </Tooltip>
                                                </>
                                            )*/}
                                    </div>
                                </div>
                            </div>
                            <div className={cn(
                                styles.row,
                                styles.mbAPY
                            )}>
                                <div className={cn(styles.col, styles.item)}>
                                    <div className={styles.label}>
                                        Borrow Limit Change
                                    </div>
                                    <div className={cn(
                                        styles.value,
                                        styles.newLimit
                                    )}>
                                        {formatMoney(
                                            toZeroIfBelowOne(summary.limitFormatted),
                                            true
                                        )}
                                        <Icon name="arrow-right" size="20" />
                                        {formatMoney(
                                            toZeroIfBelowOne(newLimit),
                                            true
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/*<div className={styles.row}>
                            <div className={cn(styles.col, styles.item)}>
                                <div className={styles.label}>Borrow Balance</div>
                                <div className={styles.value}>
                                    {formatMoney(
                                        summary.borrowFormatted,
                                        true
                                    )}
                                </div>
                            </div>
                        </div>*/}
                        </div>
                        <TransactionCost
                            nftId={nftId}
                            isAave={token.isAavePool}
                            showApproval={showApproval}
                            value={showApproval ? tokenAllowance.estimatedGasUSD : estimatedGasUSD}
                            amount={amount}
                        />
                    </>
                }
                <div className={styles.foot}>
                    {isConnected && (
                        <>
                            {showApproval && (
                                <button
                                    className={cn(
                                        "button-small",
                                        styles.button,
                                        styles.approve,
                                        {
                                            [styles.active]: amount,
                                            disabled: disableApprove
                                        }
                                    )}
                                    onClick={handleApprove}
                                >
                                    {buttonApproveText === APPROVING_TEXT && <Loader className={styles.loader} />}
                                    <span>{buttonApproveText}</span>
                                    {/* <Icon name="info" size="20" /> */}
                                </button>
                            )}
                            <button
                                className={cn(
                                    "button-small",
                                    styles.button,
                                    {
                                        disabled: disabledSubmit && buttonText !== DONE_TEXT,
                                        "button-stroke": showApproval
                                    }
                                )}
                                onClick={handleSupply}
                            >
                                {(buttonText === SUPPLYING_TEXT || isFetchingData)
                                    && <Loader className={styles.loader} />}
                                <span>
                                    {isFetchingData
                                        ? FETCHING_DATA
                                        : supplyButtonText
                                    }
                                </span>
                            </button>
                            {transactionHashLink &&
                                <a
                                    className={cn(
                                        "button-small",
                                        "button-stroke",
                                        "button-transparent",
                                        styles.button
                                    )}
                                    href={transactionHashLink}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    View Transaction Details
                                </a>
                            }
                        </>
                    )}
                    {_no(isConnected) &&
                        <button
                            className={cn(
                                "button-small",
                                styles.button
                            )}
                            onClick={() => {
                                // open()
                                login();
                            }}
                        >
                            <Icon name="profile-circle" size="20" />
                            <span>Connect Account</span>
                        </button>
                    }
                </div>
            </div>
            <TooltipGlobal />
        </>
    );
};

export default Supply;
