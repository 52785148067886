import {
    useEffect,
    // useState
} from "react";
import { useAccount } from "wagmi";
import { _no } from "../../../utils";
import SwitchNetworkModal from "../../../components/SwitchNetworkModal";
import { checkChain } from "../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { setChainId } from "../../../redux/tokenSlice";
import { setShowSwitchNetwork } from "../../../redux/settingsSlice";

const SwitchNetwork = () => {

    const { isConnected, chain } = useAccount();
    const dispatch = useDispatch();

    const {
        showSwitchNetwork
    } = useSelector(
        (state) => state.settings
    );

    useEffect(() => {
        if (isConnected && chain?.id) {
            const chainConfig = checkChain(chain?.id);

            dispatch(
                setShowSwitchNetwork(
                    _no(chainConfig.chainId)
                )
            );

            dispatch(
                setChainId(
                    chain?.id
                )
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chain?.id, isConnected]);

    const closeSwitchModal = () => {
        dispatch(
            setShowSwitchNetwork(false)
        );
    };
    return (
        <>
            <SwitchNetworkModal onClose={closeSwitchModal} visible={showSwitchNetwork} />
        </>
    );
};

export default SwitchNetwork;
