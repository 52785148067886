import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { useAccount } from "wagmi";
import Modal from "../../components/Modal";
import TooltipGlobal from "../../components/TooltipGlobal";
import usePointsSummary from "../../customHooks/usePointsSummary";
import useReferees from "../../customHooks/useReferees";
import {
    _no,
    fetchRewardsData,
    fetchUserEtherTotalPoints,
    fetchUserStableTotalPoints,
    getEnsAddressAcrossNetworks,
    getEnsNameForAddress,
    isWalletAddressFormat,
    trimAddressDisplay
} from "../../utils";
import {
    DEFAULT_DECIMALS,
    PointTypes,
    PointsV2Navigation,
    SEASON_API_HASH,
    SEASON_CONTRACT_HASH,
} from "../../utils/config";
import CreateReferral from "../AffiliateCenter/CreateReferral";
import JoinedReferralNetwork from "../AffiliateCenter/JoinedReferralNetwork";
import UseReferral from "../AffiliateCenter/UseReferral";
import BoardOverview from "../BoardOverview";
import CustomerList from "../CustomerList";
import ClaimRewards from "./ClaimRewards";
import styles from "./LeadBoards.module.sass";
import Overview from "./Overview/Overview";

const shortenAddress = (address, startLength = 6, endLength = 4) => {
    if (!address) {
        throw new Error("Invalid address provided");
    }

    // Ensure the address is a valid string
    if (address.length < startLength + endLength) {
        return address; // If the address is too short, return it as is
    }

    return `${address.slice(0, startLength)}...${address.slice(-endLength)}`;
};

const LeadBoards = () => {
    const params = useParams();
    const initialReferralAddress = params?.address;

    const { isConnected } = useAccount();

    const {
        address
    } = useSelector(
        (state) => state.settings
    );

    const {
        pricingFeeds,
    } = useSelector(
        (state) => state.token
    );

    const [activeTab, setActiveTab] = useState(PointsV2Navigation.ETHER);
    const [etherUserPoints, setUserEtherPoints] = useState([]);
    const [stableUserPoints, setUserStablePoints] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [showCustomerList, setShowCustomerList] = useState(false);
    const [rewardData, setRewardData] = useState(0);
    const [rewardClaimed, setRewardClaimed] = useState(false);

    const {
        pointsSummary,
        fetchPoints,
        loading: isPointsSummaryLoading
    } = usePointsSummary({
        address,
        pricingFeeds
    });

    const {
        referees,
        loading: isRefereesLoading
    } = useReferees({
        address,
        pricingFeeds
    });

    const { TVL } = useSelector(
        (state) => state.token
    );

    const fetchEnsData = async (userAddress) => {
        let ensName = '';
        let address = userAddress;
        if (isWalletAddressFormat(address)) {
            ensName = await getEnsNameForAddress(address);
        } else {
            address = await getEnsAddressAcrossNetworks(address);
            ensName = `${address}.eth`;
        }

        return ensName
            ? trimAddressDisplay(ensName)
            : shortenAddress(address);
    };

    const formatData = async (data, tokenPrice) => {
        return await Promise.all(
            data
                .map(([userAddress, details]) => ({
                    userAddress,
                    ...details,
                })
                ).sort((item1, item2) => {
                    return Number(item2.totalPoints) - Number(item1.totalPoints);
                })
                .map(async (item) => {

                    const contributedAmount = item.contributedAmount * tokenPrice;
                    const roundedValue = Math.round(
                        item.pointsRateTotal * 100000
                    ) / 100000;

                    const pointsRateFormatted = roundedValue.toFixed(5) || '0.00000';

                    const address = await fetchEnsData(
                        item.userAddress
                    );

                    return {
                        ...item,
                        address,
                        contributedAmount,
                        pointsRateFormatted
                    };
                })
        );
    };

    const getData = async () => {

        const etherPointsData = await fetchUserEtherTotalPoints();
        const etherPointsArray = Object.entries(
            etherPointsData?.data || {}
        );

        const formattedEtherPointsData = await formatData(
            etherPointsArray,
            tokenPriceDataETH?.price || 0
        );

        setUserEtherPoints(
            formattedEtherPointsData
        );

        const stablePointsData = await fetchUserStableTotalPoints();
        const stablePointsArray = Object.entries(
            stablePointsData?.data || {}
        );

        const formattedStablePointsData = await formatData(
            stablePointsArray,
            1
        );

        setUserStablePoints(
            formattedStablePointsData
        );
    };


    const getPointsRewards = async (address) => {

        try {
            const result = await fetchRewardsData(
                address,
                SEASON_API_HASH,
                SEASON_CONTRACT_HASH
            );

            setRewardClaimed(
                result?.message === "already claimed"
            );

            if (result?.reward === 0) {
                setRewardData(0);
            }

            if (result?.reward) {
                setRewardData(
                    result
                );
            }
        }
        catch (err) {
            console.log(err);
            setRewardData(0);
        }
    };

    const tokenPriceDataETH = {
        price: TVL?.ethUsd ?? 0,
        decimals: DEFAULT_DECIMALS,
        token: 'ETH'
    };

    const tokenPriceData = {
        price: TVL?.wiseUsd ?? 0,
        decimals: DEFAULT_DECIMALS,
        token: 'WISE'
    };

    useEffect(() => {
        getData();
        if (address) {
            getPointsRewards(address);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, TVL]);

    const getTableData = async () => {

        setIsTableLoading(true);
        setTableData([]);

        const data = activeTab === PointsV2Navigation.ETHER
            ? etherUserPoints
            : stableUserPoints;

        setTableData(
            data
        );

        setIsTableLoading(false);
    };

    useEffect(() => {
        if (stableUserPoints?.length && etherUserPoints?.length) {
            getTableData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stableUserPoints, etherUserPoints, activeTab]);

    const currentUserRank = tableData.findIndex(
        item => address?.toString().toLowerCase() === item.userAddress?.toString().toLowerCase()
    );

    const promoter = pointsSummary
        ?.[PointTypes.WISE]
        ?.promoterAddress;

    const loading = isPointsSummaryLoading
        || isRefereesLoading;

    const shouldShowJoinReferralCard = _no(isConnected)
        || loading
        || _no(promoter);

    // @TODO - put to proper config
    const season1EndDate = '2025-03-14T00:00:00';

    const isFetching = useMemo(() => {
        return !etherUserPoints.length || !stableUserPoints.length;
    }, [
        etherUserPoints,
        stableUserPoints
    ]);

    return (
        <>
            <div className={styles.section}>
                <Overview
                    className={styles.card}
                    currentAddress={address}
                    etherData={etherUserPoints}
                    stableData={stableUserPoints}
                    isFetching={isFetching}
                    activeTab={activeTab}
                    onChange={(tab) => setActiveTab(tab)}
                    seasonEndDate={season1EndDate}
                />
                <div className={styles.row}>
                    <div className={styles.col}>
                        <BoardOverview
                            isFetching={isFetching || isTableLoading}
                            activeTab={activeTab}
                            tableData={tableData}
                            onChange={(tab) => setActiveTab(tab)}
                            currentUserRank={currentUserRank}
                        />
                    </div>
                    <div className={styles.col}>
                        {rewardData?.reward > 0 &&
                            <ClaimRewards
                                className={styles.card}
                                rewardData={rewardData}
                                tokenPriceData={tokenPriceData}
                                isClaimed={rewardClaimed}
                                userAccount={address}
                            />}
                        <CreateReferral className={styles.card} onShowCustomerList={setShowCustomerList} />
                        {
                            shouldShowJoinReferralCard ? (
                                <UseReferral
                                    className={styles.card}
                                    onJoinReferralNetworkSuccess={
                                        () => fetchPoints(address)
                                    }
                                    defaultReferralNetwork={initialReferralAddress}
                                    loading={loading}
                                />
                            ) : (
                                <JoinedReferralNetwork
                                    className={styles.card}
                                    referrerAddress={
                                        promoter
                                    }
                                />
                            )
                        }
                        {!rewardData &&
                            <ClaimRewards
                                className={styles.card}
                                rewardData={rewardData}
                                tokenPriceData={tokenPriceData}
                                isClaimed={rewardClaimed}
                                userAccount={address} />
                        }
                        {/*<ProductViews />*/}
                    </div>
                </div>
                <Modal
                    outerClassName={styles.referralsOuter}
                    visible={showCustomerList}
                    onClose={() => setShowCustomerList(false)}
                >
                    <CustomerList
                        referees={referees}
                        loading={isRefereesLoading}
                    />
                </Modal>
                {/*<Referrals />*/}
            </div>
            <TooltipGlobal />
        </>
    );
};

export default LeadBoards;
