import { useState, useEffect } from "react";
import { formatUnits } from "viem";
import { useReadContract } from "wagmi";
import { _notEmpty, _no } from "../utils";
import { DEFAULT_DECIMALS, ERROR_TOKEN_BORROW_RATES } from "../utils/config";

import {
    getLendingTokenAddress,
    isEnabled
} from "../ethers/tokens";

export const useAPYEstimate = ({
    config,
    token,
    amount,
    leverage,
    apr
}) => {

    const {
        chainId,
        wiseLendingContract
    } = config;

    const tokenAddress = getLendingTokenAddress(
        token,
        token.isAavePool
    );

    const enabled = _notEmpty(tokenAddress) && isEnabled({
        config,
        nftId: null,
        token,
        allowNullNftId: true
    });

    const [netAPY, setNetAPY] = useState(0);
    const [supplyAPY, setSupplyAPY] = useState(0);
    const [isPositive, setIsPositive] = useState(true);
    const [borrowRates, setBorrowRates] = useState(0);

    const { data, isLoading } = useReadContract({
        chainId,
        abi: wiseLendingContract?.abi,
        address: wiseLendingContract?.address,
        functionName: "borrowRatesData",
        query: {
            enabled,
        },
        args: [
            tokenAddress
        ],
        onError: (error) => {
            console.error(
                ERROR_TOKEN_BORROW_RATES,
                error
            );
        },
    });

    useEffect(() => {
        if (data) {
            let pole;
            let multiplicativeFactor;
            let poleFormatted = "0";
            let multiplicativeFactorFormatted = "0";
            const borrowRatesData = data;
            if (borrowRatesData) {
                pole = borrowRatesData[0];
                poleFormatted = formatUnits(
                    pole,
                    DEFAULT_DECIMALS
                );

                multiplicativeFactor = borrowRatesData[4];
                multiplicativeFactorFormatted = formatUnits(
                    multiplicativeFactor,
                    DEFAULT_DECIMALS
                );
            }

            setBorrowRates((prev) => {
                return {
                    ...prev,
                    pole,
                    poleFormatted,
                    multiplicativeFactor,
                    multiplicativeFactorFormatted,
                };
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const getNewBorrowRate = (borrowAmount) => {

        if (borrowAmount > token.poolFormatted) {
            borrowAmount = token.poolFormatted;
        }

        if (borrowRates) {

            if (token?.pool > token?.total) {
                return 0;
            }

            const newUtilization = PRECISION_FACTOR_E18 - (PRECISION_FACTOR_E18
                * parseFloat(token.poolFormatted) - borrowAmount)
                / parseFloat(token.totalFormatted);

            const pole = borrowRates.poleFormatted;
            const mulFactor = borrowRates.multiplicativeFactorFormatted;

            const baseDivider = pole
                * (pole - newUtilization);

            return mulFactor
                * PRECISION_FACTOR_E18
                * newUtilization
                / baseDivider;
        }

        return 0;
    };

    const PRECISION_FACTOR_E18 = 1;
    const MIN_INPUT = 0.001;

    const getApproxNetAPY = (initialAmount, leverage, assetBaseAPY) => {

        assetBaseAPY = assetBaseAPY / 100;

        const leverageAmount = initialAmount
            * leverage;

        const flashLoanAmount = leverageAmount
            - initialAmount;

        const newBorrowRate = getNewBorrowRate(
            flashLoanAmount
        );

        if (initialAmount < MIN_INPUT || _no(assetBaseAPY) || _no(leverage) || _no(newBorrowRate)) {
            return {
                isPositive: true,
                netAPY: 0.00
            };
        }

        const leveragePositiveAPY = assetBaseAPY;

        /*
        const leverageNegativeAPY = newBorrowRate
            * (leverage - PRECISION_FACTOR_E18)
            / PRECISION_FACTOR_E18;
        */

        // const leverageNegativeAPY = newBorrowRate;

        const netAPY = leverage * assetBaseAPY - (leverage - 1) * newBorrowRate
        // leveragePositiveAPY - leverageNegativeAPY;

        const isPositive = netAPY > 0;

        /*
        const netAPY = isPositive
            ? leveragePositiveAPY - leverageNegativeAPY
            : leverageNegativeAPY - leveragePositiveAPY;
        */

        return {
            isPositive,
            netAPY: netAPY * 100,
            supplyAPY: leveragePositiveAPY * 100
        };
    };

    useEffect(() => {
        if (borrowRates) {

            const { isPositive, netAPY, supplyAPY } = getApproxNetAPY(
                amount,
                leverage,
                apr
            );

            setIsPositive(
                isPositive
            );

            setNetAPY(
                netAPY
            );

            setSupplyAPY(
                supplyAPY
            );

            // let optimalValue;
            // let optimalLeverageLoop;

            /*
            for (let i = 1; i <= 14; i++) {

                const { isPositive, netAPY } = getApproxNetAPY(
                    amount,
                    i + 1,
                    apr
                );

                let apyValue = netAPY;

                if (_no(isPositive) && netAPY > 0) {
                    apyValue = netAPY * -1;
                }

                if (_no(optimalValue)) {
                    optimalValue = apyValue;
                    optimalLeverageLoop = i;
                }

                if (apyValue > optimalValue) {
                    optimalValue = apyValue;
                    optimalLeverageLoop = i;
                }
            }
            */

            /*
            setOptimalLeverageMul(
                optimalLeverageLoop
            );

            setOptimalLeverageLoop(
                optimalValue
            );
            */
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount, leverage, token, borrowRates]);

    return {
        netAPY,
        supplyAPY,
        isPositive,
        isLoading,
        // optimalLeverageLoop,
        // optimalLeverageMul
    };
};
