import React from "react";
import styles from "./Details.module.sass";
import cn from "classnames";
import Product from "./Product";
import Parameter from "./Parameter";
import TooltipGlobal from "../../../../components/TooltipGlobal";
// import Editor from "../../../../components/Editor";

const approvals = [
    "Approved WiseLending routing contract",
    "Approved LiquidNFTs routing contract",
];

const Details = ({ item }) => {
    // const [content, setContent] = useState();

    return (
        <>
            <div className={styles.details}>
                <div className={cn("title-purple", styles.title)}>
                    Loan Details
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <Product className={styles.product} item={item} />
                        <div className={styles.parameters}>
                            {item.parameters.map((x, index) => (
                                <Parameter item={x} key={index} />
                            ))}
                        </div>
                        <div className={styles.btns}>
                            <button className={cn("button-stroke", styles.button)}>
                                Cancel Action
                            </button>
                            <button className={cn("button", styles.button)}>
                                Confirm Action
                            </button>
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.group}>
                            <div className={styles.box}>
                                <div className={styles.info}>Approvals</div>
                                <ul className={styles.list}>
                                    {approvals.map((x, index) => (
                                        <li key={index}>{x}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className={styles.box}>
                                <div className={styles.user}>
                                    <div className={styles.avatar}>
                                        <img src={item.avatar} alt="Avatar" />
                                    </div>
                                    {item.man}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TooltipGlobal />
        </>
    );
};

export default Details;
