import cn from "classnames";
import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import { fromHex } from "viem";
import { formatUnits, parseUnits } from "viem";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import Checkbox from "../../../../components/Checkbox";
import Icon from "../../../../components/Icon";
import Loader from "../../../../components/Loader";
import SuccessPanel from "../../../../components/Modal/SuccessPanel";
import Scrollbar from "../../../../components/Scrollbar";
import Parameter from "../../../../components/Table/Row/Parameter";
import Product from "../../../../components/Table/Row/Product";
import TokenAmountInput from "../../../../components/TokenAmountInput";
import TooltipGlobal from "../../../../components/TooltipGlobal";
import useConfig from "../../../../customHooks/useConfig";
import { useTokenAllowance } from "../../../../customHooks/useTokenAllowance";
import { useOpenRwaDeposit } from "../../../../customHooks/useOpenRwaPosition";
import { usePrivy } from "@privy-io/react-auth";
import {
    _no,
    computeUSDAmount,
    formatDecimals,
    formatMoney,
    formatMoneyDashed,
    removeNonNumeric,
    toPrecision,
    showToast,
} from "../../../../utils";
import {
    // APPROVE_IN_WALLET_TEXT,
    APPROVING_TEXT,
    // CLICK_TO_VIEW,
    CONFIRM_TEXT,
    DEFAULT_DECIMALS,
    DONE_TEXT,
    GREEN_STATUS,
    INSUFFICIENT_COST,
    OPENING_TEXT,
    OPEN_FAILED_MESSAGE,
    OPEN_SUCCESS,
    RED_STATUS,
    // TRY_AGAIN_TEXT,
    YEAR_DAYS,
} from "../../../../utils/config";
import styles from "./OpenRwaFarmDetails.module.sass";

const MIN_INPUT_AMOUNT_USDC = 0;

const OpenRwaFarmDetails = ({
    item,
    token,
    onDone,
    toggleRefetch,
    // doRefetch,
    // tokenUtilization,
    // onClose,
}) => {
    const { isConnected } = useAccount();

    const {
        login,
    } = usePrivy();

    // const { open } = useWeb3Modal();
    const config = useConfig();
    const { linkBase } = config;

    const { address } = useSelector(
        (state) => state.settings
    );

    const { pricingFeeds } = useSelector(
        (state) => state.token
    );

    const tokenPriceData = pricingFeeds.find(
        item => item.name === token.name
    );

    const [amount, setAmount] = useState("");
    const [selectedToken, setSelectedToken] = useState(token);
    const [max, setMax] = useState(token.balanceFormatted);
    const [hasAccepted, setHasAccepted] = useState(false);

    const DEFAULT_TEXT = "Confirm Position";
    const DEFAULT_APPROVE_TEXT = "Approve Vault";

    const [buttonApproveText, setButtonApproveText] = useState(
        DEFAULT_APPROVE_TEXT
    );
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [isLoading, setIsLoading] = useState(false);
    const [successfullOpen, setSuccessfullOpen] = useState();
    const [finishOpen, setFinishOpen] = useState(false);
    const [transactionHashLink, setTransactionHashLink] = useState("");

    // const dispatch = useDispatch();

    useEffect(() => {
        setSelectedToken(token);
        setMax(token.balanceFormatted);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token?.name, token?.balanceFormatted]);

    const convertedAmount = removeNonNumeric(
        amount || 0
    );

    const pureAmountInUSD = computeUSDAmount(
        DEFAULT_DECIMALS,
        convertedAmount,
        tokenPriceData?.price
    );

    const positionAmount = parseUnits(
        convertedAmount.toString(),
        selectedToken.decimals
    );

    const tokenAllowance = useTokenAllowance({
        config,
        token: selectedToken,
        userAccount: address,
        amount: positionAmount,
        customContract: config.ForwardingDepositUSDCContract
    });

    const [allowance, setAllowance] = useState(0);

    const preApprovedDefault = _no(finishOpen)
        ? allowance && allowance >= positionAmount
        : true;

    const [preApproved, setPreApproved] = useState(
        preApprovedDefault
    );

    const showApproval = amount
        && _no(preApproved)
        && _no(finishOpen);

    const openPosition = useOpenRwaDeposit({
        config,
        showApproval,
        amount: positionAmount,
        token: selectedToken,
    });

    // const isDefault = buttonText === DEFAULT_TEXT;

    const formattedAmount = formatMoneyDashed(
        amount
    );

    const OPEN_SUCCESS_MESSAGE = `${OPEN_SUCCESS} ${formattedAmount} ${selectedToken.name} position`;

    const handleError = (err) => {

        const error = err?.message || err;

        showToast(
            error
        );
    };

    const handleApprove = () => {
        tokenAllowance.approve({
            onConfirmation: () => {
                setButtonApproveText(
                    CONFIRM_TEXT
                );
                setIsLoading(true);
            }
        });
    };

    useEffect(() => {
        if (tokenAllowance.allowance) {
            setAllowance(
                tokenAllowance.allowance
            );

            if (tokenAllowance.allowance >= positionAmount) {
                setPreApproved(true);
            }

            if (tokenAllowance.allowance < positionAmount) {
                setPreApproved(false);
            }
        }
    }, [tokenAllowance.allowance, positionAmount]);

    useEffect(() => {

        if (tokenAllowance.hasError && isLoading && buttonApproveText === CONFIRM_TEXT) {

            setIsLoading(false);
            // setTransactionHash("");
            setButtonApproveText(
                DEFAULT_APPROVE_TEXT
            );

            handleError(
                tokenAllowance.error
            );
        }

        if (tokenAllowance.error && isLoading && buttonApproveText === CONFIRM_TEXT) {
            setIsLoading(false);
            handleError(
                tokenAllowance.error
            );
            // setTransactionHash("");
            setButtonApproveText(
                DEFAULT_APPROVE_TEXT
            );
        }

        if (tokenAllowance.isConfirming && isLoading) {
            setButtonApproveText(
                APPROVING_TEXT
            );
        }

        if (tokenAllowance.isConfirmed && isLoading && buttonApproveText === APPROVING_TEXT) {
            setIsLoading(false);

            const { logs } = tokenAllowance.transactionData;

            const spendingCap = fromHex(
                logs[0].data,
                "bigint"
            );

            setAllowance(
                spendingCap
            );

            if (spendingCap >= positionAmount) {
                setPreApproved(true);
            }

            const spendingCapAmount = formatUnits(
                spendingCap,
                token.decimals
            );

            const formattedSpendingCapAmount = formatMoneyDashed(
                spendingCapAmount
            );

            showToast(
                `Approved ${formattedSpendingCapAmount} ${token.name} tokens for the vault`,
                {
                    type: "success",
                }
            );

            setButtonApproveText(
                DEFAULT_APPROVE_TEXT
            );
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenAllowance]);

    const handleQuickSelect = (option) => {

        const aboveZero = parseFloat(max) > 0;
        const amount = option === "max" && aboveZero
            ? formatDecimals(Math.min(max, item.capacity))
            : "";

        setAmount(
            amount
        );
    };

    const farmDetails = useMemo(() => {

        const yearlyInterest = Number(convertedAmount) * (item.APY / 100);
        const periodInterest = (yearlyInterest / YEAR_DAYS) * item.lockTime;

        return [
            {
                title: "Remaining Capacity",
                content: `${formatMoney(toPrecision(item.capacity))} ${token.name}`,
            },
            /*{
                title: "Position Amount",
                content: `${formatMoney(amount)} ${token.name}`,
            },*/
            {
                title: "Fixed APY",
                // tooltip: "NET APY",
                content: `${formatDecimals(item.APY)}%`,
                contentClassName: 'status-green-dark',
            },
            {
                title: "Payout Schedule",
                content: `${item.lockTime} Days`,
            },
            {
                title: "Estimated Rewards",
                tooltip: "Unlocked Every 30 Days",
                content: `${formatMoney(periodInterest)} ${token.name}`
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [convertedAmount]);

    const handleConfirm = async () => {

        if (finishOpen) {
            onDone();
            return;
        }

        openPosition.deposit({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonText(
                    CONFIRM_TEXT
                );
            }
        });
    };

    useEffect(() => {

        if (openPosition.hasError && isLoading) {
            setIsLoading(false);
            handleError(
                openPosition.hasError
            );
            setTransactionHashLink("");
            setButtonText(
                DEFAULT_TEXT
            );
        }
        if (openPosition.error && isLoading) {
            setIsLoading(false);
            handleError(
                openPosition.error
            );
            setTransactionHashLink("");
            setButtonText(
                DEFAULT_TEXT
            );
        }

        if (openPosition.isConfirming && isLoading) {
            setButtonText(
                OPENING_TEXT
            );
            setTransactionHashLink(
                `${linkBase}/tx/${openPosition.hash}`
            );
        }

        if (openPosition.isConfirmed && isLoading && buttonText === OPENING_TEXT) {
            setIsLoading(false);
            setButtonText(
                DONE_TEXT
            );
            setSuccessfullOpen(true);
            setFinishOpen(true);
            toggleRefetch();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openPosition]);

    const {
        estimatesGasFormatted
    } = openPosition;

    const totalTokenAmount = parseFloat(convertedAmount) + parseFloat(estimatesGasFormatted);

    const isTokenInsufficient = totalTokenAmount > parseFloat(max)
        && _no(isLoading)
        && _no(finishOpen);

    const isInsufficient = parseFloat(convertedAmount) > parseFloat(max) && _no(finishOpen);
    const isExceedingCapacity = _no(isInsufficient) && convertedAmount > item.capacity && _no(finishOpen);
    const isBelowMinimum = parseFloat(convertedAmount) < parseFloat(MIN_INPUT_AMOUNT_USDC) && _no(finishOpen);

    const disableApprove = isInsufficient
        || isLoading
        || isTokenInsufficient
        || isBelowMinimum
        || isExceedingCapacity
        || _no(hasAccepted);

    const disableConfirm = _no(amount)
        || isInsufficient
        || isLoading
        || isExceedingCapacity
        || isBelowMinimum
        || isTokenInsufficient
        || _no(hasAccepted);

    const confirmButtonText = useMemo(() => {
        if (_no(amount)) {
            return `Enter Initial Amount`;
        } else if (isInsufficient) {
            return `Insufficient ${selectedToken.name} Balance`;
        } else if (isTokenInsufficient) {
            return INSUFFICIENT_COST;
        } else if (isExceedingCapacity) {
            return `Exceeds Vault Capacity`;
        } else if (isBelowMinimum) {
            return `Minimum Borrow is ${MIN_INPUT_AMOUNT_USDC} ${token.name}`;
        } else if (_no(hasAccepted)) {
            return `Please Accept Conditions First`;
        }/*  else if (chainId !== arbitrum.id) {
            return `Switch to Arbitrum`;
        }*/

        return buttonText;
    }, [
        amount,
        isExceedingCapacity,
        hasAccepted,
        isBelowMinimum,
        isInsufficient,
        isTokenInsufficient,
        selectedToken.name,
        token.name,
        buttonText,
    ]);

    return (
        <>
            <div className={styles.details}>
                <div className={cn("title-green", styles.title)}>
                    Create Position
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <Scrollbar className={styles.scroll}>
                            <Product className={styles.product} item={{
                                ...item,
                                product: item.source,
                                link: item.description,
                            }} />
                            <div className={styles.parameters}>
                                {farmDetails.map((x, index) => (
                                    <Parameter item={x} key={index} />
                                ))}
                            </div>
                        </Scrollbar>
                    </div>
                    <div className={styles.col} style={{ paddingTop: "0px" }}>
                        <div className={styles.group}>
                            {finishOpen &&
                                <div className={styles.successWrapper}>
                                    <SuccessPanel />
                                    <div style={{ visibility: "hidden" }}>12</div>
                                    <div className={styles.text}>
                                        {successfullOpen
                                            ? OPEN_SUCCESS_MESSAGE
                                            : OPEN_FAILED_MESSAGE
                                        }
                                    </div>
                                </div>
                            }
                            {_no(finishOpen) &&
                                <>

                                    <div className={styles.box}>

                                        <div>
                                            <div className={styles.wrapperTitle}>
                                                <div>
                                                    Position Amount
                                                </div>
                                            </div>
                                            <TokenAmountInput
                                                token={selectedToken}
                                                disabled={isLoading}
                                            >
                                                <TokenAmountInput.Input
                                                    amount={amount}
                                                    onChange={setAmount}
                                                    showClearButton={amount && _no(isLoading)}
                                                    onClearButtonClick={() => handleQuickSelect("clear")}
                                                />
                                                <TokenAmountInput.Details
                                                    amountInUsd={pureAmountInUSD}
                                                    balance={max}
                                                    onClick={() => handleQuickSelect("max")}>
                                                    Your Balance
                                                </TokenAmountInput.Details>
                                            </TokenAmountInput>
                                            <div className={styles.wrapperTitle}>
                                                <div>
                                                    Position Conditions
                                                </div>
                                            </div>
                                            <div className={styles.wrapper}>
                                                <ul className={styles.spec}>
                                                    <li>Initial deposits are permanent<br/></li>
                                                    <li>Initial deposits cannot be withdrawn<br/></li>
                                                    <li>Position is represented by an NFT<br/></li>
                                                    <li>Position could be sold on a secondary market<br/></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.acceptConditionsField}>
                                        <Checkbox
                                            value={hasAccepted}
                                            content="Accept Conditions"
                                            onChange={() => {
                                                if (isLoading) return;
                                                setHasAccepted(!hasAccepted);
                                            }}
                                        />
                                        <div className={
                                            cn({
                                                [GREEN_STATUS]: hasAccepted,
                                                [RED_STATUS]: _no(hasAccepted)
                                            })
                                        }
                                            style={{fontSize: "14px"}}
                                        >
                                            {hasAccepted ? "Accepted" : "Must Accept"}
                                        </div>
                                    </div>
                                </>
                            }
                            <div className={styles.btnsWrapper}>
                                {isConnected && (
                                    <>
                                        <div className={styles.btns}>
                                            {(showApproval && _no(openPosition.isLoading) && _no(isExceedingCapacity)) ? (
                                                <button className={cn(
                                                    "button",
                                                    styles.button,
                                                    styles.full,
                                                    {
                                                        disabled: disableApprove,
                                                        [styles.disabled]: disableApprove
                                                    }
                                                )}
                                                    onClick={() => {
                                                        handleApprove();
                                                    }}
                                                >
                                                    {buttonApproveText === APPROVING_TEXT && <Loader className={styles.loader} />}
                                                    <span>
                                                        {buttonText !== confirmButtonText
                                                            ? confirmButtonText
                                                            : buttonApproveText
                                                        }
                                                    </span>
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={() => {
                                                        handleConfirm();
                                                    }}
                                                    className={cn(
                                                        "button",
                                                        styles.button,
                                                        styles.full,
                                                        {
                                                            disabled: disableConfirm,
                                                            [styles.disabled]: disableConfirm
                                                        })}>
                                                    {buttonText === OPENING_TEXT && <Loader className={styles.loader} />}
                                                    <span>{confirmButtonText}</span>
                                                </button>
                                            )
                                            }
                                        </div>
                                        {transactionHashLink && finishOpen &&
                                            <a
                                                className={cn(
                                                    "button",
                                                    "button-stroke",
                                                    styles.button,
                                                    styles.transaction
                                                )}
                                                href={transactionHashLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                View Transaction Details
                                            </a>
                                        }
                                    </>
                                )}
                                {_no(isConnected) &&
                                    <div className={styles.btns}>
                                        <button
                                            className={cn(
                                                "button",
                                                styles.button,
                                                styles.full
                                            )}
                                            onClick={() => {
                                                // onClose();
                                                login();
                                                // open();
                                            }}
                                        >
                                            <Icon name="profile-circle" size="24" />
                                            <span>Connect Account</span>
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TooltipGlobal />
        </>
    );
};

export default OpenRwaFarmDetails;
