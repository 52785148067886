import {
    useReadContract,
    useWriteContract,
    useWalletClient,
    useWaitForTransactionReceipt
} from "wagmi";

import {
    _no,
    // _no,
    _notEmpty,
} from "../utils";
import {
    ALLOWED_SPREAD,
    // CONFIRMATIONS_COUNT,
    DEFAULT_DECIMALS,
    // ERROR_FARM_CLOSE_POSITION,
    // ERROR_FARM_CLOSE_POSITION_WRITE,
    // ERROR_FARM_LP_AMOUNT,
} from "../utils/config";
// import { useState } from "react";
// import { goerli } from "viem/chains";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { formatUnits, parseUnits } from "viem";
import { useGasEstimate } from "./useGasEstimate";
import { useSimulateTransaction } from "./useSimulateTransaction";
// import { parseUnits } from "viem";

export const useCloseFarmPosition = ({
    config,
    nftId,
    amount,
    farmContract,
    farmItem,
    callData
}) => {

    const { data: signer } = useWalletClient();
    const { chainId, LPViewerContract } = config;
    const [amountLP, setAmountLP] = useState();

    const enabled = _notEmpty(
        farmContract?.address
    ) && _notEmpty(
        nftId
    );

    const { data, isLoading } = useReadContract({
        chainId,
        address: LPViewerContract?.address,
        abi: LPViewerContract?.abi,
        functionName: "getAmountLP",
        args: [
            nftId,
            farmContract?.address
        ],
        query: {
        enabled: enabled && _notEmpty(
            LPViewerContract?.address
        )
        },
    });

    useEffect(() => {
        if (_notEmpty(data)) {
            setAmountLP(
                data
            );
        }
    }, [data]);

    let allowedSpread = parseUnits(
        farmItem.allowedSpread ?? ALLOWED_SPREAD,
        DEFAULT_DECIMALS
    );

    if (farmItem.defaultToken === "DAI") {
        allowedSpread = parseUnits(
            "1.20",
            DEFAULT_DECIMALS
        );
    }

    let ethBack = false;

    if (farmItem.defaultToken === "ETH") {
        ethBack = false;
    }

    const functionName = "exitFarm";
    let args = [
        nftId,
        // minOutAmount,
        allowedSpread,
        ethBack
    ];

    if (callData) {
        args = [
            callData,
            ...args
        ];
    }

    const {
        // gasLimit,
        estimatedGas,
        estimatesGasFormatted,
        estimatedGasUSD,
        isError
    } = useGasEstimate({
        contract: farmContract,
        functionName,
        args,
        account: signer?.account,
        amount,
        enabled:
            callData
                ? true
                : false
        // otherData: minOutAmount
    });

    // @TODO: use only on Ethereum
    // let gasLimitFormatted = parseInt(
    //     parseInt(gasLimit) * INFLATE_PERCENT
    // ).toString();

    const {
        error,
        data: hash,
        writeContract,
        // writeContractAsync,
        isPending,
    } = useWriteContract();

    const transactionSimulation = useSimulateTransaction(
        chainId
    );

    const getZeroPaddedAddress = (address) => ethers
        .utils
        .hexZeroPad(
            address,
            32)
        ?.toLowerCase();

    const simulate = async (walletAddress) => {
        const token = farmItem?.token;
        const result = await transactionSimulation.simulate(
            [
                {
                    abi: farmContract?.abi,
                    address: farmContract?.address,
                    functionName,
                    args,
                    enabled,
                    returnFullResult: true
                }
            ],
        );

        let rewardValue = result
            ?.[0]
            ?.trace
            ?.find(item =>
                item.from.toLowerCase() === farmContract.address.toLowerCase()
                && item.to.toLowerCase() === walletAddress.toLowerCase()
            )
            ?.value;

        if (_no(rewardValue)) {
            const matchedLog = result?.[0]?.logs
                ?.filter(
                    (log) =>
                        log.raw?.topics?.[0] ===
                        ethers.utils.id("Transfer(address,address,uint256)")
                );

            const matchedTransferLog = matchedLog
                ?.find(
                    (log) =>
                        log.raw?.topics?.[1]?.toLowerCase() === getZeroPaddedAddress(farmContract.address)
                        && log.raw?.topics?.[2]?.toLowerCase() === getZeroPaddedAddress(walletAddress)
                );

            if (_no(matchedTransferLog)) return 0;

            rewardValue = ethers.BigNumber.from(
                matchedTransferLog.raw.data
            );
        }

        return formatUnits(
            rewardValue,
            token.decimals
        );
    };

    const closePosition = async ({
        onConfirmation,
        onCloseComplete,
        onClosingPosition,
        onError
    }) => {

        onConfirmation?.();

        writeContract({
            chainId,
            abi: farmContract?.abi,
            address: farmContract?.address,
            signerOrProvider: signer,
            // gas: gasLimitFormatted,
            functionName,
            args,
            query: {
                enabled
            },
        });
    };

    const {
        isError: hasError,
        isLoading: isConfirming,
        isSuccess: isConfirmed
    } = useWaitForTransactionReceipt({
        hash,
    });

    return {
        hash,
        error,
        hasError,
        closePosition,
        isPending,
        isConfirming,
        isConfirmed,
        isLoading: isLoading,
        amountLP,
        estimatedGas,
        estimatesGasFormatted,
        estimatedGasUSD,
        estimateHasError: isError,
        simulate
    };
};
