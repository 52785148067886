import React from "react";
import cn from "classnames";
import styles from "./Logo.module.sass";
// import Tooltip from "../Tooltip";

const Logo = ({
    className,
    logo,
    subLogo,
    width,
    height,
    srcSet
}) => {

    return (
        <div
            className={cn(
                styles.container,
                className
            )}
            style={{ width: `${width}px`, height: `${height}px` }}
        >
            <img
                src={logo}
                srcSet={srcSet}
                className={styles.logo} alt=""
            />
            {subLogo && (
                <div className={styles.subLogo} style={{
                    backgroundImage: `url(${subLogo})`
                }}>
                </div>
            )}

        </div>
    );
};

export default Logo;
