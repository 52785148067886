import { configureStore } from "@reduxjs/toolkit";

import settingsReducer from "./settingsSlice";
import tokenReducer from "./tokenSlice";

export const store = configureStore({
    reducer: {
        settings: settingsReducer,
        token: tokenReducer
    },
});
