import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const FarmingContext = createContext({
    activeFarms: [],
    expiredFarms: []
});

export const useFarmingContext = () => {
    const context = useContext(FarmingContext);

    if (!context) {
        return null;
    }

    return context;
};

export const FarmingContextProvider = ({ children }) => {
    const address = useSelector(
        (state) => state.settings.address
    );
    const [activeFarms, setActiveFarms] = useState([]);
    const [activeRWAs, setActiveRWAs] = useState([]);
    const [expiredFarms, setExpiredFarms] = useState([]);
    const [calculatedProperties, setCalculatedProperties] = useState({});

    const addUpdateActiveFarm = (farm) => {
        setActiveFarms(prev => {
            const currentFarm = prev.find(existingFarm => existingFarm.id === farm.id);

            if (currentFarm) {
                const updatedFarm = { ...currentFarm, ...farm };
                return [
                    ...prev.filter(existingFarm => existingFarm.id !== updatedFarm.id),
                    updatedFarm
                ];
            }
            return [...prev, farm];
        });
    };

    const addUpdateActiveRWA = (farm) => {
        setActiveRWAs(prev => {
            const currentFarm = prev.find(existingFarm => existingFarm.id === farm.id);

            if (currentFarm) {
                const updatedFarm = { ...currentFarm, ...farm };
                return [
                    ...prev.filter(existingFarm => existingFarm.id !== updatedFarm.id),
                    updatedFarm
                ];
            }
            return [...prev, farm];
        });
    };

    const addUpdateExpiredFarm = (farm) => {
        setExpiredFarms(prev => {
            const currentFarm = prev.find(existingFarm => existingFarm.id === farm.id);

            if (currentFarm) {
                const updatedFarm = { ...currentFarm, ...farm };
                return [
                    ...prev.filter(existingFarm => existingFarm.id !== updatedFarm.id),
                    updatedFarm
                ];
            }
            return [...prev, farm];
        });
    };

    const addOpenFarmAPY = (token, apy) => {
        setCalculatedProperties(prev => {
            return {
                ...prev,
                farmAPYs: {
                    ...prev.farmAPYs,
                    [token]: apy
                }
            };
        });
    };

    const clearFarms = () => {
        setActiveFarms([]);
        setExpiredFarms([]);
    };

    useEffect(() => {
        setCalculatedProperties({});
    }, [address]);

    return (
        <FarmingContext.Provider value={{
            activeFarms,
            addUpdateActiveFarm,
            expiredFarms,
            addUpdateExpiredFarm,
            activeRWAs,
            addUpdateActiveRWA,
            clearFarms,
            setActiveFarms,
            setExpiredFarms,
            addOpenFarmAPY,
            farmAPYs: calculatedProperties.farmAPYs,
        }}
        >
            {children}
        </FarmingContext.Provider>
    );
};
