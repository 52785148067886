import { useEffect } from "react";
import { useReadContract, useBlockNumber } from "wagmi";
import { useQueryClient } from '@tanstack/react-query'
// import { ERROR_NFTIDS, ERROR_RWA_READ } from "../utils/config";

const useRWAPositionDetails = ({
    config,
    positionIndex,
    userAddress,
    // refetch
}) => {

    const { chainId, ForwardingDepositUSDCContract } = config;

    const { data: rwaNFTPosition, isLoading: loadingData, queryKey: indexKey } = useReadContract({
        chainId,
        address: ForwardingDepositUSDCContract?.address,
        abi: ForwardingDepositUSDCContract?.abi,
        functionName: "tokenOfOwnerByIndex",
        args: [
            userAddress,
            positionIndex
        ],
        enabled: positionIndex >= 0,
        // watch: true,
    });

    const {data: rwaPositionData, isLoading: loadingPosition, queryKey: anotherKey } = useReadContract({
        chainId,
        address: ForwardingDepositUSDCContract?.address,
        abi: ForwardingDepositUSDCContract?.abi,
        functionName: "positions",
        args: [
            rwaNFTPosition
        ],
        query: {
            enabled: rwaNFTPosition => 0,
        },
        // watch: true,
    });

    const { data: rwaRewardsData, isLoading: loadingRewards, queryKey } = useReadContract({
        chainId,
        address: ForwardingDepositUSDCContract?.address,
        abi: ForwardingDepositUSDCContract?.abi,
        functionName: "getRewards",
        args: [
            rwaNFTPosition
        ],
    });

    const queryClient = useQueryClient();
    const { data: blockNumber } = useBlockNumber({
        watch: true
    });

    useEffect(() => {
        queryClient.invalidateQueries({
            anotherKey
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        blockNumber,
        queryClient,
        chainId
    ]);

    useEffect(() => {
        queryClient.invalidateQueries({
            indexKey
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        blockNumber,
        queryClient,
        chainId
    ]);

    useEffect(() => {
        queryClient.invalidateQueries({
            queryKey
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        blockNumber,
        queryClient,
        chainId
    ]);

    const rewardData = {
        ...rwaRewardsData,
        rewards: rwaRewardsData ? rwaRewardsData[2] : 0,
    }

    return {
        rwaRewardsData: rewardData,
        rwaPositionData,
        rwaNFTPosition,
        isFetching: loadingData || loadingPosition || loadingRewards
    };
};

export default useRWAPositionDetails;
