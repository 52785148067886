
export const market = [
    {
        id: 0,
        product: "Position #1",
        category: "World Mobile Vault",
        image: "/images/content/product-pic-1.jpg",
        image2x: "/images/content/product-pic-1@2x.jpg",
        status: true,
        price: 98,
        amount: 3200,
        balance: 37.8,
        views: 79850,
        viewsPercent: 70,
        likesPercent: 75,
        likes: 111,
    },
    {
        id: 1,
        product: "Position #2",
        category: "World Mobile Vault",
        image: "/images/content/product-pic-2.jpg",
        image2x: "/images/content/product-pic-2@2x.jpg",
        status: false,
        price: 48,
        amount: 2732,
        balance: 27.5,
        views: 30432,
        viewsPercent: 50,
        likesPercent: 50,
        likes: 17,
    },
];

export const released = [
    {
        id: 0,
        product: "Bento Matte 3D Illustration",
        category: "UI design kit",
        image: "/images/content/product-pic-1.jpg",
        image2x: "/images/content/product-pic-1@2x.jpg",
        price: 98,
        title: "ETH Pool",
        icon: "/images/icons/eth.svg",
        status: true,
        ratingValue: 4.8,
        ratingCounter: 87,
        amount: 3200,
        balance: 55.8,
        views: "90%",
        viewsPercent: 90
    },
    {
        id: 1,
        product: "DSM - Geometry pattern",
        category: "UI design kit",
        image: "/images/content/product-pic-2.jpg",
        image2x: "/images/content/product-pic-2@2x.jpg",
        price: 123,
        title: "USDC Pool",
        icon: "/images/icons/usdc.svg",
        status: false,
        ratingValue: 4.6,
        ratingCounter: 127,
        amount: 4900,
        balance: 35.8,
        views: "50%",
        viewsPercent: 50
    },
    {
        id: 2,
        product: "Node - Crypto iOS UI design kit",
        category: "UI design kit",
        image: "/images/content/product-pic-3.jpg",
        image2x: "/images/content/product-pic-3@2x.jpg",
        price: 0,
        title: "DAI Pool",
        icon: "/images/icons/dai.svg",
        status: true,
        amount: 5600,
        balance: -15.8,
        views: "40%",
        viewsPercent: 40
    },
    {
        id: 4,
        product: "Academe 3D Education Icons",
        category: "UI design kit",
        image: "/images/content/product-pic-5.jpg",
        image2x: "/images/content/product-pic-5@2x.jpg",
        price: 45,
        title: "USDT Pool",
        icon: "/images/icons/usdt.svg",
        status: true,
        ratingValue: 4.8,
        ratingCounter: 87,
        amount: 3200,
        balance: 55.8,
        views: "80%",
        viewsPercent: 80
    },
];
