// import { useState } from "react";
import { useReadContract } from "wagmi";
import { useEffect } from "react";
import { useQueryClient } from '@tanstack/react-query'

const useWiseReferrals = ({
    config,
    address,
    page,
    perPage,
    doRefetch,
    offset = 0
}) => {

    const { chainId, WiseTokenContract } = config;


    const { data: referrals, isFetching, queryKey } = useReadContract({
        chainId,
        address: WiseTokenContract?.address,
        abi: WiseTokenContract?.abi,
        functionName: "referralsPagination",
        args: [
            address,
            page * perPage - offset,
            perPage
        ],
        query: {
            enabled: address,
        }
    });

    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.invalidateQueries({
            queryKey
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // userAccount,
        doRefetch
    ]);

    const { data: referralCount, isFetching: fetchingCount } = useReadContract({
        chainId,
        address: WiseTokenContract?.address,
        abi: WiseTokenContract?.abi,
        functionName: "referralCount",
        args: [
            address,
        ],
        query: {
            enabled: address,
        }
    });

    let referralCountFormatted = referralCount
        ? parseInt(referralCount)
        : 0;

    return {
        referrals,
        referralCount,
        referralCountFormatted,
        isFetching: isFetching || fetchingCount
    };
};

export default useWiseReferrals;
