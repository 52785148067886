import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./TopPools.module.sass";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import { MAIN_COLOR_A } from "../../../utils/config";
import { formatMoneyDashed } from "../../../utils";

//import useDarkMode from "use-dark-mode";

const CustomTooltip = ({ active, payload, label }) => {
    const details = payload?.[0];
    if (active && payload && payload.length) {
        return (
            <div className={styles.tooltip}>
                <div className={styles.title}>{label}</div>
                <div className={styles.label}>
                    <div className={styles.item}>
                        <div>Utilized:</div>
                        <div style={{ color: details.fill }}>
                            {details.payload.rate}
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div>Available:</div>
                        <div style={{ color: details.fill }}>
                            {formatMoneyDashed(
                                details.payload.available,
                                true
                            )}
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div>Supplied:</div>
                        <div style={{ color: details.fill }}>
                            {formatMoneyDashed(
                                details.payload.supplied,
                                true
                            )}
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div>Borrowed:</div>
                        <div style={{ color: details.fill }}>
                            {formatMoneyDashed(
                                details.payload.borrowed,
                                true
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return null;
};

const TopPools = ({ className, tokensUtilization }) => {
    const history = useHistory();

    const handleBarClick = (data) => {
        history.push(`/lending/pool/${data.name.toLowerCase()}`);
    };

    //const darkMode = useDarkMode(false);
    const darkMode = false;

    const data = tokensUtilization?.map((token) => (
        {
            name: token.name,
            utilized: token.utilizationPercentage,
            rate: token.utilizationPercentageFormatted,
            supplied: token.suppliedUSD,
            borrowed: token.borrowedUSD,
            available: token.availableUSD,

        }
    ));

    return (
        <div className={styles.chart}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    style={{cursor: "pointer"}}
                    width={500}
                    height={300}
                    data={data}
                    layout="vertical"
                    margin={{
                        top: 0,
                        right: 0,
                        left: 10,
                        bottom: 0,
                    }}
                    barSize={30}
                    barGap={35}
                    onClick={(e) => {
                        const clickedBarData = e.activePayload
                            ? e.activePayload[0].payload
                            : null;
                        if (clickedBarData) {
                            handleBarClick(clickedBarData);
                        }
                    }}
                >
                    <CartesianGrid
                        strokeDasharray="none"
                        stroke={darkMode.value ? "#272B30" : "#EFEFEF"}
                        horizontal={false}
                    />
                    <XAxis
                        type="number"
                        axisLine={false}
                        tickLine={false}
                        tick={{ fontSize: 14, width: "150", whiteSpace: "nowrap", fontWeight: "500", fill: "#6F767E" }}
                        padding={{ left: 0 }}
                    />
                    <YAxis
                        type="category"
                        dataKey="name"
                        axisLine={false}
                        tickLine={false}
                        tick={{ fontSize: 14, width: "180", whiteSpace: "nowrap", fontWeight: "500", fill: "#FFFFFF" }}
                        padding={{ left: 20 }}
                    />
                    <YAxis
                        type="category"
                        dataKey="supplied"
                        axisLine={false}
                        tickLine={false}
                        tick={{ fontSize: 12, width: "180", whiteSpace: "nowrap", fontWeight: "500", fill: "#6F767E" }}
                    />
                    <Tooltip
                        content={<CustomTooltip />}
                        cursor={{ fill: "#f3f2f3" }}
                    />
                    <Bar
                        dataKey="utilized"
                        fill={MAIN_COLOR_A}
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default TopPools;
