import { progress } from "../utils";
import { MAIN_COLOR_A, MAIN_COLOR_B } from "../utils/config";

export const traffic = [
    {
        id: 0,
        product: "Bento",
        date: "25 Sep - 4 Oct",
        image: "/images/content/product-pic-1.jpg",
        image2x: "/images/content/product-pic-1@2x.jpg",
        progress: progress(),
        items: [
            {
                title: "Market",
                color: "#FFBC99",
                counter: 550000,
                width: "20%",
            },
            {
                title: "UI8",
                color: MAIN_COLOR_B,
                counter: 512350,
                width: "20%",
            },
            {
                title: "Others",
                color: "#FFD88D",
                counter: 498000,
                width: "60%",
            },
        ],
    },
    {
        id: 1,
        product: "Node - Crypto iOS UI design kit",
        date: "25 Sep - 4 Oct",
        image: "/images/content/product-pic-2.jpg",
        image2x: "/images/content/product-pic-2@2x.jpg",
        progress: progress(),
        items: [
            {
                title: "Market",
                color: "#FFBC99",
                counter: 550000,
                width: "20%",
            },
            {
                title: "Social media",
                color: "#f99191",
                counter: 320000,
                width: "12%",
            },
            {
                title: "Direct",
                color: MAIN_COLOR_A,
                counter: 650550,
                width: "30%",
            },
            {
                title: "UI8",
                color: MAIN_COLOR_B,
                counter: 512350,
                width: "20%",
            },
            {
                title: "Others",
                color: "#FFD88D",
                counter: 498000,
                width: "18%",
            },
        ],
    },
];
