import {
    useWriteContract,
    useWalletClient,
    useWaitForTransactionReceipt
} from "wagmi";
import { getLendingContract } from "../ethers/contracts";
import { isEnabled } from "../ethers/tokens";
import { _getETHValue } from "../utils";
import {
    ERROR_REPAY,
    NATIVE_TOKENS
} from "../utils/config";
import { useGasEstimate } from "./useGasEstimate";
// import { parseUnits } from "viem";

export const useRepayToken = ({
    config,
    nftId,
    token,
    amount,
    isMax,
    showApproval
}) => {

    const isETH = NATIVE_TOKENS.includes(
        token.name
    );

    const { data: signer } = useWalletClient();
    const { chainId } = config;

    const contract = getLendingContract(
        config,
        token.isAavePool
    );

    let functionName = isETH
        ? "paybackExactAmountETH"
        : "paybackExactAmount";

    let args = isETH
        ?
        [
            nftId
        ]
        :
        [
            nftId,
            token?.address,
            amount
        ];

    const enabled = isEnabled({
        config,
        nftId,
        token
    });

    if (isMax && isETH === false) {
        functionName = "paybackExactShares";
        args = [
            nftId,
            token?.address,
            token.borrowShareBalance
        ];
    }

    if (isMax && isETH === true) {
        amount = amount * 1000n / 999n;
    }

    const value = _getETHValue(
        token.name,
        amount
    );

    const {
        // gasLimit,
        estimatedGas,
        estimatesGasFormatted,
        estimatedGasUSD,
        isError
    } = useGasEstimate({
        contract,
        functionName,
        args,
        value,
        account: signer?.account,
        amount,
        showApproval
    });

    /*
    let gasLimitFormatted = parseInt(
        parseInt(gasLimit) * INFLATE_PERCENT
    ).toString();
    */

    const {
        error,
        status,
        data: hash,
        writeContract,
        isPending,
    } = useWriteContract();

    const repay = async ({
        onConfirmation,
        onRepayComplete,
        onRepaying,
        onError
    }) => {
        try {

            onConfirmation?.();

            writeContract({
                chainId,
                abi: contract?.abi,
                address: contract?.address,
                signerOrProvider: signer,
                // gas: gasLimitFormatted,
                functionName,
                args,
                enabled,
                value,
            });

        } catch (error) {
            console.error(
                ERROR_REPAY,
                error
            );
            return false;
        }
    };

    const {
        isError: hasError,
        isLoading: isConfirming,
        isSuccess: isConfirmed
    } = useWaitForTransactionReceipt({
        hash,
    });

    return {
        hash,
        error,
        repay,
        status,
        hasError,
        isPending,
        isConfirming,
        isConfirmed,
        estimatedGas,
        estimatesGasFormatted,
        estimatedGasUSD,
        estimateHasError: isError
    };
};
