import { useEffect, useMemo, useState } from "react";
import { _no, computeUSDAmount, fetchPointsData } from "../utils";
import { PointTypes } from "../utils/config";
import { POOL_NAMES } from "../ethers/tokens";

const usePointsSummary = ({
    address,
    pricingFeeds
}) => {
    const [loading, setLoading] = useState(true);
    const [pointsSummary, setPointsSummary] = useState();
    const [shouldShowPlaceholder, setShouldShowPlaceholder] = useState(true);

    const ethPool = useMemo(() =>
        pricingFeeds
            ?.find(pool => pool.poolName === POOL_NAMES.ETH_NATIVE),
        [pricingFeeds]);

    const fetchPoints = async (address) => {
        setLoading(true);

        try {
            const result = await fetchPointsData(
                address
            );

            const pointTypesValueMap = {
                [PointTypes.WISE]: {
                    total: result.combined.totalPoints,
                    rate: result.combined.rewardRate,
                    promoterAddress: result.points.promoterAddress
                },
                [PointTypes.ETHERFI]: {
                    total: result.points.totalPoints,
                    rate: result.points.currentRewardRate,
                    deposited: Number(
                        computeUSDAmount(
                            ethPool.decimals,
                            result.deposited.depositedEth.toString(),
                            ethPool.price
                        )
                    ),
                    // promoterAddress: result.points.promoterAddress
                },
                [PointTypes.ETHENA]: {
                    total: result.stablePoints.totalPoints,
                    rate: result.stablePoints.currentRewardRate,
                    deposited: result.deposited.depositedStable,
                    // promoterAddress: result.stablePoints.promoterAddress
                },
                [PointTypes.REFERRAL]: {
                    total: 0,
                    rate: 0
                },
            }

            setPointsSummary(
                pointTypesValueMap
            );

            setShouldShowPlaceholder(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (_no(address) || _no(ethPool)) {
            setPointsSummary(null);
            setShouldShowPlaceholder(true);
        } else {
            fetchPoints(address);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, ethPool]);

    return {
        loading,
        pointsSummary,
        shouldShowPlaceholder,
        fetchPoints
    }

}

export default usePointsSummary;