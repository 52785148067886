import React, { useEffect, useState } from "react";
import { useSwitchChain } from "wagmi";
import { createPortal } from "react-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import cn from "classnames";

import { _no } from "../../utils";
import { getActiveChains } from "../../utils/config";
import styles from "./SwitchNetworkModal.module.sass";
import Loader from "../Loader";
import { useDispatch } from "react-redux";
import { setChainId } from "../../redux/tokenSlice";
import { showToast } from "../../utils";

const SwitchNetwork = ({
    visible,
    onClose
}) => {
    const {
        error,
        isLoading,
        isSuccess,
        pendingChainId,
        switchNetwork
    } = useSwitchChain();

    const dispatch = useDispatch();

    const activeChains = getActiveChains();
    const [selectedChain, setSelectedChain] = useState();

    useEffect(() => {
        if (visible) {
            const target = document.querySelector("#modal");
            disableBodyScroll(target);
        } else {
            clearAllBodyScrollLocks();
        }
    }, [visible]);

    useEffect(() => {
        if (error) {
            showToast(
                error.message,
                {
                    type: "error",
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (isSuccess) {
            showToast(
                `Successfully switched to ${selectedChain.id}`,
                {
                    type: "success",
                }
            );

            dispatch(
                setChainId(
                    selectedChain.chainId
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    const handleSwitch = (chain) => {

        setSelectedChain(
            chain
        );

        switchNetwork({ chainId: chain.id });
    };

    const SwitcherButton = ({ chain }) => {
        const showLoading = isLoading && chain.id === pendingChainId;

        return <button
            className={cn(
                "button-small",
                styles.button,
                {
                    disabled: _no(switchNetwork) || isLoading,
                }
            )}
            disabled={_no(switchNetwork) || isLoading}
            onClick={() => handleSwitch(chain)}
        >
            {showLoading
                ?
                <>
                    <Loader className={styles.loader} />
                    {`Switching to ...`}
                </>
                :
                <>
                    <img
                        src={chain.chainIcon}
                        className={styles.icon}
                        alt="network icon"
                    />
                    {chain.chainName}
                </>
            }
        </button>;
    };

    return createPortal(
        visible && (
            <div id="modal" className={styles.modal}>
                <div className={cn(styles.outer)}>
                    <div>
                        <div className={cn("title-blue", styles.title)}>
                            Switch Network
                        </div>
                        <div className={cn(styles.titleDesc)}>
                            Your wallet is connected to network we don't support.
                            You can switch to a supported network.
                        </div>
                        <div className={styles.wrapper}>
                            {activeChains.map((chain) => (
                                <SwitcherButton chain={chain} key={chain.chainId} />
                            ))}
                        </div>
                    </div>
                </div>
            </div >
        ),
        document.body
    );
};

export default SwitchNetwork;
