import { useState, useEffect } from "react";
import { formatUnits, parseUnits } from "viem";
import { useReadContract } from "wagmi";
import { _notEmpty } from "../utils";
import { DEFAULT_DECIMALS, ERROR_TOKEN_BORROW_RATES } from "../utils/config";

import {
    getLendingTokenAddress,
    isEnabled
} from "../ethers/tokens";

export const useAPYPredict = ({
    config,
    token,
    amount,
}) => {

    const PRECISION_FACTOR_E18 = parseUnits(
        "1",
        DEFAULT_DECIMALS
    );

    const {
        chainId,
        wiseLendingContract
    } = config;

    const tokenAddress = getLendingTokenAddress(
        token,
        token.isAavePool
    );

    const enabled = _notEmpty(tokenAddress) && isEnabled({
        config,
        nftId: null,
        token,
        allowNullNftId: true
    });

    const [borrowPredictedAPY, setBorrowPredictedAPY] = useState(0);
    const [borrowRates, setBorrowRates] = useState(0);

    const { data, isLoading } = useReadContract({
        chainId,
        abi: wiseLendingContract?.abi,
        address: wiseLendingContract?.address,
        functionName: "borrowRatesData",
        query: {
            enabled,
        },
        args: [
            tokenAddress
        ],
        onError: (error) => {
            console.error(
                ERROR_TOKEN_BORROW_RATES,
                error
            );
        },
    });

    useEffect(() => {
        if (data) {
            let pole;
            let multiplicativeFactor;
            let poleFormatted = "0";
            let multiplicativeFactorFormatted = "0";
            const borrowRatesData = data;

            if (borrowRatesData) {

                pole = borrowRatesData[0];
                poleFormatted = formatUnits(
                    pole,
                    DEFAULT_DECIMALS
                );

                multiplicativeFactor = borrowRatesData[4];
                multiplicativeFactorFormatted = formatUnits(
                    multiplicativeFactor,
                    DEFAULT_DECIMALS
                );
            }

            setBorrowRates((prev) => {
                return {
                    ...prev,
                    pole,
                    poleFormatted,
                    multiplicativeFactor,
                    multiplicativeFactorFormatted,
                };
            });
        }
    }, [data]);

    const { data: globalPoolData, isLoading: isLoadingData } = useReadContract({
        chainId,
        abi: wiseLendingContract?.abi,
        address: wiseLendingContract?.address,
        functionName: "globalPoolData",
        query: {
            enabled
        },
        args: [
            tokenAddress
        ],
        onError: (error) => {
            console.error(
                ERROR_TOKEN_BORROW_RATES,
                error
            );
        },
    });

    useEffect(() => {
        if (globalPoolData) {
            let poleFee;
            let poleFeeFormatted = "0";
            const globalPoolData = data;

            if (globalPoolData) {
                poleFee = globalPoolData[3];
                poleFeeFormatted = formatUnits(
                    poleFee,
                    DEFAULT_DECIMALS
                );
            }

            setBorrowRates((prev) => {
                return {
                    ...prev,
                    poleFee,
                    poleFeeFormatted
                };
            });
        }
    }, [globalPoolData, data]);

    const getNewBorrowRate = (amount) => {

        if (borrowRates) {

            if (token?.pool > token?.total) {
                return 0;
            }

            if (amount > token.pool) {
                amount = token.pool;
            }

            const totalTrue = token.pool
                - amount;

            const newUtilization = PRECISION_FACTOR_E18 - (
                PRECISION_FACTOR_E18
                * (totalTrue)
                / token.total
            );

            const baseDivider = borrowRates.pole
                * (borrowRates.pole - newUtilization);

            const borrowRate = borrowRates.multiplicativeFactor
                * newUtilization
                * PRECISION_FACTOR_E18
                / baseDivider;

            return formatUnits(
                borrowRate,
                DEFAULT_DECIMALS
            ) * 100;
        }

        return 0;
    };

    useEffect(() => {
        if (borrowRates) {
            const borrowPredictedAPY = getNewBorrowRate(
                amount
            );

            setBorrowPredictedAPY(
                borrowPredictedAPY
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount, token, borrowRates]);

    return { borrowPredictedAPY, isLoading: isLoading || isLoadingData };
};
