import styles from "./RiskLevel.module.sass";
import { formatDecimals } from "../../../../utils";


const RiskLevel = ({
    optimalLeverageLoop,
    netAPY,
    leverage,
    optimalLeverageMul,
    sourceAPY,
    borrowAPY
}) => {

    return (
        <div className={styles.riskLevel}>
            <div>Borrow APY / Source APY</div>
            <div className={styles.label}>
                <div className={"status-red"}>{formatDecimals(borrowAPY)}%</div>
                <div className={"status-green"}>{formatDecimals(sourceAPY)}%</div>
            </div>
        </div>
    );
};

export default RiskLevel;
