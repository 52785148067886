import React from "react";
import styles from "./SuccessPanel.module.sass";
const SuccessPanel = () => {
    return (
        <>
            <div className={styles.imageHolder}>
                <img
                    alt="success-background"
                    className={styles.backImage}
                    src="/images/success.svg">
                </img>
            </div >
            <div className={styles.successIcon}>
                <div className={styles.tip}></div>
                <div className={styles.long}></div>
            </div>
        </>
    );
};

export default SuccessPanel;
