import React from "react";
import styles from "./Parameter.module.sass";
import cn from "classnames";
import Tooltip from "../../../../components/Tooltip";
import { _no, _notEmpty } from "../../../../utils";
// correct path to constants
import { NO_REFERRAL } from "../../../../utils/config";


const Parameter = ({ item }) => {
    const showData = false;
    // const [showData, setShowData] = useState(false);
    let isGreen = parseFloat(item.content) >= 0;
    let status = isGreen
        ? "status-green-dark"
        : "status-red";

    if (item.title === "Duration Bonus") {
        status = "status-green";
    }

    if (item.title === "Referral Bonus") {
        status = "status-green";
    }

    if (item.title === "Referral Address") {
        status = "status-green";

        if (item.content === NO_REFERRAL) {
            status = "status-red";
        }
    }

    if (item.gray) {
        status = "status-green";
    }

    return (
        <div className={styles.parameterContainer}>
            <div className={cn(styles.parameter, { [styles.hasData]: item.data?.length })}
                // onClick={() => setShowData(!showData)}
            >
                <div className={styles.title}>
                    <span>
                        {item.title}
                    </span>
                    {item.tooltip ? (
                        <Tooltip
                            className={styles.tooltip}
                            title={item.tooltip}
                            icon="info-stroke"
                            place="right"
                        />
                    ) : null}
                </div>
                {item.content && !item.isAPY && (
                    <div className={cn(item.contentClassName, styles.content)}>
                        <div>{item.content}</div>
                        {_notEmpty(item.contentDescription) && (
                            <div className={styles.itemDescription}>
                                {item.contentDescription}
                            </div>
                        )}
                    </div>
                )}
                {item.isAPY &&
                    (item.isGreen ? (
                        <div className={cn(status,
                            {
                                [styles.big]: item.content
                            },
                                styles.big
                            )}
                        >
                            {item.content}
                        </div>
                    ) : (
                        <div className={cn(`${status}`, styles.big)}>{item.content}</div>
                    ))}
                {item.price && (
                    <div className={styles.price}>
                        <div>{item.price}</div>
                        {_notEmpty(item.priceDescription) && (
                            <div className={styles.itemDescription}>
                                {item.priceDescription}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className={cn(
                styles.data,
                {
                    [styles.hidden]: _no(showData)
                }
            )}>
                {item.data?.map((data, i) => (
                    <div className={styles.parameter} key={i}>
                        <div className={styles.title}>
                            <span
                                style={{ marginRight: "5px" }}
                            >
                                {data.title}
                            </span>
                        </div>
                        <div className={cn(
                            (data.isGreen
                                ? "status-green"
                                : "status-red"
                            ), styles.big
                        )}>{data.content}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Parameter;
