import React, { useMemo } from "react";
import styles from "./GlobalUtilization.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
// import Icon from "../../../components/Icon";
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";
import {
    // MAIN_COLOR_A,
    MAIN_COLOR_B
} from "../../../utils/config";
import { formatMoneyDashed, toPrecision } from "../../../utils";

const CustomTooltip = ({ active, payload, label }) => {
    const details = payload?.[0];
    if (active && payload && payload.length) {
        return (
            <div className={styles.tooltip}>
                <div className={styles.title}>{label}</div>
                <div className={styles.label}>
                    <div className={styles.item}>
                        <div>{details.name}:</div>
                        <div style={{ color: details.fill }}>
                            {formatMoneyDashed(
                                details.payload.value,
                                true
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return null;
};

const GlobalUtilization = ({
    className,
    widgetTitle,
    tokensUtilization,
    logo
}) => {

    const totalSuppliedUSD = tokensUtilization?.reduce((total, token) =>
        total += Number(token?.suppliedUSD || 0),
        0
    );

    const totalBorrowedUSD = tokensUtilization?.reduce((total, token) =>
        total += Number(token?.borrowedUSD || 0),
        0
    );

    const totalAvailableUSD = totalSuppliedUSD
        - totalBorrowedUSD;

    const overallUtilizationRate = totalBorrowedUSD
        / totalSuppliedUSD
        * 100;

    const totalUtilizationPercentageFormatted = useMemo(() =>
        isNaN(overallUtilizationRate)
            ? "—"
            : `${toPrecision(
                overallUtilizationRate,
                0
            )}%`,
        [overallUtilizationRate]);

    const data = useMemo(() => [
        {
            name: "Borrowed Funds",
            value: totalBorrowedUSD,
            color: MAIN_COLOR_B
        },
        {
            name: "Available Funds",
            value: totalAvailableUSD,
            color: "#33383F"
        },
    ], [
        totalAvailableUSD,
        totalBorrowedUSD
    ]);

    return (
        <Card
            className={cn(styles.card, className)}
            title={widgetTitle}
            classTitle={logo ? "title" : "title-blue"}
            icon={logo}
        >
            <div className={styles.chart}>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={400}>
                        <Tooltip content={<CustomTooltip />} />
                        <Pie
                            data={data}
                            cx={140}
                            cy={110}
                            innerRadius={88}
                            outerRadius={110}
                            fill="#8884d8"
                            paddingAngle={1}
                            dataKey="value"
                        >
                            {data.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={entry.color}
                                />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </div>
            <div className={styles.legend}>
                {data.map((x, index) => (
                    <div className={styles.indicator} key={index}>
                        <div
                            className={styles.color}
                            style={{ backgroundColor: x.color }}
                        ></div>
                        {x.name}
                    </div>
                ))}
            </div>
            <div className={styles.total}>
                {totalUtilizationPercentageFormatted}
            </div>
        </Card>
    );
};

export default GlobalUtilization;
