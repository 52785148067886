import { useEffect, useState } from "react";
import { useReadContracts, useBlockNumber } from "wagmi";
import { useQueryClient } from '@tanstack/react-query'
import { formatUnits, getAbiItem, parseUnits, erc20Abi } from "viem";
import {
    isEnabled,
    getLendingTokenAddress,
    // isCorrectTokenAddress
} from "../ethers/tokens";

import {
    APY_DECIMALS,
    // ERROR_TOKEN_BALANCE,
    NATIVE_TOKENS,
    TIME_INTERVAL
} from "../utils/config";

import {
    _notEmpty,
    _checkConvertedThreshold,
    _defaultArray
} from "../utils";
import { getSecurityContract } from "../ethers/contracts";

const useTokenBalance = ({
    config,
    nftId,
    token,
    userAccount,
    price = 0,
    walletBalance
}) => {
    const {
        chainId,
        wiseLendingContract,
        wiseSecurityContract
    } = config;

    const tokenAddress = getLendingTokenAddress(
        token,
        token.isAavePool
    );

    const isETH = NATIVE_TOKENS.includes(
        token.name
    );

    const [tokenBalance, setTokenBalance] = useState(
        token
    );

    const enabled = _notEmpty(tokenAddress) && isEnabled({
        config,
        nftId,
        token,
        allowNullNftId: true
    });

    const securityContract = getSecurityContract(
        config,
        token.isAavePool
    );

    const getWiseSecurityABI = (name) => {
        return [
            getAbiItem({
                abi: _defaultArray(wiseSecurityContract?.abi),
                name,
                type: "function"
            })
        ];
    };

    const getWiseLendingABI = (name) => {
        return [
            getAbiItem({
                abi: _defaultArray(wiseLendingContract?.abi),
                name,
                type: "function"
            })
        ];
    };

    const contracts = [
        {
            chainId,
            abi: [
                getAbiItem({
                    abi: erc20Abi,
                    name: "balanceOf",
                    type: "function"
                })
            ],
            address: token?.address,
            functionName: "balanceOf",
            args: [
                userAccount
            ],
        },
        {
            chainId,
            abi: getWiseSecurityABI("getPositionLendingAmount"),
            address: wiseSecurityContract?.address,
            functionName: "getPositionLendingAmount",
            args: [
                nftId,
                tokenAddress
            ],
        },
        {
            chainId,
            abi: getWiseSecurityABI("getExpectedLendingAmount"),
            address: wiseSecurityContract?.address,
            functionName: "getExpectedLendingAmount",
            args: [
                nftId,
                tokenAddress,
                TIME_INTERVAL
            ],
        },
        {
            chainId,
            abi: getWiseSecurityABI("maximumWithdrawToken"),
            address: wiseSecurityContract?.address,
            functionName: "maximumWithdrawToken",
            args: [
                nftId,
                tokenAddress,
                TIME_INTERVAL,
                0
            ],
        },
        {
            chainId,
            abi: getWiseLendingABI("getPureCollateralAmount"),
            address: wiseLendingContract?.address,
            functionName: "getPureCollateralAmount",
            args: [
                nftId,
                tokenAddress
            ],
        },
        {
            chainId,
            abi: getWiseLendingABI("getPositionLendingShares"),
            address: wiseLendingContract?.address,
            functionName: "getPositionLendingShares",
            args: [
                nftId,
                tokenAddress,
            ],
        },
        {
            chainId,
            abi: getWiseSecurityABI("maximumBorrowToken"),
            address: wiseSecurityContract?.address,
            functionName: "maximumBorrowToken",
            args: [
                nftId,
                tokenAddress,
                TIME_INTERVAL
            ],
        },
        {
            chainId,
            abi: getWiseLendingABI("getPositionBorrowShares"),
            address: wiseLendingContract?.address,
            functionName: "getPositionBorrowShares",
            args: [
                nftId,
                tokenAddress,
            ],
        },
        {
            chainId,
            abi: getWiseSecurityABI("getExpectedPaybackAmount"),
            address: wiseSecurityContract?.address,
            functionName: "getExpectedPaybackAmount",
            args: [
                nftId,
                tokenAddress,
                TIME_INTERVAL
            ],
        },
        {
            chainId,
            abi: getWiseLendingABI("getTotalPool"),
            address: wiseLendingContract?.address,
            functionName: "getTotalPool",
            args: [
                tokenAddress
            ],
        },
        {
            chainId,
            abi: getWiseLendingABI("getPseudoTotalPool"),
            address: wiseLendingContract?.address,
            functionName: "getPseudoTotalPool",
            args: [
                tokenAddress
            ],
        },
        {
            chainId,
            abi: getWiseLendingABI("getPseudoTotalBorrowAmount"),
            address: wiseLendingContract?.address,
            functionName: "getPseudoTotalBorrowAmount",
            args: [
                tokenAddress
            ],
        },
        {
            chainId,
            abi: getWiseSecurityABI("getBorrowRate"),
            address: wiseSecurityContract?.address,
            functionName: "getBorrowRate",
            args: [
                tokenAddress
            ],
        },
        {
            chainId,
            abi: [
                getAbiItem({
                    abi: _defaultArray(securityContract?.abi),
                    name: "getLendingRate",
                    type: "function"
                })
            ],
            address: securityContract?.address,
            functionName: "getLendingRate",
            args: [
                token.isAavePool ? token?.address : tokenAddress
            ],
        }
    ];

    const queryClient = useQueryClient();
    const { data: blockNumber } = useBlockNumber({ watch: true })

    const { data, error, isLoading, queryKey, refetch } = useReadContracts({
        allowFailure: true,
        query: {
            enabled
        },
        contracts,
    });

    useEffect(() => {
        queryClient.invalidateQueries({ queryKey });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        blockNumber,
        queryClient
    ])

    useEffect(() => {
        if (data) {
            const [
                balanceResult,
                depositShareResult,
                depositEstimateResult,
                withdrawBalanceResult,
                withdrawSolelyBalanceResult,
                withdrawShareBalanceResult,
                borrowBalanceResult,
                borrowShareBalanceResult,
                repayBalanceResult,
                poolResult,
                totalResult,
                fundResult,
                borrowRateResult,
                supplyRateResult,
            ] = data;

            let balanceFormatted = "0";
            let tokenBalanceFormatted = "0";
            let needsApproval = false;
            const balance = balanceResult?.result;

            if (balance) {
                tokenBalanceFormatted = formatUnits(
                    balance,
                    token.decimals
                );
            }

            if (isETH) {
                balanceFormatted = walletBalance ?? "0";
            } else {
                needsApproval = true;
                if (balance) {
                    balanceFormatted = formatUnits(
                        balance,
                        token.decimals
                    );
                }
            }

            let suppliedFormatted = "0";
            let supplied = depositShareResult?.result;
            if (supplied) {
                // @TODO: remove if contract is fixed
                supplied = supplied + 10n;
                suppliedFormatted = formatUnits(
                    supplied,
                    token.decimals
                );
            }

            let estimatedFormatted = "0";
            const estimated = depositEstimateResult?.result;
            if (estimated) {
                estimatedFormatted = formatUnits(
                    estimated,
                    token.decimals
                );
            }

            let withdrawBalanceFormatted = "0";
            const withdrawBalance = withdrawBalanceResult?.result;
            if (withdrawBalance) {
                withdrawBalanceFormatted = formatUnits(
                    withdrawBalance,
                    token.decimals
                );
            }

            let withdrawSolelyBalanceFormatted = "0";
            const withdrawSolelyBalance = withdrawSolelyBalanceResult?.result;
            if (withdrawSolelyBalance) {
                withdrawSolelyBalanceFormatted = formatUnits(
                    withdrawSolelyBalance,
                    token.decimals
                );
            }

            let withdrawShareBalanceFormatted = "0";
            const withdrawShareBalance = withdrawShareBalanceResult?.result;
            if (withdrawShareBalance) {
                withdrawShareBalanceFormatted = formatUnits(
                    withdrawShareBalance,
                    token.decimals
                );
            }

            let borrowBalanceFormatted = "0";
            const borrowBalance = borrowBalanceResult?.result;
            if (borrowBalance) {
                borrowBalanceFormatted = formatUnits(
                    // prevent user borrowing too much
                    borrowBalance * 999n / 1000n,
                    token.decimals
                );
            }

            let borrowShareBalance;
            let borrowShareBalanceFormatted = "0";
            if (borrowShareBalanceResult?.result) {
                borrowShareBalance = borrowShareBalanceResult?.result;
                if (borrowShareBalance) {
                    borrowShareBalanceFormatted = formatUnits(
                        borrowShareBalance,
                        token.decimals
                    );
                }
            }

            let repayBalanceFormatted = "0";
            let repayBalance = repayBalanceResult?.result;
            if (repayBalance) {
                repayBalanceFormatted = formatUnits(
                    repayBalance,
                    token.decimals
                );

                repayBalanceFormatted = _checkConvertedThreshold(
                    repayBalanceFormatted,
                    price
                );

                repayBalance = parseUnits(
                    repayBalanceFormatted,
                    token.decimals
                );

                let repayInUSD = repayBalanceFormatted * price;
                if (repayInUSD < 1) {
                    repayBalanceFormatted = "0";
                }
            }

            let poolFormatted = "0";
            const pool = poolResult?.result;
            if (pool) {
                poolFormatted = formatUnits(
                    pool,
                    token.decimals
                );
            }

            let totalFormatted = "0";
            const total = totalResult?.result;
            if (total) {
                totalFormatted = formatUnits(
                    total,
                    token.decimals
                );
            }

            let fundFormatted = "0";
            const fund = fundResult?.result;
            if (fund) {
                fundFormatted = formatUnits(
                    fund,
                    token.decimals
                );
            }

            let borrowRateFormatted = "0";
            const borrowRate = borrowRateResult?.result;
            if (borrowRate) {
                borrowRateFormatted = formatUnits(
                    borrowRate,
                    APY_DECIMALS
                );
            }

            let supplyRateFormatted = "0";
            const supplyRate = supplyRateResult?.result;
            if (supplyRate) {
                supplyRateFormatted = formatUnits(
                    supplyRate,
                    APY_DECIMALS
                );
            }

            setTokenBalance((prev) => {
                return {
                    ...prev,
                    needsApproval,
                    balance,
                    balanceFormatted,
                    tokenBalanceFormatted,
                    supplied,
                    suppliedFormatted,
                    estimated,
                    estimatedFormatted,
                    withdrawBalance,
                    withdrawBalanceFormatted,
                    withdrawSolelyBalance,
                    withdrawSolelyBalanceFormatted,
                    withdrawShareBalance,
                    withdrawShareBalanceFormatted,
                    borrowBalance,
                    borrowBalanceFormatted,
                    borrowShareBalance,
                    borrowShareBalanceFormatted,
                    repayBalance,
                    repayBalanceFormatted,
                    pool,
                    poolFormatted,
                    total,
                    totalFormatted,
                    fund,
                    fundFormatted,
                    borrowRate,
                    borrowRateFormatted,
                    supplyRate,
                    supplyRateFormatted
                };
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, price, walletBalance]);

    const isFetching = isLoading;

    useEffect(() => {
        if (error) {
            console.error(error);
        }
    }, [error]);

    useEffect(() => {
        setTokenBalance((prev) => {
            return {
                ...prev,
                ...token,
                supplied: undefined,
                suppliedFormatted: "0",
                estimated: undefined,
                estimatedFormatted: "0",
                // total: undefined,
                // totalFormatted: "0",
                // borrowed: undefined,
                // borrowedFormatted: "0",
                withdrawBalance: undefined,
                withdrawBalanceFormatted: "0",
                withdrawSolelyBalance: undefined,
                withdrawSolelyBalanceFormatted: "0",
                borrowBalance: undefined,
                borrowBalanceFormatted: "0",
                repayBalance: undefined,
                repayBalanceFormatted: "0"
            };
        });

        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId, token, userAccount, nftId, walletBalance]);

    return { tokenBalance, isFetching };
};

export default useTokenBalance;
