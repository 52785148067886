export const products = [
    {
        id: 0,
        product: "Wise Lending Hub",
        link: "/lending/hub",
        // forced: true,
        image: "/images/content/lending.jpg",
        image2x: "/images/content/lending.jpg",
        price: "28.19% APY",
        date: "0.00",
        tokens: ["ETH"],
        active: true,
        icon: "lightning",
        ratingValue: 4.8,
        ratingCounter: 87,
        contract: "wstETHManagerContract",
        APY: 'smaApr',
    },
    {
        id: 1,
        product: "Wise Staking Hub",
        link: "/staking/hub",
        // forced: true,
        image: "/images/content/staking2.jpg",
        image2x: "/images/content/staking2.jpg",
        // image: "/images/content/tracking.jpg",
        // image2x: "/images/content/tracking.jpg",
        price: "20.34% APY",
        icon: "schedule",
        tokens: ["DAI", "USDC", "USDT"],
        date: "$0.00",
        active: true,
        ratingValue: 4.8,
        ratingCounter: 87,
        contract: "pendlePowerManagerContract",
        APY: 'smaApr',
    },
    {
        id: 2,
        product: "World Mobile Vault",
        link: "/vaults",
        icon: "shopping-bag",
        // forced: true,
        image: "/images/content/world.jpg",
        image2x: "/images/content/world.jpg",
        // image: "/images/content/rewards.jpg",
        // image2x: "/images/content/rewards.jpg",
        // image: "/images/content/spark.svg",
        // image2x: "/images/content/spark.svg",
        price: "20.00% APY",
        tokens: ["ETH"],
        date: "$0.00",
        active: true,
        ratingValue: 4.8,
        ratingCounter: 87,
        APY: 'pendleAPY',
    },
    {
        id: 4,
        product: "Wise Token Swaps",
        link: "",
        // forced: true,
        image: "/images/content/swaps.jpg",
        image2x: "/images/content/swaps.jpg",
        price: "20.34% APY",
        tokens: ["DAI", "USDC", "USDT"],
        date: "$0.00",
        active: false,
        ratingValue: 4.8,
        ratingCounter: 87,
        contract: "pendlePowerManagerContract",
        APY: 'smaApr',
    },
    {
        id: 4,
        product: "Wise Lending Farms",
        link: "/lending/farms",
        // forced: true,
        image: "/images/content/farms.jpg",
        image2x: "/images/content/farms.jpg",
        price: "20.34% APY",
        tokens: ["DAI", "USDC", "USDT"],
        date: "$0.00",
        active: true,
        ratingValue: 4.8,
        ratingCounter: 87,
        contract: "pendlePowerManagerContract",
        APY: 'smaApr',
    },
    {
        id: 4,
        product: "Wise Staking Referrals",
        link: "/staking/ref",
        // forced: true,
        image: "/images/content/referrals.jpg",
        image2x: "/images/content/referrals.jpg",
        price: "20.34% APY",
        tokens: ["DAI", "USDC", "USDT"],
        date: "$0.00",
        active: true,
        ratingValue: 4.8,
        ratingCounter: 87,
        contract: "pendlePowerManagerContract",
        APY: 'smaApr',
    },
    {
        id: 4,
        product: "Wise Reserves - WiseR",
        link: "",
        // forced: true,
        image: "/images/content/reserves3.jpg",
        image2x: "/images/content/reserves3.jpg",
        price: "20.34% APY",
        tokens: ["DAI", "USDC", "USDT"],
        date: "$0.00",
        active: false,
        ratingValue: 4.8,
        ratingCounter: 87,
        contract: "pendlePowerManagerContract",
        APY: 'smaApr',
    },
    {
        id: 4,
        product: "Wise Staking Pools",
        link: "",
        // forced: true,
        image: "/images/content/pools.jpg",
        image2x: "/images/content/pools.jpg",
        price: "20.34% APY",
        tokens: ["DAI", "USDC", "USDT"],
        date: "$0.00",
        active: false,
        ratingValue: 4.8,
        ratingCounter: 87,
        contract: "pendlePowerManagerContract",
        APY: 'smaApr',
    },
    {
        id: 4,
        product: "Wise Lending Statistics",
        link: "lending/details",
        // forced: true,
        image: "/images/content/stats3.jpg",
        image2x: "/images/content/stats3.jpg",
        price: "20.34% APY",
        tokens: ["DAI", "USDC", "USDT"],
        date: "$0.00",
        active: true,
        ratingValue: 4.8,
        ratingCounter: 87,
        contract: "pendlePowerManagerContract",
        APY: 'smaApr',
    },
    {
        id: 4,
        product: "Wise Leaderboards",
        link: "",
        // forced: true,
        image: "/images/content/leaderboards.jpg",
        image2x: "/images/content/leaderboards.jpg",
        price: "20.34% APY",
        tokens: ["DAI", "USDC", "USDT"],
        date: "$0.00",
        active: false,
        ratingValue: 4.8,
        ratingCounter: 87,
        contract: "pendlePowerManagerContract",
        APY: 'smaApr',
    },
    {
        id: 4,
        product: "Security Audits",
        link: "/lending/audits",
        // forced: true,
        image: "/images/content/security.jpg",
        image2x: "/images/content/security.jpg",
        price: "20.34% APY",
        tokens: ["DAI", "USDC", "USDT"],
        date: "$0.00",
        active: true,
        ratingValue: 4.8,
        ratingCounter: 87,
        contract: "pendlePowerManagerContract",
        APY: 'smaApr',
    },
    {
        id: 4,
        product: "Wise Token Liquidity",
        link: "/uniswap",
        // forced: true,
        image: "/images/content/liquidity.jpg",
        image2x: "/images/content/liquidity.jpg",
        price: "$93.23M",
        tokens: ["DAI", "USDC", "USDT"],
        date: "$0.00",
        active: true,
        ratingValue: 4.8,
        ratingCounter: 87,
        contract: "pendlePowerManagerContract",
        APY: 'smaApr',
    },
    /*{
        id: 4,
        product: "Wise Discord Server",
        link: "www.sparkprotocol.io",
        // forced: true,
        image: "/images/content/chat.jpg",
        image2x: "/images/content/chat.jpg",
        price: "Join Server",
        tokens: ["DAI", "USDC", "USDT"],
        date: "$0.00",
        active: true,
        ratingValue: 4.8,
        ratingCounter: 87,
        contract: "pendlePowerManagerContract",
        APY: 'smaApr',
    },*/
];
