// import React, { useState } from "react";
import React, { useMemo } from "react";
import styles from "./ActiveFunds.module.sass";
// import cn from "classnames";
// import Card from "../../../components/Card";
// import Dropdown from "../../../components/Dropdown";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import {
    // MAIN_COLOR_A,
    // MAIN_COLOR_A,
    MAIN_COLOR_B,
    // MAIN_COLOR_C
} from "../../../utils/config";
import CustomChartTooltip from "../../../components/CustomChartTooltip";
import dayjs from "dayjs";
import { _notEmpty } from "../../../utils";

// import useDarkMode from "use-dark-mode";

/*
const intervals = [
    "Last 30 days",
    "Last 20 days",
    "Last 10 days"
];
*/

const ActiveFunds = ({
    chartData = [],
    label,
    lines,
    sorting,
}) => {
    // const darkMode = useDarkMode(false);

    const data = useMemo(() => chartData?.filter((data) => {
            if (_notEmpty(sorting.value)) {
                return dayjs(data.timestamp).isAfter(
                    dayjs().add(
                        sorting.value,
                        "days"
                    )
                );
            }
            return true;
        })?.map((data) => ({
            name: dayjs(data.timestamp).format("MMM DD"),
            label,
            ...data
        }))?.reduce((finalData, currentData) => {
            // use most recent value when there's more data in the same day
            const existingDate = finalData.find((existingData) => {
                return existingData.name === currentData.name;
            });

            if (existingDate) {
                const outdatedDataIndex = finalData.indexOf(
                    existingDate
                );
                finalData[outdatedDataIndex] = currentData;
            } else {
                finalData.push(
                    currentData
                );
            }

            return finalData;

        }, []),
        [chartData, sorting.value, label]);

    const darkMode = true;

    return (
        <div className={styles.chart}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid
                        strokeDasharray="none"
                        stroke={darkMode.value ? "#272B30" : "#EFEFEF"}
                        vertical={false}
                    />
                    <XAxis
                        dataKey="name"
                        axisLine={false}
                        tickLine={true}
                        tick={{ fontSize: 13, fontWeight: "600", fill: "#6F767E" }}
                        padding={{ left: 10 }}
                    />
                    <YAxis
                        axisLine={false}
                        tickLine={true}
                        tick={{ fontSize: 12, fontWeight: "500", fill: "#9A9FA5" }}
                    />
                    <Tooltip
                        content={<CustomChartTooltip />}
                    />
                    {(lines && lines.includes(0)) && (
                    <Line
                        dataKey="totalSupplyUsd"
                        fill={"#00b0aa"}
                        color={"#00b0aa"}
                        stroke={"#00b0aa"}
                        name="Lend"
                        label="Lend"
                        dot={false}
                        type="monotone"
                        strokeWidth={4}
                    />
                    )}
                    {(lines && lines.includes(1)) && (
                    <Line
                        type="monotone"
                        dataKey="totalBorrowUsd"
                        name="Borrowed"
                        fill={MAIN_COLOR_B}
                        color={MAIN_COLOR_B}
                        dot={false}
                        strokeWidth={4}
                        stroke={MAIN_COLOR_B}
                    />
                    )}
                    {(lines && lines.includes(2)) && (
                        <Line
                            type="monotone"
                            dataKey="tvlUsd"
                            name="Available"
                            dot={false}
                            strokeWidth={4}
                            fill={"#FFD88D"}
                            color={"#FFD88D"}
                            stroke={"#FFD88D"}
                        />
                    )}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ActiveFunds;
