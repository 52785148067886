import cn from "classnames";
import React, { useMemo, useState, useEffect } from "react";
import useConfig from "../../../customHooks/useConfig";
// import { formatUnits } from "viem";
import { mainnet } from "wagmi/chains";
import Card from "../../../components/Card";
import Loader from "../../../components/Loader";
import SuccessPanel from "../../../components/Modal/SuccessPanel";
import { useClaimRewards } from "../../../customHooks/useClaimRewards";
import { _no, formatMoneyDashed, showToast } from "../../../utils";
import {
    CLAIMING_TEXT,
    CLAIM_FAILED_MESSAGE,
    CLAIM_SUCCESS,
    // CLICK_TO_VIEW,
    CONFIRM_TEXT,
    CONTRACT_VERSIONS,
    // DEFAULT_DECIMALS,
    DONE_TEXT,
    // POINTS_SEASON,
    TRY_AGAIN_TEXT,
    getConfig
} from "../../../utils/config";
import styles from "./ClaimRewards.module.sass";
// import { useChainId } from "wagmi";
// import { set } from "date-fns";

const DEFAULT_TEXT = "Claim Rewards";

const ClaimRewards = ({
    className,
    loading,
    rewardData,
    tokenPriceData,
    isClaimed,
    userAccount
}) => {

    const chainId = mainnet.id;

    const { chainId: chainDynamic } = useConfig();

    // const { chainId: chainIdFromHook } = useChainId();

    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [finishClaim, setFinishClaim] = useState(false);
    const [transactionHashLink, setTransactionHashLink] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [successfullClaim, setSuccessfullClaim] = useState();

    const config = useMemo(() => {

        const version = CONTRACT_VERSIONS.default;

        if (chainDynamic !== 1) {
            setButtonText(
                "Switch To Mainnet"
            );
        } else {
            setButtonText(
                DEFAULT_TEXT
            );
        }

        return getConfig(
            chainId,
            version[chainId]
        );

    }, [chainId, chainDynamic]);

    const { linkBase } = config;

    const formattedAmount = useMemo(() => {
        if (rewardData?.reward) {
            return rewardData?.reward / 1E18;
        }

        return 0;
    }, [rewardData]);

    const usdValue = useMemo(() => {

        if (formattedAmount) {
            return formattedAmount * tokenPriceData.price;
        }

        return 0;


    }, [tokenPriceData, formattedAmount]);

    const claimRewards = useClaimRewards({
        rewardsData: {
            hash: rewardData?.hash,
            index: rewardData?.index,
            reward: rewardData?.reward,
            merkleProof: rewardData?.proof
        },
        userAccount,
        config
    });

    // @TODO: maybe move somewhere in config
    const CLAIM_SUCCESS_MESSAGE = `${CLAIM_SUCCESS} ${formattedAmount} ${tokenPriceData.token} Tokens`;

    const handleError = (err) => {
        showToast(
            err.message,
            {
                type: "error"
            }
        );
    };

    const handleClaim = () => {

        if (buttonText === TRY_AGAIN_TEXT) {
            setFinishClaim(false);
            setTransactionHashLink("");
            setButtonText(
                DEFAULT_TEXT
            );
        };

        claimRewards.claim({
            onConfirmation: () => {
                setIsLoading(true);

                setButtonText(
                    CONFIRM_TEXT
                );
            },
            /*onClaiming: (transactionHash) => {
                setIsLoading(true);

                setButtonText(
                    CLAIMING_TEXT
                );

                setTransactionHashLink(
                    `${linkBase}/tx/${transactionHash}`
                );
            },
            onClaimComplete: (claimed) => {

                setIsLoading(false);

                if (claimed) {

                    setFinishClaim(true);

                    // const transactionLink = `${linkBase}/tx/${claimed.transactionHash}`;

                    const message = claimed.status
                        ? CLAIM_SUCCESS_MESSAGE
                        : CLAIM_FAILED_MESSAGE;

                    const toastContent = toastNotification.generateLinkContent(
                        transactionLink,
                        `${message} ${CLICK_TO_VIEW}`
                    );

                    showToast(
                        claimed.status,
                        {
                            type: claimed.status ? "success" : "error",
                        }
                    );

                    setSuccessfullClaim(
                        claimed.status
                    );

                    setButtonText(
                        claimed.status
                            ? DONE_TEXT
                            : TRY_AGAIN_TEXT
                    );
                }
            },
            onError: (error) => {
                setIsLoading(false);
                handleError(
                    error
                );
                setTransactionHashLink("");
                setButtonText(
                    DEFAULT_TEXT
                );
            }*/
        });
    };

    useEffect(() => {

        if (claimRewards.error && isLoading === true) {
            setIsLoading(false);
            handleError(
                claimRewards.error
            );
            setTransactionHashLink("");
            setButtonText(
                DEFAULT_TEXT
            );
        }

        if (claimRewards.hasError && isLoading === true) {
            setIsLoading(false);
            handleError(
                claimRewards.error
            );
            setTransactionHashLink("");
            setButtonText(
                DEFAULT_TEXT
            );
        }

        if (claimRewards.isConfirming && isLoading === true) {
            setButtonText(
                CLAIMING_TEXT
            );
            setTransactionHashLink(
                `${linkBase}/tx/${claimRewards.hash}`
            );
        }

        if (claimRewards.isConfirmed && isLoading === true) {
            setIsLoading(false);
            setFinishClaim(true);
            setButtonText(
                DONE_TEXT
            );
            setSuccessfullClaim(
                true
            );
            showToast(
                "Claimed rewards successfully",
                {
                    type: "success",
                }
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimRewards]);

    return (
        <Card
            className={cn(styles.card, className)}
            title="Claim Rewards"
            classTitle="title-green"
        >
            <div className={styles.create}>
                <div className={styles.fieldset}>
                    {finishClaim &&
                        <div className={styles.successWrapper}>
                            <SuccessPanel />
                            <div className={styles.text}>
                                {successfullClaim
                                    ? CLAIM_SUCCESS_MESSAGE
                                    : CLAIM_FAILED_MESSAGE
                                }
                            </div>
                        </div>
                    }
                    {_no(finishClaim) && <>
                        <div className={styles.label}>
                            Collect reward for latest season.
                        </div>
                        <div className={styles.counter}>
                            {formatMoneyDashed(formattedAmount) ?? 0} {tokenPriceData.token} {" "}
                            <span className={styles.conversion}>
                                ({formatMoneyDashed(
                                    usdValue,
                                    true
                                )})
                            </span>
                        </div>
                    </>
                    }
                    <div className={styles.foot}>
                        <button
                            className={cn(
                                "button-small",
                                styles.button,
                                {
                                    disabled: loading || !rewardData || isClaimed || isLoading || finishClaim || chainDynamic !== 1
                                }
                            )}
                            onClick={handleClaim}
                        >
                            {isLoading && <Loader className={styles.loader} />}
                            <span>
                                {buttonText}
                            </span>
                        </button>
                        {transactionHashLink &&
                            <a
                                className={cn(
                                    "button-small",
                                    "button-stroke",
                                    styles.button,
                                    styles.stroker
                                )}
                                href={transactionHashLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                View Transaction Details
                            </a>
                        }
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default ClaimRewards;
