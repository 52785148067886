export const customers = [
    {
        id: 0,
        user: "rene.eth",
        login: "0x2342...4242",
        avatar: "/images/content/avatar-1.jpg",
        email: "chelsie@ui8.net",
        purchase: 0.0001,
        price: "384.00",
        balance: -12.8,
        comments: "14.123",
        likes: 16,
    },
    {
        id: 1,
        user: "",
        login: "0x2342...4242",
        avatar: "/images/content/avatar-2.jpg",
        email: "fahey.designer@robot.co",
        purchase: 0.0001,
        price: "223.00",
        balance: 2.8,
        comments: "14.123",
        likes: 6,
    },
];
