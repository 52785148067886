import React, { useEffect, useState } from "react";
import styles from "./PointsCenter.module.sass";
import { useAccount } from "wagmi";
import { _no, _notEmpty, displayWithLoader, fetchPointsData } from "../../../utils";
import { useParams, useHistory } from 'react-router-dom';
import ReferralNetwork from "../../AffiliateCenter/UseReferral/ReferralNetwork";

// must take from utils/cookie.js

const JoinNetwork = () => {
    const params = useParams();
    const history = useHistory();

    const { address, isConnected } = useAccount();

    const [currentPromoter, setCurrentPromoter] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchPromoter = async (address) => {
        setIsLoading(true);
        const result = await fetchPointsData(
            address
        );
        const currentPromoter = result?.points?.promoterAddress
            || result?.stablePoints?.promoterAddress;

        setCurrentPromoter(currentPromoter);
        setIsLoading(false);
    };

    useEffect(() => {
        if (address) {
            fetchPromoter(address);
        } else {
            setCurrentPromoter(null);
            setIsLoading(false)
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    const cardTitle = _no(isConnected)
        ? "Lending Referral Needs Activation"
        : displayWithLoader(
            isLoading,
            _notEmpty(currentPromoter)
                ? "Lending Referral Activated"
                : "Lending Referral Needs Activation"
            ,
            "..."
        );

    const selectedReferralAddress = currentPromoter || params?.address;

    return (
        <>
        <div className={styles.flexer}>
        <div className={styles.container}>
            <ReferralNetwork
                title={cardTitle}
                referrerAddress={selectedReferralAddress}
                showFullAddress
                currentPromoter={currentPromoter}
                onSuccessConfirm={() => history.push('/lending/hub')}
                hasPromoter={_notEmpty(currentPromoter)}
                shouldDisplayLoading={isLoading}
                hideAddressTooltips
            />
        </div>
        <div className={styles.container}>
            <ReferralNetwork
                isStaking={true}
                title={'Staking Referral Activated'}
                referrerAddress={selectedReferralAddress}
                showFullAddress
                currentPromoter={currentPromoter}
                onSuccessConfirm={() => history.push('/staking/hub')}
                hasPromoter={_notEmpty(currentPromoter)}
                shouldDisplayLoading={isLoading}
                hideAddressTooltips
            />
        </div>
        </div>
        </>
    );
};

export default JoinNetwork;
