import React, { useEffect, useState } from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";
// import OutsideClickHandler from "react-outside-click-handler";
import { shortenAddress } from "../../../utils";
import { useDetectClickOutside } from "react-detect-click-outside";
import styles from "./Network.module.sass";
import { useDisconnect } from "wagmi";
import useConfig from "../../../customHooks/useConfig";
import { useAccount, useSwitchChain } from "wagmi";
import Icon from "../../Icon";
import { useDispatch, useSelector } from "react-redux";
import { setChainId } from "../../../redux/tokenSlice";
import { usePrivy } from "@privy-io/react-auth";
import Modal from "../../Modal";
import Settings from "../../../screens/Settings";
import CreateReferral from "../../../screens/AffiliateCenter/CreateReferral";
import KadoWidgetModal from "../../KadoWidgetModal";

const Network = ({ className }) => {

    const config = useConfig();
    const { isConnected } = useAccount();
    const dispatch = useDispatch();
    const { disconnect } = useDisconnect();
    const { ensName, address } = useSelector((state) => state.settings);
    const [visible, setVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [kadoWidgetVisible, setKadoWidgetVisible] = useState(false);
    const [profileVisible, setProfileVisbile] = useState(false);

    const { logout } = usePrivy();

    // const [selectedChain, setSelectedChain] = useState();

    const {
        chainId,
        // chainName,
        chainIcon
    } = config;

    const {
        chains,
        error,
        switchChain,
        // isSuccess
    } = useSwitchChain();

    useEffect(() => {
        if (error) {
            /*toastNotification.warning(
                error.message
            );
            */
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    /*
    useEffect(() => {
        if (isSuccess) {
            toastNotification.success(
                `Successfully switched `
            );

            dispatch(
                setChainId(
                    selectedChain.chainId
                )
            );

            setVisible(false);
        }
    }, [isSuccess]);
    */

    // console.log(isSuccess, 'isSuccess');

    const handleSwitch = (chain) => {

        /*
        if (chain.chainName === "Arbitrum One") {
            toastNotification.warning(
                "Coming soon..."
            );
            return;
        }
        */

        /*
        if (chain.id !== arbitrum && isInitialLoad) {
            setIsInitialLoad(false)
        }
        */

        /*
        setSelectedChain(
            chain.id
        );
        */

        if (isConnected) {
            switchChain({
                chainId: chain.id
            });
        } else {
            dispatch(
                setChainId(
                    chain.id
                )
            );

            // setVisible(false);
        }
    };

    const ref = useDetectClickOutside({
        onTriggered: () => {
            setVisible(false);
        }
    });

    const itemsAll = [
        {
            menu: [
                /*{
                    title: "Wallet Balance",
                    icon: "wallet",
                    onlyPower: true,
                    url: "/withdrawals",
                },
                {
                    title: "Staked Balance",
                    icon: "schedule",
                    url: "/affiliate-center",
                    onlyPower: true
                },*/
                {
                    title: "Account Funding",
                    icon: "wallet",
                    onClick: "setKadoWidgetVisible(true)",
                    onlyPower: true
                },
                {
                    title: "Profile Information",
                    icon: "profile-circle",
                    onClick: "setProfileVisbile(true)",
                    onlyPower: true
                },
                {
                    title: "Show Referral Link",
                    icon: "share",
                    onlyPower: true,
                    onClick: "showReferralLink"
                },
            ],
        },
        /*{
            menu: [
                {
                    title: "About Power Farms",
                    icon: "sell",
                    color: true,
                    onlyPower: true,
                    url: "/about-power-farms",
                },
            ],
        },*/
    ];

    const handleMenuClick = (title) => {
        if (title === "Show Referral Link") {
            setVisibleModal(true);
        }

        if (title === "Account Funding") {
            setKadoWidgetVisible(true);
        }

        if (title === "Profile Information") {
            setProfileVisbile(true);
        }
    }

    return (
        <div
            ref={ref}
            // onClick={() => setVisible(false)}
        >
            <div className={cn(
                styles.pointer,
                styles.network,
                className,
                {
                    [styles.active]: visible
                }
            )}>
                <div className={styles.head} onClick={() => setVisible(!visible)}>
                    <div className={cn(
                        styles.wallet
                    )}>
                        {
                            chainId &&
                            <>
                                <div className={cn({
                                    [styles.chainIcon]: isConnected
                                })}>
                                    <img
                                        src={chainIcon}
                                        className={styles.icon}
                                        alt="network icon"
                                    />
                                </div>
                                <span className={styles.chainLabel}>
                                    {/*chainName*/}
                                    {ensName ?? shortenAddress(address)}
                                </span>
                                <Icon
                                    name="arrow-down"
                                    size="20"
                                    className={cn(
                                        styles.svgIcon,
                                        {
                                            [styles.active]: visible
                                        }
                                    )}
                                />
                            </>
                        }
                    </div>
                    <div style={{ height: "50px" }}></div>
                </div>
                <div className={cn(
                    styles.body,
                    {
                        [styles.expand]: isConnected
                    }
                )}>
                    {isConnected && itemsAll.map((item, index) => (
                        <div key={index}>
                            {item.menu.map((x, index) => {
                                return (
                                    x.url ? (
                                        <NavLink
                                            className={cn(
                                                styles.lefty,
                                                styles.item,
                                                { [styles.color]: x.color }
                                            )}
                                            activeClassName={styles.active}
                                            to={x.url}
                                            onClick={() => setVisible(false)}
                                            key={index}
                                        >
                                            {x.icon && <Icon name={x.icon} size="24" />}
                                            {x.title}
                                        </NavLink>
                                    ) : (
                                        <button
                                            className={cn(
                                                styles.item,
                                                styles.lefty,
                                            )}
                                            onClick={() => handleMenuClick(x.title)}
                                            key={index}
                                        >
                                            {x.icon && <Icon name={x.icon} size="24" />}
                                            {x.title}
                                        </button>
                                    )
                                );
                            })}
                            <div className={styles.separator} key={index}></div>
                        </div>
                    ))}
                    {chains.map((item, index) => (
                        <div key={index}>
                            <button
                                className={styles.item}
                                onClick={() => handleSwitch(item)}
                                key={index}
                            >
                                <div className={styles.chain}>
                                    <img
                                        src={`/images/networks/${item.name.toLowerCase()}.svg`}
                                        className={styles.icon}
                                        alt="network icon"
                                    />
                                    {item.name}
                                </div>
                                {
                                    chainId === item.id && isConnected &&
                                    <div className={styles.connected}>
                                        Connected
                                    </div>
                                }
                            </button>
                        </div>
                    ))}
                    {isConnected && (
                    <button
                        className={styles.item}
                        onClick={() => {
                            logout();
                            disconnect();
                        }}
                        // key={index}
                    >
                        <div className={styles.chain}>
                            <Icon name={"close"} size="24" />
                            Disconnect Account
                        </div>
                    </button>
                    )}
                </div>
            </div>
            <Modal
                outerClassName={styles.modal}
                visible={visibleModal}
                onClose={() => setVisibleModal(false)}
            >
                <CreateReferral
                    showCopyButton={true}
                    className={styles.nopad}>
                </CreateReferral>
            </Modal>
            <Modal
                outerClassName={styles.modal2}
                visible={profileVisible}
                onClose={() => setProfileVisbile(false)}
            >
                <Settings
                    preSelectIndex={1}
                    visible={profileVisible}
                    setVisible={setProfileVisbile}
                    // onClose={onClose}
                />
            </Modal>
            <KadoWidgetModal
                visible={kadoWidgetVisible}
                onClose={() => setKadoWidgetVisible(false)}
            />
        </div>
    );
};

export default Network;
