// import cn from "classnames";
// import dayjs from 'dayjs';
// import React, { useMemo, useState } from "react";
import React from "react";
// import { useSelector } from "react-redux";
// import { useAccount } from "wagmi";
// import Card from "../../components/Card";
// import Table from "../../components/Table";
// import useConfig from "../../customHooks/useConfig";
// import { powerFarms } from "../../ethers/farms";
// import { getSupportedTokens, supportedTokens } from "../../ethers/tokens";
// import styles from "./Farming.module.sass";
// import Overview from "./Overview";
import RWAVaults from "./RWAVaults";
// import { FarmingContextProvider } from "./context";

/*
const getDefaultToken = (item) => {
    return supportedTokens.find(
        token => {
            return token.name === item.defaultToken
                && token.isAavePool === (item.tokens[item.defaultToken].isAave ?? false);
        }
    );
};
*/

const VaultContent = () => {

    // const config = useConfig();

    /*
    const RWAWhitelistAddresses = [
        "0x22079A848266A7D2E40CF0fF71a6573D78adcF37",
        "0x641AD78BAca220C5BD28b51Ce8e0F495e85Fe689",
        "0xF22A17599685975A40D65b3AC1526bBd8575fe16",
        "0x2C1d7A72E480363b63F73D3D543EaC0aE4f3A30e",
        "0x434df135FE3B2c58D3BDf16F61D8675A22a4333f",
        "0xa803c226c8281550454523191375695928DcFE92",
    ];
    */

    return (
        <>
            {
                // RWAWhitelistAddresses.includes(address) &&
                <RWAVaults />
            }
        </>
    );
};

const Vaults = () => {
    return (
        <VaultContent />
    );
};

export default Vaults;
