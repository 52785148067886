import React, { useState } from "react";
import cn from "classnames";
import styles from "./Notifications.module.sass";
import Item from "../Item";
//import Tooltip from "../../../components/Tooltip";
import Switch from "../../../components/Switch";
import { appLocalStorage } from "../../../utils/localstorage";
import { useDispatch } from "react-redux";
import { setInterfacePreference } from "../../../redux/settingsSlice";

const settings = [
    {
        id: 0,
        title: "Hide Wise Lending TVL header widget",
        // tooltip: "Small description",
    },
    {
        id: 1,
        title: "Hide Wise Staking TVL header widget",
        // tooltip: "Small description",
    },
    {
        id: 2,
        title: "Hide Wise Liquidity TVL header widget",
        // tooltip: "Small description",
    },
    {
        id: 3,
        title: "Hide Wise Vault TVL header widget",
        // tooltip: "Small description",
    },
    {
        id: 4,
        title: "Hide Buy Wise (toke price) header widget",
        // tooltip: "Small description",
    },
];

const Notifications = ({ className }) => {

    const dispatch = useDispatch();
    const currentSettings = appLocalStorage.interfacePreferences.get() || [];

    // console.log(currentSettings, 'currentSettings');

    const [selectedFilters, setSelectedFilters] = useState(
        [
            ...currentSettings
        ]
    );

    const handleChange = (id) => {
        if (currentSettings.includes(id)) {
            setSelectedFilters((selectedFilters) =>
                selectedFilters.filter((x) => x !== id)
            );
            appLocalStorage.interfacePreferences.update(
                selectedFilters.filter((x) => x !== id)
            );
            dispatch(
                setInterfacePreference(
                    selectedFilters.filter((x) => x !== id)
                )
            );
        } else {
            appLocalStorage.interfacePreferences.update([...selectedFilters, id]);
            setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
            dispatch(
                setInterfacePreference([...selectedFilters, id])
            );
        }
    };

    return (
        <Item
            className={cn(styles.card, className)}
            title="Interface Preferences"
            classTitle="title-green"
        >
            <div className={styles.list}>
                {settings.map((x, index) => (
                    <div className={styles.line} key={index}>
                        <div className={styles.title}>
                            {x.title}{" "}
                            {/*<Tooltip
                                className={styles.tooltip}
                                title={x.tooltip}
                                icon="info"
                                place="top"
                            />*/}
                        </div>
                        <Switch
                            className={styles.switch}
                            value={currentSettings.includes(x.id)}
                            onChange={() => handleChange(x.id)}
                        />
                    </div>
                ))}
            </div>
        </Item>
    );
};

export default Notifications;
