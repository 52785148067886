import {
    arbitrum,
    // goerli,
    mainnet
} from "viem/chains";
// import { useAccount } from "wagmi";
import styles from "./KadoWidget.module.sass";
import { KADO_CONFIG } from "../../utils/config";
import { useSelector } from "react-redux";

const KADO_NETWORK = {
    [arbitrum.id]: "ARBITRUM",
    [mainnet.id]: "ETHEREUM",
    // [goerli.id]: "GOERLI",
}

const KadoWidget = () => {

    const { address } = useSelector((state) => state.settings);

    const { chainId } = useSelector(
        (state) => state.token
    );

    const KADO_PARAMS = {
        ...KADO_CONFIG,
        network: KADO_NETWORK[chainId],
        onToAddress: address,
        offFromAddress: address,
    }

    const widgetParams = Object
        .keys(KADO_PARAMS)
        .map((paramKey) => {
            return `${paramKey}=${KADO_PARAMS[paramKey]}`
        })
        .join('&');

    return (
        <iframe
            src={`https://app.kado.money/?${widgetParams}`}
            width="480"
            height="620"
            title="Kado Widget"
            className={styles.iframe}
            allow="clipboard-write; payment; accelerometer; gyroscope; camera; geolocation; autoplay; fullscreen;"
        ></iframe>
    )
}

export default KadoWidget;