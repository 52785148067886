import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "./Table.module.sass";
import Icon from "../../../../components/Icon";
import cn from "classnames";
import { showToast } from "../../../../utils";
import Row from "./Row";

const Table = ({ items, legend, title, allNfttPositions }) => {

    // const [chooseAll, setСhooseAll] = useState(false);

    const [selectedFilters, setSelectedFilters] = useState([]);

    const handleChange = (id) => {
        if (selectedFilters.includes(id)) {
            setSelectedFilters(selectedFilters.filter((x) => x !== id));
        } else {
            setSelectedFilters((selectedFilters) => [
                ...selectedFilters,
                id
            ]);
        }
    };

    const handleMerge = () => {

        if (selectedFilters.length < 2) {
            showToast(
                "Select at least two position to merge...",
                {
                    type: "info",
                }
            );
            return;
        }

        showToast(
            "This feature will be available in next release...",
            {
                type: "info",
            }
        );
        // console.log(selectedFilters, 'selectedFilters');
    }

    const handleImport = () => {
        showToast(
            "This feature will be available in next release...",
            {
                type: "info",
            }
        );
    }

    const {
        nftId,
    } = useSelector(
        (state) => state.settings
    );

    // let noSelected = true;

    return (
        <div className={styles.market}>
            <div className={styles.table}>
                <div className={styles.row}>
                    <div className={styles.col}>
                        {/*<Checkbox
                            className={styles.checkbox}
                            value={chooseAll}
                            onChange={() => setСhooseAll(!chooseAll)}
                        />*/}
                    </div>
                    <div className={styles.col}>Position Id</div>
                    <div className={styles.col}>Status</div>
                    <div className={styles.col}>Net APY</div>
                    <div className={styles.col}>
                        <div className={styles.line}>
                            <div className={styles.info}>Composition</div>
                            <div className={styles.indicators}>
                                {legend.map((x, index) => (
                                    <div className={styles.legend} key={index}>
                                        <div
                                            className={styles.color}
                                            style={{ backgroundColor: x.color }}
                                        ></div>
                                        {x.title}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {allNfttPositions && allNfttPositions.map((x, index) => (
                    <Row
                        item={x}
                        key={index}
                        active={parseInt(nftId) === parseInt(x)}
                        up={items.length - index <= 2}
                        value={selectedFilters.includes(index)}
                        onChange={() => handleChange(index)}
                    />
                ))}
            </div>
            { title !== 'Viewers' && (
            <div className={styles.foot}>
                <div></div>
                <div>
                    <button
                        onClick={() => {
                            handleMerge();
                        }}
                        // disabled={noSelected}
                        className={cn(
                            "button-stroke",
                            // { [styles.disabled]: noSelected }
                        )}
                    >
                        <Icon name="multiselect" size="24" />
                        Merge Selected Positions
                    </button>
                </div>
            </div>
            )}
            { title === 'Viewers' && (
            <div className={styles.foot}>
                <div></div>
                <div>
                    <button
                        onClick={() => {
                            handleImport();
                        }}
                        // disabled={noSelected}
                        className={cn(
                            "button-stroke",
                            "button-shorter",
                            // { [styles.disabled]: noSelected }
                        )}
                    >
                        <Icon name="multiselect" size="24" />
                        Import Positions
                    </button>
                </div>
            </div>
            )}
        </div>
    );
};

export default Table;
