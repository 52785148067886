import { Tenderly } from '@tenderly/sdk';
import { TENDERLY_ACCESS_KEY, TENDERLY_ACCOUNT_NAME, TENDERLY_PROJECT_NAME } from '../utils/config';
import { usePublicClient } from 'wagmi';
import { decodeFunctionResult, encodeFunctionData, formatUnits } from 'viem';
import { useSelector } from 'react-redux';

export const useSimulateTransaction = (chainId) => {

    const formatBigIntValues = (arr) => {
        return arr.map(value => {
            if (typeof value === 'bigint') {
                return formatUnits(
                    value,
                    0
                );
            }
            return value;
        });
    };

    const {
        address
    } = useSelector(
        (state) => state.settings
    );

    const publicClient = usePublicClient();

    const tenderlyInstance = new Tenderly({
        accountName: TENDERLY_ACCOUNT_NAME,
        projectName: TENDERLY_PROJECT_NAME,
        accessKey: TENDERLY_ACCESS_KEY,
        network: chainId,
    });

    const simulate = async (transactions = []) => {

        try {
            const latestBlockNumber = await publicClient.getBlockNumber();

            const mappedTransactions = transactions.map(transaction => {

                const gas = transaction?.gas ?
                    formatUnits(
                        transaction?.gas,
                        0
                    )
                    : 0;

                const value = transaction?.value ?
                    formatUnits(
                        transaction?.value,
                        0
                    )
                    : '0';

                return {
                    from: address,
                    to: transaction?.address,
                    gas,
                    gas_price: '0',
                    value,
                    input: encodeFunctionData({
                        abi: transaction?.abi,
                        functionName: transaction?.functionName,
                        args: formatBigIntValues(
                            transaction.args
                        )
                    }),
                };
            });

            const simulatedBundle = await tenderlyInstance.simulator.simulateBundle({
                transactions: mappedTransactions,
                blockNumber: formatUnits(
                    latestBlockNumber,
                    0
                )
            });

            const result = simulatedBundle.map((result, index) => {
                const transaction = transactions[index];

                if (transaction.returnFullResult) {
                    return result;
                }

                if (transaction.hasReturn) {

                    const mappedTransaction = mappedTransactions[index];

                    const traceData = result.trace.find(item => item.input === mappedTransaction.input);

                    const decodedResult = decodeFunctionResult({
                        data: traceData.output,
                        abi: transaction.abi,
                        functionName: transaction.functionName
                    });

                    return decodedResult;
                }

                return null;
            });

            return result.filter(Boolean);
        } catch (e) {
            console.error('error in simulation', e);
        }
    };

    return { simulate };
};
