import { useReadContracts } from "wagmi";
import { formatUnits } from "viem";

const useWiseGlobals = ({
    config,
}) => {

    const {
        chainId,
        WiseTokenContract,
    } = config;

    const tokenAddress = WiseTokenContract?.address;

    const contracts = [
        {
            chainId,
            abi: WiseTokenContract?.abi,
            address: tokenAddress,
            functionName: "globals",
        },
    ];

    const {
        data,
        error,
        isLoading,
        // refetch
    } = useReadContracts({
        allowFailure: true,
        query: {
            enabled: tokenAddress,
        },
        contracts,
    });

    let g = [];

    g = data
        && data[0]
        && data[0].result;

    const totalSharesFormatted = g
        ? formatUnits(g[1] || 0, 18)
        : "0.00";

    const sharePriceFormatted = g
        ?  formatUnits(g[2] || 0, 18)
        : "0.00";

    const globals = {
        totalStaked: g ? g[0] : 0,
        totalShares: parseInt(totalSharesFormatted),
        sharePrice: parseFloat(sharePriceFormatted),
        currentWiseDay: g ? g[3] : 0,
        referralShares: g ? g[4] : 0,
        liquidityShares: g ? g[5] : 0,
    }

    return {
        data: globals,
        error,
        isLoading,
    };
};

export default useWiseGlobals;
