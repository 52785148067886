import React, { useState, useEffect } from "react";
import styles from "./Staking.module.sass";
import TooltipGlobal from "../../components/TooltipGlobal";
import useConfig from "../../customHooks/useConfig";
import useWiseGlobals from "../../customHooksWise/useWiseGlobals";
import useWiseStakes from "../../customHooksWise/useWiseStakes";
import { useSelector } from "react-redux";
import { appLocalStorage } from "../../utils/localstorage";

//@TODO: used to have overview, but removed it for now
// import Overview from "./Overview";

import Transactions from "./Transactions";

const Staking = ({ fullScreen, onClose }) => {

    const {
        address,
    } = useSelector(
        (state) => state.settings
    );

    const DEFAULT_VALUE = 10;

    const howMany = appLocalStorage.showHowManyStakes.get() || DEFAULT_VALUE;
    const hideType = appLocalStorage.hideStakeType.get() || [];

    let filter = "all";

    if (hideType[0] === "Hide Scrapable Stakes") {
        filter = "transferable";
    }

    if (hideType[0] === "Hide Transferable Stakes") {
        filter = "scrapable";
    }

    const config = useConfig();
    const chainId = config.chainId;

    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(howMany);
    const [refetch, setRefetch] = useState(false);

    const offset = 0;

    const { data: globals } = useWiseGlobals({
        config,
    });

    const currentWiseDay = globals.currentWiseDay;

    const {
        stakes,
        stakeCount,
        nftCount,
        isFetching,
        refetchStakes,
        refetchStakeCount,
        refetchNftCount,
        refetchNftStakes
    } = useWiseStakes({
        config,
        address,
        perPage: perPage,
        page: currentPage,
        offset,
        filter
    });

    const doRefetch = () => {
        // refetchStakes(),
        // refetchStakeCount();
        setRefetch(!refetch);
    };

    useEffect(() => {
        refetchStakes();
        refetchNftCount();
        refetchNftStakes();
        refetchStakeCount();
        // doRefetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch, hideType[0]]);

    return (
        <>
            <div className={styles.section}>
                {/*<Overview className={styles.card} />*/}
                <Transactions
                    fullScreen={fullScreen}
                    refetch={refetch}
                    nftCount={nftCount}
                    doRefetch={doRefetch}
                    chainId={chainId}
                    address={address}
                    stakes={stakes}
                    className={styles.card}
                    currentWiseDay={currentWiseDay}
                    perPage={perPage}
                    onClose={onClose}
                    stakeCount={stakeCount}
                    setPerPage={setPerPage}
                    isFetching={isFetching}
                    setCurrentPage={setCurrentPage}
                />
            </div>
            <TooltipGlobal />
        </>
    );
};

export default Staking;
