import React from "react";
import cn from "classnames";
import styles from "./BoardOverview.module.sass";
import Card from "../../components/Card";
import Tooltip from "../../components/Tooltip";
import Row from "./Row";
import { PointsV2Navigation } from "../../utils/config";
import Scrollbar from "../../components/Scrollbar";
import Loader from "../../components/Loader";
import TooltipGlobal from "../../components/TooltipGlobal";

const BoardOverview = ({
    isFetching,
    activeTab,
    tableData,
    onChange,
    currentUserRank
}) => {

    return (
        <Card
            className={styles.card}
            classCardHead={styles.head}
            title="Points Overview"
            classTitle={cn("title-green", styles.title)}
            head={
                <div className={styles.nav}>
                    {Object.values(PointsV2Navigation).map((x, index) => (
                        <button
                            className={cn(styles.button, {
                                [styles.active]: x === activeTab,
                            })}
                            onClick={() => onChange(x)}
                            key={index}
                        >
                            {x}
                        </button>
                    ))}
                </div>
            }
        >
            <div className={styles.wrapper}>
                <Scrollbar className={styles.scroll}>
                    {isFetching ? (
                        <div className={styles.foot}>
                            <Loader className={styles.loader} />
                            <span>Loading Data</span>
                        </div>
                    ) : (
                        <div className={styles.table}>
                            <div className={styles.row}>
                                <div className={styles.col}>Rank</div>
                                <div className={styles.col}>User Address</div>
                                <div className={styles.col}>
                                    Contribution
                                    <Tooltip
                                        className={cn(
                                            styles.tooltip,
                                        )}
                                        title={'currently deposited and referred funds'}
                                        icon="info-stroke"
                                        place="right"
                                    />
                                </div>
                                <div className={styles.col}>Points Rate
                                    <Tooltip
                                        className={cn(
                                            styles.tooltip,
                                        )}
                                        title={'amount of points user earns per second'}
                                        icon="info-stroke"
                                        place="right"
                                    />
                                </div>
                                <div className={styles.col}>
                                    Points
                                    <Tooltip
                                        className={cn(
                                            styles.tooltip,
                                        )}
                                        title={'points earned by user this season'}
                                        icon="info-stroke"
                                        place="right"
                                    />
                                </div>
                                <TooltipGlobal />
                            </div>
                            {tableData.map((x, index) => (
                                <Row
                                    item={x}
                                    rank={index}
                                    key={index}
                                    isCurrent={currentUserRank === index}
                                />
                            ))}
                        </div>
                    )}
                </Scrollbar>
            </div>
            {/*<div className={styles.foot}>
                <button className={cn("button-stroke button-small", styles.button)}>
                    <Loader className={styles.loader} />
                    <span>Load more</span>
                </button>
            </div>*/}
        </Card>
    );
};

export default BoardOverview;
