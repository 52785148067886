import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { Link } from "react-router-dom";
import Modal from "../../../../components/Modal";
import useTokenBalance from "../../../../customHooks/useTokenBalance";
import Icon from "../../../../components/Icon";
import {
    _no,
    formatMoneyDashed,
    formatDecimals,
    displayWithLoaderObj,
    showToast,
    // _notEmpty,
    // toPrecision
} from "../../../../utils";

import styles from "./Row.module.sass";
import Supply from "./Supply";
import Withdraw from "./Withdraw";
import Tooltip from "../../../../components/Tooltip";
import Logo from "../../../../components/Logo";
import { formatUnits } from "viem";
// import { POOL_NAMES } from "../../../../ethers/tokens";

const Row = ({
    token,
    nftId,
    config,
    // extraApy,
    summary,
    expand,
}) => {

    const { isTestMode, isDeprecated } = config;
    const address = useSelector(
        (state) => state.settings.address
    );

    const {
        pricingFeeds,
        walletBalance,
        // chainId
    } = useSelector(
        (state) => state.token
    );

    const {
        showSwitchNetwork
    } = useSelector(
        (state) => state.settings
    );

    const { tokenBalance } = useTokenBalance({
        config,
        nftId,
        token,
        userAccount: address,
        walletBalance
    });

    const tokenPriceData = pricingFeeds.find(
        item => item.name === tokenBalance.name
    );

    const [visibleSupplyModal, setVisibleSupplyModal] = useState(false);
    const [visibleWithdrawModal, setVisibleWithdrawModal] = useState(false);

    const handleOpenModal = (modal) => {
        if (_no(token.isEnabled)) {
            showToast(
                isTestMode
                    ? "This asset is not available in testnet demo mode"
                    : "Coming very soon..."
            );
            return;
        }
        setVisibleWithdrawModal(false);
        setVisibleSupplyModal(false);

        if (modal === "withdraw")
            setVisibleWithdrawModal(true);

        if (modal === "supply")
            setVisibleSupplyModal(true);
    };

    useEffect(() => {
        if (showSwitchNetwork) {
            setVisibleWithdrawModal(false);
            setVisibleSupplyModal(false);
        }
    }, [showSwitchNetwork]);

    /*
    const extraApyLabel = useMemo(() => {
        if (_notEmpty(extraApy)) {
            // return `${toPrecision(extraApy * 100)}%`;
            // return `${(extraApy * 100).toFixed(0)}%`;
            return `${Math.ceil(extraApy * 100)}%`;
        } else {
            return 'P';
        }
    }, [extraApy]);
    */

    const bareTotalPosition = (tokenBalance.supplied ?? 0n)
        + (tokenBalance.withdrawSolelyBalance ?? 0n);

    const totalPosition = tokenBalance.supplied && formatUnits(
        bareTotalPosition,
        tokenBalance.decimals
    );

    /*
    const ethApyTooltip = useMemo(() => {
        let header, multiplier, additionalPoints;

        if (token.poolName !== POOL_NAMES.ETH) {
            // header = `${token.poolName.replace('-AAVE', '')} deposits earn points`;
            header = ``;
            multiplier = 'Up to 20x Sats multiplier';
            additionalPoints = [
                // 'Earns 2x Turtle points if activated',
                'Earns 10x Wise Points'
            ];
        } else {
            //header = 'ETH deposits earn points';
            header = '';
            multiplier = 'Up to 2x Etherfi multiplier';
            additionalPoints = [
                'Up to 1x Eigenlayer multiplier',
                // 'Earns 2x Turtle points if activated',
                'Earns 10x Wise Points'
            ];
        }

        return `
            <h4>${header}</h4>
            <!-- <br /> -->
            <div>${multiplier} <span style="color:#f99191">(+${extraApyLabel} APY)</span></div>
            ${additionalPoints.join('<br />')}
        `;
    }, [extraApyLabel, token.poolName]);
    */

    return (
        <>
            <div
                className={cn(
                    styles.row,
                    {
                        [styles.disabled]: _no(token.isEnabled)
                    }
                )}
                onClick={(event) => {
                    const classNamer = event.target.getAttribute("class");
                    if (classNamer === null || !classNamer) {
                        return;
                    }
                    if (classNamer.includes("button")) return;
                    if (_no(isDeprecated)) handleOpenModal("supply");
                }}
            >
                <div className={styles.col}>
                    <div className={styles.token}>
                        <Tooltip
                            className={styles.tooltip}
                            title={"Aave Pool - funds provided to Aave, until natively borrowed"}
                            place="right"
                        >
                            <Logo
                                className={styles.currency}
                                logo={token.icon}
                                subLogo={token.sub}
                                width={38}
                                height={38}
                            />
                        </Tooltip>
                        <div>
                            <div className={styles.description}>{tokenBalance.title}</div>
                            <div className={styles.title}>{tokenBalance.name}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.label}>Provided Total</div>
                        <div className={styles.value}>
                            {
                                displayWithLoaderObj({
                                    isLoading: !tokenBalance.totalFormatted,
                                    data: formatMoneyDashed(tokenBalance.totalFormatted)
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.label}>APY</div>
                        <div className={styles.apyValue}>
                            <div className={
                                token.canBorrow === false
                                    ? "status-green"
                                    : "status-green-dark"
                            }
                            >
                                {
                                    token.canBorrow === false
                                        ? "N / A"
                                        :
                                        `${formatDecimals(
                                            tokenBalance.supplyRateFormatted
                                        )}%`
                                }
                            </div>
                            {/*tokenBalance.name !== "wstETH" && chainId !== mainnet.id && !!extraApyLabel
                                && (
                                    <Tooltip
                                        className={styles.tooltip}
                                        title={ethApyTooltip}
                                        place="right"
                                    >
                                        <div className={
                                            token.canBorrow === false
                                                ? "status-green"
                                                : "status-purple-dark"
                                        }
                                        >
                                            +{extraApyLabel}
                                        </div>
                                    </Tooltip>
                                )*/}
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.label}>Provided By You</div>
                        <div className={styles.value}>
                            {displayWithLoaderObj({
                                isLoading: !tokenBalance.totalFormatted,
                                data: formatMoneyDashed(totalPosition)
                            })}
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.buttons}>
                        <button
                            className={cn(
                                "button-small",
                                styles.button,
                                styles.supply,
                                {
                                    disabled: isDeprecated,
                                    [styles.disabled]: isDeprecated
                                    // disabled: true,
                                    // [styles.disabled]: true
                                }
                            )}
                            onClick={() => handleOpenModal("supply")}
                        >
                            Lend
                        </button>
                        <button
                            disabled={_no(totalPosition)}
                            className={cn(
                                "button-stroke button-small",
                                styles.button,
                                {
                                    disabled: _no(totalPosition)
                                }
                            )}
                            onClick={() => handleOpenModal("withdraw")}
                        >
                            Redeem
                        </button>
                        {!expand && (
                            <Link
                                to={`/lending/pool/${tokenBalance.name.toLowerCase()}`}
                                // disabled={_no(tokenBalance.repayBalance)}
                                className={cn(
                                    "button-stroke button-small",
                                    styles.button,
                                    styles.infos
                                )}
                                // onClick={() => showInfoDefiLama(tokenBalance.name)}
                            >
                                <Icon name="info" />
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            <Modal
                visible={visibleSupplyModal}
                onClose={() => setVisibleSupplyModal(false)}
            >
                <Supply
                    config={config}
                    nftId={nftId}
                    token={tokenBalance}
                    summary={summary}
                    tokenPriceData={tokenPriceData}
                    walletBalance={walletBalance}
                    onConnect={() => handleOpenModal("connect")}
                    onDone={() => setVisibleSupplyModal(false)}
                    // extraApyLabel={extraApyLabel}
                    // ethApyTooltip={ethApyTooltip}
                />
            </Modal>
            <Modal
                visible={visibleWithdrawModal}
                onClose={() => setVisibleWithdrawModal(false)}
            >
                <Withdraw
                    config={config}
                    nftId={nftId}
                    token={tokenBalance}
                    tokenPriceData={tokenPriceData}
                    summary={summary}
                    walletBalance={walletBalance}
                    onConnect={() => handleOpenModal("connect")}
                    onDone={() => setVisibleWithdrawModal(false)}
                />
            </Modal>
        </>
    );
};

export default Row;
