import {
    arbitrum,
    mainnet,
} from "viem/chains";

// const aaveLogo = "/images/aave.svg";
// const kelpLogo = "/images/kelp.png";
const ethenaLogo = "/images/ethena.webp";
const etherFiLogo = "/images/ether.webp";
const ethLidoLogo = "/images/content/lido-eth.svg";

const pendleUSDFarmTokensMainnet = {
    USDC: {
        minimumBorrow: 2000,
        isActive: true,
        isAave: false,
        powerFarmId: 2,
    },
    USDT: {
        minimumBorrow: 2000,
        isActive: true,
        isAave: false,
        powerFarmId: 3
    },
    DAI: {
        minimumBorrow: 2000,
        isActive: true,
        isAave: false,
        powerFarmId: 4
    },
};

const ETH_UTILIZATION_THRESHOLD = 0.9;
const STABLE_UTILIZATION_THRESHOLD = 0.9;

export const PENDLE_MARKET_CONTRACT_ADDRESSES = {
    Etherfi: "0xf9F9779d8fF604732EBA9AD345E6A27EF5c2a9d6",
    Ethena: "0x2Dfaf9a5E4F293BceedE49f2dBa29aACDD88E0C4",
};

export const powerFarms = [
    {
        // new LIDO Eth Farm
        id: 1,
        product: "Pendle ETH Farm",
        link: "Pendle Lido wstETH Pool",
        maturityDate: "2025-12-25",
        image: "/images/content/pendle.jpg",
        image2x: "/images/content/pendle.jpg",
        badge: ethLidoLogo,
        active: true,
        disabled: false,
        defaultDeposit: 0.1,
        allowedSpread: "1.020",
        contract: "EthenaApiLidoRoutingETH",
        fetchCallData: true,
        APY: "pendleAPY",
        tokens: {
            ETH: {
                minimumBorrow: 0.03,
                includeWETH: true,
                isAave: false,
            },
        },
        pendleAPYMarket: "0xC374f7eC85F8C7DE3207a10bB1978bA104bdA3B2",
        pendleAPYChainId: mainnet.id,
        defaultToken: "ETH",
        utilizationThreshold: ETH_UTILIZATION_THRESHOLD
    },
    {
        // NewEtherFi Farm
        id: 10,
        product: "Pendle ETH Farm",
        link: "Pendle Ether.FI Pool",
        maturityDate: "2025-06-26",
        image: "/images/content/pendle.jpg",
        image2x: "/images/content/pendle.jpg",
        badge: etherFiLogo,
        active: true,
        disabled: false,
        defaultDeposit: 0.1,
        allowedSpread: "1.020",
        contract: "LRTfiPowerFarmApiRoutingContract2025",
        fetchCallData: true,
        APY: 'pendleAPY',
        tokens: {
            ETH: {
                minimumBorrow: 0.03,
                includeWETH: true,
                isAave: true,
            },
        },
        // farmAddress: "0xDB9Af861aae3Dd6BeC86f5512d704AFc621722d5",
        pendleAPYMarket: "0xBf5E60ddf654085F80DAe9DD33Ec0E345773E1F8",
        pendleAPYChainId: arbitrum.id,
        defaultToken: "ETH",
        utilizationThreshold: ETH_UTILIZATION_THRESHOLD
    },
    {
        // NewEtherFi Farm
        id: 1,
        product: "Pendle ETH Farm",
        link: "Pendle Ether.FI Pool",
        maturityDate: "2024-09-26",
        image: "/images/content/pendle.jpg",
        image2x: "/images/content/pendle.jpg",
        badge: etherFiLogo,
        active: true,
        disabled: false,
        defaultDeposit: 0.1,
        allowedSpread: "1.020",
        contract: "LRTfiPowerFarmApiRoutingContractFixed",
        fetchCallData: true,
        APY: 'pendleAPY',
        tokens: {
            ETH: {
                minimumBorrow: 0.03,
                includeWETH: true,
                isAave: true,
            },
        },
        // farmAddress: "0xDB9Af861aae3Dd6BeC86f5512d704AFc621722d5",
        pendleAPYMarket: PENDLE_MARKET_CONTRACT_ADDRESSES.Etherfi,
        pendleAPYChainId: arbitrum.id,
        defaultToken: "ETH",
        utilizationThreshold: ETH_UTILIZATION_THRESHOLD
    },
    {
        // Ethena Farm (USDC-USDe)
        id: 2,
        product: "Pendle USDC Farm",
        link: "Pendle Ethena sUSDe Pool",
        maturityDate: "2025-05-29",
        image: "/images/content/pendle.jpg",
        image2x: "/images/content/pendle.jpg",
        badge: ethenaLogo,
        active: true,
        disabled: false,
        defaultDeposit: 10,
        allowedSpread: "1.020",
        contract: "EthenaApiRoutingUsdc3",
        fetchCallData: true,
        APY: "pendleAPY",
        tokens: pendleUSDFarmTokensMainnet,
        pendleAPYMarket: "0xB162B764044697cf03617C2EFbcB1f42e31E4766",
        pendleAPYChainId: mainnet.id,
        defaultToken: "USDC",
        utilizationThreshold: STABLE_UTILIZATION_THRESHOLD
    },
    {
        // Ethena Farm (USDT-USDe)
        id: 3,
        product: "Pendle USDT Farm",
        link: "Pendle Ethena sUSDe Pool",
        maturityDate: "2025-05-29",
        image: "/images/content/pendle.jpg",
        image2x: "/images/content/pendle.jpg",
        badge: ethenaLogo,
        active: true,
        disabled: false,
        defaultDeposit: 10,
        allowedSpread: "1.020",
        contract: "EthenaApiRoutingUsdt3",
        fetchCallData: true,
        APY: "pendleAPY",
        tokens: pendleUSDFarmTokensMainnet,
        pendleAPYMarket: "0xB162B764044697cf03617C2EFbcB1f42e31E4766",
        pendleAPYChainId: mainnet.id,
        defaultToken: "USDT",
        utilizationThreshold: STABLE_UTILIZATION_THRESHOLD
    },
    {
        // Ethena Farm (DAI-USDe)
        id: 4,
        product: "Pendle DAI Farm",
        link: "Pendle Ethena sUSDe Pool",
        maturityDate: "2025-05-29",
        image: "/images/content/pendle.jpg",
        image2x: "/images/content/pendle.jpg",
        badge: ethenaLogo,
        active: true,
        disabled: false,
        defaultDeposit: 10,
        allowedSpread: "1.020",
        contract: "EthenaApiRoutingDai3",
        fetchCallData: true,
        APY: "pendleAPY",
        tokens: pendleUSDFarmTokensMainnet,
        pendleAPYMarket: "0xB162B764044697cf03617C2EFbcB1f42e31E4766",
        pendleAPYChainId: mainnet.id,
        defaultToken: "DAI",
        utilizationThreshold: STABLE_UTILIZATION_THRESHOLD
    },
    {
        // Ethena Farm (USDC-USDe)
        id: 20,
        product: "Pendle USDC Farm",
        link: "Pendle Ethena sUSDe Pool",
        maturityDate: "2025-03-27",
        expiring: true,
        image: "/images/content/pendle.jpg",
        image2x: "/images/content/pendle.jpg",
        badge: ethenaLogo,
        active: true,
        disabled: false,
        defaultDeposit: 10,
        allowedSpread: "1.020",
        contract: "EthenaApiRoutingUsdc2",
        fetchCallData: true,
        APY: "pendleAPY",
        tokens: pendleUSDFarmTokensMainnet,
        pendleAPYMarket: "0xcDd26Eb5EB2Ce0f203a84553853667aE69Ca29Ce",
        pendleAPYChainId: mainnet.id,
        defaultToken: "USDC",
        utilizationThreshold: STABLE_UTILIZATION_THRESHOLD
    },
    {
        // Ethena Farm (USDT-USDe)
        id: 21,
        product: "Pendle USDT Farm",
        link: "Pendle Ethena sUSDe Pool",
        maturityDate: "2025-03-27",
        expiring: true,
        image: "/images/content/pendle.jpg",
        image2x: "/images/content/pendle.jpg",
        badge: ethenaLogo,
        active: true,
        disabled: false,
        defaultDeposit: 10,
        allowedSpread: "1.030",
        contract: "EthenaApiRoutingUsdt2",
        fetchCallData: true,
        APY: "pendleAPY",
        tokens: pendleUSDFarmTokensMainnet,
        pendleAPYMarket: "0xcDd26Eb5EB2Ce0f203a84553853667aE69Ca29Ce",
        pendleAPYChainId: mainnet.id,
        defaultToken: "USDT",
        utilizationThreshold: STABLE_UTILIZATION_THRESHOLD
    },
    {
        // Ethena Farm (DAI-USDe)
        id: 22,
        product: "Pendle DAI Farm",
        link: "Pendle Ethena sUSDe Pool",
        maturityDate: "2025-03-27",
        expiring: true,
        image: "/images/content/pendle.jpg",
        image2x: "/images/content/pendle.jpg",
        badge: ethenaLogo,
        active: true,
        disabled: false,
        defaultDeposit: 10,
        allowedSpread: "1.020",
        contract: "EthenaApiRoutingDai",
        fetchCallData: true,
        APY: "pendleAPY",
        tokens: pendleUSDFarmTokensMainnet,
        pendleAPYMarket: "0xcDd26Eb5EB2Ce0f203a84553853667aE69Ca29Ce",
        pendleAPYChainId: mainnet.id,
        defaultToken: "DAI",
        utilizationThreshold: STABLE_UTILIZATION_THRESHOLD
    },
];


export const getFarmAPR = (farm, APY) => {
    // console.log(APY, 'APY');
    return farm.APY === "pendleAPY"
        ? APY[farm.APY][farm.pendleAPYMarket]
        : APY[farm.APY];
};
