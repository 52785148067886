import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
// import Loader from "../../../components/Loader";
// import Modal from "../../../components/Modal";
// import Row from "./Row";
// import NewPost from "./NewPost";

// mock data
// import { posts } from "../../../mocks/posts";

const intervals = [
    "Last 7 days",
    "This month",
    "All time"
];

const RecentPost = ({ className }) => {

    const [sorting, setSorting] = useState(intervals[0]);

    return (
        <>
            <Card
                style={{ padding: "0px" }}
                title="Lending Positions"
                className={styles.nopadding}
                classTitle={cn("title-blue", styles.title)}
            >
            <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={sorting}
                setValue={setSorting}
                options={intervals}
                small
            />
            <button
                className={cn("button-small", styles.button)}
            >
                Create New
            </button>
            </Card>
        </>
    );
};

export default RecentPost;
