import React, { useMemo } from "react";

import useConfig from "../../customHooks/useConfig";
import TooltipGlobal from "../../components/TooltipGlobal";
import { useGetTransactions } from "../../customHooks/useGetTransactions";

import styles from "./Earning.module.sass";
// import Overview from "./Overview";
// import ProductSales from "./ProductSales";
import SupportedTokens from "./SupportedTokens";
import ActivityDetailsTable from "./Table";
import GlobalUtilization from "./GlobalUtilization";
// import MoreWidgets from "./MoreWidgets";
// import TopPools from "./TopPools";
import Released from "../Statistic";
import { useSelector } from "react-redux";
import { getSupportedTokens } from "../../ethers/tokens";
import useTokenUtilization from "../../customHooks/useTokenUtilitzation";

const Earning = () => {

    const config = useConfig();

    const { chainId, availablePools } = config;

    const { pricingFeeds } = useSelector(
        (state) => state.token
    );

    const { transactions, refetch, isFetching, isRefetching } = useGetTransactions({
        config
    });

    const tokens = useMemo(
        () => {
            return getSupportedTokens(
                chainId,
                availablePools
            );
        },
        [chainId, availablePools]
    );

    const { tokenData: tokensUtilization, isLoading } = useTokenUtilization({
        tokens,
        config,
        tokenPrices: pricingFeeds,
        shouldRefetchEveryBlock: true
    });

    return (
        <>
            <div className={styles.section}>
                {/* <Overview className={styles.card} /> */}
                <div className={styles.row}>
                    <div className={styles.colfull}>
                        <Released
                            tokensUtilization={tokensUtilization}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        {/* <ProductSales /> */}
                        <ActivityDetailsTable 
                            transactions={transactions} 
                            isFetching={isFetching}
                            onRefreshClick={refetch}
                            isRefetching={isRefetching}
                        />
                    </div>
                    <div className={styles.col}>
                        <GlobalUtilization
                            widgetTitle={"Global Utilization"}
                            tokensUtilization={tokensUtilization}
                            isLoading={isLoading}
                        />
                        <SupportedTokens />
                        {/*<MoreWidgets />*/}
                    </div>
                </div>
            </div>
            <TooltipGlobal />
        </>
    );
};

export default Earning;
