import { ethers } from "ethers";
import { fromHex } from "viem";
import {
    arbitrum,
    mainnet,
} from "wagmi/chains";
import { _no } from ".";
import { getContracts } from "../ethers/contracts";

export const BACKEND_URL = "https://backend.wise.one";
export const POWER_USER_ADDRESS = "0x641AD78BAca220C5BD28b51Ce8e0F495e85Fe689";

// export const OPENSEA_API = "https://api.opensea.io";
// export const APRSMA_LINK = "https://eth-api.lido.fi/v1/protocol/steth/apr/sma";

export const PENDLE_API_BASE_URL = "https://api-v2.pendle.finance";
export const DEFI_LLAMA_BASE_LINK = "https://defillama.com";
export const DEFI_LLAMA_YIELDS_LINK = "https://yields.llama.fi";

export const DEFI_LLAMA_WISE_LENDING_INFO_LINK = `${DEFI_LLAMA_BASE_LINK}/protocol/wise-lending-v2#information`;
export const DEFI_LLAMA_WISE_LENDING_YIELDS_LINK = `${DEFI_LLAMA_BASE_LINK}/protocol/wise-lending-v2#yields`;

export const DEFI_LLAMA_POOL_IDS = {
    [mainnet.id]: {
        ETH: "70b7e8bb-d899-4e9d-8c4c-f64a16c279e0",
        USDC: "18f35f0c-9ca0-4562-a39a-d99234bcab47",
        USDT: "11b3ddf4-328f-425c-b7fd-dc69bbcada23",
        DAI: "c22c0ff8-f9a3-41e6-888a-bfcafe17ffbc",
        WBTC: "2596a9b9-f423-485a-8265-e1a1a560ef36",
    },
    [arbitrum.id]: {
        ETH: "4d36020f-626a-44eb-862e-77c9d84d168c",
        USDC: "7ecf801f-26db-4cb2-a61e-0f87969e974b",
        USDT: "7e7bd235-488d-4333-b6b5-2adbe5dbd098",
        DAI: "c0ab9b55-7885-452d-bf1d-d929c884ff79",
        WST_ETH: "11080f77-169f-41a4-9052-9d7efede8b4d",
    }
};

//PENDLE APY
export const PENDLE_APY_LINK = (chainId, marketAddress) => {
    return `${PENDLE_API_BASE_URL}/core/v1/${chainId}/markets/${marketAddress}`;
};

export const PENDLE_APY_V2_LINK = (chainId, marketAddress) => {
    return `${PENDLE_API_BASE_URL}/core/v2/${chainId}/markets/${marketAddress}/data`;
};

export const PENDLE_API_LINK_V2 = (chainId) => {
    return `${PENDLE_API_BASE_URL}/core/v2/${chainId}`;
};

export const PENDLE_API_LINK = `${PENDLE_API_BASE_URL}/sdk/api/v1`;
export const HOME_TITLE = "Wise Lending Hub";

// ETHERS
export const ALCHEMY_API_KEY = "J3bTM7KLiYYwh8Ar_VBXuo-oLlGTx7od";
export const KADO_API_KEY = "9ebc1f0e-2049-4b91-8fba-6e9ee13110a1";
export const INFURA_API_KEY = "1bffd3c056ee47f4ad9c4e004b6f1853";
// export const INFURA_API_KEY = "960773bc580844be9146d406cfdfa589"; // temporary key
// export const COINBASE_WALLET_APP_NAME = "Wise Lending";
// export const WALLET_CONNECT_API_KEY = "35b842947b68e973a2124a04f1dd590c";

//TENDERLY SDK
export const TENDERLY_ACCOUNT_NAME = "jcontillo";
export const TENDERLY_PROJECT_NAME = "wise";
export const TENDERLY_ACCESS_KEY = "9UefyZO3OtGiwEIqcow0pd470exGxRly";

export const APPROVE_MAX_VALUE = fromHex(
    ethers.constants.MaxUint256,
    "bigint"
);

export const USDT_TOKEN_NAME = "USDT";
export const ETH_TOKEN_NAME = "ETH";

export const DEFAULT_CHAIN_ID = mainnet.id;
export const DEFAULT_DECIMALS = 18;
export const DEFAULT_TOKEN_NAME = ETH_TOKEN_NAME;
export const DEFAULT_TOKEN_ICON = "/images/icons/eth.svg";
export const APY_DECIMALS = 16;
export const SAFE_lIMIT_PERCENTAGE = 95;
export const YEAR_DAYS = 365;
export const TIME_INTERVAL = 10;
export const ALLOWED_SPREAD = "1.005";
export const DECIMALS_BASE_TEN = 10;

// export const ZERO_THRESHOLD = 0.005;
export const ZERO_THRESHOLD = 0.000;

// export const USD_ZERO_THRESHOLD = 0.01;
export const USD_ZERO_THRESHOLD = 0.00;
export const SWING_PERCENT = 0.999;
export const INFLATE_PERCENT = 1.05;

export const NATIVE_TOKENS = [
    ETH_TOKEN_NAME
];

export const NEED_RESET_TOKENS = [
    USDT_TOKEN_NAME
];

export const CONTRACT_VERSIONS = {
    default: {
        [mainnet.id]: 3,
        // [goerli.id]: 10,
        [arbitrum.id]: 1
    },
    archive: {
        [mainnet.id]: 2,
        // [goerli.id]: 9,
    }
};

export const SUPPORTED_CHAINS = [
    {
        chainId: mainnet.id,
        // chainName: "Mainnet",
        chainName: mainnet.name,
        chainNetwork: "eth",
        chainIcon: "/images/networks/ethereum.svg",
        isActiveChain: true,
        hasEnsName: true,
    },
    {
        chainId: arbitrum.id,
        // chainName: "Arbitum",
        chainName: arbitrum.name,
        chainNetwork: "arbitrum",
        chainIcon: "/images/networks/arbitrum.svg",
        isActiveChain: true,
        hasEnsName: true,
    },
    /*{
        chainId: goerli.id,
        // chainName: "Goerli",
        chainName: goerli.name,
        chainNetwork: 'eth',
        chainIcon: "/images/networks/goerli.svg",
        isActiveChain: true,
        hasEnsName: true,
    },
    */
];

export const getActiveChains = () => {
    return SUPPORTED_CHAINS.filter(
        chain => chain.isActiveChain
    );
};

export const checkChain = (chainId) => {
    const activeChains = getActiveChains();
    const chain = activeChains.find(chain => chain.chainId === chainId);

    if (_no(chain)) {
        return {
            chainId: null
        };
    }

    return chain;
};

export const getConfig = (chainId, version) => {
    const chain = checkChain(
        chainId
    );

    if (chain.chainId && version) {
        const contracts = getContracts(chainId, version);

        if (contracts) return {
            ...contracts,
            ...chain
        };
    }

    return {
        chainId: null,
        version: null
    };
};

export const NO_REFERRAL = "No Referral";

// SETTINGS
export const SHOW_NETAPY = true;
export const SHOW_MANY_STAKES = 10;
export const HIDE_STAKE_TYPE = [];
export const SHOW_MANY_REFERRALS = 10;
export const INTERFACE_PREFERNCE = {};
export const NETAPY_STORAGE_KEY = "showNetAPY";
export const PREFERRED_HOME_TAB = "activeHomeTab";
export const PREFERRED_NFT_KEY = "preferredNFT";

// TRANSACTION TEXTS
export const CONFIRM_TEXT = "Confirm in Wallet";
export const APPROVE_IN_WALLET_TEXT = "Approve in Wallet";
export const DONE_TEXT = "Done";
export const FETCHING_DATA = "Loading...";
export const TRY_AGAIN_TEXT = "Try Again";
export const APPROVING_TEXT = "Approving...";
export const SUPPLYING_TEXT = "Lending...";
export const CLAIMING_TEXT = "Claiming...";
export const SIGNING_TEXT = "Signing...";
export const SUPPLY_FAILED_MESSAGE = "Lending transaction failed";
export const SUPPLY_SUCCESS = "You've successfully provided";
export const CLAIM_FAILED_MESSAGE = "Claiming rewards failed";
export const CLAIM_SUCCESS = "You've successfully claimed";
export const BORROWING_TEXT = "Borrowing...";
export const BORROW_FAILED_MESSAGE = "Borrowing transaction failed";
export const BORROW_SUCCESS = "You've successfully borrowed";
export const REPAYING_TEXT = "Repaying...";
export const REPAY_FAILED_MESSAGE = "Repaying transaction failed";
export const REPAY_SUCCESS = "You've successfully repaid";
export const WITHDRAWING_TEXT = "Redeeming...";
export const WITHDRAW_FAILED_MESSAGE = "Redeem transaction failed";
export const WITHDRAW_SUCCESS = "You've successfully redeemed";
export const OPENING_TEXT = "Confirming...";
export const OPEN_FAILED_MESSAGE = "Confirming position failed";
export const OPEN_SUCCESS = "You've successfully opened ";
export const CLOSING_TEXT = "Closing...";
export const CLOSE_FAILED_MESSAGE = "Closing position failed";
export const CLOSE_SUCCESS = "You've successfully closed position ";
export const COLLECTING_TEXT = "Collecting...";
export const COLLECT_FAILED_MESSAGE = "Collecting interest failed";
export const COLLECT_SUCCESS = "You've successfully collected interest ";
export const INSUFFICIENT_COST = "Insufficient funds to pay for transaction cost";
// export const CLICK_TO_VIEW = ". Click to view details";
export const CLICK_TO_VIEW = "";
export const WISELENDING_TEXT = "WiseLending";
export const APPROVE_FUNCTION_NAME = "approve";
export const APPROVE_AND_MINT_FUNCTION_NAME = "approveMint";

export const APPROVE_TEXT = (tokenName) => {
    return `Approve ${tokenName} to continue`;
};

export const RESET_APPROVAL_TEXT = (tokenName) => {
    return `Reset ${tokenName} approval`;
};
export const JOIN_REFERRAL_NETWORK_SUCCESS = "You've successfully joined the referral network";
// SIDEBAR
export const POWER_USERS = [
    // "0xE73168deb6831502FFC1F437e4835A3eA12D69b7"
];

// LAYOUT
export const MAIN_COLOR_A = "#00b0aa"; //$s4 (helper)
export const MAIN_COLOR_B = "#f99191"; //$s3 (helper)
export const MAIN_COLOR_C = "#f99191";
export const MAIN_COLOR_B_DIM = "#ffffff22"; //$s3 (helper)
export const GREEN_STATUS = "status-green";
export const YELLOW_STATUS = "status-yellow";
export const RED_STATUS = "status-red";

// MODES
export const MODES = {
    simple: "Enable Expert Mode",
    expert: "Enable Simple Mode"
};

export const switchMode = (mode) => {
    if (mode && mode === MODES.simple) return MODES.expert;
    return MODES.simple;
};

// CUSTOM WARNINGS
export const DEFAULT_ERROR = "Something went wrong, please try again";
export const CUSTOM_WARNING_MESSAGES = [
    "User rejected the request"
];

// PRICING FEED BACKEND
export const BACKEND_ORACLE_LINK = "https://data.wisetoken.net/WISE/globals/oracles.json";
export const WISE_TVL_LINK = "https://data.wisetoken.net/WISE/globals/tvl.json";
export const ARBITRUM_BRIDGE_URL = "https://bridge.arbitrum.io/?destinationChain=arbitrum-one&sourceChain=ethereum";
export const MAINNET_BRIDGE_URL = "https://bridge.arbitrum.io/?destinationChain=ethereum&sourceChain=arbitrum-one";

// deprecated
export const PRICE_FEED_PREFIX = "MAINNET";

//ERRORS LOG TEXT
export const ERROR_TRANSACTION = "Error in getting transactions";
export const ERROR_GAS_ESTIMATE = "The total cost (gas * gas fee + value) of executing this transaction exceeds the balance of the account.";
export const ERROR_PRICING_FEEDS = "Error in fetching token pricing feeds";
export const ERROR_NFTIDS = "Error in fetching nftIds";
export const ERROR_RWA_READ = "Error in RWA contract read";
export const ERROR_BORROW_WRITE = "Error in borrow contract write";
export const ERROR_BORROW = "Error in borrow";
export const ERROR_REPAY_WRITE = "Error in repay contract write";
export const ERROR_REPAY = "Error in repay";
export const ERROR_SUPPLY_WRITE = "Error in supply contract write";
export const ERROR_SUPPLY = "Error in supply";
export const ERROR_WITHDRAW_WRITE = "Error in withdraw contract write";
export const ERROR_WITHDRAW = "Error in withdraw";
export const ERROR_ALLOWANCE_FETCH = "Error in fetching allowance";
export const ERROR_ALLOWANCE_WRITE = "Error in token allowance contract write";
export const ERROR_ALLOWANCE_APPROVE = "Error in allowance contract approve";
export const ERROR_REWARD_WRITE = "Error in claim rewards contract write";
export const ERROR_REWARD_CLAIM = "Error in contract claiming rewards";
export const ERROR_WISELENDING_APPROVAL_FETCH = "Error in fetching wiselending approval";
export const ERROR_WISELENDING_APPROVAL_WRITE = "Error in wiselending approval contract write";
export const ERROR_WISELENDING_APPROVAL_APPROVE = "Error in wiselending contract approve";
export const ERROR_OVERALL_BALANCE = "Error in fetching lending summary";
export const ERROR_OVERALL_SUPPLY = "Error in fetching overall supply balance";
export const ERROR_OVERALL_BORROW = "Error in fetching overall borrow balance";
export const ERROR_OVERALL_LIMIT = "Error in fetching overall borrow limit";
export const ERROR_OVERALL_LENDING_APY = "Error in fetching overall lending apy";
export const ERROR_OVERALL_BORROW_APY = "Error in fetching overall borrow apy";
export const ERROR_OVERALL_NET_APY = "Error in fetching overall net apy";
export const ERROR_TOKEN_BALANCE = "Error in fetching token balances";
export const ERROR_TOKEN_BORROW_RATES = "Error in getting borrow rates";
export const ERROR_BALANCE_WALLET = "Error in getting wallet balance";
export const ERROR_BALANCE_TOKEN = "Error in getting token balance";
export const ERROR_BALANCE_DEPOSIT = "Error in fetching deposit amount";
export const ERROR_BALANCE_DEPOSIT_ESTIMATE = "Error in fetching deposit estimate amount";
export const ERROR_BALANCE_BORROW = "Error in fetching borrow balance";
export const ERROR_BALANCE_SHARE = "Error in fetching borrow share balance";
export const ERROR_BALANCE_BORROW_AMOUNT = "Error in fetching borrow amount";
export const ERROR_BALANCE_BORROW_RATE = "Error in fetching borrow rate";
export const ERROR_BALANCE_LENDING_RATE = "Error in fetching lending rate";
export const ERROR_BALANCE_WITHDRAW = "Error in fetching withdraw balance";
export const ERROR_BALANCE_WITHDRAW_SHARE = "Error in fetching withdraw share balance";
export const ERROR_BALANCE_REPAY = "Error in fetching repay balance";
export const ERROR_BALANCE_TOTAL = "Error in fetching total balance";
export const ERROR_BALANCE_TOTAL_POOL = "Error in fetching total pool";
export const ERROR_BALANCE_TOTAL_FUND = "Error in fetching total fund";
export const ERROR_FARM_OPEN_POSITION = "Error in farm open";
export const ERROR_FARM_OPEN_POSITION_WRITE = "Error in open position contract write";
export const ERROR_FARM_CLOSE_POSITION = "Error in farm close";
export const ERROR_FARM_CLOSE_POSITION_WRITE = "Error in close position contract write";
export const ERROR_RWA_CLAIM_POSITION = "Error in rwa claim position";
export const ERROR_RWA_CLAIM_POSITION_WRITE = "Error in rwa claim position contract write";
export const ERROR_FARM_OUT_OF_RANGE = "Error in getting out of range";
export const ERROR_FARM_LP_AMOUNT = "Error in getting lp amount";
export const ERROR_LENDING_POOL_DATA = "Error in fetching lending pool data";
export const ERROR_APY_VIEWER_MARKET_EQUIVALENT = "Error in fetching market equivalent";
export const ERROR_ORACLE_ETH_VALUE_DATA = "Error in fetching ETH equivalent from oracle";
export const ERROR_RWA_OPEN_POSITION_WRITE = "Error in open position contract write";
export const ERROR_RWA_OPEN_POSITION = "Error in RWA open";

// FARM CONFIG
export const MINIMUM_BORROW_ETH = 3;
export const SLIPPAGE = 990000000000000000;
export const ETH_LIDO = "ETH Lido Farm";

// KADO CONFIG
export const KADO_CONFIG = {
    apiKey: KADO_API_KEY,
    networkList: ["ETHEREUM", "ARBITRUM"],
    cryptoList: ["ETH", "USDC", "USDT", "DAI"],
    theme: "dark",
    offPayCurrency: "ETH",
    onRevCurrency: "ETH",
    mode: "full",
    offPayAmount: "0.1",
    primaryColor: "00b0aa",
    secondaryColor: "00b0aa",
    successColor: "00b0aa",
    warningColor: "00b0aa",
    errorColor: "00b0aa",
};

export const PointTypes = {
    WISE: "WISE_POINTS",
    ETHERFI: "ETHERFI_POINTS",
    ETHENA: "ETHENA_POINTS",
    REFERRAL: "REFERRAL_POINTS"
};

// REGEX
export const ETH_ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;

export const PointsV2Navigation = {
    ETHER: "Ether Contribution Board",
    STABLE: "Stable Contribution Board"
};

export const CONFIRMATIONS_COUNT = 1;

export const POINTS_SEASON = 2;
export const ARBITRUM_EXCHANGE_INCENTIVE = 5;
export const ETHEREUM_EXCHANGE_INCENTIVE = 33;

export const SEASON_API_HASH = "0x2071c86808ed89d147349111a6277ed06b82dd9cf0f03b4bfdf8fcb45f71ba82";
export const SEASON_CONTRACT_HASH = "0x446761f2cc116e93471ba43b26ba47474f64ddbf0665e110c3c2732ed88f94c9";

// RWA Vault Config
export const MAX_RWA_VAULT_AMOUNT_USDC = 5112500;