// import React, { useMemo } from "react";
import React from "react";
import styles from "./PoolRates.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
// import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";
import {
    formatDecimals,
    showToast,
    // formatMoneyDashed,
    // toPrecision
} from "../../../utils";

const PoolRates = ({
    className,
    widgetTitle,
    tokenBalance,
    logo
}) => {

    return (
        <Card
            className={cn(styles.card, className)}
            title={widgetTitle}
            classTitle="title"
            icon={logo}
        >
            <div className={styles.apys}>
                <div className={styles.col}>
                    <div className={styles.label}>Current Lending APY</div>
                    <div className={styles.sales}>
                        <div className={cn(
                            styles.numberish,
                            "status-green-dark"
                        )}>
                            {`${formatDecimals(
                                tokenBalance.supplyRateFormatted
                            )}%`}
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.label}>Current Borrowing APY</div>
                    <div className={styles.sales}>
                        <div className={cn(
                            styles.numberish,
                            "status-purple-dark"
                        )}>
                            {`${formatDecimals(
                                tokenBalance.borrowRateFormatted
                            )}%`}
                        </div>
                    </div>
                </div>
                <button
                    className={
                        cn("button-stroke",
                            styles.button,
                        )
                    }
                    onClick={() => {
                        showToast(
                            "Available In Next Release...",
                            {
                                type: "info"
                            }
                        );
                    }}>
                    Lend Funds
                </button>
            </div>
        </Card>
    );
};

export default PoolRates;
