import React, { useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import styles from "./Profile.module.sass";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import Product from "../../components/Product";
import { formatUSD } from "../../utils";
import { appLocalStorage } from "../../utils/localstorage";

// import Social from "./Social";
// import Settings from "./Settings";
// import { Link } from "react-router-dom";
// import Filters from "../../components/Filters";
// import Follower from "./Follower";
// import { displayWithSkeleton } from "../../utils";
// import { followers } from "../../mocks/followers";

const navigation = [
    "All Resources",
    "Wise Lending",
    "Wise Staking",
    "Wise Token",
    "Wise Vaults",
];

const intervals = [
    "All Supported Chains",
];

const Profile = () => {

    const activeTab = appLocalStorage.preferredHomeTab.get();

    const processValue = (tab) => {
        const tabToIndex = navigation.indexOf(tab);
        appLocalStorage.preferredHomeTab.update(tabToIndex);
        setActiveIndex(tabToIndex);
    }

    const [activeIndex, setActiveIndex] = useState(
        activeTab ?? 0
    );

    const [sorting, setSorting] = useState(
        intervals[0]
    );

    const { TVL } = useSelector((state) => {
        return state.token
    });

    const products = [
        {
            id: 0,
            product: "Wise Lending Hub",
            link: "/lending/hub",
            // forced: true,
            image: "/images/content/lending.jpg",
            image2x: "/images/content/lending.jpg",
            price: `TVL ${formatUSD(TVL?.totalSupplyValue ?? '-')}`,
            date: "0.00",
            tokens: ["ETH"],
            active: true,
            icon: "lightning",
            ratingValue: 4.8,
            ratingCounter: 87,
            contract: "wstETHManagerContract",
            APY: 'smaApr',
        },
        {
            id: 1,
            product: "Wise Staking Hub",
            link: "/staking/hub",
            // forced: true,
            image: "/images/content/staking2.jpg",
            image2x: "/images/content/staking2.jpg",
            // image: "/images/content/tracking.jpg",
            // image2x: "/images/content/tracking.jpg",
            price: `TVL ${formatUSD(TVL?.tvlStaked ?? '-')}`,
            icon: "schedule",
            tokens: ["DAI", "USDC", "USDT"],
            date: "$0.00",
            active: true,
            ratingValue: 4.8,
            ratingCounter: 87,
            contract: "pendlePowerManagerContract",
            APY: 'smaApr',
        },
        {
            id: 2,
            product: "World Mobile Vault",
            link: "/vaults/",
            icon: "shopping-bag",
            // forced: true,
            image: "/images/content/world.jpg",
            image2x: "/images/content/world.jpg",
            // image: "/images/content/rewards.jpg",
            // image2x: "/images/content/rewards.jpg",
            // image: "/images/content/spark.svg",
            // image2x: "/images/content/spark.svg",
            price: "20.00% APY",
            tokens: ["ETH"],
            date: "$0.00",
            active: true,
            ratingValue: 4.8,
            ratingCounter: 87,
            APY: 'pendleAPY',
        },
        {
            id: 4,
            product: "Wise Token Liquidity",
            link: "token/",
            externalLink: "https://app.uniswap.org/explore/pools/ethereum/0x21b8065d10f73EE2e260e5B47D3344d3Ced7596E",
            icon: "diamond",
            // forced: true,
            image: "/images/content/liquidity.jpg",
            image2x: "/images/content/liquidity.jpg",
            price: `TVL ${formatUSD(TVL?.tvlLiquidity ?? '-')}`,
            tokens: ["DAI", "USDC", "USDT"],
            date: "$0.00",
            active: true,
            ratingValue: 4.8,
            ratingCounter: 87,
            contract: "pendlePowerManagerContract",
            APY: 'smaApr',
        },
        {
            id: 4,
            product: "Wise Lending Farms",
            link: "/lending/farms",
            // forced: true,
            icon: "sun",
            image: "/images/content/farms.jpg",
            image2x: "/images/content/farms.jpg",
            price: "32.58% APY",
            tokens: ["DAI", "USDC", "USDT"],
            date: "$0.00",
            active: true,
            ratingValue: 4.8,
            ratingCounter: 87,
            contract: "pendlePowerManagerContract",
            APY: 'smaApr',
        },
        {
            id: 4,
            product: "Wise Staking Referrals",
            link: "/staking/ref",
            // forced: true,
            image: "/images/content/referrals.jpg",
            image2x: "/images/content/referrals.jpg",
            price: "Promote Now",
            icon: "profile-circle",
            tokens: ["DAI", "USDC", "USDT"],
            date: "$0.00",
            active: true,
            ratingValue: 4.8,
            ratingCounter: 87,
            contract: "pendlePowerManagerContract",
            APY: 'smaApr',
        },
        {
            id: 4,
            product: "Security Audits",
            link: "/audits",
            // forced: true,
            customIcon: "/images/icons/security.svg",
            image: "/images/content/security.jpg",
            image2x: "/images/content/security.jpg",
            price: "4+ Audits",
            tokens: ["DAI", "USDC", "USDT"],
            date: "$0.00",
            active: true,
            ratingValue: 4.8,
            ratingCounter: 87,
            contract: "pendlePowerManagerContract",
            APY: 'smaApr',
        },
        {
            id: 4,
            product: "Wise Token Swaps",
            link: "token/",
            // forced: true,
            image: "/images/content/swaps.jpg",
            image2x: "/images/content/swaps.jpg",
            price: "20.34% APY",
            tokens: ["DAI", "USDC", "USDT"],
            date: "$0.00",
            active: false,
            ratingValue: 4.8,
            ratingCounter: 87,
            contract: "pendlePowerManagerContract",
            APY: 'smaApr',
        },
        {
            id: 4,
            product: "Wise Lending Statistics",
            link: "lending/details",
            // forced: true,
            icon: "bar-chart",
            image: "/images/content/stats3.jpg",
            image2x: "/images/content/stats3.jpg",
            price: "Explore Now",
            tokens: ["DAI", "USDC", "USDT"],
            date: "$0.00",
            active: true,
            ratingValue: 4.8,
            ratingCounter: 87,
            contract: "pendlePowerManagerContract",
            APY: 'smaApr',
        },
        {
            id: 4,
            product: "Wise Staking Statistics",
            link: "/staking/stats",
            icon: "star-stroke",
            // forced: true,
            image: "/images/content/stats.jpg",
            image2x: "/images/content/stats.jpg",
            price: "Earn Rewards",
            tokens: ["DAI", "USDC", "USDT"],
            date: "$0.00",
            active: false,
            ratingValue: 4.8,
            ratingCounter: 87,
            contract: "pendlePowerManagerContract",
            APY: 'smaApr',
        },
        {
            id: 4,
            product: "Wise Staking Pools",
            link: "/staking/",
            // forced: true,
            image: "/images/content/pools.jpg",
            image2x: "/images/content/pools.jpg",
            price: "20.34% APY",
            tokens: ["DAI", "USDC", "USDT"],
            date: "$0.00",
            active: false,
            ratingValue: 4.8,
            ratingCounter: 87,
            contract: "pendlePowerManagerContract",
            APY: 'smaApr',
        },
        {
            id: 4,
            product: "Wise Reserves - WiseR",
            link: "vaults/",
            // forced: true,
            image: "/images/content/reserves3.jpg",
            image2x: "/images/content/reserves3.jpg",
            price: "20.34% APY",
            tokens: ["DAI", "USDC", "USDT"],
            date: "$0.00",
            active: false,
            ratingValue: 4.8,
            ratingCounter: 87,
            contract: "pendlePowerManagerContract",
            APY: 'smaApr',
        },
        {
            id: 4,
            product: "Wise Lending Leaderboards",
            link: "/lending/points",
            icon: "star-stroke",
            // forced: true,
            image: "/images/content/leaderboards.jpg",
            image2x: "/images/content/leaderboards.jpg",
            price: "Ranking",
            tokens: ["DAI", "USDC", "USDT"],
            date: "$0.00",
            active: true,
            ratingValue: 4.8,
            ratingCounter: 87,
            contract: "pendlePowerManagerContract",
            APY: 'smaApr',
        },
        /*{
            id: 4,
            product: "Wise Discord Server",
            link: "www.sparkprotocol.io",
            // forced: true,
            image: "/images/content/chat.jpg",
            image2x: "/images/content/chat.jpg",
            price: "Join Server",
            tokens: ["DAI", "USDC", "USDT"],
            date: "$0.00",
            active: true,
            ratingValue: 4.8,
            ratingCounter: 87,
            contract: "pendlePowerManagerContract",
            APY: 'smaApr',
        },*/
    ];

    const productsWiseLendingOnly = products.filter((x) => x.link.includes('lending/'));
    const productsStakingOnly = [
        ...products.filter((x) => x.link.includes('staking/')),
        /*{
            id: 4,
            product: "Wise Staking Statistics",
            link: "lending/details",
            // forced: true,
            image: "/images/content/stats.jpg",
            image2x: "/images/content/stats.jpg",
            price: "Explore Now",
            tokens: ["DAI", "USDC", "USDT"],
            date: "$0.00",
            active: false,
            ratingValue: 4.8,
            ratingCounter: 87,
            contract: "pendlePowerManagerContract",
            APY: 'smaApr',
        },*/
    ];

    const productsWiseTokenOnly = products.filter((x) => x.link.includes('token/'));
    const productsWiseVaultsOnly = products.filter((x) => x.link.includes('vaults/'));


    return (
        <>
            <div className={styles}>
                <div className={styles.background}>
                </div>
                <Card className={styles.card}>
                    {/*<Social />*/}
                    <div className={styles.control}>
                        <div className={styles.nav}>
                            {navigation.map((x, index) => (
                                <button
                                    className={cn(styles.link, {
                                        [styles.active]: index === activeIndex,
                                    })}
                                    onClick={() => {
                                        appLocalStorage.preferredHomeTab.update(
                                            index
                                        );
                                        setActiveIndex(index)
                                    }}
                                    key={index}
                                >
                                    {x}
                                </button>
                            ))}
                        </div>
                        <div className={styles.dropdownBox}>
                            <Dropdown
                                // disabled={true}
                                className={styles.dropdown}
                                classDropdownHead={styles.dropdownHead}
                                value={sorting}
                                setValue={setSorting}
                                options={intervals}
                                small
                            />
                        </div>
                        <div className={styles.dropdownBox2}>
                            <Dropdown
                                className={styles.dropdown}
                                classDropdownHead={styles.dropdownHead}
                                value={navigation[activeIndex]}
                                setValue={processValue}
                                options={navigation}
                                small
                            />
                        </div>
                        {/*<Filters
                            className={styles.filters}
                            title="Filtering Options"
                        >
                            <Settings />
                        </Filters>*/}
                    </div>
                    <div className={styles.wrap}>
                        {activeIndex === 0 && (
                            <>
                                <div className={styles.products}>
                                    {products.map((x, index) => (
                                        <Product
                                            className={styles.product}
                                            item={x}
                                            key={index}
                                            withoutСheckbox
                                        />
                                    ))}
                                </div>
                                {/*<div className={styles.foot}>
                                    <button
                                        className={cn("button-stroke button-small", styles.button)}
                                    >
                                        Load more
                                    </button>
                                </div>*/}
                            </>
                        )}
                        {activeIndex === 1 && (
                            <>
                                <div className={styles.products}>
                                    {productsWiseLendingOnly.map((x, index) => (
                                        <Product
                                            className={styles.product}
                                            item={x}
                                            key={index}
                                            withoutСheckbox
                                        />
                                    ))}
                                </div>
                                {/*<div className={styles.foot}>
                                    <button
                                        className={cn("button-stroke button-small", styles.button)}
                                    >
                                        Load more
                                    </button>
                                </div>*/}
                            </>
                        )}
                        {activeIndex === 2 && (
                            <>
                                <div className={styles.products}>
                                    {productsStakingOnly.map((x, index) => (
                                        <Product
                                            className={styles.product}
                                            item={x}
                                            key={index}
                                            withoutСheckbox
                                        />
                                    ))}
                                </div>
                                {/*<div className={styles.foot}>
                                    <button
                                        className={cn("button-stroke button-small", styles.button)}
                                    >
                                        Load more
                                    </button>
                                </div>*/}
                            </>
                        )}
                        {activeIndex === 3 && (
                            <>
                                <div className={styles.products}>
                                    {productsWiseTokenOnly.map((x, index) => (
                                        <Product
                                            className={styles.product}
                                            item={x}
                                            key={index}
                                            withoutСheckbox
                                        />
                                    ))}
                                </div>
                                {/*<div className={styles.foot}>
                                    <button
                                        className={cn("button-stroke button-small", styles.button)}
                                    >
                                        Load more
                                    </button>
                                </div>*/}
                            </>
                        )}
                        {activeIndex === 4 && (
                            <>
                                <div className={styles.products}>
                                    {productsWiseVaultsOnly.map((x, index) => (
                                        <Product
                                            className={styles.product}
                                            item={x}
                                            key={index}
                                            withoutСheckbox
                                        />
                                    ))}
                                </div>
                                {/*<div className={styles.foot}>
                                    <button
                                        className={cn("button-stroke button-small", styles.button)}
                                    >
                                        Load more
                                    </button>
                                </div>*/}
                            </>
                        )}
                    </div>
                </Card>
            </div>
        </>
    );
};

export default Profile;
