import {
    useWriteContract,
    useWalletClient,
    useWaitForTransactionReceipt
} from "wagmi";

import { ethers } from "ethers";
import { parseUnits } from "viem";

import { removeNonNumeric } from "../utils";

import {
    // CONFIRMATIONS_COUNT,
    // ERROR_RWA_CLAIM_POSITION,
    // ERROR_RWA_CLAIM_POSITION_WRITE
} from "../utils/config";

export const useOpenStake = ({
    config,
    isETH,
    isTransferable,
    amount,
    duration,
    referral,
}) => {

    const { data: signer } = useWalletClient();
    const {
        chainId,
        WiseTokenContract: contract,
        wiseNftStakes: nftContract,
    } = config;

    const {
        error,
        data: hash,
        writeContract,
        // writeContractAsync,
        isPending,
    } = useWriteContract();

    const openStake = ({
        onConfirmation,
        // onError
    }) => {
        onConfirmation?.();

        const convertedAmount = removeNonNumeric(
            amount || 0
        );

        const stakeAmount = parseUnits(
            convertedAmount,
            "18"
        );

        const argsNormal = [
            stakeAmount,
            removeNonNumeric(duration),
            referral || ethers.constants.AddressZero
        ];

        const argsEth = [
            removeNonNumeric(duration),
            referral || ethers.constants.AddressZero
        ];

        let functionName = isETH
            ? "createStakeWithETH"
            : "createStake";

        if (isTransferable) {
            functionName = isETH
                ? "mintWithEth"
                : "mint";
        }

        let params = {
            chainId,
            abi: isTransferable
                ? nftContract?.abi
                : contract?.abi,
            address: isTransferable
                ? nftContract?.address
                : contract?.address,
            signerOrProvider: signer,
            // gas: gasLimitFormatted,
            functionName: functionName,
            args: isETH
                ? argsEth
                : argsNormal,
            query: {
                enabled: amount && duration,
            }
        };

        if (isETH) {
            params = {
                ...params,
                value: parseUnits(amount, 18)
            };
        }

        writeContract(
            params
        );
    };

    const {
        isError: hasError,
        error: errorConfirming,
        isLoading: isConfirming,
        isSuccess: isConfirmed
    } = useWaitForTransactionReceipt({
        hash,
    });

    return {
        hash,
        error,
        hasError,
        isPending,
        isConfirming,
        isConfirmed,
        openStake,
        errorConfirming,
        isLoading: isConfirming,
    };
};
