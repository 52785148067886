import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { Link } from "react-router-dom";
import Modal from "../../../../components/Modal";
import useTokenBalance from "../../../../customHooks/useTokenBalance";
import { _no,
    formatMoneyDashed,
    formatDecimals,
    displayWithLoaderObj
} from "../../../../utils";
import Supply from "../../AssetsToSupply/Row/Supply";
import Icon from "../../../../components/Icon";
import styles from "./Row.module.sass";
import Borrow from "./Borrow";
import Repay from "./Repay";
import Tooltip from "../../../../components/Tooltip";
import Logo from "../../../../components/Logo";
import { showToast } from "../../../../utils";

const Row = ({ token, nftId, config, summary, expand }) => {

    const { isTestMode, isDeprecated } = config;

    const {
        pricingFeeds,
        walletBalance
    } = useSelector((state) => {
        return state.token;
    });

    const address = useSelector((state) => {
        return state.settings.address;
    });

    const { showSwitchNetwork } = useSelector((state) => {
        return state.settings;
    });

    const tokenPriceData = pricingFeeds.find((item) => {
        return item.name === token.name;
    });

    const { tokenBalance } = useTokenBalance({
        config,
        nftId,
        token,
        userAccount: address,
        price: tokenPriceData?.price ?? 0,
        walletBalance
    });

    const [visibleBorrowModal, setVisibleBorrowModal] = useState(false);
    const [visibleRepayModal, setVisibleRepayModal] = useState(false);
    const [visibleSupplyModal, setVisibleSupplyModal] = useState(false);

    const handleOpenModal = (modal) => {

        if (_no(token.isEnabled)) {
            showToast(
                isTestMode
                    ? "This asset is not available in testnet demo mode"
                    : "Coming very soon..."
            );
            return;
        }

        setVisibleBorrowModal(false);
        setVisibleRepayModal(false);
        setVisibleSupplyModal(false);

        if (modal === "borrow")
            setVisibleBorrowModal(true);

        if (modal === "repay")
            setVisibleRepayModal(true);

        if (modal === "supply")
            setVisibleSupplyModal(true);
    };

    useEffect(() => {
        if (showSwitchNetwork) {
            setVisibleBorrowModal(false);
            setVisibleRepayModal(false);
            setVisibleSupplyModal(false);
        }
    }, [showSwitchNetwork]);

    const disabled = _no(token.isEnabled) || token.canBorrow === false;

    return (
        <>
            <div
                className={cn(
                    styles.row,
                    {
                        [styles.disabled]: disabled
                    }
                )}
                onClick={(event) => {
                    const classNamer = event.target.getAttribute("class");
                    if (classNamer === null || !classNamer) {
                        return;
                    }
                    if (classNamer.includes("button")) return;
                    if (_no(isDeprecated)) handleOpenModal("borrow");
                }}
            >
                <div className={styles.col}>
                    <div className={styles.token}>
                        <Tooltip
                            className={styles.tooltip}
                            title={"Aave Pool - funds provided to Aave, until natively borrowed"}
                            place="right"
                        >
                            <Logo
                                className={styles.currency}
                                logo={token.icon}
                                subLogo={token.sub}
                                width={38}
                                height={38}
                            />
                        </Tooltip>
                        <div>
                            <div className={styles.description}>{tokenBalance.title}</div>
                            <div className={styles.title}>{tokenBalance.name}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    {/*<div className={styles.item}>
                        <div className={styles.label}>Total Available</div>
                        <div className={styles.value}>
                            {formatMoneyDashed(tokenBalance.poolFormatted)}
                        </div>
                    </div>*/}
                    <div className={styles.item}>
                        <div className={styles.label}>Available</div>
                        <div className={styles.value}>
                            {displayWithLoaderObj({
                                data: formatMoneyDashed(tokenBalance.poolFormatted),
                                isLoading: !tokenBalance.poolFormatted
                            })}
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.label}>APY</div>
                        <div
                            className={
                                cn(
                                    styles.value,
                                    "status-purple-dark"
                                )
                            }
                        >
                            {
                                formatDecimals(
                                    tokenBalance.borrowRateFormatted
                                )
                            }%
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.label}>Your Debt</div>
                        <div className={styles.value}>
                            {
                                displayWithLoaderObj({
                                    isLoading: !tokenBalance.fundFormatted,
                                    data: formatMoneyDashed(tokenBalance.repayBalanceFormatted)
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    {token.canBorrow ? (
                        <div className={styles.buttons}>
                            <button
                                className={cn(
                                    "button-small",
                                    styles.button,
                                    styles.borrow,
                                    {
                                        disabled: isDeprecated,
                                        [styles.disabled]: isDeprecated
                                    }
                                )}
                                onClick={() => handleOpenModal("borrow")}
                            >
                                Borrow
                            </button>
                            <button
                                disabled={tokenBalance.repayBalanceFormatted === "0"}
                                className={cn(
                                    "button-stroke button-small",
                                    styles.button,
                                    {
                                        disabled: tokenBalance.repayBalanceFormatted === "0",
                                    }
                                )}
                                onClick={() => handleOpenModal("repay")}
                            >
                                Repay
                            </button>
                            {!expand && (
                                <Link
                                    to={`/lending/pool/${tokenBalance.name.toLowerCase()}`}
                                    // disabled={_no(tokenBalance.repayBalance)}
                                    className={cn(
                                        "button-stroke button-small",
                                        styles.button,
                                        styles.infos
                                    )}
                                    // onClick={() => showInfoDefiLama(tokenBalance.name)}
                                >
                                    <Icon name="info" />
                                </Link>
                            )}
                        </div>
                    ) : (
                        <div className={styles.buttons}>
                            <div className={styles.item}>
                                —
                            </div>
                            <button
                                style={{ padding: "0px", width: "0px", overflow: "hidden" }}
                                className={cn(
                                    "button-stroke button-small",
                                    styles.button,
                                )}
                            >
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <Modal
                visible={visibleBorrowModal}
                onClose={() => setVisibleBorrowModal(false)}
            >
                <Borrow
                    config={config}
                    nftId={nftId}
                    token={tokenBalance}
                    tokenPriceData={tokenPriceData}
                    summary={summary}
                    walletBalance={walletBalance}
                    onSupply={() => handleOpenModal("supply")}
                    onDone={() => setVisibleBorrowModal(false)}
                    onConnect={() => handleOpenModal("connect")}
                />
            </Modal>
            <Modal
                visible={visibleRepayModal}
                onClose={() => setVisibleRepayModal(false)}
            >
                <Repay
                    config={config}
                    nftId={nftId}
                    token={tokenBalance}
                    tokenPriceData={tokenPriceData}
                    summary={summary}
                    walletBalance={walletBalance}
                    onDone={() => setVisibleRepayModal(false)}
                    onConnect={() => handleOpenModal("connect")}
                />
            </Modal>
            <Modal
                visible={visibleSupplyModal}
                onClose={() => setVisibleSupplyModal(false)}
            >
                <Supply
                    config={config}
                    nftId={nftId}
                    token={tokenBalance}
                    summary={summary}
                    tokenPriceData={tokenPriceData}
                    walletBalance={walletBalance}
                    onDone={() => setVisibleSupplyModal(false)}
                    onConnect={() => handleOpenModal("connect")}
                />
            </Modal>
        </>
    );
};

export default Row;
