import { useEffect, useState } from "react";
import {
    _no,
    _notEmpty,
    getEnsAddressAcrossNetworks,
    getEnsNameForAddress,
    isWalletAddressFormat,
    shortenAddress,
    trimAddressDisplay,
} from "../utils";

const useReferralNetworkUsers = ({
    userAddress,
    userEnsName,
    referrerAddress,
    shortenEnsNameLabels,
    showFullAddress,
}) => {
    const [loading, setLoading] = useState(true);
    const [referrerDetails, setReferrerDetails] = useState();
    const [addressLabels, setAddressLabels] = useState();
    const [userDetails, setUserDetails] = useState({
        address: userAddress,
        ensName: userEnsName,
    });

    const getAddressDisplay = ({ address, ensName }) => {
        const formattedEnsName = shortenEnsNameLabels
            ? trimAddressDisplay(ensName)
            : ensName;

        const formattedAddress = showFullAddress
            ? address
            : shortenAddress(address);

        return [
            formattedAddress,
            ensName ? `(${formattedEnsName})` : null
        ]
        .filter(Boolean)
        .join(" ");
    };

    const fetchEnsData = async (address) => {
        if (isWalletAddressFormat(address)) {
            return {
                address,
                ensName: await getEnsNameForAddress(address),
            };
        } else {
            return {
                address: await getEnsAddressAcrossNetworks(
                    address
                ),
                ensName: `${address}.eth`,
            };
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const userDetailsRequest =
                userDetails?.address && _no(userDetails?.ensName)
                    ? fetchEnsData(userAddress)
                    : userDetails;

            const referrerDetailsRequest = referrerAddress
                ? fetchEnsData(referrerAddress)
                : referrerDetails;

            const [updatedUserDetails, updatedReferrerDetails] = await Promise.all([
                userDetailsRequest,
                referrerDetailsRequest,
            ]);

            setAddressLabels({
                referrer: getAddressDisplay(updatedReferrerDetails),
                user: getAddressDisplay(updatedUserDetails),
            });

            setUserDetails(updatedUserDetails);
            setReferrerDetails(updatedReferrerDetails);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [referrerAddress, userAddress, userEnsName]);

    useEffect(() => {
        if (_notEmpty(addressLabels) && _no(loading)) {
            setAddressLabels({
                referrer: getAddressDisplay(referrerDetails),
                user: getAddressDisplay(userDetails),
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showFullAddress])

    return {
        loading,
        addressLabels,
        userDetails,
        referrerDetails,
    };
};

export default useReferralNetworkUsers;