import React from "react";
import cn from "classnames";
import styles from "./Help.module.sass";
import Image from "../../Image";
import { Link } from "react-router-dom";
import Icon from "../../Icon";
import Item from "./Item";

const items = [
    {
        title: "Code4rena Report",
        image: "/images/audit/code4arena.png",
        image2x: "/images/audit/code4arena.png",
        avatar: "/images/content/avatar-3.jpg",
        time: "Website",
        content: "https://code4rena.com/audits/2024-02-wise-lending",
        statusText: "Done",
        statusColor: "green",
    },
    {
        title: "Hats Finance Report",
        image: "/images/audit/hatsfinance.png",
        image2x: "/images/audit/hatsfinance.png",
        avatar: "/images/content/avatar-3.jpg",
        time: "Github",
        content: "https://github.com/hats-finance/Wise-Lending-0xa2ca45d6e249641e595d50d1d9c69c9e3cd22573/blob/master/report.md",
        statusText: "Done",
        statusColor: "green",
    },
    {
        title: "Omniscia Report",
        image: "/images/audit/omniscia.jpeg",
        image2x: "/images/audit/omniscia.jpeg",
        avatar: "/images/content/avatar-3.jpg",
        time: "PDF",
        content: "https://wise.one/omni-audit-v1.pdf",
        statusText: "Done",
        statusColor: "green",
    },
    //
    {
        title: "Hashlog Bug Bounties",
        image: "/images/content/thumb-g.jpg",
        image2x: "/images/content/thumb-g.jpg",
        avatar: "/images/content/avatar-3.jpg",
        time: "Active",
        // content: "https://wisesoft.gitbook.io/wise/developers/100-eth-bug-bounty",
        content: "https://www.hashlock.com.au/bug-bounty/wise-lending",
        statusText: "In Progress",
        statusColor: "purple",
    },
    // {
    //     title: "Immunefi Bug Bounties",
    //     image: "/images/audit/immunefi.jpeg",
    //     image2x: "/images/audit/immunefi.jpeg",
    //     avatar: "/images/content/avatar-3.jpg",
    //     time: "CS",
    // },
    /*
    {
        title: "Founder Explains",
        image: "/images/content/thumb-c.jpg",
        image2x: "/images/content/thumb-c.jpg",
        avatar: "/images/content/avatar-3.jpg",
        time: "Video",
    },
    */
];

const menu = [
    /*{
        title: "Wise Lending Docs",
        icon: "message",
        url: "https://wisesoft.gitbook.io/wise/wiselending.com/wise-lending",
    },*
    /*{
        title: "Frequent Questions",
        icon: "info-stroke",
        arrow: true,
        url: "/questions",
    },*/
    /*{
        title: "Message Center",
        icon: "message",
        counter: 8,
        url: "/message-center",
    },*/
];

const Help = ({ className, visible, setVisible, onClose }) => {
    const handleClose = () => {
        onClose();
        setVisible(false);
    };

    return (
        <>
            <div className={cn(styles.help, className, { [styles.active]: visible })}>
                <div className={styles.head}>
                    <Image
                        className={cn(styles.audit)}
                        src="/images/icons/security.svg"
                        srcDark="/images/icons/security.svg"
                        alt="audit"
                    />
                    Security Audits
                    <button className={styles.close} onClick={() => setVisible(false)}>
                        <Icon name="close" size="24" />
                    </button>
                </div>
                <div className={styles.list}>
                    {items.map((x, index) => (
                        <Item className={styles.item} item={x} key={index} />
                    ))}
                </div>
                <div className={styles.menu}>
                    {menu.map((x, index) => (
                        <div key={index}>
                            {x.url.includes("https://") ? (
                                <a
                                    className={styles.link}
                                    href={x.url}
                                    key={index}
                                    rel="noreferrer"
                                    target={"_blank"}
                                >
                                    <Icon name={x.icon} size="24" />
                                    {x.title}
                                    {x.arrow && (
                                        <div className={styles.arrow}>
                                            <Icon name="arrow-next" size="24" />
                                        </div>
                                    )}
                                    {x.counter && <div className={styles.counter}>3</div>}
                                </a>
                            ) : (
                                <Link
                                    className={styles.link}
                                    to={x.url}
                                    key={index}
                                    onClick={() => handleClose()}
                                >
                                    <Icon name={x.icon} size="24" />
                                    {x.title}
                                    {x.arrow && (
                                        <div className={styles.arrow}>
                                            <Icon name="arrow-next" size="24" />
                                        </div>
                                    )}
                                    {x.counter && <div className={styles.counter}>3</div>}
                                </Link>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div
                className={cn(styles.overlay, { [styles.active]: visible })}
                onClick={() => setVisible(false)}
            ></div>
        </>
    );
};

export default Help;
