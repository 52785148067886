import React, { useMemo, useState } from "react";
import styles from "./PerformanceByDay.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import dayjs from 'dayjs';

import { MAIN_COLOR_A, MAIN_COLOR_B } from "../../../utils/config";
import CustomChartTooltip from "../../../components/CustomChartTooltip";
import { _notEmpty, formatMoney, formatMoneyDashed } from "../../../utils";
import Loader from "../../../components/Loader";
// import useDarkMode from "use-dark-mode";

const intervalsFilter = [
    {
        label: "Last 10 days",
        value: -10
    },
    {
        label: "Last 20 days",
        value: -20
    },
    {
        label: "Last 40 days",
        value: -40
    },
    {
        label: "Last 80 days",
        value: -80
    },
    {
        label: "All Available",
        value: null
    }
];

const PerformanceByDay = ({
    className,
    poolName,
    chartData = [],
    loading,
    logo
}) => {

    const darkMode = true;

    const [sorting, setSorting] = useState(
        intervalsFilter[0]
    );

    const data = useMemo(() => chartData?.filter((data) => {
        if (_notEmpty(sorting.value)) {
            return dayjs(data.timestamp).isAfter(
                dayjs().add(
                    sorting.value,
                    "days"
                )
            );
        }
        return true;
    })?.map((data) => ({
        name: dayjs(data.timestamp).format("MMM DD"),
        // label: "Lending APY",
        "Lending APY": data.apyBase,
        "Borrowing APY": data.apyBaseBorrow,
        formatter: (value) => {return `${formatMoneyDashed(value)}%`},
    }))?.reduce((finalData, currentData) => {
        // use most recent value when there's more data in the same day
        const existingDate = finalData.find((existingData) => {
            return existingData.name === currentData.name;
        });

        if (existingDate) {
            const outdatedDataIndex = finalData.indexOf(
                existingDate
            );
            finalData[outdatedDataIndex] = currentData;
        } else {
            finalData.push(
                currentData
            );
        }

        return finalData;

    }, []),
        [sorting, chartData]);

    const handleSortChange = (newSortLabel) => {
        return setSorting(
            intervalsFilter.find((interval) => {
                return interval.label === newSortLabel;
            })
        );
    };

    return (
        <Card
            className={cn(styles.card, className)}
            title={`${poolName} Pool APY`}
            classTitle={cn("title", styles.title)}
            icon={logo}
            classCardHead={styles.head}
            head={
                <Dropdown
                    className={styles.dropdown}
                    classDropdownHead={styles.dropdownHead}
                    value={sorting.label}
                    setValue={handleSortChange}
                    options={intervalsFilter?.map((interval) => {
                        return interval.label;
                    })}
                    small
                />
            }
        >
            <div className={styles.chart}>
                {
                    !data?.length && loading ? (
                        <div className={styles.loaderContainer}>
                            <Loader className={styles.loader} />
                            <span>Loading Data</span>
                        </div>
                    ) : (
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                width={500}
                                height={300}
                                data={data}
                                margin={{
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid
                                    strokeDasharray="none"
                                    stroke={darkMode.value ? "#272B30" : "#EFEFEF"}
                                    vertical={false}
                                />
                                <XAxis
                                    dataKey="name"
                                    axisLine={false}
                                    tickLine={false}
                                    tick={{ fontSize: 12, fontWeight: "500", fill: "#9A9FA5" }}
                                    padding={{ left: 10 }}
                                />
                                <YAxis
                                    axisLine={false}
                                    tickLine={false}
                                    tick={{ fontSize: 12, fontWeight: "500", fill: "#9A9FA5" }}
                                    tickFormatter={(value) =>
                                        `${formatMoney(
                                            value,
                                            false,
                                            false,
                                            0
                                        )}%`
                                    }
                                />
                                <Tooltip
                                    content={<CustomChartTooltip />}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="Lending APY"
                                    dot={false}
                                    strokeWidth={4}
                                    stroke={MAIN_COLOR_A}
                                    fill={MAIN_COLOR_A}
                                    color={MAIN_COLOR_A}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="Borrowing APY"
                                    dot={false}
                                    strokeWidth={4}
                                    fill={MAIN_COLOR_B}
                                    stroke={MAIN_COLOR_B}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    )
                }
            </div >
        </Card >
    );
};

export default PerformanceByDay;
