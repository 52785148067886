import {
    useWriteContract,
    useWalletClient,
    useWaitForTransactionReceipt,
} from "wagmi";
import {
    // CONFIRMATIONS_COUNT,
    ERROR_REWARD_CLAIM,
    // ERROR_REWARD_WRITE,
    SEASON_CONTRACT_HASH
} from "../utils/config";

import { _notEmpty } from "../utils";

export const useClaimRewards = ({
    rewardsData,
    userAccount,
    config
}) => {

    const { data: signer } = useWalletClient();
    const { wiseRewards, chainId } = config;
    const enabled = _notEmpty(userAccount) && _notEmpty(
        rewardsData.hash
    );

    const {
        error,
        data: hash,
        writeContract,
        // writeContractAsync,
        isPending,
    } = useWriteContract();

    const claim = async ({
        onConfirmation,
        onClaimComplete,
        onClaiming,
        onError
    }) => {
        try {

            onConfirmation?.();

            writeContract({
                chainId,
                address: wiseRewards.address,
                abi: wiseRewards.abi,
                signerOrProvider: signer,
                functionName: "getClaim",
                args: [
                    SEASON_CONTRACT_HASH,
                    rewardsData.index,
                    rewardsData.reward,
                    rewardsData?.merkleProof
                ],
                query: {
                    enabled
                },
            });

            /*
            const claimTransaction = await tokenContract.writeAsync();
            const { hash } = claimTransaction;

            onClaiming?.(
                hash
            );

            const claimData = await waitForTransaction({
                hash,
                confirmations: CONFIRMATIONS_COUNT
            });

            onClaimComplete?.(
                claimData
            );*/

            return true;

        } catch (error) {

            console.error(
                ERROR_REWARD_CLAIM,
                error
            );

            onError?.(error);
            return false;
        }
    };

    const {
        isError: hasError,
        isLoading: isConfirming,
        isSuccess: isConfirmed
    } = useWaitForTransactionReceipt({
        hash,
    });

    return {
        hash,
        error,
        claim,
        hasError,
        isPending,
        isConfirming,
        isConfirmed,
    };
};
