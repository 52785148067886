import React, { useEffect, useMemo, useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
// import Checkbox from "../../../../components/Checkbox";
// import Balance from "../../../../components/Balance";
import { _sum, formatUSD, getEnsNameForAddress, shortenAddress, formatToFiveDecimalsOrThreshold, _notEmpty } from "../../../../utils";
import Tooltip from "../../../../components/Tooltip";

const Row = ({
    item,
    // value,
    // onChange,
    activeTable,
    // setActiveTable,
    activeId,
    // setActiveId,
}) => {
    // const handleClick = (id) => {
    //     setActiveTable(true);
    //     setActiveId(id);
    // };
    const [ensName, setEnsName] = useState();

    useEffect(() => {
        const fetchEnsName = async (address) => {
            const newEnsName = await getEnsNameForAddress(address);
            setEnsName(newEnsName);
        }
        if (!ensName && item?.address) {
            fetchEnsName(item.address);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item?.address]);

    const totalReferralPointsRate = useMemo(() => _sum(
        item.points?.referralRewardRate,
        item.stablePoints?.referralRewardRate
    ), [
        item.points?.referralRewardRate,
        item.stablePoints?.referralRewardRate
    ]);

    if (!totalReferralPointsRate) {
        return null;
    }

    return (
        <>
            <div
                className={cn(
                    styles.row,
                    {
                        [styles.selected]: activeId === item.id,
                        [styles.active]: activeTable,
                        [styles.inactive]: !totalReferralPointsRate
                    }
                )}
            >
                <div className={styles.col}>
                    {/* <Checkbox
                        className={styles.checkbox}
                        value={value}
                        onChange={onChange}
                    /> */}
                </div>
                <div className={styles.col}>
                    <div className={styles.item}
                        // onClick={() => handleClick(item.id)}
                    >
                        <div className={styles.avatar}>
                            <img src={'/images/content/shadow-owl.jpg'} alt="Avatar" />
                        </div>
                        <Tooltip
                            title={item?.address}
                            place="right"
                        >
                            <div className={styles.details}>
                                <div className={styles.user}>{ensName}</div>
                                <div className={styles.login}>
                                    {
                                        _notEmpty(item?.address)
                                            ? shortenAddress(item.address)
                                            : "-"
                                    }
                                </div>
                            </div>
                        </Tooltip>
                    </div>
                </div>
                {/*<div className={styles.col}>
                    <div className={styles.email}>{item.email}</div>
                </div>*/}
                <div className={styles.col}>
                    <div className={cn(
                        "status-green-dark",
                        styles.purchase
                    )}>
                        {formatToFiveDecimalsOrThreshold(
                            totalReferralPointsRate
                        )}
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.lifetime}>
                        <div className={styles.price}>
                            {formatUSD(
                                item.deposited?.depositedEthInUsd
                            )}
                        </div>
                        {/*<Balance className={styles.balance} value={item.balance} />*/}
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.lifetime}>
                        <div className={styles.price}>
                            {formatUSD(
                                item.deposited?.depositedStable
                            )}
                        </div>
                        {/*<Balance className={styles.balance} value={item.balance} />*/}
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={cn(
                        "status-green-dark",
                        styles.purchase
                    )}>
                        {formatUSD(
                            item.combined?.totalReferralPoints,
                            true
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Row;
