// import { useState } from "react";
import { useReadContract } from "wagmi";
import { getDateString } from "../utils";
import { formatUnits } from "viem";
import {
    numberWithCommasForDays,
    toPrecision,
    formatMoney
} from "../utils";

const useWiseStakeData = ({
    config,
    address,
    stakeId,
    currentWiseDay,
    wiseCost,
}) => {

    wiseCost = wiseCost || 0;

    const DEFAULT_DECIMALS = 18;
    const { chainId, WiseTokenContract } = config;

    const { data: stakeData, isFetching, refetch: refetchStakeData } = useReadContract({
        chainId,
        address: WiseTokenContract?.address,
        abi: WiseTokenContract?.abi,
        functionName: "checkStakeByID",
        args: [
            address,
            stakeId
        ],
        query: {
            enabled: !!address && !!stakeId && !!chainId,
        }
    });

    const startDate = stakeData ? parseInt(stakeData[0]) : 0;
    const lockDays = stakeData ? parseInt(stakeData[1]) : 0;
    const finalDay = stakeData ? parseInt(stakeData[2]) : 0;
    const closeDay = stakeData ? parseInt(stakeData[3]) : 0;
    const scrapeDay = stakeData ? parseInt(stakeData[4]) : 0;

    const penaltyAmount = stakeData ? stakeData[8] : 0;
    const stakedAmount = stakeData ? stakeData[5] : 0;
    const rewardAmount = stakeData ? stakeData[7] : 0;
    const stakeShares = stakeData ? stakeData[6] : 0;

    const inceptionDay = "2020-11-10";

    const getDaysLeft = (stakeData, currentWiseDay) => {
        let daysLeft = stakeData && stakeData[9] === true
            ? stakeData && finalDay - currentWiseDay
            : stakeData && finalDay - closeDay;

        if (daysLeft < 0) daysLeft = 0;
        return daysLeft;
    }

    const daysLeft = getDaysLeft(
        stakeData,
        currentWiseDay
    );

    const servedDays = stakeData && lockDays - daysLeft
        ? lockDays - daysLeft
        : 0;

    const matureStake = stakeData && stakeData[10]
        ? stakeData[10] === true
        : false;

    const closedStake = (stakeData && stakeData[9])
        ? stakeData[9] === false
        : true;

    const onGoingStake = stakeData && stakeData[9]
        ? stakeData[9] === true && stakeData[10] === false
        : false;

    const pendingStake = servedDays < 0  && !closedStake;

    const reward = stakeData ? formatUnits(
        stakeData[7],
        18
    ) : 0;

    let rewardPrecision = 2;
    if (reward > 999) {
        rewardPrecision = 0;
    }

    const stakeRewards = stakeData ? toPrecision((
        parseFloat(reward || 0)
    ).toFixed(2), rewardPrecision) : 0;

    const nakedReward = (
        parseFloat(reward || 0)
    ).toFixed(2);

    let stakeRewardsInUSD = stakeData ? (formatMoney(
        wiseCost * reward
    )) : "0.00";

    if (reward > 999) {
        stakeRewardsInUSD = stakeData ? (toPrecision(
            wiseCost * reward,
            2
        )) : "0.00";
    }

    let precison = 2;

    const value = stakeData ? formatUnits(
        stakedAmount,
        DEFAULT_DECIMALS
    ) : 0;

    if (value > 999) {
        precison = 0;
    }

    let stakeRewardsDynamicInUSD = stakeData ? (toPrecision(
        wiseCost * reward,
        precison
    )) : "0.00";

    if (reward < 999) {
        stakeRewardsDynamicInUSD = stakeData ? (formatMoney(
            wiseCost * reward
        )) : "0.00";
    }

    const stakedAmountFormatted = stakeData ? toPrecision(
        value,
        precison
    ) : 0;

    const stakedAmountInUSD = stakeData ? toPrecision(
        wiseCost *
        formatUnits(
            stakedAmount,
            DEFAULT_DECIMALS
        ),
        precison
    ) : "0.00";

    const stakedAmountInUSDWithPrecision = stakeData ? toPrecision(
        wiseCost *
        formatUnits(
            stakedAmount,
            DEFAULT_DECIMALS
        )
    ) : "0.00";

    const penaltyValue = formatUnits(
        penaltyAmount,
        DEFAULT_DECIMALS
    );

    let penaltyAmountFormatted = stakeData ? toPrecision(
        penaltyValue,
        2
    ) : 0;

    if (penaltyValue > 999) {
        penaltyAmountFormatted = stakeData ? toPrecision(
            penaltyValue,
            0
        ) : 0;
    }

    let terminationFeeInUSD = stakeData ? toPrecision(
        wiseCost *
        formatUnits(
            penaltyAmount,
            DEFAULT_DECIMALS
        )
    ) : "0.00";

    if (penaltyValue > 999) {
        terminationFeeInUSD = stakeData ? toPrecision(
            wiseCost *
            formatUnits(
                penaltyAmount,
                DEFAULT_DECIMALS
            ),
            0
        ) : "0.00";
    }

    const terminationPayout = parseFloat(formatUnits(
        stakedAmount,
        DEFAULT_DECIMALS
    )) +
    parseFloat(nakedReward) -
    parseFloat(formatUnits(
        penaltyAmount,
        DEFAULT_DECIMALS
    ));

    let precisonA = 2;
    if (terminationPayout > 999) {
        precisonA = 0;
    }

    let terminationPayoutFormatted = stakeData ? toPrecision(
        terminationPayout,
        precisonA
    ) : 0;

    let precisoinB = 2;
    if (terminationPayout > 100000) {
        precisoinB = 0;
    }

    const terminationPayoutInUSD = stakeData ? toPrecision(
        wiseCost *
        (parseFloat(formatUnits(
            stakedAmount,
            DEFAULT_DECIMALS
        )) + parseFloat(formatUnits(
            reward,
            DEFAULT_DECIMALS
        )) - parseFloat(formatUnits(
            penaltyAmount,
            DEFAULT_DECIMALS
        ))
        ) ,
        precisoinB
    ) : "0.00";

    const closedPrematurely = stakeData && stakeData[9] === false && stakeData[10] === false;


    let tooltipText = "Ongoing Stake";

    if (matureStake) {
        tooltipText = "Matured Stake";
    }

    if (closedStake) {
        tooltipText = "Closed Mature Stake";
    }

    if (closedPrematurely) {
        tooltipText = "Prematurely Terminated";
    }

    if (pendingStake) {
        tooltipText = "Confirmation Phase";
    }

    let progress = stakeData && (1 - daysLeft / lockDays) * 100;
    if (progress <= 0) progress = 0.001;

    let stakeColor = matureStake
        ? "#1bc943"
        : closedStake
            ? "gray"
            : "#f4772e";

    if (pendingStake) {
        stakeColor = "#bd67f7";
    }

    const formattedStakeData = {
        startDay: startDate,
        lockDays: lockDays,
        lockDaysFormatted: numberWithCommasForDays(lockDays),
        finalDay: finalDay,
        closeDay: closeDay,
        scrapeDay: scrapeDay,
        stakedAmount: stakedAmount,
        stakedAmountFormatted: `${stakedAmountFormatted} WISE`,
        stakaedAmountInUSD: `${stakedAmountInUSD} USD`,
        stakaedAmountInUSD2: `$${stakedAmountInUSD}`,
        stakedAmountInUSDWithPrecision: `${stakedAmountInUSDWithPrecision} USD`,
        stakesShares: stakeShares,
        rewardAmount: rewardAmount,
        currentWiseDay: currentWiseDay,
        stakeRewards: `${stakeRewards} WISE`,
        stakeRewardsInUSD: `${stakeRewardsInUSD} USD`,
        stakeRewardsDynamicInUSD: `$${stakeRewardsDynamicInUSD}`,
        stakeRewardsInUSD2: `$${stakeRewardsInUSD}`,
        penaltyAmount: penaltyAmount,
        penaltyAmountFormatted: `${penaltyAmountFormatted} WISE`,
        terminationPayoutFormatted: `${terminationPayoutFormatted} WISE`,
        terminationFeeInUSD: `${terminationFeeInUSD} USD`,
        terminationFeeInUSD2: `$${terminationFeeInUSD}`,
        terminationPayoutInUSD: `$${terminationPayoutInUSD}`,
        isActive: stakeData ? stakeData[9] : false,
        isMature: stakeData ? stakeData[10] : false,
        daysLeft: daysLeft,
        daysLeftFormatted: numberWithCommasForDays(getDaysLeft(stakeData, currentWiseDay)),
        opensDayDate: getDateString(startDate - 1, inceptionDay),
        startDayDate: getDateString(startDate, inceptionDay),
        finalDayDate: getDateString(finalDay, inceptionDay),
        closeDayDate: getDateString(closeDay, inceptionDay),
        matureStake: matureStake,
        closedStake: closedStake,
        onGoingStake: onGoingStake,
        closedPrematurely: closedPrematurely,
        tooltipText: tooltipText,
        servedDays: servedDays,
        stakeProgress: progress,
        servedDaysFormatted: numberWithCommasForDays(servedDays),
        stakeColor: stakeColor,
        pendingStake: pendingStake,
        stakeIcon: matureStake
            ? "smile"
            : closedStake
                ? "close-circle"
                : "clock",
    };

    return {
        refetchStakeData,
        stakeData: formattedStakeData,
        isFetching: isFetching
    };
};

export default useWiseStakeData;
