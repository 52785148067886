// import React, { useState } from "react";
import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import ReferralNetwork from "../ReferralNetwork";

const ReferralNetworkModal = ({
    referrerAddress,
    onSuccessConfirm,
    visible,
    onClose,
}) => {
    const [shouldCloseAsSuccess, setShouldCloseAsSuccess] = useState(false);

    const handleClose = () => {
        if (shouldCloseAsSuccess && onSuccessConfirm) {
            onSuccessConfirm?.();
        } else {
            onClose();
        }
    }
    return (
        <Modal
            visible={visible}
            onClose={handleClose}
        >
            <ReferralNetwork
                referrerAddress={referrerAddress}
                onSuccessConfirm={onSuccessConfirm}
                onSuccess={() => setShouldCloseAsSuccess(true)}
            />
        </Modal>
    )
}

export default ReferralNetworkModal;