import React from "react";
// import cn from "classnames";
import styles from "./Withdrawals.module.sass";
import TooltipGlobal from "../../components/TooltipGlobal";
import Overview from "./Overview";
import History from "./History";

const Withdrawals = () => {
    return (
        <>
            <div className={styles.section}>
                <Overview className={styles.card} />
                <History />
            </div>
            <TooltipGlobal />
        </>
    );
};

export default Withdrawals;
