import styles from "./EmptyPlaceholder.module.sass";

const EmptyPlaceholder = ({
    children
}) => {
    return (
        <div className={styles.placeholder}>
            {children}
        </div>
    );
}

export default EmptyPlaceholder;