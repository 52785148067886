// import Dropdown from "../../../components/Dropdown";
// import Balance from "../../../components/Balance";
// import Chart from "./Chart";
// import { OverviewMain } from "./Overview";
import { PointTypes, PointsV2Navigation } from "../../../utils/config";

export const intervals = [
    "This week",
    "This month",
    "This year"
];

export const items = [
    {
        type: PointTypes.WISE,
        title: "Current Prize Pool",
        counter: "128k",
        icon: "activity",
        tooltip: "Distributed Between Top Ranks",
        value: 37.8,
        //image: "images/blueLogo.svg",
        image: "/images/wise.png",
        background: "#edf8f2",
        chartColor: "#83BF6E",
        data: [
            {
                name: "1",
                earning: 500,
            },
            {
                name: "2",
                earning: 800,
            },
            {
                name: "3",
                earning: 1100,
            },
            {
                name: "4",
                earning: 900,
            },
            {
                name: "5",
                earning: 1300,
            },
            {
                name: "6",
                earning: 800,
            },
            {
                name: "7",
                earning: 1000,
            },
        ],
    },
    {
        type: PointTypes.ETHERFI,
        title: "Ether Contribution Rank",
        counter: "64k",
        tooltip: "For ETH Deposit and Referrals",
        icon: "activity",
        image: "/images/icons/eth.svg",
        // useHolder: true,
        background: "#ecf9fe",
        chartColor: "#2A85FF",
        data: [
            {
                name: "1",
                earning: 1300,
            },
            {
                name: "2",
                earning: 800,
            },
            {
                name: "3",
                earning: 1000,
            },
            {
                name: "4",
                earning: 500,
            },
            {
                name: "5",
                earning: 800,
            },
            {
                name: "6",
                earning: 1100,
            },
            {
                name: "7",
                earning: 900,
            },
        ],
        onClickData: PointsV2Navigation.ETHER
    },
    {
        type: PointTypes.ETHENA,
        title: "Stable Contribution Rank",
        counter: "64k",
        tooltip: "For Stable Coins Deposit and Referrals",
        icon: "activity",
        value: 37.8,
        useHolder: true,
        // image: "/images/icons/dai.svg",
        background: "#ecf9fe",
        chartColor: "#8E59FF",
        data: [
            {
                name: "1",
                earning: 1200,
            },
            {
                name: "2",
                earning: 800,
            },
            {
                name: "3",
                earning: 1300,
            },
            {
                name: "4",
                earning: 600,
            },
            {
                name: "5",
                earning: 1300,
            },
            {
                name: "6",
                earning: 800,
            },
            {
                name: "7",
                earning: 1000,
            },
        ],
        onClickData: PointsV2Navigation.STABLE
    },
    {
        type: PointTypes.REFERRAL,
        // title: `Season ${POINTS_SEASON} Rewards In:`,
        title: `Season 3 End In:`,
        tooltip: "April 30, 2022",
        counter: "512",
        icon: "activity",
        value: -37.8,
        image: "/images/wise-gold.png",
        background: "#A7AAF7",
        chartColor: "#2A85FF",
        data: [
            {
                name: "1",
                earning: 1300,
            },
            {
                name: "2",
                earning: 800,
            },
            {
                name: "3",
                earning: 1000,
            },
            {
                name: "4",
                earning: 500,
            },
            {
                name: "5",
                earning: 800,
            },
            {
                name: "6",
                earning: 1100,
            },
            {
                name: "7",
                earning: 900,
            },
        ],
    },
];
