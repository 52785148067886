import { useEffect, useMemo } from "react";
import { useBlockNumber, useReadContracts } from "wagmi";
import { formatUnits, getAbiItem } from "viem";
import { getLendingTokenAddress } from "../ethers/tokens";
import { _defaultArray, _no, toPrecision, computeUSDAmount, _notEmpty } from "../utils";
import { getSecurityContract } from "../ethers/contracts";
import { APY_DECIMALS } from "../utils/config";

const FUNCTION_COUNT = 4;

export const useTokenUtilization = ({ tokens, config, tokenPrices, skip, shouldRefetchEveryBlock }) => {

    const {
        chainId,
        wiseLendingContract
    } = config;

    const contracts = useMemo(() => {
        return tokens?.filter((token) => {
            return _notEmpty(token)
        }).flatMap((token) => {

            const tokenAddress = getLendingTokenAddress(
                token,
                token.isAavePool
            );

            const getWiseLendingABI = (name) => {
                return [
                    getAbiItem({
                        abi: _defaultArray(wiseLendingContract?.abi),
                        name,
                        type: "function",
                    }),
                ];
            };

            const securityContract = getSecurityContract(
                config,
                token.isAavePool
            );

            return [
                {
                    chainId,
                    abi: getWiseLendingABI("getPseudoTotalPool"),
                    address: wiseLendingContract?.address,
                    functionName: "getPseudoTotalPool",
                    args: [tokenAddress],
                },
                {
                    chainId,
                    abi: getWiseLendingABI("getPseudoTotalBorrowAmount"),
                    address: wiseLendingContract?.address,
                    functionName: "getPseudoTotalBorrowAmount",
                    args: [tokenAddress],
                },
                {
                    chainId,
                    abi: getWiseLendingABI("getTotalPool"),
                    address: wiseLendingContract?.address,
                    functionName: "getTotalPool",
                    args: [tokenAddress],
                },
                {
                    chainId,
                    abi: [
                        getAbiItem({
                            abi: _defaultArray(securityContract?.abi),
                            name: "getLendingRate",
                            type: "function"
                        })
                    ],
                    address: securityContract?.address,
                    functionName: "getLendingRate",
                    args: [token.isAavePool ? token?.address : tokenAddress],
                }
            ];
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokens, chainId, wiseLendingContract?.address, wiseLendingContract?.abi]);

    const { data, isLoading, refetch } = useReadContracts({
        contracts,
        allowFailure: true,
        watch: false,
        enabled: false
    });

    useEffect(() => {
        if (_no(data)
            && wiseLendingContract?.abi
            && tokens.length
            && _no(isLoading)
            && _no(skip)
        ) {
            refetch();
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wiseLendingContract, tokens, skip]);

    useBlockNumber({
        enabled: _no(skip) && shouldRefetchEveryBlock,
        onBlock: refetch,
    });

    const tokenData = useMemo(() => {
        if (_no(data)) return [];

        const groupedData = data.reduce((dataGroup, _, index, currentBatch) => {
            if (index % FUNCTION_COUNT === 0) {
                dataGroup.push(
                    currentBatch.slice(
                        index,
                        index + FUNCTION_COUNT
                    ).map((item) => {
                        return item?.result
                    })
                );
            }
            return dataGroup;
        }, []);

        return tokens?.map((token, index) => {
            const [
                totalResult,
                fundResult,
                poolResult,
                supplyResult
            ] = groupedData[index];

            let totalFormatted = "0";
            if (totalResult) {
                totalFormatted = formatUnits(
                    totalResult,
                    token.decimals
                );
            }

            let fundFormatted = "0";
            if (fundResult) {
                fundFormatted = formatUnits(
                    fundResult,
                    token.decimals
                );
            }

            let poolFormatted = "0";
            if (poolResult) {
                poolFormatted = formatUnits(
                    poolResult,
                    token.decimals
                );
            }

            let supplyRateFormatted = "0";
            if (supplyResult) {
                supplyRateFormatted = formatUnits(
                    supplyResult,
                    APY_DECIMALS
                );
            }

            const utilizationPercentage = Number(fundFormatted)
                / Number(totalFormatted)
                * 100;

            const noResultYet = _no(fundResult) || _no(totalResult);
            const utilizationPercentageFormatted = noResultYet
                ? "—"
                : `${toPrecision(
                    utilizationPercentage,
                    0
                )}%`;

            const tokenPriceData = tokenPrices?.find((item) => {
                return item.name === token.name;
            }) || 0;

            const tokenPrice = tokenPriceData?.price ?? 0;

            const suppliedUSD = computeUSDAmount(
                token.decimals,
                totalFormatted,
                tokenPrice
            );

            const borrowedUSD = computeUSDAmount(
                token.decimals,
                fundFormatted,
                tokenPrice
            );

            const availableUSD = computeUSDAmount(
                token.decimals,
                poolFormatted,
                tokenPrice
            );

            return {
                ...token,
                token: token.name,
                utilizationPercentage,
                utilizationPercentageFormatted,
                fundFormatted,
                totalFormatted,
                poolFormatted,
                suppliedUSD,
                borrowedUSD,
                availableUSD,
                supplyResult,
                supplyRateFormatted
            };
        });
    }, [data, tokens, tokenPrices]);

    return { tokenData, isLoading };
};

export default useTokenUtilization;
