import {
    useWriteContract,
    useWalletClient,
    useWaitForTransactionReceipt
} from "wagmi";
import { erc20Abi } from "viem";
import { getLendingTokenAddress, isEnabled } from "../ethers/tokens";

import {
    getContractInputs,
    getLendingContract,
    getSecurityContract
} from "../ethers/contracts";

import {
    _getETHValue,
    _isNotEmptyNftId,
    _no,
} from "../utils";

import {
    APPROVE_FUNCTION_NAME,
    // CONFIRMATIONS_COUNT,
    // ERROR_SUPPLY,
    // ERROR_SUPPLY_WRITE,
    INFLATE_PERCENT,
    NATIVE_TOKENS,
    USDT_TOKEN_NAME
} from "../utils/config";

import { useEffect, useState } from "react";
import { getAbiItem } from "viem";
import { USDT_ERC20 } from "../ethers/abis";
import { useGasEstimate } from "./useGasEstimate";
import { useSimulateTransaction } from "./useSimulateTransaction";
// import { parseUnits } from "viem";

export const useSupplyToken = ({
    config,
    nftId,
    token,
    amount,
    runSimulation = true,
    collateralState,
    isPrivate,
    showApproval,
}) => {

    const isETH = NATIVE_TOKENS.includes(
        token.name
    );

    const {
        error,
        data: hash,
        writeContract,
        // writeContractAsync,
        isPending,
    } = useWriteContract();

    const hasNFT = _isNotEmptyNftId(nftId);
    const { data: signer } = useWalletClient();
    const { chainId, version } = config;

    const contract = getLendingContract(
        config,
        token.isAavePool
    );

    const enabled = isEnabled({
        config,
        nftId,
        token,
        allowNullNftId: true
    });

    const transactionSimulation = useSimulateTransaction(
        chainId
    );

    let functionName = hasNFT
        ? "depositExactAmount"
        : "depositExactAmountMint";

    let ethFunctionName = hasNFT
        ? "depositExactAmountETH"
        : "depositExactAmountETHMint";

    let args = hasNFT
        ? [
            nftId,
            token?.address,
            amount,
        ]
        : [
            token?.address,
            amount,
        ];

    let ethArgs = hasNFT
        ? [ nftId ]
        : [];

    const needCollateralState = enabled ?
        getContractInputs({
            name: "needCollateralState",
            chainId,
            version
        }) : null;

    if (needCollateralState) {
        args = [
            ...args,
            collateralState
        ];
        ethArgs = [
            ...ethArgs,
            collateralState
        ];
    }

    if (isPrivate) {
        functionName = hasNFT
            ? "solelyDeposit"
            : "solelyDepositMint";

        ethFunctionName = hasNFT
            ? "solelyDepositETH"
            : "solelyDepositETHMint";

        args = hasNFT
            ? [
                nftId,
                token?.address,
                amount
            ]
            : [
                token?.address,
                amount
            ];

        ethArgs = hasNFT
            ? [nftId]
            : [];
    }

    const finalFunctionName = isETH
        ? ethFunctionName
        : functionName;

    const finalArgs = isETH
        ? ethArgs
        : args;

    const value = _getETHValue(
        token.name,
        amount
    );

    const {
        estimatedGas,
        estimatesGasFormatted,
        estimatedGasUSD,
        gasLimit,
        isError
    } = useGasEstimate({
        contract,
        functionName: finalFunctionName,
        args: finalArgs,
        value,
        account: signer?.account,
        amount,
        showApproval
    });

    let gasLimitFormatted = parseInt(
        parseInt(gasLimit) * INFLATE_PERCENT
    ).toString();

    const [simulationResult, setSimulationResult] = useState(null);
    const [isCalculating, setIsCalculating] = useState(false);

    const securityContract = getSecurityContract(
        config,
        token.isAavePool
    );

    const tokenAddress = getLendingTokenAddress(
        token,
        token.isAavePool
    );

    const isUSDT = token.name === USDT_TOKEN_NAME && chainId === 1;
    const approveContract = {
        address: token?.address,
        abi: isUSDT
            ? USDT_ERC20
            : erc20Abi
    };

    const approveArgs = [
        contract?.address,
        amount
    ];

    const approveTransactions = [];

    if (isUSDT) {
        approveTransactions.push({
            address: approveContract.address,
            abi: approveContract.abi,
            signerOrProvider: signer,
            functionName: APPROVE_FUNCTION_NAME,
            args: [
                contract?.address,
                0
            ],
        });
    }

    if (_no(isETH)) {
        approveTransactions.push({
            address: approveContract.address,
            abi: approveContract.abi,
            signerOrProvider: signer,
            functionName: APPROVE_FUNCTION_NAME,
            args: approveArgs,
        });
    }

    useEffect(() => {
        const runSimulation = async () => {
            if (amount) {

                setIsCalculating(true);

                const result = await transactionSimulation.simulate([
                    ...approveTransactions,
                    {
                        abi: [
                            getAbiItem({
                                abi: contract?.abi,
                                name: finalFunctionName,
                                type: "function"
                            })
                        ],
                        address: contract?.address,
                        functionName: finalFunctionName,
                        args: finalArgs,
                        gas: gasLimitFormatted,
                        value,
                        hasReturn: false,
                    },
                    {
                        abi: [
                            getAbiItem({
                                abi: securityContract?.abi,
                                name: "getLendingRate",
                                type: "function"
                            })
                        ],
                        address: securityContract?.address,
                        functionName: 'getLendingRate',
                        args: [
                            token.isAavePool
                                ? token?.address
                                : tokenAddress
                        ],
                        hasReturn: true,
                    },
                ]);

                setIsCalculating(false);
                setSimulationResult(result);
            }
        };

        if (runSimulation && token.address) {
            runSimulation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount]);

    const supply = async ({
        onConfirmation,
        onSupplyComplete,
        onSupplying,
        onError
    }) => {

            onConfirmation?.();

            writeContract({
                chainId,
                abi: contract?.abi,
                address: contract?.address,
                signerOrProvider: signer,
                functionName: finalFunctionName,
                args: finalArgs,
                query: {
                    enabled,
                },
                value
            });

            /*

            const supplyTransaction = await tokenContract.writeContract();
            const { hash } = supplyTransaction;

            onSupplying?.(
                hash
            );

            const supply = await waitForTransaction({
                hash,
                confirmations: CONFIRMATIONS_COUNT
            });

            onSupplyComplete?.(
                supply
            );
            */

            // return true;

    };

    const {
        isError: hasError,
        isLoading: isConfirming,
        isSuccess: isConfirmed
    } = useWaitForTransactionReceipt({
        hash,
    });

    return {
        hash,
        error,
        supply,
        hasError,
        estimatedGas,
        isPending,
        isConfirming,
        isConfirmed,
        estimatesGasFormatted,
        estimatedGasUSD,
        estimateHasError: isError,
        simulationAPY: simulationResult?.[0],
        isCalculatingSimulationAPY: isCalculating
    };
};
