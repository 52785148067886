import React, { useState } from "react";
import styles from "./Transactions.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import { useAccount, useSwitchChain } from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import { setChainId } from "../../../redux/tokenSlice";
import Modal from "../../../components/Modal";
// import Dropdown from "../../../components/Dropdown";
import Loader from "../../../components/Loader";
import Panel from "../../Drafts/Panel";
import TransactionRow from './TransactionRow/'; // Adjust the path as necessary
import ReactPaginate from "react-paginate";
// import Checkbox from '../../../components/Checkbox';
// import Icon from '../../../components/Icon';
import Filters from "../../../components/Filters";
import Settings from "../Settings";
import ModalReferrals from "../../../components/ModalReferrals";
import CreateReferral from "../../../screens/AffiliateCenter/CreateReferral";
import Icon from "../../../components/Icon";

// const intervals = ["10 Per Page", "20 Per Page", "30 Per Page"];

const Transactions = ({
    className,
    referrals,
    address,
    currentWiseDay,
    setCurrentPage,
    perPage,
    setPerPage,
    referralCountFormatted,
    doRefetch,
    isFetching,
    chainId,
    fullScreen,
    onClose,
}) => {

    const { isConnected } = useAccount();
    const dispatch = useDispatch();

    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleModalProduct, setVisibleModalProduct] = useState(false);

    const NoReferrals = () => {
        return (
            <div className={styles.noStake}>
                <div className={styles.noStakeTitle}>
                    <div>You don't have any referrals yet</div>
                    <div>Start earning by promoting your referral link</div>
                    <br />
                    <button
                        onClick={() => setVisibleModal(true)}
                        className={cn(
                            "button-small",
                            "button-stroke",
                            styles.button
                        )}
                    >
                        Show Referral Link
                    </button>
                </div>
            </div>
        );
    }

    const WrongNetwork = (chain) => {

        const {
            switchChain,
            // isSuccess
        } = useSwitchChain();

        const switchChainNow = (chainId) => {

            if (isConnected) {
                switchChain({
                    chainId: chainId
                });
            } else {
                dispatch(
                    setChainId(
                        chainId
                    )
                );
                // setVisible(false);
            }
        }

        return (
            <div className={styles.noStake}>
                <div className={styles.noStakeTitle}>
                    <button
                        onClick={() => switchChainNow(1)}
                        className={cn(
                            "button-small",
                            "button-stroke",
                            styles.button
                        )}
                    >
                        Switch To Ethereum
                    </button>
                </div>
            </div>
        );
    }

	const wiseCost = useSelector(
		(state) => state.token.TVL?.wiseUsd
	);

    /*
    const [sorting, setSorting] = useState(
        intervals[0]
    );
    */

    const [selected, setSelected] = useState([]);
    const [totalWise, setTotalWise] = useState(0);

    const pageCount = Math.ceil(
        referralCountFormatted / perPage
    );

    const handlePageClick = (data) => {
        const selected = data.selected;
        setCurrentPage(selected);
    }

    const handleSelect = (id, amount) => {
        if (selected.includes(id)) {
            setTotalWise((totalWise) => totalWise - parseFloat(amount));
            setSelected(selected.filter((x) => x !== id));
        } else {
            setTotalWise((totalWise) => totalWise + parseFloat(amount));
            setSelected([
                ...selected,
                id
            ]);
        }
    }

    // const [isChecked, setIsChekced] = useState(false);

    return (
        <Card
            className={cn(styles.card, className)}
            classCardHead={styles.head}
            title={`Your WISE Referrals (${referralCountFormatted})`}
            classTitle={cn("title-green", styles.title)}
            head={
                <>
                    <div style={{ display: "flex" }}>
                        <button
                            // onClick={() => showStakeCreation()}
                            onClick={() => setVisibleModal(true)}
                            className={cn("button-small", styles.button)}
                        >
                            Show Referral Link
                        </button>
                        <Filters
                            icon="filter"
                            className={styles.filters}
                            title="Filtering Options"
                        >
                            <Settings
                                doRefetch={doRefetch}
                                setPerPage={setPerPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </Filters>
                        <div
                            className={styles.filters}
                        >
                            <button
                                className={cn("button-square-stroke button-small", styles.head)}
                                onClick={() => {
                                    if (onClose) {
                                        onClose();
                                    }
                                    !fullScreen && setVisibleModalProduct(true)
                                }}
                            >
                                <Icon name={fullScreen ? "close" : "expand"} size="24" />
                            </button>
                        </div>
                    </div>
                </>
            }
        >
        <div className={cn(
                styles.wrapper,
                fullScreen && styles.fullscreen
            )}>
            <div
                className={cn(
                    styles.table,
                    { [styles.marginyes]: selected.length > 0 }
                )}
            >
            <div></div>
            <div className={cn(styles.row, styles.header)}>
                <div onClick={()=> {}} style={{color: "white"}} className={styles.col}>
                    Select
                    {/*<Checkbox value={isChecked}></Checkbox>*/}
                </div>
                <div className={styles.col}>Referral Started</div>
                <div className={styles.col}>Referral Saturation</div>
                <div className={styles.col}>Referral Expires</div>
                <div className={styles.col}>Referred Staker</div>
                <div className={styles.col}>Referred Value</div>
                <div className={styles.col}>Your Rewards</div>
                <div className={styles.col}>Actions</div>
            </div>
            {referrals && referrals.map((x, index) => (
                <TransactionRow
                    key={index}
                    index={index}
                    doRefetch={doRefetch}
                    selected={selected}
                    handleSelect={handleSelect}
                    wiseCost={wiseCost}
                    currentWiseDay={currentWiseDay}
                    referralId={referrals && referrals[index]}
                    address={address}
                    setDoRefetch={doRefetch}
                />
            ))}
            </div>
        </div>
        {
            isFetching && !referralCountFormatted && <div className={styles.loader}>
                <Loader></Loader>
            </div>
        }
        {
            !isFetching && !referralCountFormatted && <div className={styles.loader}>
                {chainId !== 1
                    ? <WrongNetwork></WrongNetwork>
                    : <NoReferrals></NoReferrals>
                }
            </div>
        }
        <Modal
            outerClassName={styles.modal}
            visible={visibleModal}
            onClose={() => setVisibleModal(false)}
        >
            <CreateReferral
                showCopyButton={true}
                className={styles.nopad}
            ></CreateReferral>
        </Modal>
        <ModalReferrals
            visible={visibleModalProduct}
            onClose={() => setVisibleModalProduct(false)}
        />
        <Panel
            totalWise={totalWise}
            setTotalWise={setTotalWise}
            wiseCost={wiseCost}
            selected={selected}
            setSelected={setSelected}
            className={styles.panel}
            setDoRefetch={doRefetch}
        />
        {referralCountFormatted > perPage && (
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                activeClassName={styles.activepage}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                disabledClassName={styles.disabledbutton}
                className={styles.pagination}
                previousLabel="<"
            />
        )}
        </Card>
    );
};

export default Transactions;
