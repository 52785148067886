import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import LinearBar from "../../../../../components/LinearBar";
import Radio from "../../../../../components/Radio";
import { useMintPosition } from "../../../../../customHooks/useMintPosition";
import useLendingSummary from "../../../../../customHooks/useLendingSummary";
import useConfig from "../../../../../customHooks/useConfig";
import { useAccount } from "wagmi";
import { showToast } from "../../../../../utils";

import {
    // numberWithCommas,
    toPrecision,
    formatMoney,
} from "../../../../../utils";

const Row = ({
    item,
    active,
    reservedNftId,
    value,
    onChanger,
    up,
    toggleRefetch,
}) => {

    const config = useConfig();
    const { address } = useAccount();

    const [isLoading, setIsLoading] = useState(false);
    const [buttonText, setButtonText] = useState("Mint Now");

    const summary = useLendingSummary({
        config,
        nftId: item,
        userAccount: address,
    });

    const isGray = parseInt(summary?.borrowFormatted) === 0;

    const borrowLimitUsed = !!summary && !isGray
        ? (summary?.borrowFormatted / summary?.limitFormatted) * 100
        : 0;

    const mintPosition = useMintPosition({
        config,
    });

    const handleMint = () => {
        mintPosition.mintPosition({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonText(
                    "Confirm..."
                );
            }
        });
    }

    useEffect(() => {

        if (mintPosition.error && isLoading) {
            setIsLoading(false);
            setButtonText(`Mint Now`);
        }

        if (mintPosition.isConfirming && isLoading) {
            setButtonText(`Minting...`);
        }

        if (mintPosition.isConfirmed && isLoading) {

            setIsLoading(false);
            const message = "Position minted successfully!";

            showToast(message, {
                type: "success",
            });

            setButtonText(
                `Minted`
            );

            setIsLoading(false);
            toggleRefetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mintPosition]);

    return (
        <>
            <div className={styles.row}
                // onMouseLeave={() => setVisibleActions(false)}
            >
                <div
                    className={styles.col}
                    // onClick={() => onChanger(item)}
                >
                    <Radio
                        className={styles.radio}
                        name="active"
                        value={active}
                        onChange={() => {
                            onChanger(item)
                        }}
                    />
                </div>
                <div
                    className={styles.col}
                    onClick={() => onChanger(item)}
                >
                    <div
                        //onClick={() => onChange(item)}
                        className={styles.item}
                        // onClick={() => setVisibleModalProduct(true)}
                    >
                        <div className={styles.details}>
                            <div className={styles.product}>Position #{item}</div>
                            <div className={styles.wrap}>
                                <div className={styles.category}>Wise Lending</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    onClick={() => onChanger(item)}
                    className={styles.col}
                >
                    <div className={styles.label}>Status</div>
                    {active ? (
                        <div className={cn("status-green", styles.status)}>Active</div>
                    ) : (
                        <div className={cn("status-yellow", styles.status)}>Switch</div>
                    )}
                    {/*<Control
                        className={styles.control}
                        visibleActions={visibleActions}
                        setVisibleActions={setVisibleActions}
                        up={up}
                    />*/}
                </div>
                <div
                    onClick={() => onChanger(item)}
                    className={styles.col}
                >
                    {summary && summary?.isNegativeApy ? (<>-</>) : (<>+</>)}
                    {summary && toPrecision(summary?.overallNetAPYFormatted)}%
                </div>
                <div
                    onClick={() => onChanger(item)}
                    className={styles.col}
                >
                    <div className={styles.label}>Lending</div>
                    <div className={styles.sales}>
                        <div className={cn("status-green-dark", styles.status)}>
                            ${summary && formatMoney(summary?.supplyFormatted)}
                        </div>
                    </div>
                </div>
                <div
                    onClick={() => onChanger(item)}
                    className={styles.col}
                >
                    <div className={styles.label}>Borrowing</div>
                    <div className={styles.box}>
                        <div className={cn(
                            "status-purple-dark",
                            styles.status,
                            {
                                [styles.grayscale]: isGray
                            }
                        )}>
                            ${summary && formatMoney(parseInt(summary?.borrowFormatted))}
                        </div>
                    </div>
                </div>
                <div
                    onClick={() => onChanger(item)}
                    className={styles.col}
                >
                    <div className={styles.label}>Borrow Limit Used</div>
                    <div className={styles.box}>
                        <div className={styles.number}>{!!borrowLimitUsed && !isGray ? parseInt(borrowLimitUsed) : 0 }%</div>
                        <div className={styles.line}>
                            <LinearBar
                                height={7}
                                width={parseInt(borrowLimitUsed)}
                                backColor="#333333"
                                fill="#333333"
                                limit={200}
                                limitColor="#333333"
                            />
                        </div>
                    </div>
                </div>
                <div
                    // onClick={() => onChanger(item)}
                    className={styles.col}
                >
                    <div className={styles.label}>Minted</div>
                    <div className={styles.box}>{
                        parseInt(reservedNftId) === parseInt(item) ? (
                            <div
                            onClick={() => {
                                handleMint();
                            }}
                            className={cn("status-green", styles.status, styles.hover)}>
                                {buttonText}
                            </div>
                        ) : (
                            <div className={cn("status-gray", styles.status)}>
                                Minted
                            </div>
                        )
                    }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Row;
