import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_CHAIN_ID } from "../utils/config";

const initialState = {
    chainId: DEFAULT_CHAIN_ID,
    pricingFeeds: [],
    walletBalance: null,
    APY: {
        smaApr: 0,
        pendleAPY: {}
    },
    TVL: null,
};

export const tokenSlice = createSlice({
    name: "token",
    initialState,
    reducers: {
        setPricingFeeds: (state, action) => {
            state.pricingFeeds = action.payload;
        },
        setChainId: (state, action) => {
            state.chainId = action.payload;
        },
        setWalletBalance: (state, action) => {
            state.walletBalance = action.payload;
        },
        setAPY: (state, action) => {
            state.APY = action.payload;
        },
        setTVL: (state, action) => {
            state.TVL = action.payload;
        }
    },
});

export const {
    setPricingFeeds,
    setChainId,
    setWalletBalance,
    setAPY,
    setTVL
} = tokenSlice.actions;

export default tokenSlice.reducer;
