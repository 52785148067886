import React, { useState, useEffect, useRef } from "react";
import { NumericFormat } from "react-number-format";
import debounce from "lodash.debounce";
import cn from "classnames";
import styles from "./NumberInput.module.sass";

const NumberInput = ({
    onChange,
    value,
    decimalPlaces = 2,
    ...props
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const debouncedOnChange = useRef(null);

    useEffect(() => {
        debouncedOnChange.current = debounce((values) => {
            const { formattedValue } = values;
            onChange?.(formattedValue);
        }, 300);

        return () => {
            debouncedOnChange.current?.cancel();
        };
    }, [onChange]);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleValueChange = (values) => {
        debouncedOnChange.current(values);
    };

    return (
        <NumericFormat
            {...props}
            className={cn(
                props.className || styles.input,
                {
                    [styles.truncate]: !isFocused,
                    [styles.focused]: isFocused
                }
            )}
            value={value}
            onValueChange={handleValueChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={decimalPlaces}
            fixedDecimalScale={false}
            allowNegative={false}
            autoComplete="off"
            required
        />
    );
};

export default NumberInput;
