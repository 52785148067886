import styles from "./ProductLogo.module.sass";

const ProductLogo = ({ item }) => {

    return (
        <div className={styles.previewContainer}>
            <div className={styles.preview}>
                <img
                    className={styles.data}
                    srcSet={`${item.image2x} 2x`}
                    src={item.image}
                    alt="Product"
                />
            </div>
            {item.badge && (
                <img src={item.badge} className={styles.badge} alt="Badge" width={25} />
            )}
        </div>);
};

export default ProductLogo;
