export const getCookie = (cname) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

export const WriteReferralCookie = (ref) => {
    let domain;
    if (!/^0x[a-fA-F0-9]{40}$/g.test(ref)) return;
    const host = window.location.hostname;
    if (host && host[0] !== '[' && !Number.parseInt(host[0], 10)) {
        domain = host.substring(
            host.lastIndexOf('.', host.lastIndexOf('.') - 1) + 1
        );
    } else domain = host;
    document.cookie =
        'w=' +
        ref +
        ';domain=' +
        domain +
        ';path=/;expires=Sat, 28 Mar 2037 00:00:00 GMT;max-age=536479200;';
};