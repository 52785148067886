import React, { useState } from "react";
import cn from "classnames";
import styles from "./Profile.module.sass";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import Product from "../../components/Product";
import { appLocalStorage } from "../../utils/localstorage";

// import Social from "./Social";
// import Settings from "./Settings";
// import { Link } from "react-router-dom";
// import Filters from "../../components/Filters";
// import Follower from "./Follower";
// import { displayWithSkeleton } from "../../utils";
// import { followers } from "../../mocks/followers";

const navigation = [
    "All Audits",
    "Wise Lending",
    "Wise Token",
];

const Audit = () => {

    const [activeIndex, setActiveIndex] = useState(
        0
    );

    const items = [
        {
            product: "Wise Lending Audit",
            image: "/images/audit/arena.jpg",
            image2x: "/images/audit/arena.jpg",
            content: "https://code4rena.com/audits/2024-02-wise-lending",
            externalLink: "https://code4rena.com/audits/2024-02-wise-lending",
            active: true,
            price: "200,000 USD",
            link: "token/",
            statusText: "Done",
            statusColor: "green",
            avatar: "/images/content/avatar-3.jpg",
            time: "Active",
        },
        {
            product: "Wise Lending Audit",
            time: "Github",
            externalLink: "https://github.com/hats-finance/Wise-Lending-0xa2ca45d6e249641e595d50d1d9c69c9e3cd22573/blob/master/report.md",
            link: "token/",
            active: true,
            price: "160,000 USD",
            image: "/images/audit/hats.jpg",
            image2x: "/images/audit/hats.jpg",
            statusText: "Done",
            statusColor: "green",
        },
        {
            product: "Wise Lending Audit",
            link: "token/",
            image: "/images/audit/omniscia.jpg",
            image2x: "/images/audit/omniscia.jpg",
            avatar: "/images/content/avatar-3.jpg",
            time: "PDF",
            active: true,
            price: "12,000 USD",
            externalLink: "https://wise.one/omni-audit-v1.pdf",
            statusText: "Done",
            statusColor: "green",
        },
        //
        {
            product: "Wise Lending Bounty",
            link: "token/",
            active: true,
            price: "100,000 USD",
            image: "/images/audit/hashlock.jpg",
            image2x: "/images/audit/hashlock.jpg",
            avatar: "/images/content/avatar-3.jpg",
            time: "Active",
            externalLink: "https://hashlock.com/bug-bounty/wise-lending",
            content: "https://www.hashlock.com.au/bug-bounty/wise-lending",
            statusText: "In Progress",
            statusColor: "purple",
        },
        {
            product: "Wise Token Audit",
            link: "token/",
            active: true,
            price: "15,000 USD",
            image: "/images/audit/coinfabrik.jpg",
            image2x: "/images/audit/coinfabrik.jpg",
            avatar: "/images/content/avatar-3.jpg",
            time: "Active",
            externalLink: "https://wise.one/coin-fabrik.pdf",
            content: "https://www.hashlock.com.au/bug-bounty/wise-lending",
            statusText: "In Progress",
            statusColor: "purple",
        },
        // {
        //     title: "Immunefi Bug Bounties",
        //     image: "/images/audit/immunefi.jpeg",
        //     image2x: "/images/audit/immunefi.jpeg",
        //     avatar: "/images/content/avatar-3.jpg",
        //     time: "CS",
        // },
        /*
        {
            title: "Founder Explains",
            image: "/images/content/thumb-c.jpg",
            image2x: "/images/content/thumb-c.jpg",
            avatar: "/images/content/avatar-3.jpg",
            time: "Video",
        },
        */
    ];

    const onlyWiseLending = items.filter((x) => x.product.includes("Wise Lending"));
    const onlyWiseToken = items.filter((x) => x.product === "Wise Token Audit");

    return (
        <>
            <div className={styles}>
                <div className={styles.background}>
                </div>
                <Card className={styles.card}>
                    {/*<Social />*/}
                    <div className={styles.control}>
                        <div className={styles.nav}>
                            {navigation.map((x, index) => (
                                <button
                                    className={cn(styles.link, {
                                        [styles.active]: index === activeIndex,
                                    })}
                                    onClick={() => {
                                        appLocalStorage.preferredHomeTab.update(
                                            index
                                        );
                                        setActiveIndex(index)
                                    }}
                                    key={index}
                                >
                                    {x}
                                </button>
                            ))}
                        </div>
                        <div className={styles.dropdownBox2}>
                            <Dropdown
                                className={styles.dropdown}
                                classDropdownHead={styles.dropdownHead}
                                value={navigation[activeIndex]}
                                setValue={setActiveIndex}
                                options={navigation}
                                small
                            />
                        </div>
                        {/*<Filters
                            className={styles.filters}
                            title="Filtering Options"
                        >
                            <Settings />
                        </Filters>*/}
                    </div>
                    <div className={styles.wrap}>
                        {activeIndex === 0 && (
                            <>
                                <div className={styles.products}>
                                    {items.map((x, index) => (
                                        <Product
                                            className={styles.product}
                                            item={x}
                                            key={index}
                                            withoutСheckbox
                                        />
                                    ))}
                                </div>
                                {/*<div className={styles.foot}>
                                    <button
                                        className={cn("button-stroke button-small", styles.button)}
                                    >
                                        Load more
                                    </button>
                                </div>*/}
                            </>
                        )}
                        {activeIndex === 1 && (
                            <>
                                <div className={styles.products}>
                                    {onlyWiseLending.map((x, index) => (
                                        <Product
                                            className={styles.product}
                                            item={x}
                                            key={index}
                                            withoutСheckbox
                                        />
                                    ))}
                                </div>
                                {/*<div className={styles.foot}>
                                    <button
                                        className={cn("button-stroke button-small", styles.button)}
                                    >
                                        Load more
                                    </button>
                                </div>*/}
                            </>
                        )}
                        {activeIndex === 2 && (
                            <>
                                <div className={styles.products}>
                                    {onlyWiseToken.map((x, index) => (
                                        <Product
                                            className={styles.product}
                                            item={x}
                                            key={index}
                                            withoutСheckbox
                                        />
                                    ))}
                                </div>
                                {/*<div className={styles.foot}>
                                    <button
                                        className={cn("button-stroke button-small", styles.button)}
                                    >
                                        Load more
                                    </button>
                                </div>*/}
                            </>
                        )}
                    </div>
                </Card>
            </div>
        </>
    );
};

export default Audit;
