import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useReadContract } from "wagmi";
import { _notEmpty } from "../utils";
import { useQueryClient } from '@tanstack/react-query'

const useRWAPositionNFT = ({
    config,
    userAddress,
    refetchTrigger
}) => {

    const { chainId, ForwardingDepositUSDCContract } = config;
    const { TVL } = useSelector((state) => state.token);

    const enabled = _notEmpty(userAddress)
        && _notEmpty(chainId)
        && _notEmpty(ForwardingDepositUSDCContract?.address);

    const { data: nftPositionCount, queryKey } = useReadContract({
        chainId,
        address: ForwardingDepositUSDCContract?.address,
        abi: ForwardingDepositUSDCContract?.abi,
        functionName: "balanceOf",
        args: [
            userAddress,
        ],
        query: {
            enabled,
        },
    });

    // console.log(nftPositionCount, 'nftPositionCount');

    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.invalidateQueries({
            queryKey
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // blockNumber,
        queryClient,
        chainId,
        refetchTrigger
    ]);

    /*
    const { data: totalDepositedData, queryKey: anotherKey } = useReadContract({
        address: ForwardingDepositUSDCContract?.address,
        abi: ForwardingDepositUSDCContract?.abi,
        functionName: "totalDeposited",
        args: [],
    });
    */

    /*
    useEffect(() => {
        queryClient.invalidateQueries({
            anotherKey
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        blockNumber,
        queryClient,
        chainId
    ]);
    */

    return {
        nftPositionCount: nftPositionCount || 0,
        totalDepositedData: TVL?.vaultTVL,
        isFetching: false
    };
};

export default useRWAPositionNFT;
