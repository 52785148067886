import { useEffect } from "react";
import { useReadContracts, useBlockNumber } from "wagmi";
import { useQueryClient } from '@tanstack/react-query'
import { formatUnits, getAbiItem, erc20Abi } from "viem";

import {
    _notEmpty,
} from "../utils";

const useWiseBalance = ({
    config,
    userAccount,
}) => {

    const {
        chainId,
        WiseTokenContract,
    } = config;

    const tokenAddress = WiseTokenContract?.address;

    const contracts = [
        {
            chainId,
            abi: [
                getAbiItem({
                    abi: erc20Abi,
                    name: "balanceOf",
                    type: "function"
                })
            ],
            address: tokenAddress,
            functionName: "balanceOf",
            args: [
                userAccount
            ],
        },
    ];

    const queryClient = useQueryClient();

    const { data: blockNumber } = useBlockNumber({
        watch: true
    });

    const {
        data: wiseTokenBalance,
        error,
        isLoading,
        queryKey,
        // refetch
    } = useReadContracts({
        allowFailure: true,
        query: {
            enabled: tokenAddress,
        },
        contracts,
    });

    useEffect(() => {
        queryClient.invalidateQueries({ queryKey });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        blockNumber,
        queryClient
    ]);

    const hasValue = wiseTokenBalance
        && _notEmpty(wiseTokenBalance)
        && wiseTokenBalance[0]
        && wiseTokenBalance[0].result;

    const wiseTokenBalanceFormatted = hasValue
        ? formatUnits(wiseTokenBalance[0]?.result?.toString() || "0.00", 18)
        : "0.00";

    const result = {
        wiseTokenBalance,
        wiseTokenBalanceFormatted,
        isFetching: isLoading,
        error
    }

    return result;
};

export default useWiseBalance;
