import {
    useWriteContract,
    useWalletClient,
    useWaitForTransactionReceipt
} from "wagmi";

export const useScrapeReferral = ({
    config,
    referrals,
}) => {

    const { data: signer } = useWalletClient();
    const { chainId, WiseTokenContract: contract } = config;

    const {
        error,
        data: hash,
        writeContract,
        // writeContractAsync,
        isPending,
    } = useWriteContract();

    const scrapeReferrals = ({
        onConfirmation,
    }) => {
        onConfirmation?.();

        const args = [
            referrals,
            Array(referrals.length).fill(0)
        ];

        writeContract({
            chainId,
            abi: contract?.abi,
            address: contract?.address,
            signerOrProvider: signer,
            // gas: gasLimitFormatted,
            functionName: "referrerInterestBulk",
            args: args,
            query: {
                enabled: referrals,
            }
        });
    };

    const {
        isError: hasError,
        isLoading: isConfirming,
        isSuccess: isConfirmed
    } = useWaitForTransactionReceipt({
        hash,
    });

    return {
        hash,
        error,
        hasError,
        isPending,
        isConfirming,
        isConfirmed,
        scrapeReferrals,
        isLoading: isConfirming,
    };
};
