import {
    useWriteContract,
    useWalletClient,
    useWaitForTransactionReceipt,
} from "wagmi";
import { getLendingContract } from "../ethers/contracts";
import { isEnabled } from "../ethers/tokens";
import {
    ERROR_BORROW,
    INFLATE_PERCENT,
    NATIVE_TOKENS
} from "../utils/config";
import { useGasEstimate } from "./useGasEstimate";
// import { parseUnits } from "viem";

export const useBorrowToken = ({
    config,
    nftId,
    token,
    amount,
    showApproval
}) => {

    const isETH = NATIVE_TOKENS.includes(
        token.name
    );

    const { data: signer } = useWalletClient();
    const { chainId } = config;

    const contract = getLendingContract(
        config,
        token.isAavePool
    );

    const functionName = isETH
        ? "borrowExactAmountETH"
        : "borrowExactAmount";

    const args = isETH
        ?
        [
            nftId,
            amount
        ]
        :
        [
            nftId,
            token.address,
            amount
        ];

    const enabled = isEnabled({
        config,
        nftId,
        token
    });

    const {
        gasLimit,
        estimatedGas,
        estimatesGasFormatted,
        estimatedGasUSD,
        isError
    } = useGasEstimate({
        contract,
        functionName,
        args,
        account: signer?.account,
        amount,
        showApproval
    });

    let gasLimitFormatted = parseInt(
        parseInt(gasLimit) * INFLATE_PERCENT
    ).toString();

    const {
        error,
        data: hash,
        writeContract,
        // writeContractAsync,
        isPending,
    } = useWriteContract();

    const borrow = async ({
        onConfirmation,
        onBorrowComplete,
        onBorrowing,
        onError
    }) => {
        try {

            onConfirmation?.();

            writeContract({
                chainId,
                abi: contract?.abi,
                address: contract?.address,
                signerOrProvider: signer,
                gas: gasLimitFormatted,
                functionName,
                args,
                query: {
                    enabled
                },
            });

            return true;

        } catch (error) {

            console.error(
                ERROR_BORROW,
                error
            );

            return false;
        }
    };

    const {
        isError: hasError,
        isLoading: isConfirming,
        isSuccess: isConfirmed
    } = useWaitForTransactionReceipt({
        hash,
    });

    return {
        hash,
        error,
        borrow,
        hasError,
        estimatedGas,
        isPending,
        isConfirming,
        isConfirmed,
        estimatesGasFormatted,
        estimatedGasUSD,
        estimateHasError: isError
    };
};
