import React, { useState, useEffect, useMemo } from "react";
import cn from "classnames";
import { useAccount } from "wagmi";
import { formatUnits, parseUnits } from "viem";
import { usePrivy } from '@privy-io/react-auth';
import Icon from "../../../../../components/Icon";
import Loader from "../../../../../components/Loader";
import LinearBar from "../../../../../components/LinearBar";
// import NumberInput from "../../../../../components/NumberInput";
import { useTokenApproval } from "../../../../../customHooks/useTokenApproval";
import { useWithdrawToken } from "../../../../../customHooks/useWithdrawToken";
import {
    _no,
    _notEmpty,
    _checkThreshold,
    removeNonNumeric,
    formatMoney,
    formatMoneyDashed,
    toPrecision,
    computeUSDAmount,
    toZeroIfBelowOne,
    showToast,
} from "../../../../../utils";
import {
    FETCHING_DATA,
    TRY_AGAIN_TEXT,
    CONFIRM_TEXT,
    APPROVING_TEXT,
    DONE_TEXT,
    WITHDRAWING_TEXT,
    WITHDRAW_FAILED_MESSAGE,
    WITHDRAW_SUCCESS,
    // CLICK_TO_VIEW,
    APPROVE_TEXT,
    INSUFFICIENT_COST,
    WISELENDING_TEXT,
    SWING_PERCENT
} from "../../../../../utils/config";
import styles from "./Withdraw.module.sass";
import SuccessPanel from "../../../../../components/Modal/SuccessPanel";
import TransactionCost from "../../../../../components/TransactionCost";
import TooltipGlobal from "../../../../../components/TooltipGlobal";
import NavSwitch from "../../../../../components/NavSwitch";
import TokenAmountInput from "../../../../../components/TokenAmountInput";
// import { useWeb3Modal } from "@web3modal/wagmi/react";

const FUNCTION_NAME = "Redeem";
const WITHDRAW_PUBLIC_TYPE = "Public";
const WITHDRAW_PRIVATE_TYPE = "Private";

const Withdraw = ({
    config,
    nftId,
    tokenPriceData,
    token,
    summary,
    walletBalance,
    onDone,
    // onConnect
}) => {

    const {
        login,
    } = usePrivy();

    const DEFAULT_APRROVE_TEXT = APPROVE_TEXT(
        WISELENDING_TEXT
    );

    const DEFAULT_TEXT = `${FUNCTION_NAME} ${token.name}`;

    const { isConnected } = useAccount();
    // const { open } = useWeb3Modal();
    const { linkBase } = config;

    const [amount, setAmount] = useState("");

    const [buttonApproveText, setButtonApproveText] = useState(
        DEFAULT_APRROVE_TEXT
    );

    const [buttonText, setButtonText] = useState(
        DEFAULT_TEXT
    );

    const withdrawalBalances = useMemo(() => {
        if (_no(token.withdrawBalance) && _no(token.withdrawSolelyBalance)) {
            return [{
                type: WITHDRAW_PUBLIC_TYPE,
                amount: 0,
                value: 0
            }];
        }

        return [
            _notEmpty(token.withdrawBalance) && {
                type: WITHDRAW_PUBLIC_TYPE,
                amount: token.withdrawBalance,
                value: token.withdrawBalanceFormatted,
                display: token.suppliedFormatted,
            },
            _notEmpty(token.withdrawSolelyBalance) && {
                type: WITHDRAW_PRIVATE_TYPE,
                amount: token.withdrawSolelyBalance,
                value: token.withdrawSolelyBalanceFormatted,
                display: token.withdrawSolelyBalanceFormatted,
            }
        ].filter(Boolean);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token.withdrawBalance, token.withdrawSolelyBalance]);

    const [isLoading, setIsLoading] = useState(false);
    const [successfullWithdraw, setSuccessfullWithdraw] = useState();
    const [finishWithdraw, setFinishWithdraw] = useState(false);
    const [transactionHashLink, setTransactionHashLink] = useState("");
    const [withdrawBalance, setWithdrawBalance] = useState(withdrawalBalances[0]);

    const tokenPrice = tokenPriceData?.price || 0;
    const hasEnough = withdrawBalance.type === WITHDRAW_PUBLIC_TYPE
        ? token.pool >= withdrawBalance.amount
        : true;

    let withdrawLimit = hasEnough
        ? withdrawBalance.value
        : token.poolFormatted;

    let maxLimit = parseFloat(
        summary.limitFormatted
    );

    let summaryBorrowLimit = parseFloat(
        summary.borrowFormatted
    );

    /*
    let maxRemoveInUSD = computeUSDAmount(
        token.decimals,
        withdrawLimit,
        tokenPrice
    );
    */

    // let remainingBuffer = parseFloat(maxLimit) - summaryBorrowLimit;

    let maxNewBorrowUsed = summary.borrowFormatted
        / parseFloat(maxLimit)
        * 100;

    if (maxNewBorrowUsed > 95) {
        const delta = maxNewBorrowUsed - 95;
        withdrawLimit = withdrawLimit * (1 - delta / 100);
    }

    const convertedAmount = removeNonNumeric(
        amount || 0
    );

    const formattedAmount = formatMoneyDashed(
        amount
    );

    const pureAmountInUSD = computeUSDAmount(
        token.decimals,
        convertedAmount,
        tokenPrice
    );

    const amountInUSD = pureAmountInUSD * token.poolCollateralFactor;

    // let newLimit = parseFloat(summary.limitFormatted) - amountInUSD;

    const WITHDRAW_PART = `${WITHDRAW_SUCCESS} ${formattedAmount}`;
    const WITHDRAW_SUCCESS_MESSAGE = `${WITHDRAW_PART} ${token.name}`;

    const withdrawAmount = parseUnits(
        convertedAmount,
        token.decimals
    );

    const tokenApproval = useTokenApproval({
        config,
        nftId,
        token,
        amount: withdrawAmount,
    });

    const isFetchingData = _no(tokenPrice)
        || _no(token.withdrawBalanceFormatted)
        || _no(token.withdrawSolelyBalanceFormatted)
        || tokenApproval.isFetching;

    const isMax = parseFloat(convertedAmount) >= parseFloat(withdrawLimit) * SWING_PERCENT;
    const isAll = parseFloat(convertedAmount) === parseFloat(token.estimatedFormatted);

    const bareTotalPosition = (token.supplied ?? 0n)
        + (token.withdrawSolelyBalance ?? 0n);

    const totalPosition = formatUnits(
        bareTotalPosition,
        token.decimals
    );

    const [isApproved, setIsApproved] = useState(false);

    const preApproved = token.isAavePool && _no(finishWithdraw)
        ? isApproved
        : true;

    useEffect(() => {
        setIsApproved(
            tokenApproval.isApproved
        );
    }, [tokenApproval]);

    const showApproval = amount
        && _no(preApproved)
        && _no(isFetchingData);

    const withdrawToken = useWithdrawToken({
        config,
        nftId,
        token,
        isMax,
        isAll,
        hasEnough,
        showApproval,
        amount: withdrawAmount,
        isPrivate: withdrawBalance.type === WITHDRAW_PRIVATE_TYPE
    });

    const isDefault = buttonText.includes(FUNCTION_NAME)
        && buttonText.includes(token.name);

    const handleWithdraw = () => {

        if (buttonText === DONE_TEXT) {
            onDone && onDone();
            return;
        };

        if (buttonText === TRY_AGAIN_TEXT) {
            setFinishWithdraw(false);
            setTransactionHashLink("");
            setButtonText(
                DEFAULT_TEXT
            );
        };

        if (_no(isDefault)) return;

        withdrawToken.withdraw({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonText(
                    CONFIRM_TEXT
                );
            }
        });
    };

    useEffect(() => {

        if (withdrawToken.hasError && isLoading) {
            setIsLoading(false);
            setTransactionHashLink("");
            setButtonText(
                TRY_AGAIN_TEXT
            );
            handleError(
                withdrawToken.error
            );
        }

        if (withdrawToken.error && isLoading) {
            setIsLoading(false);
            setTransactionHashLink("");

            handleError(
                withdrawToken.error
            );

            const amountInDecimal = toPrecision(
                convertedAmount
            );

            const amountInFloat = parseFloat(
                removeNonNumeric(
                    amountInDecimal
                )
            );

            // @TODO: get rid of duplicate
            const textPrefix = convertedAmount > amountInFloat
                ? "~"
                : "";

            const amountText = _notEmpty(amountInFloat)
                ? `${textPrefix}${amountInDecimal} `
                : "";

            setButtonText(
                `${FUNCTION_NAME} ${amountText} ${token.name}`
            );
        }
        if (withdrawToken.isConfirming && isLoading) {
            setButtonText(WITHDRAWING_TEXT);
            setTransactionHashLink(
                `${linkBase}/tx/${withdrawToken.hash}`
            );
        }
        if (withdrawToken.isConfirmed && isLoading && !finishWithdraw) {

            setIsLoading(false);
            setFinishWithdraw(true);

            // const transactionLink = `${linkBase}/tx/${withdrawToken.hash}`;

            const message = withdrawToken.isConfirmed
                ? WITHDRAW_SUCCESS_MESSAGE
                : WITHDRAW_FAILED_MESSAGE;

            /*
            const toastContent = toastNotification.generateLinkContent(
                transactionLink,
                `${message} ${CLICK_TO_VIEW}`
            );
            */

            /*
            toastNotification.showToast(
                withdrawToken.isConfirmed,
                toastContent
            );
            */

            showToast(
                message,
                {
                    type: "success"
                }
            );

            setSuccessfullWithdraw(
                withdrawToken.isConfirmed
            );

            setButtonText(
                withdrawToken.isConfirmed
                    ? DONE_TEXT
                    : TRY_AGAIN_TEXT
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [withdrawToken]);

    const handleApprove = () => {

        if (buttonApproveText !== DEFAULT_APRROVE_TEXT) {
            return;
        }

        tokenApproval.approve({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonApproveText(
                    CONFIRM_TEXT
                );
            }
        });
    };

    useEffect(() => {
        if (tokenApproval.error && isLoading) {
            setIsLoading(false);
            setButtonApproveText(
                DEFAULT_APRROVE_TEXT
            );
            handleError(
                tokenApproval.error
            );
        }

        if (tokenApproval.isConfirming && isLoading) {
            setButtonApproveText(
                APPROVING_TEXT
            );
        }

        if (tokenApproval.isConfirmed && buttonApproveText === APPROVING_TEXT) {
            setIsLoading(false);
            setButtonApproveText(
                DONE_TEXT
            );

            const message = `Approved - You can redeem tokens now`;

            showToast(message, {
                type: "success",
            });

            setIsApproved(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenApproval]);

    const handleError = (err) => {
        const error = err?.message || err;
        showToast(
            error,
            {
                type: "warning"
            }
        );
    };

    const handleQuickSelect = (option) => {
        let amount = option === "max" && parseFloat(withdrawLimit) > 0
            ? withdrawLimit
            : "";

        amount = _checkThreshold(
            amount
        );

        setAmount(
            amount
        );
    };

    useEffect(() => {
        if (isDefault) {
            const amountInDecimal = toPrecision(convertedAmount);
            const amountInFloat = parseFloat(
                removeNonNumeric(
                    amountInDecimal
                )
            );
            const textPrefix = convertedAmount > amountInFloat
                ? "~"
                : "";

            const amountText = _notEmpty(amountInFloat)
                ? `${textPrefix}${amountInDecimal} `
                : "";

            setButtonText(
                `${FUNCTION_NAME} ${amountText} ${token.name}`
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [convertedAmount]);

    const {
        estimatedGasUSD,
        estimatesGasFormatted
    } = withdrawToken;

    const isTokenInsufficient = parseFloat(estimatesGasFormatted) > walletBalance
        && _no(isLoading)
        && _no(finishWithdraw);

    const isInsufficient = parseFloat(convertedAmount) > parseFloat(withdrawLimit)
        && _no(isLoading)
        && _no(finishWithdraw);

    const disableApprove = isInsufficient
        || isTokenInsufficient
        || isLoading;

    const disabledSubmit = _no(amount)
        || parseFloat(amount) === 0.00
        || _no(preApproved)
        || isInsufficient
        || isTokenInsufficient
        || isLoading
        || isFetchingData;

    let withdrawButtonText = buttonText;

    if (buttonText !== DONE_TEXT) {
        if (_no(amount)) {
            withdrawButtonText = `Enter ${token.name} amount`;
        } else if (isInsufficient) {
            withdrawButtonText = `Redeem Limit Exceeded`;
        } else if (isTokenInsufficient) {
            withdrawButtonText = INSUFFICIENT_COST;
        }
    }

    const fundsNav = withdrawalBalances.map(item => {
        return {
            title: `Your ${item.type} Funds`,
            counter: `${formatMoneyDashed(item.display)} ${token.name}`,
            onClick: () => {
                setWithdrawBalance(item);
            }
        };
    });

    /*
    let borrowUsed = summaryBorrowLimit
        / maxLimit
        * 100;

    let borrowUsedInUSD = summaryBorrowLimit
    let newLimit = parseFloat(summary.borrowFormatted) - parseFloat(amountInUSD);
    */

    /*
    let newBorrowUsed = (summaryBorrowLimit + parseFloat(amountInUSD))
        / parseFloat(maxLimit)
        * 100;
    */

    let newBorrowUsedInUSD = maxLimit - (parseFloat(amountInUSD));

    let newBorrowUsedTrue = (summaryBorrowLimit)
        / newBorrowUsedInUSD
        * 100;

    return (
        <>
            <div>
                {finishWithdraw &&
                    <div className={styles.successWrapper}>
                        <SuccessPanel />
                        {/* <div className={cn(
                        styles.icon,
                        {
                            [styles.failed]: _no(successfullWithdraw)
                        }
                    )}>
                        <Icon
                            name={successfullWithdraw
                                ? "check-circle"
                                : "close-circle"
                            }
                            size="50"
                        />
                    </div> */}
                        <div className={styles.text}>
                            {successfullWithdraw
                                ? WITHDRAW_SUCCESS_MESSAGE
                                : WITHDRAW_FAILED_MESSAGE
                            }
                        </div>
                    </div>
                }
                {_no(finishWithdraw) &&
                    <>
                        <div className={cn("title-purple", styles.title)}>
                            {FUNCTION_NAME} {token.name}
                        </div>
                        <div className={styles.wrapperTitle}>
                            {FUNCTION_NAME} Amount
                            {/*<Icon name="info" size="14" />*/}
                        </div>
                        <TokenAmountInput
                            token={token}
                            disabled={isLoading}>
                            <TokenAmountInput.Input
                                amount={amount}
                                onChange={setAmount}
                                showClearButton={amount && _no(isLoading)}
                                onClearButtonClick={() => handleQuickSelect("clear")}
                            />
                            <TokenAmountInput.Details
                                amountInUsd={pureAmountInUSD}
                                balance={withdrawLimit}
                                onClick={() => handleQuickSelect("max")}>
                                Max {FUNCTION_NAME}
                            </TokenAmountInput.Details>
                        </TokenAmountInput>
                        {fundsNav.length > 1 && <NavSwitch nav={fundsNav} />}
                        <div className={styles.wrapper}>
                            <div className={styles.row}>
                                <div className={cn(styles.col, styles.item)}>
                                    <div className={styles.label}>Your Funds</div>
                                    <div className={styles.value}>
                                        {toPrecision(totalPosition)} {token.name}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={cn(styles.col, styles.item)}>
                                    <div className={styles.label}>Available Funds</div>
                                    <div className={styles.value}>
                                        {toPrecision(
                                            withdrawBalance.type === WITHDRAW_PUBLIC_TYPE
                                                ? token.poolFormatted
                                                : withdrawBalance.value
                                        )} {token.name}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.wrapperTitle}>
                            {FUNCTION_NAME} Impact
                        </div>
                        <div className={styles.wrapper}>
                            <div className={styles.row}>
                                <div className={cn(styles.col, styles.item)}>
                                    <div className={styles.label}>Borrow Balance</div>
                                    <div className={styles.value}>
                                        {formatMoney(
                                            toZeroIfBelowOne(summary.borrowFormatted),
                                            true
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={cn(
                                styles.row,
                                styles.mbAPY
                            )}>
                                <div className={cn(styles.col, styles.item)}>
                                    <div className={styles.label}>Borrow Limit Change</div>
                                    <div className={cn(
                                        styles.value,
                                        styles.newLimit
                                    )}>
                                        {formatMoney(
                                            toZeroIfBelowOne(summary.limitFormatted),
                                            true
                                        )}
                                        <Icon name="arrow-right" size="20" />
                                        {formatMoney(
                                            toZeroIfBelowOne(newBorrowUsedInUSD),
                                            true
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={cn(
                                styles.row,
                                styles.borrowLine,
                                styles.mbAPY
                            )}>
                                <LinearBar
                                    height={7}
                                    width={newBorrowUsedTrue}
                                    backColor="#31363C"
                                    fill="#00b0aa"
                                    limit={summary.percentageCap}
                                    limitColor="#FF6A55"
                                />
                            </div>
                        </div>
                        <TransactionCost
                            nftId={nftId}
                            isAave={token.isAavePool}
                            showApproval={showApproval}
                            value={showApproval ? tokenApproval.estimatedGasUSD : estimatedGasUSD}
                            amount={amount}
                        />
                    </>
                }
                <div className={styles.foot}>
                    {isConnected &&
                        <>
                            {showApproval && (
                                <button
                                    className={cn(
                                        "button-small",
                                        styles.button,
                                        styles.approve,
                                        {
                                            [styles.active]: amount,
                                            disabled: disableApprove
                                        }
                                    )}
                                    onClick={handleApprove}
                                >
                                    {buttonApproveText === APPROVING_TEXT && <Loader className={styles.loader} />}
                                    <span>{buttonApproveText}</span>
                                    {/* <Icon name="info" size="20" /> */}
                                </button>
                            )}
                            <button
                                className={cn(
                                    "button-small",
                                    styles.button,
                                    {
                                        disabled: disabledSubmit && buttonText !== DONE_TEXT,
                                        "button-stroke": showApproval,
                                        [styles.stroke]: showApproval
                                    }
                                )}
                                onClick={handleWithdraw}
                            >
                                {(buttonText === WITHDRAWING_TEXT || isFetchingData)
                                    && <Loader className={styles.loader} />
                                }
                                <span>
                                    {isFetchingData
                                        ? FETCHING_DATA
                                        : withdrawButtonText
                                    }
                                </span>
                            </button>
                            {transactionHashLink &&
                                <a
                                    className={cn(
                                        "button-small",
                                        "button-stroke",
                                        "button-transparent",
                                        styles.button
                                    )}
                                    href={transactionHashLink}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    View Transaction Details
                                </a>
                            }
                        </>
                    }
                    {_no(isConnected) &&
                        <button
                            className={cn(
                                "button-small",
                                styles.button
                            )}
                            onClick={() => {
                                // open()
                                login();
                            }}
                        >
                            <Icon name="profile-circle" size="20" />
                            <span>Connect Account</span>
                        </button>
                    }
                </div >
            </div >
            <TooltipGlobal />
        </>
    );
};

export default Withdraw;
