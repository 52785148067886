import cn from "classnames";
import React, {
    useEffect,
    // useEffect,
    useMemo,
    useState
} from "react";
import { fromHex } from "viem";
import { Range, getTrackBackground } from "react-range";
import { useAccount } from "wagmi";
import { useSelector } from "react-redux";
import { usePrivy } from '@privy-io/react-auth';
import TooltipGlobal from "../../../TooltipGlobal";
import useConfig from "../../../../customHooks/useConfig";
import {
    _intPrefix,
    _no,
    _notEmpty,
    addPendleLiquiditySingleToken,
    computeUSDAmount,
    formatDecimals,
    formatMoney,
    formatMoneyDashed,
    formatUSD,
    // removeBigInt,
    removeNonNumeric,
    showToast
} from "../../../../utils";
import { useAPYEstimate } from "../../../../customHooks/useAPYEstimate";
import { ALL_SUPPORTED_POOLS, POOL_NAMES, WETHToken, getSupportedTokens } from "../../../../ethers/tokens";
import {
    OPENING_TEXT,
    OPEN_FAILED_MESSAGE,
    OPEN_SUCCESS,
    CONFIRM_TEXT,
    DEFAULT_DECIMALS,
    APPROVE_IN_WALLET_TEXT,
    DONE_TEXT,
    APPROVE_TEXT,
    RESET_APPROVAL_TEXT,
    // MINIMUM_BORROW_ETH,
    APPROVING_TEXT,
    INSUFFICIENT_COST,
    NEED_RESET_TOKENS,
    NATIVE_TOKENS,
    YEAR_DAYS,
} from "../../../../utils/config";
import Switch from "../../../Switch";
import TokenAmountInput from "../../../TokenAmountInput";
import Tooltip from "../../../Tooltip";
import styles from "./OpenFarmDetails.module.sass";
import Parameter from "../Parameter";
import Product from "../Product";
import { useOpenFarmPosition } from "../../../../customHooks/useOpenFarmPosition";
import { formatUnits, parseUnits } from "viem";
import SuccessPanel from "../../../Modal/SuccessPanel";
import Icon from "../../../Icon";
import Loader from "../../../Loader";
import RiskLevel from "../RiskLevel";
import { useTokenAllowance } from "../../../../customHooks/useTokenAllowance";
import NavSwitch from "../../../NavSwitch";
import { getFarmAPR } from "../../../../ethers/farms";
import dayjs from 'dayjs';
import { useAPYPredict } from "../../../../customHooks/useAPYPredict";
import Scrollbar from "../../../Scrollbar";
// import { useFarmingContext } from "../../../../screens/Farming/context";

const STEP = 0.01;
const MIN = 1;
const MAX = 14;

const OpenFarmDetails = ({
    item,
    token,
    onDone,
    // doRefetch,
    tokenBalance,
    availableFunds,
    // tokenUtilization,
    onClose,
}) => {

    // const farmingContext = useFarmingContext();
    const { isConnected, address } = useAccount();
    // const { open } = useWeb3Modal();
    const config = useConfig();
    const { linkBase, chainId } = config;

    const needReset = NEED_RESET_TOKENS.includes(
        token.name
    ) && chainId === 1;

    const {
        login,
    } = usePrivy();

    const supportedTokens = useMemo(
        () => {
            return getSupportedTokens(
                chainId,
                ALL_SUPPORTED_POOLS
            );
        },
        [chainId]
    );

    const DEFAULT_APPRROVE_TEXT = APPROVE_TEXT(
        token.name
    );

    const RESET_APPROVE_TEXT = RESET_APPROVAL_TEXT(
        token.name
    );

    const getDefaultApprovalText = (allowance) => {
        if (needReset) {
            return allowance === 0n
                ? DEFAULT_APPRROVE_TEXT
                : RESET_APPROVE_TEXT;
        }

        return DEFAULT_APPRROVE_TEXT;
    };

    const { pricingFeeds, walletBalance, APY } = useSelector(
        (state) => state.token
    );

    const APR = getFarmAPR(item, APY);

    const tokenPriceData = pricingFeeds.find(
        item => item.name === token.name
    );

    const WETH = {
        ...token,
        ...WETHToken
    };

    const [leverage, setLeverage] = useState(3);
    const [amount, setAmount] = useState("");
    const [selectedToken, setSelectedToken] = useState(token);
    const [finalAPY, setFinalAPY] = useState();
    // const [initiateSubmit, setInitiateSubmit] = useState(false);
    const [max, setMax] = useState(token.balanceFormatted);
    const [useWETHBalance, setUseWETHBalance] = useState(false);

    const DEFAULT_TEXT = "Confirm Position";

    const [buttonApproveText, setButtonApproveText] = useState(
        DEFAULT_APPRROVE_TEXT
    );

    const [buttonText, setButtonText] = useState(
        DEFAULT_TEXT
    );

    const [isLoading, setIsLoading] = useState(false);
    const [successfullOpen, setSuccessfullOpen] = useState();
    const [finishOpen, setFinishOpen] = useState(false);
    const [transactionHashLink, setTransactionHashLink] = useState("");
    const [allowance, setAllowance] = useState(0);

    useEffect(() => {
        setButtonApproveText(
            getDefaultApprovalText(
                allowance
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowance, amount]);

    useEffect(() => {
        setSelectedToken(token);
        setMax(token.balanceFormatted);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token.name]);

    const convertedAmount = removeNonNumeric(
        amount || 0
    );

    const convertedLeverage = parseInt(
        leverage + 1
    );

    const pureAmountInUSD = computeUSDAmount(
        DEFAULT_DECIMALS,
        convertedAmount,
        tokenPriceData?.price
    );

    const realBalance = formatUnits(
        (parseInt(tokenBalance.balance)).toString(),
        tokenBalance.decimals
    );

    const formattedWETHBalance = realBalance;

    const apyEstimate = useAPYEstimate({
        config,
        amount: convertedAmount,
        leverage: convertedLeverage,
        token: selectedToken,
        apr: APR
    });

    /*
    const APYPrefix = _intPrefix(
        apyEstimate.isPositive
    );
    */

    const positionAmount = parseUnits(
        convertedAmount.toString(),
        selectedToken.decimals
    );

    const positionLeverage = parseUnits(
        (convertedLeverage).toString(),
        DEFAULT_DECIMALS
    );

    const minimumBorrow = item.tokens[token.name].minimumBorrow;

    const isReset = buttonApproveText === RESET_APPROVE_TEXT;

    const tokenAllowance = useTokenAllowance({
        config,
        isReset,
        token: selectedToken,
        userAccount: address,
        amount: positionAmount,
        customContract: config[item.contract]
    });

    useEffect(() => {
        if (tokenAllowance.allowance) {
            setAllowance(
                tokenAllowance.allowance
            );

            if (tokenAllowance.allowance >= positionAmount) {
                setPreApproved(true);
            }

            if (tokenAllowance.allowance < positionAmount) {
                setPreApproved(false);
            }
        }
    }, [tokenAllowance.allowance, positionAmount]);

    const isETH = NATIVE_TOKENS.includes(
        selectedToken.name
    );

    const preApprovedDefault = _no(isETH) && _no(finishOpen)
        ? allowance && allowance >= positionAmount
        : true;

    const [preApproved, setPreApproved] = useState(
        preApprovedDefault
    );

    let showApproval = amount
        && _no(isETH)
        && _no(preApproved);

    const farmContract = config[
        item.contract
    ];

    const openFarmPosition = useOpenFarmPosition({
        config,
        showApproval,
        leverage: positionLeverage,
        amount: positionAmount,
        token: selectedToken,
        farmContract,
        farmItem: item,
        isAave: item.tokens[token.name].isAave ?? false,
    });

    const formattedAmount = formatMoneyDashed(
        amount
    );

    const OPEN_SUCCESS_MESSAGE = `${OPEN_SUCCESS} ${formattedAmount} ${selectedToken.name}
        position with ${convertedLeverage}X leverage loop level`;

    const handleError = (err) => {

        const error = err?.message || err;
        showToast(
            error,
            {
                type: "error"
            }
        )
    };

    const handleApprove = () => {
        tokenAllowance.approve({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonApproveText(
                    APPROVE_IN_WALLET_TEXT
                );
            },
        });
    };

    useEffect(() => {

        if (tokenAllowance.isPending) {
            return;
        }

        if (tokenAllowance.hasError && isLoading && tokenAllowance.isConfirming) {
            setIsLoading(false);
            handleError(
                tokenAllowance.hasError
            );
            setButtonApproveText(
                getDefaultApprovalText(allowance)
            );
        }

        if (tokenAllowance.error && isLoading)  {

            setIsLoading(false);
            handleError(
                tokenAllowance.error
            );
            // setTransactionHash("");
            setButtonApproveText(
                DEFAULT_APPRROVE_TEXT
            );
        }

        if (tokenAllowance.isConfirming && isLoading) {
            setButtonApproveText(
                APPROVING_TEXT
            );
        }

        if (tokenAllowance.isConfirmed && isLoading && buttonApproveText === APPROVING_TEXT) {

            setButtonApproveText(
                DEFAULT_APPRROVE_TEXT
            );

            setIsLoading(false);

            const { logs } = tokenAllowance.transactionData;

            const spendingCap = fromHex(
                logs[0].data,
                "bigint"
            );

            setAllowance(
                spendingCap
            );

            if (spendingCap >= positionAmount) {
                setPreApproved(true);
            }

            const spendingCapAmount = formatUnits(
                spendingCap,
                token.decimals
            );

            const formattedSpendingCapAmount = formatMoneyDashed(
                spendingCapAmount
            );

            const successMessage = spendingCap === 0n
                ? `Successfully reset ${token.name} approval`
                : `Approved ${formattedSpendingCapAmount} ${token.name} tokens`;

            showToast(successMessage, {
                type: "success",
            });

            setButtonApproveText(
                DEFAULT_APPRROVE_TEXT
            );

            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenAllowance]);

    const handleQuickSelect = (option) => {

        const aboveZero = parseFloat(max) > 0;
        const amount = option === "max" && aboveZero
            ? formatDecimals(max)
            : "";

        setAmount(
            amount
        );
    };

    const handleSwitch = () => {
        setMax(
            useWETHBalance
                ? walletBalance
                : formattedWETHBalance
        );
        setSelectedToken(
            useWETHBalance
                ? token
                : WETH
        );
        setUseWETHBalance(
            !useWETHBalance
        );
    };


    const borrowAmount = (parseFloat(convertedAmount) || 0)
        * (convertedLeverage - 1);

    const totalAmount = (parseFloat(convertedAmount)
        * (convertedLeverage)).toFixed(10);

    const maturityDaysDuration = dayjs(item.maturityDate).diff(
        new Date(),
        "d"
    );

    const formattedNetAPY = _no(apyEstimate.isPositive) && apyEstimate.netAPY > 0
        ? apyEstimate.netAPY * -1
        : apyEstimate.netAPY * +1;

    let estimatedRewards = (totalAmount
        * (formattedNetAPY / 100));

    estimatedRewards = (
        estimatedRewards / YEAR_DAYS
    ) * maturityDaysDuration;

    const openingFee = totalAmount
        * 0.003;

    const getPriceDescription = (price) => {
        const value =
            parseFloat(price) > 0
                && _no([POOL_NAMES.USDC, POOL_NAMES.USDT].includes(token.poolName))
                ? computeUSDAmount(
                    tokenPriceData?.decimals,
                    removeNonNumeric(price || 0),
                    tokenPriceData?.price
                )
                : null;

        return _notEmpty(parseFloat(value))
            ? ` (${formatUSD(value)})`
            : null;
    };

    estimatedRewards = estimatedRewards.toFixed(2);

    const parsedBorrowAmount = parseUnits(
        borrowAmount.toString(),
        token.decimals
    );

    const predictedAPY = useAPYPredict({
        config,
        token,
        amount: parsedBorrowAmount
    });

    const BorrowAPYPrefix = _intPrefix(
        predictedAPY.borrowPredictedAPY
    );

    const getAmountDisplayValue = (value) => {
        return formatMoney(value, false, false, 2);
    };

    const checkParameters = (param) => {
        switch (param.title) {
            case "Available Funds":
                param.content = `${getAmountDisplayValue(availableFunds)} ${token.name}`;
                param.contentDescription = getPriceDescription(availableFunds);
                break;
            case "Borrow Amount":
                param.content = `${getAmountDisplayValue(borrowAmount)} ${token.name}`;
                param.contentDescription = getPriceDescription(borrowAmount);
                break;
            case "Estimated APY":
                param.content = `${formatDecimals(formattedNetAPY)}%`;
                break;
            case "Estimated Rewards":
                param.content = `${getAmountDisplayValue(estimatedRewards)} ${token.name}`;
                param.contentDescription = getPriceDescription(estimatedRewards);
                break;
            case "Minimum Borrow":
                param.content = `${formatDecimals(minimumBorrow)} ${token.name}`;
                param.contentDescription = getPriceDescription(minimumBorrow);
                break;
            case "Position Amount":
                param.content = `${getAmountDisplayValue(totalAmount)} ${token.name}`;
                param.contentDescription = getPriceDescription(totalAmount);
                break;
            default:
                break;
        }

        param.data = param.data?.map((data) => {
            if (data.title === "Borrow APY") {
                data.content = `${BorrowAPYPrefix}${formatDecimals(predictedAPY.borrowPredictedAPY)}%`;
            } else if (data.title === "Source APY") {
                data.content = `${formatDecimals(apyEstimate.supplyAPY)}%`;
            }
            return data;
        });

        return param;
    };

    const parameters = item.parameters.map((param) => {

        if (param.content) {
            return {
                ...param,
                ...checkParameters(param)
            };
        } else if (param.title === "Opening Transaction Cost") {
            return {
                ...param,
                price: openFarmPosition?.estimatesGasFormatted
                    ? `${parseFloat(openFarmPosition?.estimatedGasUSD).toFixed(2)} ${token.name}`
                    : `0.00 ${token.name}`,
                priceDescription: getPriceDescription(openFarmPosition?.estimatedGasUSD)
            };
        } else if (param.title === "Position Opening Fee") {
            const openingFeeValue = openFarmPosition?.estimatesGasFormatted
                ? openingFee
                : 1;
            const formattedPrice = `${getAmountDisplayValue(openingFeeValue)} ${token.name}`;

            return {
                ...param,
                price: formattedPrice,
                priceDescription: getPriceDescription(openingFeeValue)
            };
        }

        return param;
    });

    const farmDetails = useMemo(() => {
        return [
            {
                title: "Position Amount",
                content: `${formatMoney(convertedAmount)} ${token.name}`,
            },
            {
                title: "Leverage Loop Level",
                content: `${convertedLeverage}x`,
            },
            {
                title: "Borrow Amount",
                content: `${formatMoney(borrowAmount)} ${token.name}`,
            },
            {
                title: "Estimated APY",
                tooltip: "NET APY",
                content: finalAPY,
                downloadedStatus: true,
                downloadedValue: true,
            },
            {
                title: "Estimated Rewards",
                tooltip: "Current Net Interest",
                content: `${formatMoney(estimatedRewards)} ${token.name}`
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalAPY]);

    const fetchCallData = async () => {
        if (convertedAmount > 0 && item.fetchCallData) {

            const leverageAmount = (convertedLeverage * convertedAmount).toFixed(10);

            const amount = parseUnits(
                leverageAmount.toString(),
                selectedToken.decimals
            );

            const pendleAPI = await addPendleLiquiditySingleToken({
                chainId: item.pendleAPYChainId,
                receiverAddress: farmContract?.address,
                marketAddress: item.pendleAPYMarket,
                token: selectedToken.address,
                amount,
                slippage: 0.5 / 100,
            });

            return pendleAPI?.transaction?.data;
        } else {
            return false;
        }
    };

    const handleConfirm = async () => {

        if (buttonText === DONE_TEXT) {
            onDone && onDone();
            return;
        };

        const callData = await fetchCallData();
        if (callData) {
            openFarmPosition.openPosition({
                callData,
                onConfirmation: () => {
                    setFinalAPY(`${formatDecimals(apyEstimate.netAPY)}%`);
                    setIsLoading(true);
                    setButtonText(
                        CONFIRM_TEXT
                    );
                },
            });
        } else {
            showToast(
                "Error fetching data from Pendle API. Try later...",
                {
                    type: "error"
                }
            );
            return;
        }
    };

    useEffect(() => {

        if (openFarmPosition.isPending) {
            return;
        }

        if (openFarmPosition.hasError && isLoading && openFarmPosition.isConfirming) {
            setIsLoading(false);
            handleError(
                openFarmPosition.hasError
            );
            setTransactionHashLink("");
            setButtonText(
                DEFAULT_TEXT
            );
        }

        if (openFarmPosition.error && isLoading) {
            setIsLoading(false);
            handleError(
                openFarmPosition.error
            );
            setTransactionHashLink("");
            setButtonText(
                DEFAULT_TEXT
            );
        }

        if (openFarmPosition.isConfirming && isLoading) {
            setButtonText(
                OPENING_TEXT
            );
            setTransactionHashLink(
                `${linkBase}/tx/${openFarmPosition.hash}`
            );
        }

        if (openFarmPosition.isConfirmed && isLoading && buttonText === OPENING_TEXT) {
            setIsLoading(false);
            setButtonText(
                DONE_TEXT
            );
            setSuccessfullOpen(true);
            setFinishOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openFarmPosition]);

    const {
        estimatesGasFormatted
    } = openFarmPosition;

    const ethTokenAmount = _no(isETH)
        ? 0
        : convertedAmount;

    const totalTokenAmount = parseFloat(ethTokenAmount) + parseFloat(estimatesGasFormatted);

    const isTokenInsufficient = totalTokenAmount > walletBalance
        && _no(isLoading)
        && _no(finishOpen);

    const isInsufficient = parseFloat(convertedAmount) > parseFloat(max) && _no(finishOpen);
    const exceedFunds = _no(isInsufficient) && borrowAmount > availableFunds && _no(finishOpen);
    const isBelowMinimum = parseFloat(borrowAmount) < parseFloat(minimumBorrow) && _no(finishOpen);

    const disableApprove = isInsufficient
        || isLoading
        || isTokenInsufficient
        || isBelowMinimum
        || exceedFunds;

    const disableConfirm = _no(amount)
        || isInsufficient
        || isLoading
        || exceedFunds
        || isBelowMinimum
        || isTokenInsufficient;

    let confirmButtonText = buttonText;
    let approveButtonText = buttonApproveText;

    if (_no(amount)) {
        // confirmButtonText = `Enter ${selectedToken.name} amount`;
        confirmButtonText = `Enter Initial Amount`;
        approveButtonText = `Enter Initial Amount`;
    } else if (isInsufficient) {
        confirmButtonText = `Insufficient ${selectedToken.name} Balance`;
        approveButtonText = `Insufficient ${selectedToken.name} Balance`;
    } else if (isTokenInsufficient) {
        confirmButtonText = INSUFFICIENT_COST;
        approveButtonText = INSUFFICIENT_COST;
    } else if (exceedFunds) {
        confirmButtonText = `Exceeds Available Funds`;
        approveButtonText = `Exceeds Available Funds`;
    } else if (isBelowMinimum) {
        confirmButtonText = `Minimum Borrow is ${getAmountDisplayValue(minimumBorrow)} ${token.name}`;
        approveButtonText = `Minimum Borrow is ${getAmountDisplayValue(minimumBorrow)} ${token.name}`;
    }

    const handleSwitchToken = (powerFarmId) => {
        onClose();
        const rowClickEvent = new CustomEvent("farmRowClick" + powerFarmId, {
            detail: {
                key: powerFarmId,
            },
        });

        window.dispatchEvent(
            rowClickEvent
        );
    };

    const tokensNav = Object.keys(item.tokens).length > 1
        && Object.entries(item.tokens).map(
            ([item, value]) => {
                const tokenData = supportedTokens.find(
                    token => token.name === item
                        && token.isAavePool === (value.isAave ?? false)
                );

                const hasNoPosition = true;

                let description = token.name === item
                    ? "Active"
                    : "Select";

                return {
                    title: `${tokenData.name}`,
                    image: tokenData.icon,
                    counter: hasNoPosition || _no(isConnected)
                        ? description
                        : "Ongoing",
                    onClick: () => {
                        handleSwitchToken(value.powerFarmId);
                    },
                    value: item,
                    // disabled: description !== "Active"
                    disabled: _no(hasNoPosition)
                };
            });

    return (
        <>
            <div className={styles.details}>
                <div className={cn("title-green", styles.title)}>
                    Open Position
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <Scrollbar className={styles.scroll}>
                            <Product className={styles.product} item={item} />
                            <div className={styles.parameters}>
                                {finishOpen ?
                                    <>
                                        {farmDetails.map((x, index) => (
                                            <Parameter item={x} key={index} />
                                        ))}
                                    </>
                                    :
                                    <>
                                        {parameters.map((x, index) => (
                                            <Parameter item={x} key={index} />
                                        ))}
                                    </>
                                }
                            </div>
                        </Scrollbar>
                    </div>
                    <div className={styles.col} style={{ paddingTop: "0px" }}>
                        <div className={styles.group}>
                            {finishOpen &&
                                <div className={styles.successWrapper}>
                                    <SuccessPanel />
                                    <div style={{ visibility: "hidden" }}>12</div>
                                    <div className={styles.text}>
                                        {successfullOpen
                                            ? OPEN_SUCCESS_MESSAGE
                                            : OPEN_FAILED_MESSAGE
                                        }
                                    </div>
                                </div>
                            }
                            {_no(finishOpen) &&
                                <>

                                    <div className={styles.box}>
                                        <div className={styles.info}>
                                            Position Settings
                                        </div>
                                        {/*<ul className={styles.list}>
                                            {approvals.map((x, index) => (
                                                <li key={index}>{x}</li>
                                            ))}
                                        </ul>*/}
                                        <div>
                                            <div className={styles.wrapperTitle}>
                                                <div>
                                                    Initial Amount
                                                </div>
                                            </div>
                                            <TokenAmountInput
                                                token={selectedToken}
                                                disabled={isLoading}
                                            >
                                                <TokenAmountInput.Input
                                                    amount={amount}
                                                    onChange={setAmount}
                                                    showClearButton={amount && _no(isLoading)}
                                                    onClearButtonClick={() => handleQuickSelect("clear")}
                                                />
                                                <TokenAmountInput.Details
                                                    amountInUsd={pureAmountInUSD}
                                                    balance={max}
                                                    onClick={() => handleQuickSelect("max")}>
                                                    Your Balance
                                                </TokenAmountInput.Details>
                                            </TokenAmountInput>
                                            {item.tokens[token.name].includeWETH ? (
                                                <>
                                                    <div className={styles.wrapperTitle}>
                                                        <div>
                                                            Borrowing Asset
                                                        </div>
                                                    </div>
                                                    <div className={styles.wrapper}>
                                                        <div className={cn(
                                                            styles.wrapperRow,
                                                            styles.wrapperItem
                                                            // styles.labelDisabled
                                                        )}>
                                                            <div className={cn(
                                                                styles.label
                                                            )}>
                                                                Use WETH Balance
                                                                <Tooltip
                                                                    className={styles.tooltip}
                                                                    title={`You have ${formattedWETHBalance} WETH available.`}
                                                                    icon="info-stroke"
                                                                    place="right"
                                                                />
                                                            </div>
                                                            <div className={styles.value}>
                                                                <Switch
                                                                    // disabled={true}
                                                                    className={styles.switch}
                                                                    value={useWETHBalance}
                                                                    onChange={handleSwitch}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className={styles.wrapperTitle}>
                                                        <div>
                                                            Borrowing Asset
                                                        </div>
                                                    </div>
                                                    {tokensNav.length > 1 &&
                                                        <NavSwitch nav={tokensNav} selectedValue={token.name} />}
                                                </>
                                            )}
                                            <div className={styles.wrapperTitle}>
                                                <div>
                                                    Leverage Loop Level
                                                </div>
                                            </div>
                                            <div className={styles.wrapper}>
                                                <div className={cn(
                                                    styles.wrapperRow
                                                )}>
                                                    <div className={cn(
                                                        styles.wrapperItem,
                                                        styles.range
                                                    )}>
                                                        <Range
                                                            values={[leverage]}
                                                            step={STEP}
                                                            min={MIN}
                                                            max={MAX}
                                                            onChange={(values) => {
                                                                setLeverage(values[0]);
                                                            }}
                                                            disabled={isLoading}
                                                            renderTrack={({ props, children }) => (
                                                                <div
                                                                    onMouseDown={props.onMouseDown}
                                                                    onTouchStart={props.onTouchStart}
                                                                    style={{
                                                                        ...props.style,
                                                                        width: "95%",
                                                                        height: "26px",
                                                                        paddingTop: 23,
                                                                        marginLeft: "7px"
                                                                    }}
                                                                >
                                                                    <div
                                                                        ref={props.ref}
                                                                        style={{
                                                                            height: "8px",
                                                                            width: "100%",
                                                                            borderRadius: "20px",
                                                                            background: getTrackBackground({
                                                                                values: [leverage],
                                                                                colors: [
                                                                                    "#00b0aa",
                                                                                    "#EFEFEF"
                                                                                ],
                                                                                min: MIN,
                                                                                max: MAX,
                                                                            }),
                                                                            alignSelf: "center",
                                                                        }}
                                                                    >
                                                                        {children}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            renderThumb={({ index, props, isDragged }) => (
                                                                <div
                                                                    {...props}
                                                                    style={{
                                                                        ...props.style,
                                                                        height: "24px",
                                                                        width: "24px",
                                                                        borderRadius: "50%",
                                                                        backgroundColor: "#FFF",
                                                                        border: "4px solid #00b0aa",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        boxShadow: "inset 0px 2px 2px #FFFFFF",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            position: "absolute",
                                                                            bottom: "calc(100% + 5px)",
                                                                            color: "#fff",
                                                                            fontWeight: "600",
                                                                            fontSize: "14px",
                                                                            lineHeight: "18px",
                                                                            fontFamily: "Inter",
                                                                            padding: "4px 8px",
                                                                            borderRadius: "8px",
                                                                            marginBottom: "5px",
                                                                            backgroundColor: "#272B30",
                                                                        }}
                                                                    >
                                                                        {convertedLeverage}x
                                                                    </div>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {<RiskLevel
                                        leverage={leverage}
                                        optimalLeverageMul={apyEstimate.optimalLeverageMul}
                                        optimalLeverageLoop={apyEstimate.optimalLeverageLoop}
                                        netAPY={formattedNetAPY}
                                        sourceAPY={apyEstimate.supplyAPY}
                                        borrowAPY={predictedAPY.borrowPredictedAPY}
                                    />}
                                </>
                            }
                            <div className={styles.btnsWrapper}>
                                {isConnected && (
                                    <>
                                        <div className={styles.btns}>
                                            { showApproval ? (
                                                <button className={cn(
                                                    "button",
                                                    styles.button,
                                                    styles.full,
                                                    {
                                                        disabled: disableApprove,
                                                        [styles.disabled]: disableApprove
                                                    }
                                                )}
                                                    onClick={handleApprove}
                                                >
                                                    {buttonApproveText === APPROVING_TEXT && <Loader className={styles.loader} />}
                                                    <span>{approveButtonText}</span>
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={handleConfirm}
                                                    className={cn(
                                                        "button",
                                                        styles.button,
                                                        styles.full,
                                                        {
                                                            disabled: disableConfirm,
                                                            [styles.disabled]: disableConfirm
                                                        })}>
                                                    {(buttonText === OPENING_TEXT)
                                                        && <Loader className={styles.loader} />}
                                                    <span>{confirmButtonText}</span>
                                                </button>
                                            )
                                            }
                                        </div>
                                        {transactionHashLink && finishOpen &&
                                            <a
                                                className={cn(
                                                    "button",
                                                    "button-stroke",
                                                    styles.button,
                                                    styles.transaction
                                                )}
                                                href={transactionHashLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                View Transaction Details
                                            </a>
                                        }
                                    </>
                                )}
                                {_no(isConnected) &&
                                    <div className={styles.btns}>
                                        <button
                                            className={cn(
                                                "button",
                                                styles.button,
                                                styles.full
                                            )}
                                            onClick={() => {
                                                // onClose();
                                                login();
                                            }}
                                        >
                                            <Icon name="profile-circle" size="20" />
                                            <span>Connect Account</span>
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <TooltipGlobal />
        </>
    );
};

export default OpenFarmDetails;
