import cn from "classnames";
import React, { useMemo } from "react";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import TooltipGlobal from "../../../components/TooltipGlobal";
import styles from "./Overview.module.sass";
// import Tooltip from "../../../components/Tooltip";
// import Modal from "../../../components/Modal";
import { displayWithLoaderObj,  formatMoney } from "../../../utils";
// import { useFarmingContext } from "../context";
// import Success from "./Success";
// import Tooltip from "../../../components/Tooltip";

const Overview = ({
    className,
    // loading,
    isEmpty,
    data,
    sum,
    sumNet,
    sumAPY,
}) => {

    // const [visibleModal, setVisibleModal] = useState(false);
    // const farmingContext = useFarmingContext();
    const isLoading = false;
    /*
    const isLoading = useMemo(() => {
        return loading
            || _no(data.length)
            || _no(farmingContext.activeFarms.length);
    }, [data, loading, farmingContext.activeFarms]);
    */

    /*
    const consolidatedData = useMemo(() => {
        return [
            ...farmingContext.activeRWAs,
            ...data
        ];
    }, [data]);
    */

    /*
    const averageAPY = useOpenFarmsAverageAPY({
        data: consolidatedData
    });
    */

    /*
    const totalDepositedValue = useMemo(() => {
        return consolidatedData.reduce((totalAmount, currentFarm) => {
            // console.log(currentFarm, 'currentFarm');
            return totalAmount += Number(currentFarm.amountInUSD ?? 0);
        }, 0);
    }, [consolidatedData]);
    */

    /*
    const totalValuation = useMemo(() => {
        return [
            ...farmingContext.activeFarms,
            // ...farmingContext.activeRWAs
        ].reduce((totalAmount, currentFarm) => {
            return totalAmount += Number(currentFarm.rewardsValue ?? 0);
        }, 0);
    }, [farmingContext.activeFarms]);
    */

    /*
    const totalAPY = useMemo(() => {
        return farmingContext.activeFarms
            .reduce((totalAmount, currentFarm) => {
                console.log(currentFarm, 'currentFarm');
                return totalAmount += Number(currentFarm.rewardsValue ?? 0);
            }, 0);
    }, [farmingContext.activeFarms]);
    */

    const items = useMemo(() => {

        const estimatedEarn = sumNet
            - sum;

        return [
            {
                title: `Your Deposits`,
                counter: displayWithLoaderObj({
                    isLoading: isLoading,
                    data: formatMoney(
                        sum,
                        true
                    ),
                    isEmpty
                }),
                value: sum,
                icon: "wallet",
                color: "#B1E5FC",
                tooltip: "Small description Available for withdrawal",
            },
            {
                title: "Potential Value",
                counter: displayWithLoaderObj({
                    isLoading,
                    data: formatMoney(
                        sumNet,
                        true
                    ),
                    isEmpty
                }),
                icon: "activity",
                color: "#B1E5FC",
                tooltip: "Small description Current account balance",
            },
            {
                title: "Average APY",
                counter: displayWithLoaderObj({
                    isLoading: isLoading,
                    data: `${formatMoney(
                        sumAPY,
                        false,
                        true
                    )}%`,
                    isEmpty
                }),
                icon: "promotion",
                color: '#B1E5FC',
                tooltip: "Small description Available for withdrawal",
            },
            {
                title: "Expected Earn",
                counter: displayWithLoaderObj({
                    isLoading,
                    data: formatMoney(
                        estimatedEarn,
                        true,
                        true
                    ),
                    isEmpty
                }),
                icon: "pie-chart",
                color: "#B1E5FC",
                tooltip: "Small description Available for withdrawal",
            },
            // {
            //     title: "Farm Points",
            //     icon: "lightning",
            //     color: "#FFEEFC",
            //     tooltip: "Small description Available for withdrawal",
            //     counter: displayWithLoader({
            //         isLoading,
            //         data: '0.000',
            //         isEmpty
            //     }),
            //     /*
            //     content: (
            //         <div className={styles.pointsFarmingIcons}>
            //             <Tooltip title="Earn EtherFi Points" place="right">
            //                 <div className={styles.pointsFarmingTooltip}>
            //                     <img src="/images/icons/etherfi.webp" className={styles.purple} alt="etherfi" />
            //                 </div>
            //             </Tooltip>
            //             <Tooltip title="Earn Ethena Points" place="right" className={styles.pointsFarmingTooltip}>
            //                 <div className={styles.pointsFarmingTooltip}>
            //                     <img src="/images/icons/ethena.webp" className={styles.blue} alt="ethena" />
            //                 </div>
            //             </Tooltip>
            //         </div>
            //     ),
            //     */
            // },
        ];
    }, [sum, sumAPY, sumNet, isEmpty, isLoading]);

    return (
        <>
            <Card
                className={cn(styles.card, className)}
                // title="Overview"
                classTitle="title-green"
            >
                <div className={styles.overview}>
                    <div className={styles.list}>
                        {items.map((x, index) => (
                            <div className={styles.item} key={index}>
                                <div
                                    className={styles.icon}
                                    style={{ backgroundColor: x.color }}
                                >
                                    <Icon name={x.icon} size="24" />
                                </div>
                                <div className={styles.details}>
                                    <div className={styles.label}>
                                        {x.title}
                                        {/*<Tooltip
                                            className={styles.tooltip}
                                            title={x.tooltip}
                                            icon="info"
                                            place="top"
                                        />*/}
                                    </div>
                                    {
                                        !!x.counter && (
                                            <div className={styles.counter}>
                                                {x.counter}
                                            </div>
                                        )
                                    }
                                    {
                                        !!x.content && (
                                            x.content
                                        )
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Card>
            {/*<button
                className={cn("button", styles.button)}
                onClick={() => setVisibleModal(true)}
            >
                Withdraw balance
            </button>*/}
            <TooltipGlobal />
            {/*<Modal
                outerClassName={styles.outer}
                visible={visibleModal}
                onClose={() => setVisibleModal(false)}
            >
                <Success />
            </Modal>*/}
        </>
    );
};

export default Overview;
