// import React from "react";
import React, { useEffect, useState } from "react";
import styles from "./TransactionCost.module.sass";
import cn from 'classnames';
import {
    _isNotEmptyNftId,
    // dollarsFormat,
    _no,
    _notEmpty,
    formatUSD
} from "../../utils";
import Tooltip from "../Tooltip";

const TransactionCost = ({
    nftId,
    isAave,
    amount,
    value,
    showApproval
}) => {
    const hasNFT = _isNotEmptyNftId(
        nftId
    );

    const [cost, setCost] = useState(0.00);

    useEffect(() => {
        if (value > 0.01) {
            const result = formatUSD(
                value
            );

            setCost(`${result}`);
        } else {
            setCost('—');
        }
    }, [value]);

    const description = isAave
        ? "If expensive use lower gas price."
        : "If expensive use lower gas price.";

    return (
        <div className={styles.item}>
            <div className={cn(
                styles.label,
                styles.gray
            )}>
                Transaction Cost Details
                <Tooltip
                    className={styles.tooltip}
                    title={description}
                    icon="info-stroke"
                    place="right"
                />
            </div>
            <div className={cn(
                styles.label,
                styles.gray
            )}>
                {cost}
                {_no(hasNFT) && _no(showApproval) && _notEmpty(amount) && (
                    <Tooltip
                        className={styles.tooltip}
                        title="This cost includes NFT drop"
                        icon="promotion"
                        place="right"
                    />
                )}
            </div>
        </div>
    );
};

export default TransactionCost;
