import React from "react";
import { useState, useEffect } from "react";
import styles from "./Details.module.sass";
import cn from "classnames";
// import Product from "./Product";
import useConfig from "../../../../../customHooks/useConfig";
import Parameter from "./Parameter";
// import TooltipGlobal from "../../../components/TooltipGlobal";
// import Editor from "../../../../components/Editor";
import { numberWithCommasForDays } from "../../../../../utils";
import { useScrapeInterest } from "../../../../../customHooksWise/useScrapeInterest";
import { useCloseStake } from "../../../../../customHooksWise/useCloseStake";
import { showToast } from "../../../../../utils";
import { CONFIRM_TEXT } from "../../../../../utils/config";
import Loader from "../../../../../components/Loader";

const Details = ({
    stakeData,
    currentWiseDay,
    stakeId,
    nftId,
    // address,
    currentAPY,
    onClose,
    doRefetchStakes
}) => {

    const config = useConfig();
    const DEFAULT_TEXT = "Scrape Rewards";
    const DEFAULT_TEXT2 = stakeData.matureStake
        ? "Close Stake"
        : "Terminate Stake";

    // const [content, setContent] = useState();
    // console.log(stakeData, 'stakeData');
    const [isLoading, setIsLoading] = useState(false);
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [buttonText2, setButtonText2] = useState(DEFAULT_TEXT2);

    let previousScrapeDaysAgo = stakeData.scrapeDay;

    if (previousScrapeDaysAgo === 0) {
        previousScrapeDaysAgo = nftId
            ? `Not Scrapable`
            : `Never Scraped`;
    } else {
        previousScrapeDaysAgo = `${numberWithCommasForDays(currentWiseDay - stakeData.scrapeDay)} Ago`;
        if (stakeData.matureStake) {
            console.log(stakeData);
            previousScrapeDaysAgo = `Scraped`;
        }
    }

    let title = "Stake Rewards";
    let titleClass = "title-purple";
    let stakeStatus = "In Progress";

    if (stakeData.matureStake) {
        title = "Stake Rewards";
        titleClass = "title-green";
        stakeStatus = "Matured";
    }

    if (stakeData.closedStake) {
        title = "Stake Summary";
        titleClass = "title-green";
        stakeStatus = "Closed Mature Stake";
    }

    if (stakeData.closedPrematurely) {
        title = "Stake Summary";
        titleClass = "title-purple";
        stakeStatus = "Prematurely Terminated";
    }

    if (stakeData.pendingStake) {
        title = "Stake Summary";
        titleClass = "title-purple";
        stakeStatus = "Pending Stake";
    }

    const apyLabel = stakeData.isMature
        ? "N/A"
        : currentAPY;

    const item = {
        parameters: [
            {
                title: "Stake Status",
                // content: "330 days",
                downloadedStatus: stakeStatus,
            },
            {
                title: "Lock Duration",
                content: `${stakeData.lockDaysFormatted}`,
            },
            {
                title: "Remaining Days",
                content: `${stakeData.pendingStake ? "Starts Tomorrow" : stakeData.daysLeftFormatted}`,
            },
            {
                title: "Rewards Scraped",
                downloadedStatus: `${previousScrapeDaysAgo}`,
            },
            {
                title: "Available Rewards",
                content: `${stakeData.stakeRewards} (${stakeData.stakeRewardsDynamicInUSD})`,
            },
        ],
        properties: [
            {
                title: "Current APY",
                downloadedStatus: `${apyLabel}`,
            },
            {
                title: "Amount Staked",
                content: `${stakeData.stakedAmountFormatted} (${stakeData.stakaedAmountInUSD2})`,
            },
            {
                title: "Termination Fee",
                content: `${stakeData.pendingStake ? '-90% From Tomorrow' : `-${stakeData.penaltyAmountFormatted} (${stakeData.terminationFeeInUSD2})` }`,
            },
            {
                title: "Available Rewards",
                content: `+${stakeData.stakeRewards} (${stakeData.stakeRewardsDynamicInUSD})`,
            },
            {
                title: `${stakeData.isMature ? "Closing Payout" : "Termination Payout"}`,
                content: `${stakeData.terminationPayoutFormatted} (${stakeData.terminationPayoutInUSD})`,
            }
        ],
        postdata: [
            {
                title: "Stake Status",
                downloadedStatus: stakeStatus,
            },
            {
                title: "Amount Staked",
                content: `${stakeData.stakedAmountFormatted} (${stakeData.stakaedAmountInUSD2})`,
            },
            {
                title: "Staked Duration",
                content: `${stakeData.servedDaysFormatted} (${parseFloat(stakeData.stakeProgress).toFixed(2)}%)`,
            },
            {
                title: "Termination Fee",
                content: `-${stakeData.penaltyAmountFormatted} (${stakeData.terminationFeeInUSD2})`,
            },
            {
                title: "Rewards Paid",
                content: `+${stakeData.stakeRewards} (${stakeData.stakeRewardsDynamicInUSD})`,
            },
            {
                title: "Amount Returned",
                content: `${stakeData.terminationPayoutFormatted} (${stakeData.terminationPayoutInUSD})`,
            }
        ]
    }

    const firstRowParams = stakeData.closedStake
        ? item.postdata
        : item.parameters;

    const scrapeInterest = useScrapeInterest({
        config: config,
        stakeId: stakeId,
    });

    const closeStake = useCloseStake({
        config: config,
        nftId: nftId,
        stakeId: stakeId,
    });

    const handleScrapeRewards = async () => {

        if (buttonText === "Done") {
            onClose();
            return;
        }

        await scrapeInterest.scrapeInterest({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonText(
                    CONFIRM_TEXT
                );
            }
        });
    }

    const handleCloseStake = async () => {

        if (buttonText2 === "Done") {
            onClose();
            return;
        }

        await closeStake.closeStake({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonText2(
                    CONFIRM_TEXT
                );
            }
        });
    }

    useEffect(() => {

        if (scrapeInterest.error && isLoading) {
            setIsLoading(false);
            setButtonText(
                DEFAULT_TEXT
            );
            showToast(
                "User rejected the request.",
                {
                    type: "warning"
                }
            );
        }

        if (scrapeInterest.hasError && isLoading) {
            setIsLoading(false);
            setButtonText(
                DEFAULT_TEXT
            );
            showToast(
                "User rejected the request.",
                {
                    type: "warning"
                }
            );
        }

        if (scrapeInterest.isConfirming && isLoading) {
            setButtonText(
                "Scraping..."
            );
        }

        if (scrapeInterest.isConfirmed && isLoading) {
            setIsLoading(false);
            setButtonText(
                "Done"
            );
            showToast(
                "Congrats! Rewards Scraped Successfully",
                {
                    type: "success"
                }
            );
            stakeData.stakeRewards = "0.00 WISE";
            stakeData.stakeRewardsDynamicInUSD = "$0.00";
            doRefetchStakes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrapeInterest]);


    useEffect(() => {

        if (closeStake.error && isLoading) {
            setIsLoading(false);
            setButtonText2(
                DEFAULT_TEXT2
            );
            showToast(
                "User rejected the request.",
                {
                    type: "warning"
                }
            );
        }

        if (closeStake.hasError && isLoading) {
            setIsLoading(false);
            setButtonText2(
                DEFAULT_TEXT2
            );
            showToast(
                "User rejected the request.",
                {
                    type: "warning"
                }
            );
        }

        if (closeStake.isConfirming && isLoading) {
            setButtonText2(
                stakeData.isMature
                    ? "Closing..."
                    : "Terminating..."
            );
        }

        if (closeStake.isConfirmed && isLoading) {
            setIsLoading(false);
            setButtonText2(
                "Done"
            );
            showToast(
                stakeData.isMature
                    ? "Congrats! Stake Closed Successfully"
                    : "Uhh... Stake Terminated Prematurely",
                {
                    type: "success"
                }
            );
            doRefetchStakes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeStake]);

    return (
        <>
            <div className={styles.details}>
                <div className={cn(titleClass, styles.title)}>
                    {title}
                </div>
                <div className={cn(
                    styles.row,
                    {
                        [styles.closed]: stakeData.closedStake,
                    }
                )}>
                    <div className={styles.col}>
                        <div className={styles.parameters}>
                            {firstRowParams.map((x, index) => (
                                <Parameter isMature={stakeData.matureStake} item={x} key={index} />
                            ))}
                        </div>
                        {!stakeData.closedStake && !nftId && (
                            <div className={styles.btns}>
                                <button
                                    onClick={() => {
                                        handleScrapeRewards(stakeId);
                                    }}
                                    className={cn(
                                        "button-full",
                                        styles.button,
                                        {
                                        disabled: isLoading || buttonText2 === "Done",
                                        }
                                    )}
                                >
                                    {buttonText === "Scraping..." && (
                                        <Loader className={styles.loader}></Loader>
                                    )}
                                    {buttonText}
                                </button>
                            </div>
                        )}
                        {stakeData.closedStake && (
                        <div className={cn(styles.btns, styles.terminate)}>
                            <button
                                onClick={() => {
                                    onClose();
                                }}
                                className={cn("button-stroke", styles.button)}
                            >
                                Done
                            </button>
                        </div>
                        )}
                    </div>
                    <div className={styles.col}>
                        <div className={styles.group}>
                            <div className={styles.box}>
                                <ul className={styles.list}>
                                {item.properties.map((x, index) => (
                                    <Parameter isMature={stakeData.matureStake} item={x} key={index} />
                                ))}
                                </ul>
                            </div>
                        </div>
                        {!stakeData.closedStake && (
                        <div className={cn(styles.btns, styles.terminate)}>
                            <button
                                onClick={() => {
                                    handleCloseStake();
                                }}
                                className={cn(
                                    "button-stroke",
                                    styles.button,
                                    {
                                        disabled: isLoading,
                                        [styles.disabled]: isLoading
                                    })}
                            >
                                {buttonText2 === "Closing..." && (
                                    <Loader className={styles.loader}></Loader>
                                )}
                                {buttonText2 === "Terminating..." && (
                                    <Loader className={styles.loader}></Loader>
                                )}
                                {buttonText2}
                            </button>
                        </div>
                        )}
                    </div>
                </div>
            </div>
            {/*<TooltipGlobal />*/}
        </>
    );
};

export default Details;
