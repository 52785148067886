import React from "react";
import PropTypes from "prop-types";
import styles from "./LinearBar.module.sass";

const LinearBar = ({
    fill,
    height,
    width,
    backColor,
    limit,
    limitColor,
    customFill
}) => {

    let progressWidth = width;
    let limitWidth = 0;

    if (limit && width && width > limit) {
        progressWidth = limit;
        limitWidth = width - limit;
    }

    // fill = fill || "inear-gradient(to right, #e5405e 0%, #ffdb3a 45%, #3fffa2 100%);";

    let myFill = "linear-gradient(to right, #3fffa2 0%, #3fffa2 100%)";

    if (width > 20) {
        myFill = "linear-gradient(to right, #3fffa2 0%, #ffdb3a 100%)";
    }

    if (width > 70) {
        myFill = "linear-gradient(to right, #3fffa2 0%, #ffdb3a 50%, #e5405e 100%)";
    }

    if (customFill) {
        myFill = customFill;
    }

    return (
        <div
            className={styles.line}
            style={{
                height: `${(height || 12)}px`,
                backgroundColor: backColor || "#1A1D1F"
            }}
        >
            <div
                className={styles.progress}
                style={{
                    background: myFill,
                    width: `${(progressWidth || 0)}%`,
                }}
            ></div>
            {limit > 0 && <div
                className={styles.limit}
                style={{
                    backgroundColor: limitColor,
                    left: `${limit}%`,
                    width: (limitWidth ? `${limitWidth}%` : "5px"),
                }}
            ></div>
            }
        </div>
    );
};

LinearBar.propTypes = {
    fill: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number, // 0 -100
    backColor: PropTypes.string,
    limit: PropTypes.number, // 1 -100
    limitColor: PropTypes.string,
};

export default LinearBar;
