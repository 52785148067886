import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setTVL } from "../../../redux/tokenSlice";
import cn from "classnames";
import styles from "./Tvl.module.sass";
import Icon from "../../Icon";
import { DEFI_LLAMA_WISE_LENDING_INFO_LINK, WISE_TVL_LINK } from "../../../utils/config";
import { _no, displayWithSkeleton, formatUSD } from "../../../utils";
import { setChainId } from "../../../redux/tokenSlice";
import { useAccount, useSwitchChain } from "wagmi";
import { Link } from "react-router-dom";
import Tooltip from "../../Tooltip";
import { appLocalStorage } from "../../../utils/localstorage";

const Tvl = ({ className, TVL }) => {

    const [visible, setVisible] = useState(false);
    // const [visibleModalProduct, setVisibleModalProduct] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();
    // const config = useConfig();

    const { interfacePreference } = useSelector((state) => state.settings);

    const { isConnected } = useAccount();
    const { chains, switchChain } = useSwitchChain();

    const currentSettings = appLocalStorage.interfacePreferences.get() || [
        ...interfacePreference
    ];

    useEffect(() => {
        const fetchTVL = async () => {
            try {
                const response = await axios.get(
                    WISE_TVL_LINK
                );

                const response2 = await axios.get(
                    "https://api.wise.one/getVaultsTVL"
                );

                dispatch(
                    setTVL({
                        ...response.data,
                        vaultTVL: response2?.data?.result || 0
                    })
                );
            }
            catch(err) {
                console.log(`${err} TVL`);
            }
            finally {
                setIsLoading(false);
            }
        }

        fetchTVL();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tvlDisplay = displayWithSkeleton({
        isLoading,
        data: `${formatUSD(TVL?.totalSupplyValue ?? 0)}`,
        isEmpty: _no(isLoading) && _no(TVL),
        skeletonWidth: '100%'
    });

    const tvlDisplayStaking = displayWithSkeleton({
        isLoading,
        data: `${formatUSD(TVL?.tvlStaked ?? 0)}`,
        isEmpty: _no(isLoading) && _no(TVL),
        skeletonWidth: '100%'
    });

    const tvlDisplayLiquidity = displayWithSkeleton({
        isLoading,
        data: `${formatUSD(TVL?.tvlLiquidity ?? 0)}`,
        isEmpty: _no(isLoading) && _no(TVL),
        skeletonWidth: '100%'
    });

    const tvlDisplayVault = displayWithSkeleton({
        isLoading,
        data: `${formatUSD(TVL?.vaultTVL ?? 0)}`,
        isEmpty: _no(isLoading) && _no(TVL),
        skeletonWidth: '100%'
    });

    const handleSwitchChainEth = () => {
        handleSwitchChain(
            chains[0].id
        );
    };

    const handleSwitchChainArb = () => {
        handleSwitchChain(
            chains[1].id
        );
    };

    const handleSwitchChain = (chainId) => {
        if (isConnected) {
            switchChain({
                chainId: chainId
            });
        } else {
            dispatch(
                setChainId(
                    chainId
                )
            );
        }
    };

    return (
        <>
            <div
                className={cn(
                    className,
                    styles.widgets,
                    {
                        [styles.active]: visible
                    }
                )}
            >
                {currentSettings && currentSettings.indexOf(0) === -1 && (
                    <Link to="/lending/hub" className={styles.link}>
                        <div className={styles.headvalue}>
                            <span>Wise Lending</span>
                            <div className={styles.result}>{tvlDisplay}</div>
                        </div>
                    </Link>
                )}
                {currentSettings && currentSettings.indexOf(1) === -1 && (
                <Link to="/staking/hub" className={styles.link}>
                    <div className={styles.headvalue}>
                        <span>Wise Staking</span>
                        <div className={styles.result}>{tvlDisplayStaking}</div>
                    </div>
                </Link>
                )}
                {currentSettings && currentSettings.indexOf(2) === -1 && (
                <a
                    href={"https://app.uniswap.org/explore/pools/ethereum/0x21b8065d10f73EE2e260e5B47D3344d3Ced7596E"}
                    target={"_blank"}
                    rel={"noreferrer"}
                >
                    <div className={styles.headvalue}>
                        <span>Wise Liquidity</span>
                        <div className={styles.result}>{tvlDisplayLiquidity}</div>
                    </div>
                </a>
                )}
                {currentSettings && currentSettings.indexOf(3) === -1 && (
                <Link to="/vaults" className={styles.link}>
                    <div className={styles.headvalue}>
                        <span>Wise Vaults</span>
                        <div className={styles.result}>{tvlDisplayVault}</div>
                    </div>
                </Link>
                )}
                <div style={{display: "none"}} className={styles.head}>
                    <a
                        href={DEFI_LLAMA_WISE_LENDING_INFO_LINK}
                        target={"_blank"}
                        rel={"noreferrer"}
                    >
                        <Tooltip
                            title="Arbitrum + Ethereum Liquidity"
                            place="right"
                        >
                        </Tooltip>
                    </a>
                    <input
                        className={styles.input}
                        type="text"
                        placeholder=""
                        onChange={() => setVisible(true)}
                    />
                    <img
                        className={styles.network}
                        onClick={handleSwitchChainArb}
                        src="/images/networks/arbitrum.svg"
                        alt="network icon"
                    >
                    </img>
                    <img
                        className={styles.networkEth}
                        onClick={handleSwitchChainEth}
                        src="/images/networks/ethereum.svg"
                        alt="network icon"
                    >
                    </img>
                </div>
                <button className={styles.close} onClick={() => setVisible(false)}>
                    <Icon name="close-circle" size="24" />
                </button>
            </div>
        </>
    );
};

export default Tvl;
