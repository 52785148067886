import React, { useState } from "react";
import styles from "./Transactions.module.sass";
import cn from "classnames";
import { useAccount, useSwitchChain } from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../components/Card";
// import Dropdown from "../../../components/Dropdown";
// import Tooltip from "../../../components/Tooltip";
import Filters from "../../../components/Filters";
import Modal from "../../../components/Modal";
import Settings from "../Settings";
import ReactPaginate from "react-paginate";
import { setChainId } from "../../../redux/tokenSlice";
// import Pagination from "@material-ui/lab/Pagination";
// import Loader from "../../../components/Loader";
import ModalStaking from "../../../components/ModalStaking";
import TransactionRow from './TransactionRow/'; // Adjust the path as necessary
import { TransactionRowWrapperNft } from './TransactionRow/'; // Adjust the path as necessary
import OpenStakeDetails from "../../../components/Table/Row/OpenStakeDetails";
import Icon from "../../../components/Icon";
// import { transactions } from "../../../mocks/transactions";
import Loader from "../../../components/Loader";
// import NewPost from "../../Promote/RecentPost/NewPost";
import useConfig from "../../../customHooks/useConfig";
import { appLocalStorage } from "../../../utils/localstorage";

/*
const navigation = [
    {
        title: "Customers",
        icon: "profile-circle",
    },
    {
        title: "Everyone",
        icon: "lightning",
    },
];
*/

const Transactions = ({
    className,
    stakes,
    nftCount,
    doRefetch,
    address,
    stakeCount,
    setCurrentPage,
    isFetching,
    chainId,
    perPage,
    setPerPage,
    currentWiseDay,
    fullScreen,
    visible,
    setVisible,
    onClose,
}) => {

// const [sorting, setSorting] = useState(intervals[0]);

const { TVL } = useSelector(
    (state) => state.token
);

const wiseCost = TVL?.wiseUsd ?? 0;
const hiddenFilters = appLocalStorage.hideStakeType.get() || [];

// const [activeIndex, setActiveIndex] = useState(0);
const [visibleModalProduct, setVisibleModalProduct] = useState(false);
const [visibleModal, setVisibleModal] = useState(false);
const pageCount = Math.ceil(parseInt(stakeCount + nftCount) / perPage) || 0;

// const [activeIndex, setActiveIndex] = useState(0);

const handlePageClick = (value) => {
    setCurrentPage(value.selected);
}

const showStakeCreation = () => {
    setVisibleModal(true);
}

const config = useConfig();
const { isConnected } = useAccount();
const dispatch = useDispatch();
// const { disconnect } = useDisconnect();

const { wiseNftStakes } = config;

const NoStake = () => {
    return (
        <div className={styles.noStake}>
            <div className={styles.noStakeTitle}>
                <div>You don't have any stakes yet</div>
                <div>Start earning by creating your first stake</div>
                <br />
                <button
                    onClick={() => setVisibleModal(true)}
                    className={cn(
                        "button-small",
                        "button-stroke",
                        styles.button
                    )}
                >
                    Create WISE Stake
                </button>
            </div>
        </div>
    );
}

const WrongNetwork = (chain) => {

    const {
        switchChain,
        // isSuccess
    } = useSwitchChain();

    const switchChainNow = (chainId) => {

        if (isConnected) {
            switchChain({
                chainId: chainId
            });
        } else {
            dispatch(
                setChainId(
                    chainId
                )
            );
            // setVisible(false);
        }
    }

    return (
        <div className={styles.noStake}>
            <div className={styles.noStakeTitle}>
                <button
                    onClick={() => switchChainNow(1)}
                    className={cn(
                        "button-small",
                        "button-stroke",
                        styles.button
                    )}
                >
                    Switch To Ethereum
                </button>
            </div>
        </div>
    );
}

return (
    <Card
        className={cn(styles.card, className)}
        classCardHead={styles.head}
        title={`Your WISE Stakes (${stakeCount + nftCount})`}
        classTitle={cn("title-green", styles.title)}
        head={
            <>
            {
                chainId === 1 && (
                    <>
                    {/*<div className={styles.nav}>
                        {navigation.map((x, index) => (
                            <button
                                className={cn(styles.button, {
                                    [styles.active]: activeIndex === index,
                                })}
                                onClick={() => setActiveIndex(index)}
                                key={index}
                            >
                                <Icon name={x.icon} size="24" />
                                {x.title}
                            </button>
                        ))}
                    </div>*/}
                    <div style={{ display: "flex" }}>
                        <button
                            onClick={() => showStakeCreation()}
                            className={cn("button-small", styles.button)}
                        >
                            Create WISE Stake
                        </button>
                        <Filters
                            visible={visible}
                            setVisible={setVisible}
                            icon="filter"
                            className={cn(
                                styles.filters,
                                {
                                    [styles.withdot]: hiddenFilters?.length > 0
                                }
                            )}
                            title="Filtering Options"
                        >
                            <Settings
                                doRefetch={doRefetch}
                                nftCount={nftCount}
                                stakeCount={stakeCount}
                                setPerPage={setPerPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </Filters>
                        <div
                            className={styles.filters}
                        >
                            <button
                                className={cn("button-square-stroke button-small", styles.head)}
                                onClick={() => {
                                    if (onClose) {
                                        onClose();
                                    }
                                    !fullScreen && setVisibleModalProduct(true);
                                }}
                            >
                                <Icon name={fullScreen ? "close" : "expand"} size="24" />
                            </button>
                        </div>
                    </div>
                    </>
                )
            }
            {/*<Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={sorting}
                setValue={setSorting}
                options={intervals}
                small
            />*/}
            <Modal
                visible={visibleModal}
                title="Create New Stake"
                // className={styles.modal}
                outerClassName={styles.outer}
                onClose={() => setVisibleModal(false)}
            >
                <OpenStakeDetails
                    onClose={() => setVisibleModal(false)}
                    item={{}}
                    doRefetchStakes={doRefetch}
                    tokenBalance={0}
                    token={{}}
                ></OpenStakeDetails>
            </Modal>
            <ModalStaking
                visible={visibleModalProduct}
                onClose={() => setVisibleModalProduct(false)}
            />
            </>
        }
    >
    <div className={cn(
            styles.wrapper,
            fullScreen && styles.fullscreen
        )}>
        <div className={cn(styles.labeler)}>
            {/*Showing 1 to 10 of {stakeCount} stakes*/}
            {/*<Tooltip
                className={styles.tooltip}
                title={"tooltip"}
                icon="info"
                place={"right"}
            />*/}
        </div>
        <div className={styles.table}>
        <div className={cn(styles.labeler)}>
        </div>
        <div className={cn(
            styles.row,
            styles.head,
            styles.header
        )}>
            <div className={styles.col}>Stake Created</div>
            <div className={styles.col}>Maturity Progress</div>
            <div className={styles.col}>Stake Status</div>
            {/*<div className={styles.col}>Stake ID</div>*/}
            <div className={styles.col}>Amount Staked</div>
            <div className={styles.col}>Stake APY</div>
            <div className={styles.col}>Stake Reward</div>
            <div className={styles.col}>Actions</div>
        </div>
        {stakes && stakes.map((x, index) => x.toString().includes("0x") ? (
            <TransactionRow
                key={index}
                wiseCost={wiseCost}
                transaction={x}
                index={index}
                transferable={false}
                config={config}
                currentWiseDay={parseInt(currentWiseDay)}
                stakeId={stakes && stakes[index]}
                address={address}
            />
        ) : (
            <><TransactionRowWrapperNft
                key={index}
                doRefetch={doRefetch}
                wiseCost={wiseCost}
                transaction={x}
                index={index}
                config={config}
                currentWiseDay={parseInt(currentWiseDay)}
                nftId={stakes && stakes[index]}
                address={wiseNftStakes?.address}
            /></>
        ))}
        </div>
    </div>
    {
        chainId === 1 && isFetching && !stakeCount && !nftCount && <div className={styles.loader}>
            <Loader></Loader>
        </div>
    }
    {
        chainId !== 1 && <div className={styles.loader}>
            <WrongNetwork></WrongNetwork>
        </div>
    }
    {
        chainId === 1 && !isFetching && !stakeCount && !nftCount && <div className={styles.loader}>
            <NoStake></NoStake>
        </div>
    }
    <div className={styles.paginator}>
    {pageCount > 0  && (
        <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            activeClassName={styles.activepage}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            disabledClassName={styles.disabledbutton}
            className={styles.pagination}
            previousLabel="<"
        />
    )}
    </div>
    {/*<div className={styles.foot}>
        <button className={cn("button-stroke button-small", styles.button)}>
        <Loader className={styles.loader} />
        <span>Load more</span>
        </button>
    </div>*/}
    </Card>
);
};

export default Transactions;
