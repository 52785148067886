import { useEffect, useState } from "react";
import {
    useReadContract,
    useWriteContract,
    useWalletClient,
    useBlockNumber,
    useWaitForTransactionReceipt
} from "wagmi";
import { useQueryClient } from '@tanstack/react-query'
import { getContractMethod } from "../ethers/contracts";
import { isEnabled } from "../ethers/tokens";
import {
    APPROVE_AND_MINT_FUNCTION_NAME,
    APPROVE_FUNCTION_NAME,
    // CONFIRMATIONS_COUNT,
    // ERROR_WISELENDING_APPROVAL_APPROVE,
    // ERROR_WISELENDING_APPROVAL_FETCH,
    // ERROR_WISELENDING_APPROVAL_WRITE,
    INFLATE_PERCENT
} from "../utils/config";
import { useGasEstimate } from "./useGasEstimate";
// import { parseUnits } from "viem";

export const useTokenApproval = ({
    config,
    nftId,
    token,
    amount
}) => {

    const { data: signer } = useWalletClient();
    const {
        chainId,
        version,
        positionNFTContract,
        aaveHubContract
    } = config;

    const enabled = isEnabled({
        config,
        nftId,
        token
    }) && token.isAavePool;

    const [isApproved, setIsApproved] = useState(false);
    const [isFetching, setIsFetching] = useState(enabled);

    const approvalMethod = getContractMethod({
        name: "aaveTokenApproval",
        chainId,
        version,
    }) ?? "getApprovedSoft";

    const functionName = enabled
        ? approvalMethod
        : null;

    const { data, queryKey } = useReadContract({
        chainId,
        address: positionNFTContract?.address,
        abi: positionNFTContract?.abi,
        functionName,
        args: [
            nftId
        ],
        query: {
            enabled
        },
    });

    const queryClient = useQueryClient();
    const { data: blockNumber } = useBlockNumber({
        watch: true
    });

    useEffect(() => {
        queryClient.invalidateQueries({
            queryKey
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        blockNumber,
        queryClient,
        chainId
    ]);

    useEffect(() => {
        if (data) {
            setIsApproved(
                data === aaveHubContract?.address
            );
            setIsFetching(
                false
            );
        }
    }, [data, aaveHubContract?.address]);

    const approveArgs = [
        aaveHubContract?.address,
        nftId,
    ];

    let functionNameToExecute = APPROVE_FUNCTION_NAME;

    if (token.isAavePool && isApproved === false) {
        functionNameToExecute = APPROVE_AND_MINT_FUNCTION_NAME;
    }

    const {
        gasLimit,
        estimatedGas,
        estimatesGasFormatted,
        estimatedGasUSD,
        isError
    } = useGasEstimate({
        contract: positionNFTContract,
        // functionName: APPROVE_FUNCTION_NAME,
        functionName: functionNameToExecute,
        args: approveArgs,
        account: signer?.account,
        amount
    });

    let gasLimitFormatted = parseInt(
        parseInt(gasLimit) * INFLATE_PERCENT
    ).toString();

    const {
        error,
        isError: hasError,
        data: hash,
        writeContract,
        isPending,
    } = useWriteContract();

    const approve = async ({
        onConfirmation,
        onApprovalComplete,
        onApproving,
        onError
    }) => {

        onConfirmation?.();

        writeContract({
            chainId,
            address: positionNFTContract?.address,
            abi: positionNFTContract?.abi,
            signerOrProvider: signer,
            // functionName: APPROVE_FUNCTION_NAME,
            functionName: functionNameToExecute,
            args: approveArgs,
            gas: gasLimitFormatted,
            query: {
                enabled
            }
        });
    };

    const {
        isLoading: isConfirming,
        isSuccess: isConfirmed
    } = useWaitForTransactionReceipt({
        hash,
    });

    return {
        hash,
        error,
        hasError,
        approve,
        isPending,
        isConfirming,
        isConfirmed,
        isApproved,
        isFetching,
        estimatedGas,
        estimatesGasFormatted,
        estimatedGasUSD,
        estimateHasError: isError
    };
};
