import React, { useMemo } from "react";
import cn from "classnames";
import { getSupportedTokens } from "../../../ethers/tokens";
import Card from "../../../components/Card";
import styles from "./AssetsToBorrow.module.sass";
import Tooltip from "../../../components/Tooltip";
import Row from "./Row";

const AssetsToBorrow = ({ config, nftId, summary, expand }) => {

    const { chainId, availablePools } = config;

    const tokens = useMemo(
        () => {
            return getSupportedTokens(
                chainId,
                availablePools
            );
        },
        [chainId, availablePools]
    );

    return (
        <Card
            className={cn(styles.card)}
            title="Funds to Borrow"
            classTitle="title-purple"
        >
            <div className={styles.wrapper}>
                <div className={styles.table}>
                    <div className={cn(styles.row, styles.head)}>
                        <div className={styles.col}>Pool Type</div>
                        <div className={styles.col}>Available</div>
                        <div className={cn(styles.col, styles.tilt)}>
                            <Tooltip
                                className={styles.tooltip}
                                title={"Variable APY based on supply and demand"}
                                place="right"
                            >
                                <b>APY</b>
                            </Tooltip>
                        </div>
                        <div className={styles.col}>Your Debt</div>
                        <div className={styles.col}>Actions / Info</div>
                    </div>
                    {tokens.map((x, index) => (
                        <Row
                            token={x}
                            expand={expand}
                            key={`aave_${index}`}
                            nftId={nftId}
                            config={config}
                            summary={summary}
                        />
                    ))}
                </div>
            </div>
        </Card>
    );
};

export default AssetsToBorrow;
