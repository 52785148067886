// import React, { useState } from "react";
// import React, { useEffect, useMemo, useState } from "react";
import React from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlobal from "../../../components/TooltipGlobal";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import Tooltip from "../../../components/Tooltip";
import { items } from ".";
import { PointTypes } from "../../../utils/config";
import { getOrdinalSuffix } from "../../../utils";
// import dayjs from 'dayjs';

const DEFAULT_DASH = '-';

const OverviewMain = ({
    className,
    currentAddress,
    etherData,
    stableData,
    isFetching,
    activeTab,
    onChange,
    seasonEndDate
}) => {

    const etherRankResult = etherData?.findIndex(
        item => currentAddress?.toString().toLowerCase() === item.userAddress?.toString().toLowerCase()
    );

    const stableRankResult = stableData?.findIndex(
        item => currentAddress?.toString().toLowerCase() === item.userAddress?.toString().toLowerCase()
    );

    const currentUserEtherRank = etherRankResult === -1
        ? 0 :
        etherRankResult + 1;

    const currentUserStableRank = stableRankResult === -1
        ? 0 :
        stableRankResult + 1;

    /*
    const getTimeLeft = () => {
        const now = dayjs();
        const end = dayjs(seasonEndDate);
        const diff = end.diff(now);

        // Calculate days, hours, minutes, and seconds
        const days = Math.floor(diff / (1000 * 60 * 60 * 24)).toString().padStart(2, "0");
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, "0");
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, "0");
        const seconds = Math.floor((diff % (1000 * 60)) / 1000).toString().padStart(2, "0");

        return `${days}:${hours}:${minutes}:${seconds}`;
    };*/

    // const [timeLeft, setTimeLeft] = useState(getTimeLeft());

    /*
    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(getTimeLeft());
        }, 1000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seasonEndDate]);
    */

    /*
    const seasonDeadline = useMemo(() => {
        return dayjs(seasonEndDate).format('MMM D, YYYY');
    }, [seasonEndDate]);
    */

    return (
        <>
            <Card
                className={cn(styles.card, className)}
                title="Reward Rank Overview"
                classTitle="title-green"
                head={
                    <>
                        <a href="https://wisesoft.gitbook.io/wise/wise-lending/points-farming"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <button className={cn("button-stroke button-small secondary")}>
                                <Icon name="star-fill" size="18" />
                                <span className={styles.infoButton}>
                                    About Season Rewards
                                </span>
                                <span className={styles.infoButtonMobile}>
                                    Points Farming
                                </span>
                            </button>
                        </a>
                    </>
                }
            >
                <div className={styles.overview}>
                    <div className={styles.list}>
                        {items.map((x, index) => (
                            <div
                                className={cn(styles.item, {
                                    [styles.active]: activeTab === x.onClickData
                                })}
                                key={index}
                                style={{ backgroundColor: "gray !important" }}
                                onClick={() => x.onClickData && onChange(x.onClickData)}
                            >
                                <div className={styles.icon}>
                                    {x.useHolder ? (
                                        <>
                                            <div className={styles.stables}>
                                                <img alt="points-a" width="80%" src={"/images/icons/dai.svg"} />
                                                <img alt="points-b" width="80%" src={"/images/icons/usdc.svg"} />
                                                <img alt="points-b" width="80%" src={"/images/icons/usdt.svg"} />
                                            </div>
                                            {/*<Holder></Holder>*/}
                                        </>
                                    ) : (
                                        <>
                                            {(x.type === PointTypes.REFERRAL) && (
                                                <Icon name="clock" size="32" />
                                            )}
                                            {(x.type === PointTypes.WISE) && (
                                                <Icon name="coin" size="32" />
                                            )}
                                            {(x.type === PointTypes.ETHERFI) && (
                                                <img alt="points" style={{ borderRadius: "50px" }} width="80%" src={x.image} />
                                            )}

                                        </>
                                    )}
                                </div>
                                <div className={styles.line}>
                                    <div className={styles.details}>
                                        <div className={styles.category}>
                                            {x.title}
                                            <Tooltip
                                                className={styles.tooltip}
                                                title={
                                                    x.type === PointTypes.REFERRAL
                                                        // ? `${seasonDeadline}`
                                                        ? `Mystery date`
                                                        : x.tooltip
                                                }
                                                icon="info"
                                                place="right" />
                                        </div>
                                        <div className={styles.counter}>
                                            {(x.type === PointTypes.WISE) && (
                                                <div>10,000 WISE</div>
                                            )}
                                            {(x.type === PointTypes.ETHERFI) && (
                                                <div>
                                                    {isFetching
                                                        ? DEFAULT_DASH
                                                        : getOrdinalSuffix(currentUserEtherRank, DEFAULT_DASH)}
                                                    {' / '}
                                                    {isFetching
                                                        ? DEFAULT_DASH
                                                        : etherData?.length}
                                                </div>
                                            )}
                                            {(x.type === PointTypes.ETHENA) && (
                                                <div>
                                                    {isFetching
                                                        ? DEFAULT_DASH
                                                        : getOrdinalSuffix(currentUserStableRank, DEFAULT_DASH)}
                                                    {' / '}
                                                    {isFetching
                                                        ? DEFAULT_DASH
                                                        : stableData?.length}
                                                </div>
                                            )}
                                            {(x.type === PointTypes.REFERRAL) && (
                                                <>
                                                    {/*<div>{timeLeft}</div>*/}
                                                April, 2025
                                                </>
                                            )}
                                        </div>
                                        {
                                            /*<div className={styles.indicator}>
                                            <Balance className={styles.balance} value={x.value} />
                                            <span>this week?</span>
                                            </div>*/
                                        }
                                    </div>
                                    {/*<Chart className={styles.chart} item={x} />*/}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Card>
            <TooltipGlobal />
        </>
    );
};

export default OverviewMain;
