// import React, { useState } from "react";
import React, { useMemo, useState } from "react";
import styles from "./UseReferral.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
// import Dropdown from "../../../components/Dropdown";
import TextInput from "../../../components/TextInput";
import { _no, checkIfValidEnsOrAddress } from "../../../utils";
import ReferralNetworkModal from "./ReferralNetworkModal";
import { useAccount } from "wagmi";
// import Icon from "../../../components/Icon";
// import { useWeb3Modal } from "@web3modal/wagmi/react";
import Loader from "../../../components/Loader";
import { showToast } from "../../../utils";
// import Icon from "../../../components/Icon";

/*
const options = [
    "Choose Popular Networks",
    "Choose option 2",
    "Choose option 2",
];
*/

/*
const users = [
    {
        avatar: "/images/content/shadow-owl.jpg",
        icon: "facebook-fill",
    },
    {
        avatar: "/images/content/shadow-owl.jpg",
        icon: "twitter-fill",
    },
];
*/

const BUTTON_STATES = {
    JOIN_NETWORK: "Join Referral Network",
    LOADING: "Fetching Data...",
    CONNECT_WALLET: "Connect Account"
};

const SIGN_TOAST_MESSAGES = {
    notConnected: "Please connect wallet to join referral network",
    invalidReferralLink: "Please enter a valid referral network link to use",
};

const REFERRAL_NETWORD_ID_REGEX = /r\/([^/]+)/;

const UseReferral = ({
    className,
    onJoinReferralNetworkSuccess,
    loading
}) => {
    const { isConnected } = useAccount();
    // const { open } = useWeb3Modal();

    const [referralNetwork, setReferralNetwork] = useState();
    const [isReferralNetworkModalVisible, setIsReferralNetworkModalVisible] = useState(false);

    // @TODO: improve code!
    const referrerAddress = useMemo(() => {
        return referralNetwork?.match(
            REFERRAL_NETWORD_ID_REGEX
        )?.[1];
    }, [referralNetwork]);

    const handleJoinReferralClick = async () => {
        if (_no(isConnected)) {
            // open();
            return;
        }

        if (_no(referralNetwork) || _no(referrerAddress)) {
            showToast(
                SIGN_TOAST_MESSAGES.invalidReferralLink,
                {
                    type: "error"
                }
            );
            return;
        }

        const isValidAddress = await checkIfValidEnsOrAddress(
            referrerAddress
        );

        if (!isValidAddress) {
            showToast(
                SIGN_TOAST_MESSAGES.invalidReferralLink,
                {
                    type: "error"
                }
            );
            return;
        }

        setIsReferralNetworkModalVisible(true);
    };

    return (
        <Card
            className={cn(styles.card, className)}
            title="Join Referral Network"
            classTitle="title-green"
        >
            <div className={styles.create}>
                <div className={styles.fieldset}>
                    {/*<Dropdown
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        value={sorting}
                        setValue={setSorting}
                        options={options}
                    />*/}
                    <div className={styles.label}>
                        Earn +10% points by joining a referral network.
                    </div>
                    <TextInput
                        className={styles.field}
                        name="link-product"
                        type="text"
                        placeholder="Enter referral network link to use"
                        required
                        value={referralNetwork}
                        onChange={(e) => setReferralNetwork(e.target.value)}
                        disabled={loading}
                    />
                    <button
                        className={cn(
                            "button",
                            styles.button,
                            {
                                disabled: loading
                            }
                        )}
                        onClick={handleJoinReferralClick}
                    >
                        {
                            _no(isConnected)
                                ? (
                                    <>
                                        {BUTTON_STATES.CONNECT_WALLET}
                                    </>
                                )
                                : (
                                    <>
                                        {loading ? (
                                            <>
                                                <Loader className={styles.loader} />
                                                {BUTTON_STATES.LOADING}
                                            </>
                                        ) : (
                                            BUTTON_STATES.JOIN_NETWORK
                                        )}
                                    </>
                                )
                        }
                    </button>
                </div>
                {/*
                <div className={styles.box}>
                    <div className={styles.label}>Share to social accounts</div>
                    <div className={styles.line}>
                        <div className={styles.list}>
                            {users.map((x, index) => (
                                <div className={styles.avatar} key={index}>
                                    <img src={x.avatar} alt="Avatar" />
                                    <div className={styles.icon}>
                                        <Icon name={x.icon} size="12" />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <button className={cn("button-small", styles.button)}>
                            <span>Share</span>
                            <Icon name="arrow-right" size="24" />
                        </button>
                    </div>
                </div>
                */}
            </div>
            <ReferralNetworkModal
                visible={isReferralNetworkModalVisible}
                referrerAddress={referrerAddress}
                onClose={() => {
                    setIsReferralNetworkModalVisible(false);
                }}
                onSuccessConfirm={() => {
                    setIsReferralNetworkModalVisible(false);
                    onJoinReferralNetworkSuccess?.();
                }}
            />
        </Card>
    );
};

export default UseReferral;
