import cn from "classnames";
import React, { useState } from "react";
import Card from "../../../components/Card";
import ActiveFunds from "../ActiveFunds";
import Item from "./Item";
import styles from "./Snapshot.module.sass";
// import Dropdown from "../../../components/Dropdown";
// import Chart from "./Chart";
import {
    MAIN_COLOR_A,
    MAIN_COLOR_B,
} from "../../../utils/config";

import Dropdown from "../../../components/Dropdown";
import Loader from "../../../components/Loader";

const intervalsFilter = [
    {
        label: "Last 10 days",
        value: -10
    },
    {
        label: "Last 20 days",
        value: -20
    },
    {
        label: "Last 40 days",
        value: -40
    },
    {
        label: "Last 80 days",
        value: -80
    },
    {
        label: "All Available",
        value: null
    }
];

const DATA_INDEXES = {
    suppliedUSD: 0,
    borrowedUSD: 1,
    availableUSD: 2,
};

const Snapshot = ({
    poolName,
    tokenUtilization,
    chartData = [],
    className,
    loading,
    logo
}) => {
    // const [sorting, setSorting] = useState(intervals[0]);
    const [activeIndexes, setActiveIndexes] = useState([
        DATA_INDEXES.suppliedUSD
    ]);

    const [sorting, setSorting] = useState(
        intervalsFilter[0]
    );

    const title = `${poolName.toUpperCase()} Pool TVL`;

    const nav = [
        {
            title: "Provided Funds",
            icon: "arrow-bottom",
            color: MAIN_COLOR_A,
            value: tokenUtilization?.suppliedUSD,
        },
        {
            title: "Borrowed Funds",
            icon: "arrow-top",
            color: MAIN_COLOR_B,
            value: tokenUtilization?.borrowedUSD,
        },
        {
            title: "Available Funds",
            icon: "pie-chart",
            color: "#FFD88D",
            value: tokenUtilization?.availableUSD,
        },
    ];

    const handleSortChange = (newSortLabel) => {
        return setSorting(
            intervalsFilter.find((interval) => {
                return interval.label === newSortLabel;
            })
        );
    };

    return (
        <Card
            className={cn(styles.card, className)}
            title={title}
            classTitle="title"
            icon={logo}
            head={
                <>
                    <Dropdown
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        value={sorting.label}
                        setValue={handleSortChange}
                        options={intervalsFilter?.map(interval => interval.label)}
                        small
                    />
                </>
            }
        >
            <div className={styles.overview}>
                <div className={styles.nav}>
                    {nav.map((x, index) => (
                        <Item
                            className={cn(styles.item, {
                                [styles.active]: activeIndexes.includes(index),
                            })}
                            key={index}
                            onActive={() => {
                                if (activeIndexes.length === 1 && activeIndexes.includes(index)) {
                                    return;
                                }
                                if (activeIndexes.includes(index)) {
                                    setActiveIndexes(active =>
                                        active.filter(i => i !== index)
                                    );
                                } else {
                                    setActiveIndexes(
                                        active => [...active, index]
                                    );
                                }
                            }}
                            item={x}
                        />
                    ))}
                </div>
                <div className={styles.body}>
                    {
                        loading ? (
                            <div className={styles.loaderContainer}>
                                <Loader className={styles.loader} />
                                <span>Loading Data</span>
                            </div>
                        ) : (
                            <ActiveFunds
                                lines={activeIndexes}
                                chartData={chartData}
                                sorting={sorting}
                            />
                        )
                    }
                </div>
            </div>
        </Card>
    );
};

export default Snapshot;
