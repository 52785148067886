import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Settings.module.sass";
// import Form from "../../../components/Form";
// import Dropdown from "../../../components/Dropdown";
import Checkbox from "../../../components/Checkbox";
// import Icon from "../../../components/Icon";
import { Range, getTrackBackground } from "react-range";
// import useDarkMode from "use-dark-mode";
import { appLocalStorage } from "../../../utils/localstorage";
import { showToast } from "../../../utils";
/*
const options = [
    "Featured",
    "Popular",
    "New"
];
*/

const filters = [
    "Hide Scrapable Stakes",
    "Hide Transferable Stakes",
];

const STEP = 1;
const MIN = 5;
const MAX = 30;

const Settings = ({
    setPerPage,
    setCurrentPage,
    nftCount,
    stakeCount,
    doRefetch,
}) => {

    // const [search, setSearch] = useState("");
    // const [sorting, setSorting] = useState(options[0]);

    const DEFAULT_VALUE = 10;
    const howMany = appLocalStorage.showHowManyStakes.get();
    const hiddenFilters = appLocalStorage.hideStakeType.get();

    const haveBothTypes = (nftCount > 0 && stakeCount > 0) || hiddenFilters?.length > 0;

    const [selectedFilters, setSelectedFilters] = useState(
        hiddenFilters || []
    );
    const [values, setValues] = useState([
        howMany || DEFAULT_VALUE
    ]);

    const darkMode = true;
    //const darkMode = useDarkMode(false);

    // const handleSubmit = (e) => {
    //     alert();
    // };


    useEffect(() => {
        if (!haveBothTypes) {
            setSelectedFilters([]);
            appLocalStorage.hideStakeType.update([]);
        }
    }, [haveBothTypes]);

    const handleApply = () => {
        appLocalStorage.showHowManyStakes.update(
            values[0]
        );
        appLocalStorage.hideStakeType.update(
            selectedFilters
        );
        showToast(
            `Filtering Options: Settings Saved`,
            {
                type: "success"
            }
        );
        setPerPage(values[0]);
        setCurrentPage(0);
        doRefetch();
    };

    const handleReset = () => {
        setPerPage(DEFAULT_VALUE);
        setValues([DEFAULT_VALUE]);
        setCurrentPage(0);
        setSelectedFilters([]);
        doRefetch();
    };

    const handleChange = (filter) => {

        if (!haveBothTypes) {
            showToast(
                `Filtering Options: You have only 1 type of stakes`,
                {
                    type: "warning"
                }
            );

            return;
        }

        // clean filters first
        setSelectedFilters([]);

        selectedFilters.includes(filter) === true
            ? setSelectedFilters(
                selectedFilters.filter((x) => x !== filter)
            )
            : setSelectedFilters((selectedFilters) => [
                ...selectedFilters,
                filter
            ]
        );
    };

    return (
        <div className={styles.settings}>
            {/*<Form
                className={styles.form}
                value={search}
                setValue={setSearch}
                onSubmit={() => handleSubmit()}
                placeholder="Search for Loans!"
                type="text"
                name="search"
                icon="search"
            />*/}
            <div className={styles.group}>
                {/*<div className={styles.item}>
                    <Dropdown
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        classDropdownLabel={styles.label}
                        value={sorting}
                        setValue={setSorting}
                        options={options}
                        label="Sort by"
                    />
                </div>*/}
                <div style={{marginBottom: "20px"}} className={styles.item}>
                    <div className={styles.label}>Stakes Per Page ({values[0]})</div>
                    {<Range
                        values={values}
                        step={STEP}
                        min={MIN}
                        max={MAX}
                        onChange={(values) => {
                            if (values[0] < 0) {
                                values[0] = 0;
                            }
                            if (values[0] > 30) {
                                values[0] = 30;
                            }
                            setValues(values);
                        }}
                        renderTrack={({ props, children }) => (
                            <div
                                onMouseDown={props.onMouseDown}
                                onTouchStart={props.onTouchStart}
                                style={{
                                    ...props.style,
                                    width: "100%",
                                    paddingTop: 20,
                                    marginTop: -20,
                                }}
                            >
                                <div
                                    ref={props.ref}
                                    style={{
                                        height: "8px",
                                        width: "100%",
                                        borderRadius: "2px",
                                        background: getTrackBackground({
                                            values,
                                            colors: darkMode.value
                                                ? ["#272B30", "#00b0aa"]
                                                : ["#00b0aa", "#EFEFEF"],
                                            min: MIN,
                                            max: MAX,
                                        }),
                                        alignSelf: "center",
                                    }}
                                >
                                    {children}
                                </div>
                            </div>
                        )}
                        renderThumb={({ index, props, isDragged }) => {
                            // Extract the key from props
                            const { key, ...otherProps } = props;

                            return (
                                <div
                                    key={key} // Pass the key directly
                                    {...otherProps} // Spread the remaining props
                                    style={{
                                        ...props.style,
                                        height: "20px",
                                        width: "20px",
                                        borderRadius: "50%",
                                        backgroundColor: "#FFF",
                                        border: "4px solid #00b0aa",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        boxShadow: "inset 0px 4px 4px #FFFFFF",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "absolute",
                                            bottom: "calc(100% - 60px)",
                                            color: "#fff",
                                            fontWeight: "600",
                                            fontSize: "15px",
                                            lineHeight: "18px",
                                            fontFamily: "Inter",
                                            padding: "5px 11px",
                                            borderRadius: "8px",
                                            width: "95px",
                                            textAlign: "center",
                                            marginBottom: "8px",
                                            display: "none",
                                            backgroundColor: "#272B30",
                                        }}
                                    >
                                        {values[index].toFixed(0)} Stakes
                                    </div>
                                </div>
                            );
                        }}
                    />}
                </div>
                <div
                    className={styles.item}>
                    <div
                        className={cn(
                        styles.list,
                        {
                            [styles.disabled]: !haveBothTypes
                        }
                    )}
                    >
                        {filters.map((x, index) => (
                            <Checkbox
                                className={styles.checkbox}
                                content={x}
                                value={
                                    haveBothTypes
                                        ? selectedFilters.includes(x)
                                        : false
                                }
                                checked={selectedFilters.includes(x)}
                                // value={selectedFilters.includes(x)}
                                onChange={() => handleChange(x)}
                                key={index}
                                // reverse
                            />
                        ))}
                    </div>
                </div>
                {/*<div className={styles.item}>
                    <div className={styles.box}>
                        <Dropdown
                            className={styles.dropdown}
                            classDropdownLabel={styles.label}
                            classDropdownHead={styles.dropdownHead}
                            value={rating}
                            setValue={setRating}
                            options={optionsRating}
                            label="Display Per Page"
                            upBody
                        />
                        <Icon name="heart-fill" size="24" />
                    </div>
                </div>*/}
                <div className={styles.btns}>
                    <button
                        onClick={() => {handleApply()}}
                        className={cn("button", styles.button)}
                    >
                        Apply
                    </button>
                    <button
                        onClick={() => {handleReset()}}
                        className={cn("button-stroke", styles.button)}
                    >
                        Reset
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Settings;
