// import axios from "axios";
import axios from "axios";
import { createPublicClient, http } from "viem";
import { ethers } from "ethers";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import Loader from "../components/Loader";
import { formatUnits, parseUnits, recoverMessageAddress } from "viem";
import { normalize, getEnsName } from "viem/ens";
import { mainnet } from "wagmi/chains";
import { toast } from "react-toastify";
import {
    // APRSMA_LINK,
    DECIMALS_BASE_TEN,
    ETH_ADDRESS_REGEX,
    BACKEND_URL,
    // OPENSEA_API,
    NATIVE_TOKENS,
    PENDLE_API_LINK,
    PENDLE_API_LINK_V2,
    PENDLE_APY_LINK,
    PENDLE_APY_V2_LINK,
    USD_ZERO_THRESHOLD,
    ZERO_THRESHOLD,
} from "./config";

// const DEFAULT_SLUG = "owlmybabies";

/*
const findAssets = (data, slug) => {
    return data && data.assets.find(
        (x) => x.collection.slug === slug
    );
};

const findResponse = (address) => {
    return memory.find(
        (x) => x.config.url.includes(
            address
        )
    );
};
*/

// const memory = [];

/*
const fetchOpenSea = async (address) => {

    const response = findResponse(
        address
    );

    if (response) {

        const asset = findAssets(
            response.data,
            DEFAULT_SLUG
        );

        const image = asset
            ? `${asset.image_url}=s500`
            : undefined;

        const token = asset
            ? asset.token_id
            : undefined;

        return { image, token };
    }

    try {

        if (_no(address)) {
            return;
        }

        const { image, token } = await axios.get(
            `${OPENSEA_API}/api/v1/assets?format=json&owner=${address}&collection_slug=${DEFAULT_SLUG}&order_by=sale_date&order_direction=desc`
        ).then(function (response) {

            memory.push(
                response
            );

            const asset = findAssets(
                response.data,
                DEFAULT_SLUG
            );

            const image = asset
                ? `${asset.image_url}=s1000`
                : undefined;

            const token = asset
                ? asset.token_id
                : undefined;

            return { image, token };

        }).catch(function (error) {
            console.log(error, "error");
        });

        return { image, token };

    } catch (e) {
        console.log(e);
    }
};

export const getOwlImage = async (address) => {

    if (_no(address)) return;

    if (address) {
        const { image } = await fetchOpenSea(
            address
        );

        if (image) {
            return {
                image,
                isOwned: true
            };
        }
    }

    return {
        image: `/images/content/shadow-owl.png`,
        isOwned: false
    };
};
*/

export const formatAPY = (stake) => {
    const daysServed = stake && stake.servedDays;
    // return stake.daysLeft;
    if (daysServed <= 0) return "0.00%";
    const apy = parseFloat(stake.rewardAmount)
        / parseFloat(stake.stakedAmount)
        * 100
        * 365
        / daysServed;
    return `${apy && apy.toFixed(2)}%`;
};

export const showToast = (message, props) => {

    if (typeof message === "string" && message?.includes("User rejected the request.")) {
        message = "Transaction cancelled in wallet application.";
        props = {
            type: "warning"
        };
    }

    if (typeof message === "string" && message?.includes('The contract function')) {
        message ="Transaction failed. Please try again later.";
    }

    if (typeof message === "string" && message?.includes('out of gas')) {
        message ="Transaction failed due to insufficient gas.";
    }

    if (typeof message === "string" && message?.includes('Unknown error')) {
        message = "Unknown error. Please report to discord.";
    }

    if (!message) {
        message = "Transaction failed. Please try again later.";
    }

    toast(message, props);
}

export const fetchPendleAPY = async (chainId, marketAddress) => {
    try {
        const { data } = await axios.get(
            PENDLE_APY_LINK(
                chainId,
                marketAddress
            )
        );

        return data;

    } catch (e) {
        console.log("pendle apy error", e);
    }
};

export const fetchPendleV2APY = async (chainId, marketAddress) => {
    try {
        const { data } = await axios.get(
            PENDLE_APY_V2_LINK(
                chainId,
                marketAddress
            )
        );

        return data;

    } catch (e) {
        console.log("pendle apy error", e);
    }
};

export const fetchMarketDataForAddress = async (chainId, address) => {
    try {
        const { data } = await axios.get(
            `${PENDLE_API_LINK_V2(
                chainId,
            )}/markets/${address}/data`
        );

        return data;

    } catch (e) {
        console.log("pendle api market data error", e);
    }
};

export const dayDiff = (dayA, dayB) => {
    return dayA > dayB ? dayA - dayB : 0;
}

export const daysLeftToTitle = (days) => {
    if (days === 0) return "MATURED ON";
    if (days === 1) return "TOMORROW";
    return `${days} DAYS LEFT`;
};

export const getDateString = (days, inceptionDay) => {
    return new Date(
        new Date(inceptionDay)
        .setDate(
            new Date(inceptionDay)
            .getDate() + parseInt(days)
        )
    ).toDateString().replace(/^\S+\s/,'');
}

export const daysAgoToTitle = (days) => {
    if (days === 0) return "Today";
    if (days === 1) return "Yesterday";
    return `${numberWithCommas(days)} Days Ago`;
};

export const addPendleLiquiditySingleToken = async ({
    chainId,
    receiverAddress,
    marketAddress,
    token,
    amount,
    slippage
}) => {
    try {
        const params = {
            chainId,
            receiverAddr: receiverAddress,
            marketAddr: marketAddress,
            tokenInAddr: token,
            amountTokenIn: amount,
            slippage
        };

        const { data } = await axios.get(
            `${PENDLE_API_LINK}/addLiquiditySingleToken`,
            { params }
        );

        return data;

    } catch (e) {
        console.log("pendle api error", e);
    }
};

export const removePendleLiquiditySingleToken = async ({
    chainId,
    receiverAddress,
    marketAddress,
    token,
    amount,
    slippage
}) => {
    try {

        const newMarkets = [
            "0x",
            "0xBf5E60ddf654085F80DAe9DD33Ec0E345773E1F8",
            "0xB162B764044697cf03617C2EFbcB1f42e31E4766"
        ];

        let returnData;

        // if (newMarkets.includes(marketAddress)) {
        if (newMarkets.includes("0x")) {

            const params = {
                chainId: chainId,
                receiver: receiverAddress,
                enableAggregator: true,
                tokenOut: token,
                amountIn: amount,
                slippage: slippage
            };

            const { data } = await axios.get(
                `https://api-v2.pendle.finance/core/v1/sdk/${chainId}/markets/${marketAddress}/remove-Liquidity`,
                { params }
            );

            returnData = data;

        } else {

            const params = {
                chainId,
                receiverAddr: receiverAddress,
                marketAddr: marketAddress,
                tokenOutAddr: token,
                amountLpToRemove: amount,
                slippage
            };

            const { data } = await axios.get(
                `${PENDLE_API_LINK}/removeLiquiditySingleToken`,
                { params }
            );
            returnData = data;
        }

        return returnData;

    } catch (e) {
        console.log("pendle api error", e);
    }
};

export const numberWithCommas = (x) => {
    if (_no(x)) return x;
    var parts = x?.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
};

export const numberWithCommasForDays = (x, prefix = "") => {
    if (_no(x)) return `0 Days`;
    var parts = x?.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const res = parts.join(".");
    if (parseInt(x) === 1) {
        return `${res} Day`;
    }

    if (parseInt(x) === 0) {
        return `0 Days`;
    }

    if (parseInt(x) < 0) {
        return `0 Days`;
    }

    return `${prefix}${res} Days`;
};

export const numberWithCommasForFutureDays = (x, prefix = "") => {
    if (_no(x)) return `Today`;
    var parts = x?.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const res = parts.join(".");
    if (parseInt(x) === 1) {
        return `Ends Tomorrow`;
    }

    if (parseInt(x) === 0) {
        return `Ends Today`;
    }

    if (parseInt(x) < 0) {
        return `Ends Today`;
    }

    return `${prefix}${res} Days`;
};

export const removeNonNumeric = (num) => {
    return num?.toString().replace(/[^0-9.]/g, "");
};

export const progress = () => {
    return Math.floor(Math.random() * 90) + 10 + "%";
};

export const dollarsFormat = (dollars, optionalPrecision = 2) => {
    dollars = removeNonNumeric(dollars);

    if (dollars >= 1000000) {
        return `${toPrecision(dollars / 1000000, 2)}M`;
    }

    if (dollars >= 100000) {
        return `${toPrecision(dollars / 1000, 0)}K`;
    }

    if (dollars >= 10000) {
        return `${toPrecision(dollars / 1000, 1)}K`;
    }

    if (dollars >= 1000) {
        return `${toPrecision(dollars, 0)}`;
    }

    if (dollars >= 100) {
        return `${toPrecision(dollars, optionalPrecision || 0)}`;
    }

    const precisionValue = toPrecision(dollars, optionalPrecision);

    if (isNaN(precisionValue)) return "0";

    return precisionValue;
};

export const toPrecision = (numericValue, precision = 2) => {

    if (_no(numericValue)) {
        const prefix = "0.";
        return prefix.padEnd(precision + prefix.length, "0");
    }

    const stringValue = getPreciseValue(
        numericValue
    );

    return getPrettyValue(
        stringValue,
        precision
    );
};

export const getPreciseValue = (numericValue) => {

    if (_no(numericValue)) {
        return "0.00";
    }

    const startPosition = 0;
    const numericString = numericValue?.toString();
    const strippedValue = parseInt(numericString)?.toString();
    const finalPosition = strippedValue.length + 3;

    return numericString.substr(
        startPosition,
        finalPosition
    );
};

export const getPrettyValue = (uglyValue, precision = 2) => {

    if (_no(uglyValue)) return "0.".padEnd(precision, "0");

    return parseFloat(
        uglyValue
    ).toLocaleString("en-US", {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision
    });
};

export const shortenAddress = (address) => {
    if (typeof address === "string" && address.length > 0)
        return address.substring(0, 6) + "..." + address.substring(address.length - 4);

    return "";
};

export const displayWithSkeleton = ({
    isLoading,
    data,
    skeletonWidth = "100%",
    isEmpty,
    emptyPlaceholder = "-"
}) => {
    if (isLoading && _no(isEmpty)) {
        return <Skeleton width={skeletonWidth} />;
    }

    if (_no(data) || isEmpty) {
        return emptyPlaceholder;
    }

    return data;
};

export const displayWithLoaderObj = ({
    isLoading,
    data,
    loader = "—",
    emptyPlaceholder = "-"
}) => {
    if (isLoading) {
        return <div style={{transform: "scale(0.8)", display: "flex", justifyContent: "center" }}><Loader /></div>;
    }

    if (_no(data)) {
        return emptyPlaceholder;
    }

    return data;
};

export const displayWithLoader = (
    isLoading,
    data,
    loader = "—",
    emptyPlaceholder = "-"
) => {
    if (isLoading) {
        return loader;
    }

    if (_no(data)) {
        return emptyPlaceholder;
    }

    return data;
};

export const formatDate = (date, format, showDefault = false) => {
    const convertedDate = moment(date);

    if (convertedDate.isValid()) {
        return convertedDate.format(format);
    }

    if (showDefault) {
        return moment().format(format);
    }
};

export const floorToDecimals = (value, decimals) => {
    const factor = 10 ** decimals;
    return Math.floor(value * factor) / factor;
}

export const formatMoney = (
    value,
    isDollar = false,
    allowNegative = false,
    precision = 2
) => {

    if (_no(allowNegative) && value < 0) {
        value = 0;
    }

    const dollarSign = isDollar
        ? "$"
        : "";

    const formattedValue = dollarsFormat(
        value,
        precision
    );

    let prefix = value < 0
        ? "-"
        : "";

    let displayValue = formattedValue;

    // calculate the display threshold based on the desired precision. e.g. precision = 2, displayThreshold = 0.01
    const displayThreshold = Math.pow(10, -precision);

    const shouldDisplayThreshold =
        value > 0
        && parseFloat(formattedValue) < displayThreshold;

    if (shouldDisplayThreshold) {
        prefix = "<";
        displayValue = displayThreshold;
    }

    if (parseFloat(displayValue) < 0.01) {
        displayValue = "0.00"
        prefix = "";
    }

    return `${prefix}${dollarSign}${displayValue}`;
};

export const formatUSD = (
    value,
    hideDollarSign = false
) => {
    const isLessZero = parseFloat(value) < 0.01 && value > 0;

    let prefix = isLessZero
        ? "< "
        : "";

    let formattedValue = isLessZero
        ? "0.01"
        : dollarsFormat(
            value
        );

    const dollarSign = hideDollarSign
        ? ""
        : "$";

    return `${prefix}${dollarSign}${formattedValue}`;
};

export const formatMoneyDashed = (value, isDollar = false) => {

    const isFetching = _no(
        parseFloat(value)
    );

    if (_no(isFetching)) {
        value = formatMoney(
            value,
            isDollar
        );
    }

    return displayWithLoader(
        isFetching,
        value
    );
};

export const formatDecimals = (value, defaultDisplay = "0.00", decimals = 2) => {
    if (_no(value)) {
        return defaultDisplay;
    }

    if (!isNaN(parseFloat(value))) {
        const factor = Math.pow(10, decimals);
        const flooredValue = Math.floor(value * factor) / factor;
        return flooredValue.toFixed(decimals);
    }

    return value;
};

export const computeUSDAmount = (decimals, amount, price, priceDecimals = 0) => {
    if (amount && price) {

        const amountFormatted = parseUnits(
            amount,
            decimals
        );

        const priceFormatted = parseUnits(
            price,
            priceDecimals
        );

        const totalPrice = amountFormatted * priceFormatted;

        return formatUnits(
            totalPrice,
            decimals + priceDecimals
        );
    }
};

export const _no = (value) => {
    return !value;
};

export const _notEmpty = (value) => {
    return !!value;
};

export const _getETHValue = (tokenName, value) => {
    const isETH = NATIVE_TOKENS.includes(
        tokenName
    );

    if (isETH) {
        return value;
    }
};

export const _isNotEmptyNftId = (nftId) => {
    if (_notEmpty(nftId)) {
        return nftId !== -1;
    }

    return false;
};

export const _isNoDataNft = (nftId) => {
    if (_notEmpty(nftId)) {
        return nftId === -1;
    }
};

export const _getNotETHValue = (tokenName, value) => {
    const isETH = NATIVE_TOKENS.includes(
        tokenName
    );

    if (_no(isETH)) {
        return value;
    }
};

export const _checkThreshold = (amount) => {
    if (amount < ZERO_THRESHOLD) {
        amount = "0.00";
    }

    return amount;
};

export const _checkConvertedThreshold = (amount, price) => {
    const convertedAmount = amount * price;

    if (convertedAmount < USD_ZERO_THRESHOLD) {
        amount = "0.00";
    }

    return amount;
};

export const _booleanToText = (value) => {
    return value
        ? "Yes"
        : "No";
};

export const _intPrefix = (isPositive) => {
    return _no(isPositive)
        ? "-"
        : "";
};

export const _defaultArray = (value) => {
    return value ?? [];
};

export const _sum = (...values) => {
    return values?.reduce(
        (a, b) => a + Number(b ?? 0),
        0
    );
};

export const removeBigInt = (obj) => {
    let data = {};
    for (const key in obj) {
        if (typeof obj[key] !== 'bigint') {
            data[key] = obj[key];
        }
    }

    return data;
};

export const toZeroIfBelowOne = (value) => {
    if (value < 1) {
        return 0;
    }
    return value;
}

export const toZeroIfSuperSmall = (value) => {
    if (value < 0.0000) {
        return 0;
    }
    return value;
}

export const toZeroIfExponential = (value) => {
    if (value === null || value === undefined) return;

    if (value?.toString()?.includes("e")) {
        return 0;
    }

    return value;
}

export const scientificToDecimal = (value) => {

    if (value === null || value === undefined) return;

    // If the value is not a number, return it as is
    if (_no(value?.toString()?.includes("e"))) {
        return value;
    }

    let valueStr = value?.toString();

    // if the number is already in decimal notation, return it as is
    if (_no(valueStr.includes("e"))) {
        return valueStr;
    }

    // handle scientific notation
    let [base, exponent] = valueStr.split("e");

    exponent = parseInt(
        exponent,
        DECIMALS_BASE_TEN
    ); // Convert the exponent to an integer

    // split the base into its integer and fractional parts
    let [integerPart, fractionalPart] = base.split(".");

    // if there is no fractional part, just adjust the integer part
    if (_no(fractionalPart)) {
        fractionalPart = "";
    }

    if (exponent > 0) {
        // positive exponent: shift the decimal point to the right
        let wholePart = integerPart + fractionalPart.slice(
            0,
            exponent
        );

        let decimalPart = fractionalPart.slice(
            exponent
        );

        // ensure the decimal part is not empty
        if (decimalPart === "") {
            return wholePart;
        } else {
            return `${wholePart}.${decimalPart}`;
        }

    } else {
        // negative exponent: shift the decimal point to the left
        let zeros = '0'.repeat(Math.abs(exponent) - 1);
        return `0.${zeros}${integerPart}${fractionalPart}`;
    }
};

export const fetchRewardsData = async (address, hash, bash) => {
    const response = await fetch(
        `${BACKEND_URL}/api/getReward/${address}/${hash}/${bash}`
    );
    const objResult = await response.json();
    return objResult;
};

export const fetchVaultTV = async () => {
    const response = await fetch(
        `https://api.wise.one/getVaultsTVL`
    );
    const objResult = await response.json();
    return objResult;
};

export const fetchPointsData = async (address) => {
    const response = await fetch(
        `${BACKEND_URL}/getPointsAll/${address}/`
    );
    const objResult = await response.json();
    return objResult;
};

export const fetchRefereesData = async (address) => {
    const response = await fetch(
        `${BACKEND_URL}/api/getReferees/${address}/`
    );
    const objResult = await response.json();
    return objResult;
};

export const fetchUserEtherTotalPoints = async () => {
    const response = await fetch(
        `${BACKEND_URL}/api/getSnapshotTotal/points`
    );
    const objResult = await response.json();
    return objResult;
};

export const fetchUserStableTotalPoints = async () => {
    const response = await fetch(
        `${BACKEND_URL}/api/getSnapshotTotal/stablePoints`
    );
    const objResult = await response.json();
    return objResult;
};

export const formatToFiveDecimalsOrThreshold = (value) => {
    const parsedValue = scientificToDecimal(value);

    if (parsedValue > 0) {
        const formattedAmount = Number(parsedValue).toFixed(5);

        return formattedAmount > 0
            ? formattedAmount
            : "< 0.00001";
    }

    return "0.00000";
};

export const isExistingWalletAddress = async (address) => {
    try {
        return ethers.utils.isAddress(address);
    } catch {
        return false;
    }
};

export const getEnsAddress2 = async (name, chainId, publicClient) => {

    const formattedEnsName = name?.split('.').length === 1
        ? `${name}.eth`
        : name;

    try {
        const ensAddress = await publicClient.getEnsAddress({
            name: normalize(formattedEnsName),
        });

        return ensAddress;
    } catch {
        console.log("ens error");
        return null;
    }
};

const mainnetClient = createPublicClient({
    chain: mainnet,
    transport: http(`https://mainnet.infura.io/v3/1bffd3c056ee47f4ad9c4e004b6f1853`), // Use Infura, Alchemy, or another Mainnet RPC
});

export const getEnsAddressFromMainnet = async (name) => {
    try {
        const formattedEnsName = name.includes(".") ? name : `${name}.eth`;
        return await mainnetClient.getEnsAddress({
            name: normalize(formattedEnsName),
        });
    } catch (error) {
        console.error("ENS resolution error on Mainnet:", error);
        return null;
    }
};

export const getEnsAddressAcrossNetworks = async (name) => {
    return await getEnsAddressFromMainnet(name);
};

export const getEnsName2 = async (address, chainId) => {
    try {
        const ensName = await getEnsName({
            address,
            chainId
        });
        return ensName;
    } catch {
        return null;
    }
};

export const getEnsNameForAddress = async (address) => {
    const ensName = await getEnsName2(
        address,
        mainnet.id
    );
    return ensName;
};

export const checkIfValidEnsOrAddress = async (addressOrEnsName) => {
    try {
        const isWalletAddress = isWalletAddressFormat(
            addressOrEnsName
        );
        if (isWalletAddress) {
            const isValidAddress = await isExistingWalletAddress(
                addressOrEnsName
            );
            return isValidAddress;

        } else {
            const ensName = await getEnsAddressAcrossNetworks(
                addressOrEnsName
            );
            return _notEmpty(ensName);
        }
    } catch {
        return false;
    }
};

export const isWalletAddressFormat = (address) => {
    return ETH_ADDRESS_REGEX.test(
        address
    );
};

export const getAddressDisplay = (address) => {
    if (_no(address)) {
        return "";
    }

    return isWalletAddressFormat(address)
        ? shortenAddress(address)
        : address;
};

export const setPromoterAddress = async ({ walletAddress, promoterAddress, signedMessage }) => {
    const response = await fetch(
        `${BACKEND_URL}/setPromoterSecure/${walletAddress}/${promoterAddress}/${signedMessage}`,
        // {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: signatureData
        //         ? JSON.stringify(signatureData)
        //         : undefined
        // }
    );

    const objResult = await response.json();
    return objResult;
};

export const isAddressSignerOfMessage = async ({
    address,
    message,
    signature
}) => {
    const messageAddress = await recoverMessageAddress({
        message,
        signature
    });

    return messageAddress?.toLowerCase().trim() === address?.toLowerCase().trim();
};

export const trimAddressDisplay = (address = "") => {
    if (address?.length < 12) {
        return address;
    }

    return `${address?.substr(0, 9)}...`;
};

export const randomIntFromRange = ({ min, max }) => {
    return Math.floor(
        Math.random() * (max - min + 1) + min
    );
};

export const calculateAvailablePoolFunds = ({
    poolTotalBalance,
    poolUtilizationRate,
    poolUtilizationThreshold,
}) => {

    if (_no(poolTotalBalance)) {
        return null;
    };

    const totalAvailableFunds = parseFloat(
        poolTotalBalance
    );

    const utilizationRate = poolUtilizationRate / 100;

    // @TODO: better wrap Math.max to custom function in utils
    const availableFundsPercentage = Math.max(
        0,
        poolUtilizationThreshold - utilizationRate
    );

    const availableFunds = parseFloat(
        totalAvailableFunds * availableFundsPercentage
    );

    return availableFunds;
};

export const generateEventSignature = (eventAbi) => {
    const eventName = eventAbi.name;
    const inputTypes = eventAbi.inputs.map((input) => {
        return input.type;
    });

    const eventSignature = `${eventName}(${inputTypes.join(",")})`;
    return eventSignature;
};

// export const showInfoDefiLama = (pool) => {
//     const defiLLamaLink = DEFI_LLAMA_POOL_YIELD_LINKS[pool.toUpperCase()];

//     if (defiLLamaLink) {
//         window.open(defiLLamaLink);
//         return;
//     }

//     window.open(`${DEFI_LLAMA_BASE_LINK}/protocol/wise-lending-v2#yields`);
// };

export const getOrdinalSuffix = (number, defaultValue) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = number % 100;

    if (number === 0) {
        return defaultValue;
    }

    return (
        number +
        (suffixes[(value - 20) % 10] ||
            suffixes[value] ||
            suffixes[0])
    );
};

export const getRemainingDaysAndNextClaimDate = (timestamp) => {

    let remainingDays = 0;
    let nextClaimDate = '';
    const windowDays = 30;

    if (timestamp) {


        const lastClaimedDate = new Date(
            timestamp?.toString() * 1000
        );

        // @TODO: Move this to a helper function or we alread have this in utils
        const millisecondsPerDay = 24 * 60 * 60 * 1000;

        const currentDate = new Date();

        const differenceInMilliseconds = currentDate
            - lastClaimedDate;

        const daysElapsed = Math.floor(
            differenceInMilliseconds / millisecondsPerDay
        );

        remainingDays = windowDays - (daysElapsed % windowDays);
        nextClaimDate = new Date(currentDate.getTime() + (remainingDays * millisecondsPerDay));
    }

    const remainingDaysCorrect = remainingDays > windowDays
        ? 0
        : remainingDays;

    return {
        remainingDays: remainingDaysCorrect,
        nextClaimDate: nextClaimDate
    };
};
