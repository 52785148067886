import React from "react";
import { useState, useEffect } from "react";
import styles from "./Details.module.sass";
import cn from "classnames";
// import Product from "./Product";
import useConfig from "../../../../../customHooks/useConfig";
import { shortenAddress } from "../../../../../utils";
import Parameter from "./Parameter";
// import TooltipGlobal from "../../../components/TooltipGlobal";
// import Editor from "../../../../components/Editor";
import { useScrapeReferral } from "../../../../../customHooksWise/useScrapeReferral";
import { showToast } from "../../../../../utils";
import { CONFIRM_TEXT } from "../../../../../utils/config";
import Loader from "../../../../../components/Loader";

const Details = ({
    referralId,
    stakeData,
    // currentWiseDay,
    stakeId,
    // address,
    // currentAPY,
    onClose,
    referralData,
    setDoRefetch,
}) => {

    const config = useConfig();
    const DEFAULT_TEXT = "Collect Rewards";

    // const [content, setContent] = useState();
    // console.log(stakeData, 'stakeData');
    const [isLoading, setIsLoading] = useState(false);
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);

    let title = "Referral Details";
    let titleClass = "title-green";
    let stakeStatus = "In Progress";
    let referralStatus = "Active Referral";
    // let referralClass = "title-green";

    if (stakeData.matureStake) {
        title = "Referral Details";
        titleClass = "title-green";
        stakeStatus = "Matured";
        referralStatus = "Expired Referral";
    }

    if (stakeData.closedStake) {
        title = "Referral Details";
        titleClass = "title-green";
        stakeStatus = "Closed Mature Stake";
        referralStatus = "Expired Referral";
    }

    if (stakeData.closedPrematurely) {
        title = "Referral Details";
        titleClass = "title-green";
        stakeStatus = "Prematurely Terminated";
        referralStatus = "Terminated Referral";
    }

    if (stakeData.pendingStake) {
        title = "Referral Details";
        titleClass = "title-green";
        stakeStatus = "Pending Stake";
        referralStatus = "Pending Referral";
    }

    /*
    const apyLabel = stakeData.isMature
        ? "N/A"
        : currentAPY;
    */

    const item = {
        parameters: [
            {
                title: "Referral Status",
                // content: "330 days",
                downloadedStatus: referralStatus,
            },
            {
                title: `Referred Staker`,
                content: `${shortenAddress(referralData.staker)}`,
            },
            {
                title: "Referred Value",
                content: `${stakeData.stakedAmountFormatted} (${stakeData.stakaedAmountInUSD2})`,
            },
            {
                title: "Referral Duration",
                content: `${stakeData.lockDaysFormatted} (100%)`,
            },
            {
                title: "Remaining Saturation",
                content: `${stakeData.pendingStake ? "Starts Tomorrow" : stakeData.daysLeftFormatted} (${(100 - parseFloat(stakeData.stakeProgress)).toFixed(2)}%)`,
            },
            /*{
                title: "Rewards Scraped",
                downloadedStatus: `${previousScrapeDaysAgo}`,
            },*/
            {
                title: "Available Rewards",
                content: `${referralData.referralInterestFormatted} ($${referralData.referralAmountInUSD})`,
            },
        ],
        properties: [
            {
                title: "Referred Stake Status",
                downloadedStatus: `${stakeStatus}`,
            },
            {
                title: `Referred Staker`,
                content: `${shortenAddress(referralData.staker)}`,
            },
            /*{
                title: "Stake ID",
                content: `${stakeData.stakeID}`,
            },*/
            /*{
                title: "Referral ID",
                content: `${referralData.stakeID}`,
            },*/
            {
                title: "Referred Stake Maturity",
                content: `${stakeData.servedDaysFormatted} (${parseFloat(stakeData.stakeProgress).toFixed(2)}%)`,
            },
            {
                title: "Referred Stake Value",
                content: `${stakeData.stakedAmountFormatted} (${stakeData.stakaedAmountInUSD2})`,
            },
        ],
        postdata: [
            {
                title: "Referral Status",
                downloadedStatus: stakeStatus,
            },
            {
                title: "Amount Staked",
                content: `${stakeData.stakedAmountFormatted} (${stakeData.stakaedAmountInUSD2})`,
            },
            {
                title: "Staked Duration",
                content: `${stakeData.servedDaysFormatted} (${parseFloat(stakeData.stakeProgress).toFixed(2)}%)`,
            },
            {
                title: "Termination Fee",
                content: `-${stakeData.penaltyAmountFormatted} (${stakeData.terminationFeeInUSD2})`,
            },
            {
                title: "Rewards Paid",
                content: `+${stakeData.stakeRewards} (${stakeData.stakeRewardsDynamicInUSD})`,
            },
            {
                title: "Amount Returned",
                content: `${stakeData.terminationPayoutFormatted} (${stakeData.terminationPayoutInUSD})`,
            }
        ]
    }

    const firstRowParams = stakeData.closedStake
        ? item.parameters
        // ? item.postdata
        : item.parameters;

    const scrapeReferral = useScrapeReferral({
        config: config,
        referrals: [referralId],
    });

    const handleScrapeRewards = async () => {

        if (buttonText === "Done") {
            onClose();
            return;
        }

        await scrapeReferral.scrapeReferrals({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonText(
                    CONFIRM_TEXT
                );
            }
        });
    }

    useEffect(() => {

        if (scrapeReferral.isPending) {
            return;
        }

        if (scrapeReferral.error && isLoading) {
            setIsLoading(false);
            setButtonText(
                DEFAULT_TEXT
            );
            showToast(
                "User rejected the request.",
                {
                    type: "warning"
                }
            );
        }

        if (scrapeReferral.hasError && isLoading) {
            setIsLoading(false);
            setButtonText(
                DEFAULT_TEXT
            );
            showToast(
                "User rejected the request.",
                {
                    type: "warning"
                }
            );
        }

        if (scrapeReferral.isConfirming && isLoading) {
            setButtonText(
                "Collecting..."
            );
        }

        if (scrapeReferral.isConfirmed && isLoading) {
            setIsLoading(false);
            setButtonText(
                "Done"
            );
            showToast(
                "Congrats! Rewards collected successfully",
                {
                    type: "success"
                }
            );
            referralData.referralInterestFormatted = "+0.00 WISE";
            referralData.referralAmountInUSD = "$0.00";
            setDoRefetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrapeReferral]);

    return (
        <>
            <div className={styles.details}>
                <div className={cn(titleClass, styles.title)}>
                    {title}
                </div>
                <div className={cn(
                    styles.row,
                    {
                        [styles.closed]: stakeData.closedStake,
                    }
                )}>
                    <div className={styles.col}>
                        <div className={styles.parameters}>
                            {firstRowParams.map((x, index) => (
                                <Parameter isMature={true} item={x} key={index} />
                            ))}
                        </div>
                        {referralData.referralInterestValue !== "0" && (
                            <div className={styles.btns}>
                                <button
                                    onClick={() => {
                                        handleScrapeRewards(stakeId);
                                    }}
                                    className={cn(
                                        "button-full",
                                        styles.button,
                                        {
                                        disabled: isLoading,
                                        }
                                    )}
                                >
                                    {buttonText === "Collecting..." && (
                                        <Loader className={styles.loader}></Loader>
                                    )}
                                    {buttonText}
                                </button>
                            </div>
                        )}
                        {referralData.referralInterestValue === "0" && (
                        <div className={cn(styles.btns, styles.terminate)}>
                            <button
                                onClick={() => {
                                    onClose();
                                }}
                                className={cn("button-stroke", styles.button)}
                            >
                                Done
                            </button>
                        </div>
                        )}
                    </div>
                    {/*<div className={styles.col}>
                        <div className={styles.group}>
                            <div className={styles.box}>
                                <ul className={styles.list}>
                                {item.properties.map((x, index) => (
                                    <Parameter isMature={stakeData.matureStake} item={x} key={index} />
                                ))}
                                </ul>
                            </div>
                        </div>
                    </div>*/}
                </div>
            </div>
            {/*<TooltipGlobal />*/}
        </>
    );
};

export default Details;
