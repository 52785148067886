import React, {
    // useMemo,
    useState
} from "react";
import styles from "./Market.module.sass";
// import cn from "classnames";
// import Checkbox from "../../../components/Checkbox";
// import Loader from "../../../components/Loader";
import Row from "./Row";

// data
import useConfig from "../../../customHooks/useConfig";

const Market = ({ tokensUtilization, loading }) => {
    const config = useConfig();

    // const [chooseAll, setСhooseAll] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);

    const handleChange = (id) => {
        if (selectedFilters.includes(id)) {
            setSelectedFilters(selectedFilters.filter((x) => {
                return x !== id;
            }));
        } else {
            setSelectedFilters((selectedFilters) => [
                ...selectedFilters,
                id
            ]);
        }
    };

    return (
        <div className={styles.market}>
            <div className={styles.table}>
                <div className={styles.row}>
                    {/*<div className={styles.col}>
                        <Checkbox
                            className={styles.checkbox}
                            value={chooseAll}
                            onChange={() => setСhooseAll(!chooseAll)}
                        />
                    </div>*/}
                    {/*<div className={styles.col}>Product</div>*/}
                    <div className={styles.col}>Pool Type</div>
                    <div className={styles.col}>Provided Funds</div>
                    <div className={styles.col}>Borrowed Funds</div>
                    <div className={styles.col}>Available Funds</div>
                    <div className={styles.col}>Utilization Rate</div>
                    <div className={styles.col}>Pool Details</div>
                    {/*<div className={styles.col}>Supply APY</div>
                    <div className={styles.col}>Borrow APY</div>*/}
                </div>
                {tokensUtilization?.map((token, index) => (
                    <Row
                        token={token}
                        key={index}
                        value={selectedFilters.includes(token.id)}
                        onChange={() => handleChange(token.id)}
                        config={config}
                        loading={loading}
                    />
                ))}
            </div>
            {/*<div className={styles.foot}>
                <button className={cn("button-stroke button-small", styles.button)}>
                    <Loader className={styles.loader} />
                    <span>Load more</span>
                </button>
            </div>*/}
        </div>
    );
};

export default Market;
