// import React, { useState } from "react";
import React from "react";
import styles from "./CreateReferral.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
// import Dropdown from "../../../components/Dropdown";
import TextInput from "../../../components/TextInput";
import { useSelector } from "react-redux";
import { showToast } from "../../../utils";
import { usePrivy } from '@privy-io/react-auth';

const CreateReferral = ({
    className,
    onShowCustomerList,
    showCopyButton
}) => {

    const { ensName, address } = useSelector((state) => state.settings);

    const {
        login,
    } = usePrivy();


    const adjustedName = ensName && ensName.replace(
        ".eth", ""
    );
    const walletId = ensName || address;

    const referralLink = walletId
        ? `wise.one/r/${adjustedName || address}`
        : `wise.one/r/connect_your_account`;

    const handleCopyToClipboard = () => {
        if (!referralLink) return;
        if (!walletId) {
            login();
            return;
        }

        navigator.clipboard.writeText(`https://${referralLink}`);

        showToast(
            "Referral link copied to clipboard",
            {
                type: "success",
            }
        );
    };

    return (
        <Card
            className={cn(styles.card, className)}
            title="Your Referral Network"
            classTitle="title-green"
        >
            <div className={styles.create}>
                {/*<div className={styles.fieldset}>
					<Dropdown
						className={styles.dropdown}
						classDropdownHead={styles.dropdownHead}
						value={sorting}
						setValue={setSorting}
						options={options}
					/>
					<TextInput
						className={styles.field}
						name="link-product"
						type="text"
						placeholder="Or enter ENS name link"
						required
					/>
					<button className={cn("button-small", styles.button)}>Create Referral Link</button>
				</div>*/}
                <div className={styles.box}>
                    <div className={styles.label}>
                        Promote this link to earn lending points and staking rewards.
                    </div>
                    <TextInput
                        className={styles.field}
                        name="link-promote"
                        type="text"
                        disabled
                        placeholder={referralLink}
                        required
                        icon="link"
                        onCopyClick={handleCopyToClipboard}
                        copy
                    />
                    {!showCopyButton && (
                        <button
                            style={{ width: "100%", marginTop: "16px" }}
                            className={cn("button",
                                styles.button,
                                {
                                    "button-stroke": true,
                                    // disabled: loading
                                }
                            )}
                            onClick={onShowCustomerList}
                        >
                            Show Active Referrals
                        </button>
                    )}
                    {showCopyButton && (
                        <button
                            style={{ width: "100%", marginTop: "16px" }}
                            className={cn("button",
                                styles.button,
                                {
                                    "button-stroke": true,
                                    // disabled: loading
                                }
                            )}
                            onClick={handleCopyToClipboard}
                        >
                            {
                                walletId
                                    ? "Copy Referral Link"
                                    : "Connect Your Account"
                            }
                        </button>
                    )}
                </div>
                {/*
				<div className={styles.box}>
					<div className={styles.label}>Share to social accounts</div>
					<div className={styles.line}>
						<div className={styles.list}>
							{users.map((x, index) => (
								<div className={styles.avatar} key={index}>
									<img src={x.avatar} alt="Avatar" />
									<div className={styles.icon}>
										<Icon name={x.icon} size="12" />
									</div>
								</div>
							))}
						</div>
						<button className={cn("button-small", styles.button)}>
							<span>Share</span>
							<Icon name="arrow-right" size="24" />
						</button>
					</div>
				</div>
				*/}
            </div>
        </Card>
    );
};

export default CreateReferral;
