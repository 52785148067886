import cn from "classnames";
import React, {
    // use,
    useEffect,
    // useEffect,
    useMemo,
    useState
} from "react";
import { formatUnits, parseUnits } from "viem";
import { fromHex } from "viem";
// import { Range, getTrackBackground } from "react-range";
import { useAccount } from "wagmi";
import { useSelector } from "react-redux";
import { usePrivy } from '@privy-io/react-auth';
import TooltipGlobal from "../../../TooltipGlobal";
import useConfig from "../../../../customHooks/useConfig";
import { shortenAddress } from "../../../../utils";
import {
    _no,
    // addPendleLiquiditySingleToken,
    // calculateAvailablePoolFunds,
    computeUSDAmount,
    formatDecimals,
    // formatMoney,
    // formatMoneyDashed,
    // removeBigInt,
    toPrecision,
    formatMoneyDashed,
    removeNonNumeric,
    showToast,
} from "../../../../utils";

import { getCookie } from "../../../../utils/cookie";
import { useOpenStake } from "../../../../customHooksWise/useOpenStake";
// could be useful
// import { useAPYEstimate } from "../../../../customHooks/useAPYEstimate";

import {
    OPENING_TEXT,
    OPEN_FAILED_MESSAGE,
    // OPEN_SUCCESS,
    CONFIRM_TEXT,
    DEFAULT_DECIMALS,
    // APPROVE_IN_WALLET_TEXT,
    DONE_TEXT,
    TRY_AGAIN_TEXT,
    // APPROVING_TEXT,
    INSUFFICIENT_COST,
    NATIVE_TOKENS,
    NO_REFERRAL,
    APPROVING_TEXT,
} from "../../../../utils/config";
import Switch from "../../../Switch";
import TokenAmountInput from "../../../TokenAmountInput";
import Tooltip from "../../../Tooltip";
import styles from "./OpenStakeDetails.module.sass";
import Parameter from "../Parameter";
import SuccessPanel from "../../../Modal/SuccessPanel";
import Icon from "../../../Icon";
import Loader from "../../../Loader";
// import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useTokenAllowance } from "../../../../customHooks/useTokenAllowance";
// import NavSwitch from "../../../NavSwitch";

import useWiseBalance from "../../../../customHooksWise/useWiseBalance";
import useWiseGlobals from "../../../../customHooksWise/useWiseGlobals";
import Scrollbar from "../../../Scrollbar";
import { zeroAddress } from "viem";

// import { set } from "date-fns";
// import { is } from "date-fns/locale";
// import { useFarmingContext } from "../../../../screens/Farming/context";

const OpenStakeDetails = ({
    // item,
    token,
    // onDone,
    // doRefetch,
    // tokenBalance,
    // availableFunds,
    // tokenUtilization ,
    onClose,
    doRefetchStakes,
}) => {

    const {
        login,
    } = usePrivy();

    // const includeWETH = true;
    // const farmingContext = useFarmingContext();
    const { isConnected, address } = useAccount();
    // const { open } = useWeb3Modal();
    const config = useConfig();
    const { wiseNftStakes, WiseTokenContract } = config;

    const { TVL: totalValue } = useSelector(
        (state) => state.token
    );

    const DEFAULT_TEXT = "Create WISE Stake";
    const DEFAULT_APRROVE_TEXT = "Approve WISE";
    const wiseCost = totalValue?.wiseUsd;

    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [allowance, setAllowance] = useState(0);
    const [amount, setAmount] = useState("");
    const [duration, setDuration] = useState("");
    const [teamReferral, setTeamReferral] = useState(null);
    const [selectedToken, setSelectedToken] = useState(token);
    const [useETHBalance, setUseETHBalance] = useState(false);
    const [buttonApproveText, setButtonApproveText] = useState(
        DEFAULT_APRROVE_TEXT
    );

    const [transferable, setTransferable] = useState(false);

    const convertedAmount = removeNonNumeric(
        amount || 0
    );

    const stakeAmount = parseUnits(
        convertedAmount,
        "18"
    );

    const tokenAllowance = useTokenAllowance({
        config,
        token: {
            ...WiseTokenContract,
            isAavePool: true,
        },
        userAccount: address,
        amount: stakeAmount,
        customContract: wiseNftStakes
    });

    const preApprovedDefault = allowance && allowance >= stakeAmount;

    const [preApproved, setPreApproved] = useState(
        preApprovedDefault
    );

    let showApproval = amount
        && transferable
        && _no(useETHBalance)
        && buttonText !== DONE_TEXT
        && buttonText !== OPENING_TEXT
        && _no(preApproved);
        // && _no(isFetchingData);

    const handleApprove = () => {
        tokenAllowance.approve({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonApproveText(
                    CONFIRM_TEXT
                );
            }
        });
    };

    useEffect(() => {
        if (tokenAllowance.allowance) {
            setAllowance(
                tokenAllowance.allowance
            );

            if (tokenAllowance.allowance >= stakeAmount) {
                setPreApproved(true);
            }

            if (tokenAllowance.allowance < stakeAmount) {
                setPreApproved(false);
            }
        }
    }, [tokenAllowance.allowance, stakeAmount]);

    useEffect(() => {

        if (tokenAllowance.hasError && isLoading) {
            setIsLoading(false);
            handleError(
                tokenAllowance.error
            );
            // setTransactionHash("");
            setButtonApproveText(
                DEFAULT_APRROVE_TEXT
            );
        }

        if (tokenAllowance.error && isLoading) {
            setIsLoading(false);
            handleError(
                tokenAllowance.error
            );
            // setTransactionHash("");
            setButtonApproveText(
                DEFAULT_APRROVE_TEXT
            );
        }

        if (tokenAllowance.isConfirming && isLoading) {
            setButtonApproveText(
                APPROVING_TEXT
            );
        }

        if (tokenAllowance.isConfirmed && buttonApproveText === APPROVING_TEXT) {

            setIsLoading(false);

            const { logs } = tokenAllowance.transactionData;

            const spendingCap = fromHex(
                logs[0].data,
                "bigint"
            );

            setAllowance(
                spendingCap
            );

            if (spendingCap >= amount) {
                setPreApproved(true);
            }

            const spendingCapAmount = formatUnits(
                spendingCap,
                "18"
            );

            const formattedSpendingCapAmount = formatMoneyDashed(
                spendingCapAmount
            );

            showToast(
                `Approved ${formattedSpendingCapAmount} WISE - you can stake now`,
                {
                    type: "success",
                }
            );

            setButtonApproveText(
                DEFAULT_APRROVE_TEXT
            );


            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenAllowance]);

    const wcookie = getCookie("w");

    const { wiseTokenBalanceFormatted } = useWiseBalance({
        config,
        userAccount: address
    });

    const { data: globalData } = useWiseGlobals({
        config
    });

    const isETH = NATIVE_TOKENS.includes(
        selectedToken.name
    );

    const ethTokenAmount = _no(isETH)
        ? 0
        : convertedAmount;

    const durationBonusFormula = (days) => {

        if (!days) {
            return 0.00;
        }

        if (days <= 0) {
            return 0.00;
        }

        days = parseFloat(days.replace(/,/g, ''));

        if (days <= 1825) {
            return days * 0.0136986301369863;
        } else {
            return (Math.min(days, 15330) - 1825) * 0.00037023324 + 25;
        }
    };

    const getRefferalAddress = () => {

        if (useTeamReferral) {
            return shortenAddress(teamReferral) || NO_REFERRAL;
        }

        return shortenAddress(wcookie) || shortenAddress(teamReferral) || NO_REFERRAL;
    }

    const getDailyRewards = () => {

        const globalWise = totalValue?.globalTotalWISE || 0;
        let realAmount = parseFloat(amount.replace(/,/g, ''));

        if (useETHBalance) {
            realAmount = pureAmountInUSD / wiseCost * 0.99;
        }

        const totalReward = globalWise * 0.03 / 365;
        const durationBonus = parseFloat(durationBonusFormula(duration));
        const referralBonus = getRefferalAddress().includes("0x") ? 10.00 : 0.00;
        const totalBonus = parseFloat((durationBonus + referralBonus) / 100);
        realAmount = realAmount + (realAmount * totalBonus);
        const realAmountInShares = (realAmount / globalData.sharePrice);
        const inputAmountPercent = realAmountInShares / globalData.totalShares;
        const dailyRewards = parseFloat(totalReward * inputAmountPercent);
        return toPrecision(dailyRewards);
    }

    // const [finalAPY, setFinalAPY] = useState(0);
    // const [initiateSubmit, setInitiateSubmit] = useState(false);
    const [max, setMax] = useState(token.balanceFormatted);
    const [useTeamReferral, setUseTeamReferral] = useState(false);

    const { pricingFeeds, walletBalance } = useSelector(
        (state) => state.token
    );

    const ethTokenPriceData = pricingFeeds.find(
        item => item.name === "ETH"
    );

    const pureAmountInUSD = computeUSDAmount(
        DEFAULT_DECIMALS,
        convertedAmount,
        useETHBalance
            ? ethTokenPriceData?.price
            : wiseCost ? wiseCost.toString() : "0.00",
        2
    );

    const [isLoading, setIsLoading] = useState(false);
    const [successfullOpen, setSuccessfullOpen] = useState();
    const [finishOpen, setFinishOpen] = useState(false);
    const [transactionHashLink, setTransactionHashLink] = useState("");


    const getStakingAmount = () => {
        const val = toPrecision((amount ? amount.replace(/,/g, '') : 0));

        if (useETHBalance) {
            return toPrecision(pureAmountInUSD / wiseCost * 0.99);
        }

        return val;
    }

    const parameters = useMemo(() => [
        {
            title: "Staking Amount",
            content: `${getStakingAmount()} WISE`,
        },
        {
            title: "Stake Duration",
            content: `${duration ? duration : 0} ${parseInt(duration ? duration.replace(/,/g, '') : 0) === 1 ? 'Day' : 'Days'}`,
            // should count from tomorrow + duration
            // tooltip: `From ${dayjs().add(1, 'days').format('MMM DD, YYYY')} - Till ${dayjs().add(parseInt(duration && duration.replace(/,/g, '') || 0) + 1, 'days').format('MMM DD, YYYY')}`,
            tooltip: `Starting from tomorrow`,
        },
        {
            title: "Duration Bonus",
            content: `${parseFloat(durationBonusFormula(duration)).toFixed(2)}%`,
            isAPY: true,
            tooltip: "Longer stakes get a bonus",
        },
        {
            title: "Referral Address",
            content: `${getRefferalAddress()}`,
            isAPY: true,
            isGreen: false,
            // tooltip: "Check if you are using a referral",
        },
        {
            title: "Referral Bonus",
            content: `${getRefferalAddress().includes("0x") ? "10.00%" : "0.00%"}`,
            isAPY: true,
            tooltip: "Extra bonus for using a referral",
        },
        {
            title: "Daily Estimated Rewards",
            content: `${getDailyRewards()} WISE`,
        },
        {
            title: "Total Estimated Rewards",
            content: `${toPrecision(getDailyRewards() * parseFloat(duration ? duration.replace(/,/g, '') : 0))} WISE`,
        },
        {
            title: "Preferred Stake Type",
            content: `${transferable ? "Transferable" : "Scrapable"}`,
            isAPY: true,
            gray: true,
            tooltip: "Either allows to transfer stake or scrape rewards",
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [
        amount,
        duration,
        useTeamReferral,
        useETHBalance,
        transferable,
    ]);

    const wiseToken = {
        name: "WISE",
        icon: "/images/icons/wise.svg",
        customIcon: "/images/icons/wise.svg",
        customWise: true,
        decimals: 18,
        price: 10.00,
    }

    useEffect(() => {
        if (!useETHBalance) {
            setSelectedToken(wiseToken);
            setMax(wiseTokenBalanceFormatted);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wiseTokenBalanceFormatted, useETHBalance]);

    /*
    const formattedAmount = formatMoneyDashed(
        amount
    );
    */

    const OPEN_SUCCESS_MESSAGE = `Stake Created Successfully`;

    const handleError = (err) => {
        const error = err.details || err.message || err;
        showToast(
            error,
            {
                type: "warning"
            }
        )
    };

    const handleQuickSelect = (option) => {

        const aboveZero = parseFloat(max) > 0;
        const amount = option === "max" && aboveZero
            ? formatDecimals(max)
            : "";

        setAmount(
            amount
        );
    };

    const handleClearDuration = () => {
        setDuration(
            "null"
        );
    };

    const handleSwitch3 = () => {
        setTransferable(
            !transferable
        );
    }

    const handleSwitch2 = () => {

        setUseTeamReferral(
            !useTeamReferral
        );

        const tealReferrals = [
            "0x641AD78BAca220C5BD28b51Ce8e0F495e85Fe689",
            "0xa803c226c8281550454523191375695928DcFE92",
            "0xb49949Aee8F59095B5E297f84140DC4458D87886", // Eric
            "0xE58F13c04fA451556C60F86c002de08aAa388A52", // Peter
            "0x641AD78BAca220C5BD28b51Ce8e0F495e85Fe689",
            "0xa803c226c8281550454523191375695928DcFE92",
            "0x7FE340942cE108d929fA284Cc93AbCB6e48ed172", // Rene
            "0xA7f676d112CA58a2e5045F22631A8388E9D7D8dE", // Christoph
            "0x641AD78BAca220C5BD28b51Ce8e0F495e85Fe689",
            "0xa803c226c8281550454523191375695928DcFE92",
        ];

        const randomReferral = tealReferrals[
            Math.floor(Math.random() * tealReferrals.length)
        ];

        setTeamReferral(
            !useTeamReferral
                ? randomReferral
                : null
        );
    };

    const handleSwitch = () => {

        setMax(
            useETHBalance
                ? wiseTokenBalanceFormatted
                : walletBalance
        );

        const ethToken = {
            name: "ETH",
            icon: "/images/icons/eth.svg",
            decimals: 18,
            price: 10.00,
        }

        setSelectedToken(
            useETHBalance
                ? wiseToken
                : ethToken
        );

        setUseETHBalance(
            !useETHBalance
        );

        setAmount("");
    };

    // const minimumBorrow = 0.01;
    const openStake = useOpenStake({
        config,
        isETH: useETHBalance,
        isTransferable: transferable,
        amount: amount,
        duration: duration,
        referral: teamReferral || wcookie || zeroAddress,
    });

    const handleConfirm = async () => {

        if (buttonText === "Done") {
            onClose();
            return;
        }

        await openStake.openStake({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonText(
                    CONFIRM_TEXT
                );
            }
        });
    };

    useEffect(() => {

        if (openStake.error && isLoading) {
            setIsLoading(false);
            handleError(openStake.error);
            setButtonText(
                DEFAULT_TEXT
            );
        }

        if (openStake.hasError && isLoading) {
            setIsLoading(false);
            handleError(openStake.errorConfirming);
            setButtonText(
                TRY_AGAIN_TEXT
            );
        }

        if (openStake.isConfirming && isLoading) {
            setButtonText(
                OPENING_TEXT
            );
            setTransactionHashLink(
                `${config.linkBase}/tx/${openStake.hash}`
            );
        }

        if (openStake.isConfirmed && isLoading) {
            setSuccessfullOpen(true);
            setButtonText(
                DONE_TEXT
            );
            setIsLoading(false);
            setFinishOpen(true);
            doRefetchStakes();
            // doRefetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openStake]);


    const totalTokenAmount = parseFloat(ethTokenAmount);

    const isTokenInsufficient = totalTokenAmount > walletBalance
        && _no(isLoading)
        && _no(finishOpen);

    const durReal = parseInt(duration ? duration.replace(/,/g, '') : 0);
    const isInsufficient = parseFloat(convertedAmount) > parseFloat(max) && _no(finishOpen);
    const isDurationInvalid = parseFloat(durReal) < 1 || parseFloat(durReal) > 15330;
    const isSelfReferral = (teamReferral || wcookie) === address;

    const isInsufficientBalance = parseFloat(convertedAmount) > parseFloat(token.balanceFormatted)
        && _no(isLoading);

    const disableApprove = isInsufficient
        || isLoading
        || isTokenInsufficient
        || isInsufficientBalance;

    const disableConfirm = _no(amount)
        || isInsufficient
        || isLoading
        || isTokenInsufficient
        || isSelfReferral
        || isDurationInvalid;

    let confirmButtonText = buttonText;

    const d = parseFloat(duration ? duration.replace(/,/g, '') : 0);

    if (_no(amount)) {
        // confirmButtonText = `Enter ${selectedToken.name} amount`;
        confirmButtonText = `Enter Staking Amount`;
    } else if (isInsufficient) {
        confirmButtonText = `Insufficient ${selectedToken.name} Balance`;
    } else if (isTokenInsufficient) {
        confirmButtonText = INSUFFICIENT_COST;
    } else if (d < 1) {
        confirmButtonText = `Enter Stake Duration`;
    } else if (d > 15330) {
        confirmButtonText = `Exceeds Maximum Stake Duration`;
    } else if (isSelfReferral) {
        confirmButtonText = `Self-Referral Not Allowed`;
    }

    return (
        <>
            <div className={styles.details}>
                <div className={cn("title-green", styles.title)}>
                    Create WISE Stake
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <Scrollbar className={styles.scroll}>
                            <div className={styles.parameters}>
                                {parameters.map((x, index) => (
                                    <Parameter item={x} key={index} />
                                ))}
                            </div>
                        </Scrollbar>
                    </div>
                    <div className={styles.col} style={{ paddingTop: "0px" }}>
                        <div className={styles.group}>
                            {finishOpen &&
                                <div className={styles.successWrapper}>
                                    <SuccessPanel />
                                    <div style={{ visibility: "hidden" }}>12</div>
                                    <div className={styles.text}>
                                        {successfullOpen
                                            ? OPEN_SUCCESS_MESSAGE
                                            : OPEN_FAILED_MESSAGE
                                        }
                                    </div>
                                </div>
                            }
                            {_no(finishOpen) &&
                                <>

                                    <div className={styles.box}>
                                        <div className={styles.info}>
                                            Stake Settings
                                        </div>
                                        {/*<ul className={styles.list}>
                                            {approvals.map((x, index) => (
                                                <li key={index}>{x}</li>
                                            ))}
                                        </ul>*/}
                                        <div>
                                            <div className={styles.wrapperTitle}>
                                                <div>
                                                    Staking Amount
                                                </div>
                                            </div>
                                            <TokenAmountInput
                                                token={selectedToken}
                                                disabled={isLoading}
                                            >
                                                <TokenAmountInput.Input
                                                    amount={amount}
                                                    onChange={setAmount}
                                                    showClearButton={amount && _no(isLoading)}
                                                    onClearButtonClick={() => handleQuickSelect("clear")}
                                                />
                                                <TokenAmountInput.Details
                                                    amountInUsd={pureAmountInUSD}
                                                    balance={max}
                                                    onClick={() => handleQuickSelect("max")}>
                                                    Your Balance
                                                </TokenAmountInput.Details>
                                            </TokenAmountInput>
                                            <div className={styles.wrapper2}>
                                                <div className={cn(
                                                    styles.wrapperRow,
                                                    styles.wrapperItem
                                                    // styles.labelDisabled
                                                )}>
                                                    <div className={cn(
                                                        styles.label
                                                    )}>
                                                        I want to use ETH for staking
                                                        <Tooltip
                                                            className={styles.tooltip}
                                                            title={`ETH will be converted to WISE tokens`}
                                                            icon="info-stroke"
                                                            place="right"
                                                        />
                                                    </div>
                                                    <div className={styles.value}>
                                                        <Switch
                                                            // disabled={true}
                                                            className={styles.switch}
                                                            value={useETHBalance}
                                                            onChange={handleSwitch}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.wrapper2}>
                                                <div className={cn(
                                                    styles.wrapperRow,
                                                    styles.wrapperItem
                                                    // styles.labelDisabled
                                                )}>
                                                    <div className={cn(
                                                        styles.label
                                                    )}>
                                                        I want to use team referral address
                                                        <Tooltip
                                                            className={styles.tooltip}
                                                            title={`In case you don't have a referral address`}
                                                            icon="info-stroke"
                                                            place="right"
                                                        />
                                                    </div>
                                                    <div className={styles.value}>
                                                        <Switch
                                                            // disabled={true}
                                                            className={styles.switch}
                                                            value={useTeamReferral}
                                                            onChange={handleSwitch2}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.wrapperTitle}>
                                                <div>
                                                    Stake Duration
                                                </div>
                                            </div>
                                            <TokenAmountInput
                                                token={{
                                                    name: "Days",
                                                    customIcon: "clock",
                                                    icon: "days",
                                                }}
                                                disabled={isLoading}
                                            >
                                                <TokenAmountInput.Input
                                                    amount={duration}
                                                    placeholder={"0"}
                                                    integerOnly={true}
                                                    onChange={setDuration}
                                                    showClearButton={duration && _no(isLoading)}
                                                    onClearButtonClick={() => handleClearDuration()}
                                                />
                                            </TokenAmountInput>
                                            <div className={styles.quicks}>
                                                <div onClick={() => {setDuration("365")}} className={cn("status-green", styles.status)}>365 Days</div>
                                                <div onClick={() => {setDuration("730")}} className={cn("status-green", styles.status)}>2 Years</div>
                                                <div onClick={() => {setDuration("1095")}} className={cn("status-green", styles.status)}>3 Years</div>
                                                <div onClick={() => {setDuration("1460")}} className={cn("status-green", styles.status)}>4 Years</div>
                                                <div onClick={() => {setDuration("1825")}} className={cn("status-green", styles.status)}>5 Years</div>
                                                <div onClick={() => {setDuration("15330")}} className={cn("status-green", styles.status)}>42 Years</div>
                                            </div>
                                            <div className={styles.wrapper} style={{ marginTop: "26px", marginBottom: "0px" }}>
                                                <div className={cn(
                                                    styles.wrapperRow,
                                                    styles.wrapperItem
                                                    // styles.labelDisabled
                                                )}>
                                                    <div className={cn(
                                                        styles.label
                                                    )}>
                                                        I prefer this stake to be transferable
                                                        <Tooltip
                                                            className={styles.tooltip}
                                                            title={`Mints NFT to allow stake transfer, but disables scraping`}
                                                            icon="info-stroke"
                                                            place="right"
                                                        />
                                                    </div>
                                                    <div className={styles.value}>
                                                        <Switch
                                                            // disabled={true}
                                                            className={styles.switch}
                                                            value={transferable}
                                                            onChange={handleSwitch3}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            <div className={styles.btnsWrapper}>
                                {isConnected && (
                                    <>
                                        <div className={styles.btns}>
                                        {showApproval && (
                                            <button
                                                className={cn(
                                                    "button",
                                                    styles.button,
                                                    styles.full,
                                                    {
                                                        [styles.active]: amount,
                                                        disabled: disableApprove,
                                                        [styles.disabled]: disableApprove
                                                    }
                                                )}
                                                onClick={handleApprove}
                                            >
                                                {buttonApproveText === APPROVING_TEXT && <Loader className={styles.loader} />}
                                                <span>{buttonApproveText}</span>
                                                {/* <Icon name="info" size="20" /> */}
                                            </button>
                                        )}
                                        { !showApproval && (
                                            <button
                                                onClick={handleConfirm}
                                                className={cn(
                                                    "button",
                                                    styles.button,
                                                    styles.full,
                                                    {
                                                        disabled: disableConfirm,
                                                        [styles.disabled]: disableConfirm
                                                    })}>
                                                {(buttonText === OPENING_TEXT)
                                                    && <Loader className={styles.loader} />}
                                                <span>{confirmButtonText}</span>
                                            </button>
                                        )}
                                        </div>
                                        {transactionHashLink && finishOpen &&
                                            <a
                                                className={cn(
                                                    "button",
                                                    "button-stroke",
                                                    styles.button,
                                                    styles.transaction
                                                )}
                                                href={transactionHashLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                View Transaction Details
                                            </a>
                                        }
                                    </>
                                )}
                                {_no(isConnected) &&
                                    <div className={styles.btns}>
                                        <button
                                            className={cn(
                                                "button",
                                                styles.button,
                                                styles.full
                                            )}
                                            onClick={() => {
                                                // onClose();
                                                login();
                                                // open();
                                            }}
                                        >
                                            <Icon name="profile-circle" size="20" />
                                            <span>Connect Account</span>
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <TooltipGlobal />
        </>
    );
};

export default OpenStakeDetails;
