import React from "react";
import styles from "./MoreWidgets.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";

const MoreWidgets = ({ className }) => {
    return (
        <Card
            className={cn(styles.card, className)}
            title="More Widgets"
            classTitle="title-blue"
        >
            <div className={styles.text}>
                Available In Next Release...
            </div>
        </Card>
    );
};

export default MoreWidgets;
