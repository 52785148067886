import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import styles from "./Header.module.sass";
import Icon from "../Icon";
import Tvl from "./Tvl";
import { appLocalStorage } from "../../utils/localstorage";
// import User from "./User";
// import Search from "./Search";
// import Messages from "./Messages";
// import Notification from "./Notification";
// import KadoWidgetModal from "../KadoWidgetModal";
// import ConnectWalletModal from "../ConnectWalletModal";

import { useAccount } from "wagmi";

import {
    usePrivy,
    // useWallets
} from '@privy-io/react-auth';
// import { useSetActiveWallet } from '@privy-io/wagmi';

import Network from "./Network";
// import Loader from "../Loader";
/*
import {
    MAINNET_BRIDGE_URL,
    ARBITRUM_BRIDGE_URL,
} from "../../utils/config";
 */
// import { useWeb3Modal } from "@web3modal/wagmi/react";

const Header = ({ onOpen, expand }) => {

    const {
        // ready,
        user,
        // authenticated,
        login,
        connectWallet,
        // logout,
        // linkWallet
    } = usePrivy();

    const [visible, setVisible] = useState(false);
    const [initConnecting, setInitConnecting] = useState(true);

    // const [kadoWidgetVisible, setKadoWidgetVisible] = useState(false);
    // const { open } = useWeb3Modal();

    const { interfacePreference } = useSelector((state) => state.settings);

    const currentSettings = appLocalStorage.interfacePreferences.get() || [
        ...interfacePreference
    ];

    const handleClick = () => {
        onOpen();
        setVisible(false);
    };

    const {
        isConnecting,
        isConnected,
        address,
        // connect
    } = useAccount();

    useEffect(() => {
        if (isConnecting === true) {
            setInitConnecting(true);
        }
    }, [isConnecting]);

    // const disableLogin = !ready || (ready && authenticated);

    /*
    const openDiscrd = () => {
        const url = "https://discord.gg/wise-token-727513630332944504"
        window.open(
            url,
            "_blank"
        );
    }
    */

    const goBuyWise = () => {
        window.open(
            "https://app.uniswap.org/explore/tokens/ethereum/0x66a0f676479cee1d7373f3dc2e2952778bff5bd6",
            "_blank"
        );
    }

    /*
    const openBridgeUrl = (chainId) => {
        let url = ARBITRUM_BRIDGE_URL;
        if (chainId === mainnet.id) {
            url = MAINNET_BRIDGE_URL;
        }
        window.open(
            url,
            "_blank"
        );
    }
    */

    const { TVL } = useSelector((state) => state.token);

    return (
        <header className={cn(styles.header, {
            [styles.expand]: expand
        })}>
            <button className={styles.burger} onClick={() => handleClick()}></button>
            <Tvl
                isExpanded={expand}
                TVL={TVL}
                className={cn(
                styles.search,
                {
                    [styles.visible]: visible
                }
            )} />

            <button
                className={cn(
                    styles.buttonSearch,
                    {
                        [styles.active]: visible
                    }
                )}
                onClick={() => setVisible(!visible)}
            >
                <Icon name="search" size="24" />
            </button>
            <div className={styles.control} onClick={() => setVisible(false)}>
                {currentSettings && currentSettings.indexOf(4) === -1 && (
                    <button
                        className={styles.link}
                        // onClick={() => setKadoWidgetVisible(true)}
                        onClick={() => goBuyWise()}
                    >
                        <span className={styles.hidler}>
                            {!expand && (
                                <Icon name="coin" size="24" />
                            )}
                        </span>
                        <span className={styles.label}>
                        Buy WISE {!expand && (<>
                                <span className={styles.hidler}>(${TVL && (TVL.wiseUsd).toFixed(2)})</span>
                                </>
                            )}
                        </span>
                    </button>
                )}
                {/*<button
                    className={styles.link}
                    onClick={() => {
                        openDiscrd();
                    }}
                >
                    <Icon name="link" size="24" />
                    <span className={styles.label}>
                        Join Discord Server
                    </span>
                </button>*/}
                {/*<Messages className={styles.messages} />
                <Notification className={styles.notification} />*/}
                {isConnected && address ? (
                    <>
                    {/*{<User />}*/}
                    </>
                ) : (
                    <button className={cn("button", styles.button)}
                        onClick={() => {
                            // connect();
                                // console.log(user, 'user');
                                // console.log(authenticated, 'authenticated');
                                // console.log(ready, 'ready');
                                if (!user) {
                                    login();
                                }
                                else {
                                    // linkWallet();
                                    connectWallet();
                                }
                        }}>
                        {initConnecting ? (
                            <Icon name="profile-circle" size="24" />
                        ): (
                            <Icon name="profile-circle" size="24" />
                        )}
                        <span className={styles.connectWalletLabel}>
                            {initConnecting ? (
                                <>
                                    Connect <span className={styles.wallet}>Account</span>
                                </>
                            ): (
                                <>
                                    Connect <span className={styles.wallet}>Account</span>
                                </>
                            )}
                        </span>
                    </button>
                )}
                {isConnected && address && (
                    <Network />
                )}
            </div>
            {/*<ConnectWalletModal
                visible={visibleConnect}
                onClose={() => setVisibleConnect(false)}
            />*/}
            {/*<KadoWidgetModal
                visible={kadoWidgetVisible}
                onClose={() => setKadoWidgetVisible(false)}
            />*/}
        </header >
    );
};

export default Header;
