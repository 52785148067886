import React from "react";
import styles from "./Product.module.sass";
import cn from "classnames";
import ProductLogo from "../ProductLogo";

const Product = ({ item, className }) => {
    return (
        <div className={cn(styles.item, className)}>
            <ProductLogo item={item} />
            <div className={styles.details}>
                <div className={styles.product}>{item.product}</div>
                <div className={styles.link}>{item.link}</div>
            </div>
        </div>
    );
};

export default Product;
