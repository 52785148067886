import { parseUnits } from "viem";
import {
    useWriteContract,
    useWalletClient,
    useWaitForTransactionReceipt,
} from "wagmi";
import { _getETHValue, _notEmpty } from "../utils";
import {
    ALLOWED_SPREAD,
    // CONFIRMATIONS_COUNT,
    DEFAULT_DECIMALS,
    // ERROR_FARM_OPEN_POSITION,
    // ERROR_FARM_OPEN_POSITION_WRITE,
    // INFLATE_PERCENT,
    NATIVE_TOKENS
} from "../utils/config";
import { useGasEstimate } from "./useGasEstimate";

export const useOpenFarmPosition = ({
    config,
    leverage,
    amount,
    showApproval,
    token,
    farmContract,
    isAave,
    farmItem,
    callData
}) => {

    const isETH = NATIVE_TOKENS.includes(
        token.name
    );

    const { data: signer } = useWalletClient();
    const { chainId } = config;

    const {
        error,
        data: hash,
        writeContract,
        // writeContractAsync,
        isPending,
    } = useWriteContract();

    const enabled = _notEmpty(
        farmContract?.address
    );

    const value = _getETHValue(
        token.name,
        amount
    );

    const functionName = isETH
        ? "enterFarmETH"
        : "enterFarm";

    const allowedSpread = parseUnits(
        farmItem.allowedSpread ?? ALLOWED_SPREAD,
        DEFAULT_DECIMALS
    );

    let args = isETH
        ?
        [
            isAave,
            leverage,
            allowedSpread
        ]
        :
        [
            isAave,
            amount,
            leverage,
            allowedSpread
        ];

    if (callData) {
        args = [
            callData,
            ...args
        ];
    }

    const {
        // gasLimit,
        estimatedGas,
        estimatesGasFormatted,
        estimatedGasUSD,
        isError
    } = useGasEstimate({
        contract: farmContract,
        functionName,
        args,
        account: signer?.account,
        amount,
        otherData: leverage,
        value: amount,
        showApproval
    });

    // let gasLimitFormatted = parseInt(
    //     parseInt(gasLimit) * INFLATE_PERCENT
    // ).toString();

    // console.log('gasLimitFormatted', gasLimitFormatted, estimatesGasFormatted);


    const openPosition = async ({
        callData,
        onConfirmation,
        onOpenComplete,
        onOpeningPosition,
        onError
    }) => {

        onConfirmation?.();

        args = isETH
        ?
            [
                isAave,
                leverage,
                allowedSpread
            ]
        :
            [
                isAave,
                amount,
                leverage,
                allowedSpread
            ];

        if (callData) {
            args = [
                callData,
                ...args
            ];
        }

        writeContract({
            chainId,
            abi: farmContract?.abi,
            address: farmContract?.address,
            signerOrProvider: signer,
            // @TODO: make chain specific -> no need to pass on Arbitrum
            // gas: gasLimitFormatted,
            functionName,
            args,
            query: {
                enabled,
            },
            value,
        });

        return true;
    };

    const {
        isError: hasError,
        isLoading: isConfirming,
        isSuccess: isConfirmed
    } = useWaitForTransactionReceipt({
        hash,
    });

    return {
        hash,
        error,
        openPosition,
        hasError,
        isPending,
        isConfirming,
        isConfirmed,
        estimatedGas,
        estimatesGasFormatted,
        estimatedGasUSD,
        estimateHasError: isError,
        isLoading: isConfirming
    };
};
