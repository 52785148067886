import React from "react";
import styles from "./JoinedReferralNetwork.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import { _no, displayWithLoader } from "../../../utils";
import { useAccount } from "wagmi";
// import Icon from "../../../components/Icon";
import Comment from "../../../components/Comment";
import { useSelector } from "react-redux";
import useReferralNetworkUsers from "../../../customHooks/useReferralNetworkUsers";
import Loader from "../../../components/Loader";
import { showToast } from "../../../utils";

const BUTTON_STATES = {
    LOADING: "Fetching Data...",
    LEAVE_NETWORK: "Leave Referral Network"
}

const NETWORK_LABELS = {
    EXISTING_NETWORK: "Your are part of this referral network earning +10% points",
    LEAVE_NETWORK: "Leaving referral network may result in points loss"
}

const JoinedReferralNetwork = ({
    className,
    referrerAddress
}) => {
    const { address, ensName } = useSelector((state) => {
        return state.settings
    });

    const { isConnected } = useAccount();


    const handleLeaveReferralNetwork = () => {
        showToast(
            "Available in the next release",
            {
                type: "info"
            }
        )
    }

    const { loading, addressLabels } = useReferralNetworkUsers({
        userAddress: address,
        userEnsName: ensName,
        referrerAddress,
        shortenEnsNameLabels: true
    });

    return (
        <Card
            className={cn(styles.card, className)}
        >
            <Comment
                avatar={"/images/content/shadow-owl.jpg"}
                author={
                    displayWithLoader(
                        loading,
                        addressLabels?.referrer,
                        " "
                    )
                }
                authorClassName={cn({
                    [styles.referrerAddress]: _no(loading)
                })}
                tooltip={referrerAddress}
                comment={
                    displayWithLoader(
                        loading,
                        NETWORK_LABELS.EXISTING_NETWORK,
                        " "
                    )
                }
                className={cn(
                    styles.comments,
                    {
                        [styles.disabled]: loading
                    }
                )}
                compact
            >
                <Comment
                    avatar={"/images/content/shadow-owl.jpg"}
                    author={
                        displayWithLoader(
                            loading,
                            NETWORK_LABELS.LEAVE_NETWORK,
                            ""
                        )
                    }
                    isAnswer
                    compact
                />
            </Comment>
            <button
                className={cn("button",
                    styles.button,
                    {
                        "button-stroke": isConnected,
                        disabled: loading
                    }
                )}
                onClick={handleLeaveReferralNetwork}
            >
                {
                    _no(isConnected)
                        ? (
                            <>
                                Connect Account
                            </>
                        )
                        : <>
                            {loading ? (
                                <>
                                    <Loader className={styles.loader} />
                                    {BUTTON_STATES.LOADING}
                                </>
                            ) : (
                                BUTTON_STATES.LEAVE_NETWORK
                            )}
                        </>
                }
            </button>
        </Card>
    );
};

export default JoinedReferralNetwork;
