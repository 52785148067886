import { useEffect, useState } from "react";
import { POOL_NAMES } from "../ethers/tokens";
import { computeUSDAmount, fetchRefereesData } from "../utils";

const useReferees = ({
    address,
    pricingFeeds
}) => {
    const [referees, setReferees] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const ethPrice = pricingFeeds.find((item) => {
        return item.poolName === POOL_NAMES.ETH_NATIVE;
    });

    const fetchReferees = async (address) => {
        setLoading(true);
        try {
            const result = await fetchRefereesData(
                address
            );

            const refereesData = result?.referees;

            if (refereesData && ethPrice.decimals) {
                const newReferees = Object.keys(refereesData).reduce((refereesList, refereeAddress) => {
                    const referee = refereesData[refereeAddress];

                    const depositedEthInUsd = computeUSDAmount(
                        ethPrice.decimals,
                        `${referee?.deposited?.depositedEth ?? 0}`,
                        ethPrice.price
                    );

                    return [
                        ...refereesList,
                        {
                            address: refereeAddress,
                            ...referee,
                            deposited: {
                                ...referee?.deposited,
                                depositedEthInUsd
                            }
                        }
                    ]
                }, []);
                setReferees(newReferees);
                setData(result);
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (address && ethPrice) {
            fetchReferees(address);
        } else {
            setReferees(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, ethPrice])

    return {
        data,
        referees,
        loading
    }

}

export default useReferees;