
import React, { useState } from "react";
import { useAccount } from "wagmi";
import cn from "classnames";
import { useSelector } from "react-redux";
import TooltipGlobal from "../../components/TooltipGlobal";
import useConfig from "../../customHooks/useConfig";
import styles from "./Home.module.sass";
import AssetsToSupply from "./AssetsToSupply";
import AssetsToBorrow from "./AssetsToBorrow";
import HealthFactors from "./HealthFactors";
import useLendingSummary from "../../customHooks/useLendingSummary";
// import { setEarnPoints } from "../../redux/settingsSlice";

const navigation = [
    "Supply",
    "Borrow"
];

const Home = ({expand}) => {

    const { isConnected } = useAccount();
    const config = useConfig();
    // const dispatch = useDispatch();

    const { nftId, address } = useSelector(
        (state) => state.settings
    );

    const summary = useLendingSummary({
        config,
        nftId: nftId === -1 ? 10000000000 : nftId,
        userAccount: address
    });

    const showNetAPY = useSelector((state) => {
        return state.settings.showNetAPY
    });

    /*
    useEffect(() => {
        dispatch(
            setEarnPoints(
                summary?.points?.combined?.totalPoints
            )
        );
    }, [dispatch, summary?.points?.combined]);
    */

    const [
        activeIndex,
        setActiveIndex
    ] = useState(0);

    return (
        <>
            <div>
                <HealthFactors show={isConnected && showNetAPY} summary={summary} />
                <div className={styles.nav}>
                    {navigation.map((x, index) => (
                        <button
                            className={cn(styles.button, {
                                [styles.active]: index === activeIndex,
                            })}
                            onClick={() => setActiveIndex(index)}
                            key={index}
                        >
                            {x}
                        </button>
                    ))}
                </div>
                <div className={styles.row}>
                    <div className={cn(styles.col, {
                        [styles.active]: activeIndex === 0
                    })}>
                        <AssetsToSupply
                            expand={expand}
                            config={config}
                            nftId={nftId}
                            summary={summary}
                        />
                    </div>
                    <div className={cn(styles.col, {
                        [styles.active]: activeIndex === 1
                    })}>
                        <AssetsToBorrow
                            expand={expand}
                            config={config}
                            nftId={nftId}
                            summary={summary}
                        />
                    </div>
                </div>
            </div>
            <TooltipGlobal />
        </>
    );
};

export default Home;
