// import dayjs from 'dayjs';
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import Card from "../../components/Card";
import Table from "../../components/Table";
import useConfig from "../../customHooks/useConfig";

// import useGetFarmPosition from "../../customHooks/useGetFarmPosition";
import useTokenUtilization from "../../customHooks/useTokenUtilitzation";

import { powerFarms } from "../../ethers/farms";
import { getSupportedTokens, supportedTokens } from "../../ethers/tokens";
import styles from "./Farming.module.sass";
import Overview from "./Overview";
// import Panel from "./Panel";

import { FarmingContextProvider } from "./context";

const getDefaultToken = (item) => {
    return supportedTokens.find(
        token => {
            return token.name === item.defaultToken
                && token.isAavePool === (item.tokens[item.defaultToken].isAave ?? false);
        }
    );
};

const FarmingContent = () => {

    const config = useConfig();
    const { isConnected, chainId } = useAccount();
    const { availablePools } = config;

    let defaultChain = chainId || 1;
    const [refetch, setRefetch] = useState(false);

    const {
        pricingFeeds,
    } = useSelector(
        (state) => state.token
    );

    const allFarms = useMemo(() => {

        return powerFarms.filter((farm) => {
            return farm.active === true
                && farm.pendleAPYChainId === defaultChain;
        }).map(farm => {

            /*
            const maturityDaysDuration = dayjs(farm.maturityDate).diff(
                new Date(),
                "d"
            );
            */

            return {
                ...farm,
                // expired: maturityDaysDuration <= 0
            };
        });
    }, [defaultChain]);

    /*
    const activeFarms = useMemo(() => {
        const filteredFarms = allFarms.filter((farm) => {
            return _no(farm.expired);
        });
        return filteredFarms.map(farm => ({
            ...farm,
            contractConfig: config[farm.contract],
            token: getDefaultToken(farm)
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allFarms]);
    */

    const allFarmsFull = useMemo(() => {
        /*
        const removeExpiring = allFarms.filter((farm) => {
            return farm.expiring === false;
        });
        */
        return allFarms.map(farm => ({
            ...farm,
            contractConfig: config[farm.contract],
            token: getDefaultToken(farm)
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultChain, allFarms, config]);

    /*
    const expiredFarms = useMemo(() => {
        const filteredFarms = allFarms.filter(farm => farm.expired);
        return filteredFarms.map(farm => ({
            ...farm,
            contractConfig: config[farm.contract],
            token: getDefaultToken(farm)
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allFarms]);
    */

    // console.log(address, 'address');

    /*
    const activeFarmsData = useGetFarmPosition({
        config,
        address,
        farms: allFarmsFull,
        refetch
    });
    */

    /*
    const expiredFarmsData = useGetFarmPositions({
        config,
        address,
        farms: expiredFarms,
        refetch
    });
    */

    /*
    const activeFarmsOverviewData = useMemo(() => {
        return activeFarmsData?.farmDatas?.map((farm) => {
            const { transaction, token, defaultDeposit, farmNftId } = farm;

            const tokenPriceData = pricingFeeds.find(
                (item) => {
                    return item.name === token.name;
                }
            );

            const tokenPrice = tokenPriceData?.price ?? 0;

            const amountFormatted = transaction
                ? parseFloat(
                    transaction.amount
                ) : defaultDeposit;

            const amountInUSD = transaction ?
                computeUSDAmount(
                    token.decimals,
                    amountFormatted.toString(),
                    tokenPrice
                ) : 0;

            return {
                id: farm.id,
                amountInUSD: farmNftId ? amountInUSD : 0,
                rewardsValue: 0,
                isLoading: activeFarmsData.isFetching,
                farmNftId,
                poolName: token.poolName,
                hasNFT: !!farmNftId
            };
        });
    }, [chainId, activeFarmsData, pricingFeeds]);
    */

    /*
    const filteredExpiredFarms = useMemo(() => {
        return expiredFarmsData?.farmDatas?.filter(farm => farm.farmNftId);
    }, [expiredFarmsData]);
    */

    const tokens = useMemo(
        () => {
            return getSupportedTokens(
                defaultChain,
                availablePools
            );
        },
        [availablePools, defaultChain]
    );

    const { tokenData: tokensUtilization } = useTokenUtilization({
        tokens,
        config,
        tokenPrices: pricingFeeds
    });

    const isEmpty = isConnected

    /*
    const RWAWhitelistAddresses = [
        "0x22079A848266A7D2E40CF0fF71a6573D78adcF37",
        "0x641AD78BAca220C5BD28b51Ce8e0F495e85Fe689",
        "0xF22A17599685975A40D65b3AC1526bBd8575fe16",
        "0x2C1d7A72E480363b63F73D3D543EaC0aE4f3A30e",
        "0x434df135FE3B2c58D3BDf16F61D8675A22a4333f",
        "0xa803c226c8281550454523191375695928DcFE92",
    ];
    */

    // console.log(allFarms, 'allFarms');

    const [childValues, setChildValues] = useState({});

    const handleChildValueChange = (childId, newValue) => {
        setChildValues((prev) => ({
            ...prev,
            [childId]: newValue
        }));
    };

    const sum = useMemo(() => {
        return Object.values(childValues).reduce(
            (acc, val) => parseFloat(acc) + parseFloat(val.amountFormatted),
            0
        );
    }, [childValues]);

    const sumAPY = useMemo(() => {
        const sum = Object.values(childValues).reduce(
            (acc, val) => parseFloat(acc) + parseFloat(val.apyReal),
            0
        );
        // only count childValues with APY
        const count = Object.values(childValues).filter(
            (val) => val.apyReal
        ).length;
        return sum / count;
    }, [childValues]);

    const sumNet = useMemo(() => {
        return Object.values(childValues).reduce(
            (acc, val) => parseFloat(acc) + parseFloat(val.netAmount),
            0
        );
    }, [childValues]);

    return (
        <>
            {<Overview
                className={styles.overview}
                sum={sum}
                sumNet={sumNet}
                sumAPY={sumAPY}
                // loading={activeFarmsData.isFetching}
                data={allFarmsFull}
                isEmpty={isEmpty}
            />}
            <Card
            >
                <div className={styles.wrapper}>
                    <Table
                        // data={activeFarmsOverviewData}
                        defaultChain={defaultChain}
                        handleChildValueChange={handleChildValueChange}
                        items={allFarmsFull}
                        // isFetching={activeFarmsData.isFetching}
                        doRefetch={() => setRefetch(!refetch)}
                        isEmpty={false}
                        tokensUtilization={tokensUtilization}
                    />
                </div>
            </Card>
        </>
    );
};

const Farming = () => {

    return (
        <FarmingContextProvider>
            <FarmingContent />
        </FarmingContextProvider>
    );
};

export default Farming;
