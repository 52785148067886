export const history = [
    {
        id: 0,
        product: "Bento Matte 3D Illustration",
        link: "ui8.net/product/product-link",
        image: "/images/content/product-pic-1.jpg",
        image2x: "/images/content/product-pic-1@2x.jpg",
        price: 64,
        date: "Apr 9, 2023",
    },
    {
        id: 3,
        product: "Fleet - Travel shopping UI design kit",
        link: "ui8.net/product/product-link",
        image: "/images/content/product-pic-4.jpg",
        image2x: "/images/content/product-pic-4@2x.jpg",
        price: 89,
        date: "Apr 31, 2023",
    },
];
