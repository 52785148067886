import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Icon from "../../../../components/Icon";
import { formatMoneyDashed } from "../../../../utils";
// import Tooltip from "../../../../components/Tooltip";
// import Balance from "../../../../components/Balance";

const Item = ({ className, onActive, item }) => {
    return (
        <div className={cn(styles.item, className)} onClick={onActive}>
            <div className={styles.icon} style={{ backgroundColor: item.color }}>
                <Icon name={item.icon} size="24" />
            </div>
            <div className={styles.details}>
                <div className={styles.subtitle}>
                    <span>{item.title}</span>
                </div>
                <div className={styles.counter}>
                    <span>
                        {formatMoneyDashed(
                            item.value,
                            true
                        )}
                    </span>
                </div>
            </div>
            {/*
            <div className={styles.value}>
                <Balance className={styles.balance} value={item.value} background />
            </div>
            */}
        </div>
    );
};

export default Item;
