import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import styles from "./Sidebar.module.sass";
import {
    NavLink,
    // Link
} from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
// import Theme from "../Theme";
import Dropdown from "./Dropdown";
import Help from "./Help";
import Image from "../Image";
import Modal from "../Modal";
import Settings from "../../screens/Settings";
import useMediaQuery from "../../customHooks/useMediaQuery";
import { POWER_USERS, MAIN_COLOR_A } from "../../utils/config";

const navigationFull = [
    {
        title: "Home",
        icon: "home",
        url: "/",
    },
    {
        title: "Overview",
        slug: "income",
        icon: "bar-chart",
        url: "/details",
    },
    {
        title: "Activity",
        slug: "income",
        icon: "activity",
        dropdown: [
            {
                title: "Overview Details",
                url: "/details",
            },
            {
                title: "Liquidations",
                url: "/liquidations",
            },
            {
                title: "Statements",
                url: "/statements",
            },
            {
                title: "Contributors",
                url: "/explore-contributors",
            },
        ],
    },
    {
        title: "Dashboards",
        slug: "products",
        icon: "diamond",
        add: true,
        dropdown: [
            {
                title: "Lending Stats",
                url: "/products/dashboard",
            },
            {
                title: "Drafts",
                url: "/products/drafts",
                counter: "2",
                colorCounter: "#FFBC99",
            },
            {
                title: "Released",
                url: "/products/released",
            },
            {
                title: "Comments",
                url: "/products/comments",
            },
            {
                title: "Scheduled",
                url: "/products/scheduled",
                counter: "8",
                colorCounter: MAIN_COLOR_A,
            },
        ],
    },
    {
        title: "Analytics",
        slug: "customers",
        icon: "pie-chart",
        dropdown: [
            {
                title: "Overview",
                url: "/customers/overview",
            },
            {
                title: "List View",
                url: "/customers/customer-list",
            },
        ],
    },
    {
        title: "LiquidNFTs",
        icon: "promotion",
        url: "/promote",
    },
];

const navigationLite = [
    {
        title: "Wise Home",
        icon: "home",
        url: "/home",
    },
    {
        title: "Wise Lending",
        slug: "lending",
        icon: "lightning",
        dropdown: [
            {
                title: "Lending Hub",
                icon: "schedule",
                url: "/lending/hub",
            },
            {
                title: "Lending Farms",
                icon: "schedule",
                url: "/lending/farms",
            },
            {
                title: "Lending Statistics",
                icon: "schedule",
                url: "/lending/details",
            },
            {
                title: "Lending Leaderboard",
                icon: "leaderboard",
                url: "/lending/points",
            },
        ],
    },
    {
        title: "Wise Staking",
        icon: "schedule",
        slug: "/staking",
        dropdown: [
            {
                title: "Staking Hub",
                icon: "schedule",
                url: "/staking/hub",
            },
            {
                title: "Staking Referrals",
                icon: "schedule",
                url: "/staking/ref",
            },
            {
                title: "Staking Statistics",
                icon: "schedule",
                url: "/staking/info",
            },
        ],
    },
    {
        title: "Wise Vaults",
        icon: "shopping-bag",
        url: "/vaults",
    },
    {
        title: "Wise Audits",
        icon: "shield",
        url: "/audits",
    },
    /*{
        title: "Archive",
        slug: "archive",
        icon: "schedule",
        url: "/archive",
    },*/
    /*{
        title: "Wise Swapping",
        slug: "swap",
        icon: "repeat",
        url: "/swap",
    },*/
    /*{
        title: "Points",
        slug: "points",
        icon: "diamond",
        url: "/points",
    },*/
    /*{
        title: "Overview",
        slug: "income",
        icon: "bar-chart",
        url: "/details",
    },*/
    /*{
        title: "Analytics",
        slug: "analytics",
        icon: "activity",
        url: "/customers/overview",
    },
    {
        title: "LiquidNFTs",
        icon: "promotion",
        url: "/promote",
    },*/
];

const Sidebar = ({ className, onClose, onExpand, expand }) => {

    const { address } = useSelector(
        (state) => state.settings
    );

    const [visiblePreference, setVisiblePreference] = useState(false);
    const [visibleHelp, setVisibleHelp] = useState(false);
    const [visible, setVisible] = useState(false);
    const { isConnected } = useAccount();

    const isLargeDesktop = useMediaQuery("(max-width: 2000px)");
    const isMediumDesktop = useMediaQuery("(max-width: 1259px)");

    const handleExpand = () => {
        const isVisible = !visible;
        onExpand(isVisible);
        setVisible(isVisible);
    };

    useEffect(() => {
        onExpand(isLargeDesktop && visible);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLargeDesktop, visible]);

    const openDiscrd = () => {
        const url = "https://discord.gg/wise-token-727513630332944504"
        window.open(
            url,
            "_blank"
        );
    }

    const navigation = isConnected && POWER_USERS.includes(address)
        ? navigationFull
        : navigationLite;

    return (
        <>
            <div
                className={cn(
                    styles.sidebar,
                    className,
                    {
                        [styles.active]: isMediumDesktop && visible,
                        [styles.expand]: isLargeDesktop && visible
                    }
                )}
            >
                <button className={styles.close} onClick={onClose}>
                    <Icon name="close" size="24" />
                </button>
                <a className={styles.logo} href="https://wise.one/" rel="noreferrer" target="_blank">
                    <Image
                        className={styles.icon}
                        src="/images/blueLogo.svg"
                        srcDark="/images/blueLogo.svg"
                        alt="Core"
                    />
                    <div className={styles.titler}>
                        <h1>WiseOne</h1>
                    </div>
                </a>
                <div className={styles.menu}>
                    {navigation.map((x, index) => {
                        const showNav = true;
                        if (showNav) {
                            const hostname = window.location.hostname;
                            const path = window.location.pathname;
                            return (
                                x.url ? (
                                    <NavLink
                                        isActive={(match, location) => {
                                            if (
                                                hostname === "localhost"
                                                && path === "/"
                                                && x.url === "/home"
                                            ) {
                                                return true;
                                            }

                                            if (
                                                hostname === "wise.one"
                                                && path === "/"
                                                && x.url === "/home"
                                            ) {
                                                return true;
                                            }

                                            return match && match.url === location.pathname;
                                        }}
                                        className={styles.item}
                                        activeClassName={styles.active}
                                        to={x.url}
                                        key={index}
                                        exact
                                        onClick={onClose}
                                    >
                                        {x.icon === "shield" ? (
                                            <Image
                                                className={styles.iconer}
                                                src="/images/icons/security.svg"
                                                srcDark="/images/icons/security.svg"
                                                alt="icon"
                                                style={{width: "24px"}}
                                            />
                                        ) : (
                                            <Icon name={x.icon} size="24" />
                                        )}
                                        {x.title}
                                    </NavLink>
                                ) : (
                                    <div key={index} onClick={(el) => {
                                        // console.log(el.target.tagName);
                                        // console.log(visible, 'visible');
                                        if (el.target.tagName === "BUTTON") {
                                            // setVisible(!visible);
                                            return;
                                        }
                                        if (el.target.tagName === "svg") {
                                            // setVisible(!visible);
                                            return;
                                        }
                                        if (el.target.tagName === "A") {
                                            setVisible(!visible);
                                        }
                                        }}>
                                    <Dropdown
                                        expand={expand}
                                        className={styles.dropdown}
                                        visibleSidebar={visible}
                                        setValue={setVisible}
                                        key={index}
                                        item={x}
                                        onClose={onClose}
                                    />
                                    </div>
                                )
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
                <div>
                <button className={styles.toggle} onClick={() => {
                    setVisible(!visible)
                }}>
                    <Icon name="arrow-right" size="24" />
                    <Icon name="close" size="24" />
                </button>
                <button className={styles.expandToggle} onClick={() => handleExpand()}>
                    <Icon name="arrow-right" size="24" />
                    <Icon name="arrow-left" size="24" />
                    {/*<div className={styles.switcher}>
                    <Switch
                        value={switchValue}
                        onChange={handleSwitch}
                    >
                    </Switch>
                    <span style={{color: "white"}}>Auto-collapse</span>
                    </div>*/}
                </button>
                <span style={{display: "none"}}>Collapse</span>
                </div>
                <div className={styles.foot}>
                    <button className={styles.link} onClick={() => openDiscrd()}>
                        <div className={styles.discorder}><Icon viewBox={"0 0 24 24"} name="discord" size="24" /></div>
                        Join Discord Server
                        {/*<div className={styles.counter}>New</div>*/}
                    </button>
                    <button className={styles.link} onClick={() => setVisiblePreference(true)}>
                        <Icon name="setting" size="24" />
                        Interface Preferences
                    </button>
                    {/*<button className={styles.link} onClick={() => setVisibleHelp(true)}>
                        <Image
                            className={cn(styles.audit)}
                            src="/images/icons/security.svg"
                            srcDark="/images/icons/security.svg"
                            alt="audit"
                        />
                            Security Audits
                        <div className={styles.counter}>5</div>
                    </button>*/}
                    <button className={styles.link} onClick={() => {
                        window.open("https://docs.wise.one", "_blank", "noreferrer");
                    }}>
                        <Icon name="help" size="24" />
                        About Wise Token
                        {/*<div className={styles.counter}>1</div>*/}
                    </button>
                </div>
                <div className={styles.foot}></div>
                {/*<Theme className={styles.theme} visibleSidebar={visible} />*/}
            </div>

            <Help
                visible={visibleHelp}
                setVisible={setVisibleHelp}
                onClose={onClose}
            />
            <Modal
                outerClassName={styles.modal}
                visible={visiblePreference}
                onClose={() => setVisiblePreference(false)}
            >
                <Settings
                    preSelectIndex={0}
                    visible={visiblePreference}
                    setVisible={setVisiblePreference}
                    onClose={onClose}
                />
            </Modal>
            <div
                className={cn(styles.overlay, { [styles.active]: visible })}
                onClick={() => setVisible(false)}
            >
            </div>
        </>
    );
};

export default Sidebar;
