import React from "react";
import styles from "./Table.module.sass";
import EmptyPlaceholder from "../EmptyPlaceholder";
// import cn from "classnames";
// import Checkbox from "../Checkbox";
// import Loader from "../Loader";
import Row from "./Row";
import TooltipGlobal from "../TooltipGlobal";
import { _no } from "../../utils";

const Table = ({
    items,
    // isExpired,
    // isEmpty,
    defaultChain,
    // isFetching,
    handleChildValueChange,
    tokensUtilization,
    // doRefetch
}) => {

    // const [selectedFilters, setSelectedFilters] = useState([]);

    /*
    const handleChange = (id) => {
        if (selectedFilters.includes(id)) {
            setSelectedFilters(selectedFilters.filter((x) => x !== id));
        } else {
            setSelectedFilters((selectedFilters) => [
                ...selectedFilters,
                id
            ]);
        }
    };
    */

    // const excluded = [14];

    return (
        <div className={styles.wrapper}>
            <div className={styles.table}>
                <div className={styles.row}>
                    <div className={styles.col}></div>
                    <div className={styles.col}>Yield Source</div>
                    <div className={styles.col}>Farm Token</div>
                    <div className={styles.col}>Available To Borrow</div>
                    <div className={styles.col}>Expected APY</div>
                    <div className={styles.col}>Farm Maturity</div>
                    <div className={styles.col}>Your Position</div>
                    <div className={styles.col}>Action</div>
                </div>
                {_no(items.length) && (
                    <div className={styles.emptyPlaceholder}>
                        <EmptyPlaceholder>Looking for farms...</EmptyPlaceholder>
                    </div>
                )}
                {items.map((x, index) => (
                    <Row
                        item={x}
                        key={`key_${index}-${x.id}`}
                        index={index}
                        defaultChain={defaultChain}
                        handleChildValueChange={handleChildValueChange}
                        // value={selectedFilters.includes(x.id)}
                        // onChange={() => handleChange(x.id)}
                        isExpired={false}
                        isFetching={false}
                        doRefetch={false}
                        isEmpty={false}
                        tokenUtilization={
                            tokensUtilization?.find((utilization) => {
                                return utilization?.poolName === x?.token?.poolName;
                            })
                        }
                    />
                ))}
            </div>
            <TooltipGlobal />
        </div>
    );
};

export default Table;
