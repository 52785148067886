import React from "react";
import cn from "classnames";
import styles from "./Switch.module.sass";

const Switch = ({ className, value, onChange, disabled }) => {
    return (
        <label className={cn(
            styles.switch,
            className,
            {
                [styles.disabled]: disabled
            }
        )}>
            <input
                disabled={disabled}
                className={cn(
                    styles.input,
                    {
                        [styles.disabled]: disabled
                    }
                )}
                type="checkbox"
                checked={value}
                onChange={onChange}
            />
            <span className={styles.inner}>
                <span className={styles.box}></span>
            </span>
        </label>
    );
};

export default Switch;
