import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Icon from "../../../components/Icon";
import Tooltip from "../../../components/Tooltip";
import { MAIN_COLOR_B } from "../../../utils/config";

const Item = ({ className, onActive, item, isTitleCounter = false, disabled }) => {
    return (
        <div className={cn(styles.item, className)} onClick={() => {
            if (disabled) {
                return;
            }
            onActive();
            item.onClick && item.onClick();
        }}>
            {item.icon && (
                <div className={styles.icon} style={{ backgroundColor: item.color ?? MAIN_COLOR_B }}>
                    <Icon name={item.icon} size="24" />
                </div>
            )}
            {item.image && (
                <div className={styles.image} >
                    <img
                        className={styles.data}
                        alt={item.title}
                        src={item.image}>
                    </img>
                </div>
            )}
            <div className={styles.details}>
                <div className={styles.subtitle}>
                    {item.title}
                    {item.description && (
                        <Tooltip
                            className={styles.tooltip}
                            title={item.description}
                            icon="info"
                            place="top"
                        />
                    )}
                </div>
                <div className={cn(
                    styles.counter,
                    {
                        [styles.isTitle]: isTitleCounter
                    }
                )}>{item.counter}</div>
            </div>
        </div>
    );
};

export default Item;
