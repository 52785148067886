import React, { useState } from "react";
import { Link } from "react-router-dom";
import Icon from "../../../../components/Icon";
import styles from "./Row.module.sass";
import cn from "classnames";
// import Checkbox from "../../../../components/Checkbox";
// import Icon from "../../../../components/Icon";
import ModalProduct from "../../../../components/ModalProduct";
import { displayWithSkeleton, formatMoneyDashed } from "../../../../utils";
import Logo from "../../../../components/Logo";

const Row = ({ token, loading }) => {

    const [visibleModalProduct, setVisibleModalProduct] = useState(false);

    const {
        suppliedUSD,
        borrowedUSD,
        utilizationPercentageFormatted,
        utilizationPercentage,
        availableUSD
    } = token || {};

    const ValueDisplay = ({ children }) => {
        return (
            displayWithSkeleton({
                isLoading: loading,
                data: children,
                skeletonWidth: 50
            })
        );
    };

    const computedWidth = isFinite(utilizationPercentage)
        ? utilizationPercentage
        : 0;

    return (
        <>
            <Link to={`/lending/pool/${token.name.toLowerCase()}`} className={styles.row}>
                {/*<div className={styles.col}>
                    <Checkbox
                        className={styles.checkbox}
                        value={value}
                        onChange={onChange}
                    />
                </div>
                <div className={styles.col}>
                    <div
                        className={styles.item}
                        onClick={() => setVisibleModalProduct(true)}
                    >
                        <div className={styles.preview}>
                            <img
                                srcSet={`${item.image2x} 2x`}
                                src={item.image}
                                alt="Product"
                            />
                        </div>
                        <div className={styles.details}>
                            <div className={styles.product}>{item.product}</div>
                            <div className={styles.wrap}>
                                <div className={styles.price}>${item.price}</div>
                                <div className={styles.category}>{item.category}</div>
                            </div>
                        </div>
                    </div>
                </div>
                */}
                {/*<div className={styles.col}>${item.price}</div>*/}
                <div className={styles.col}>
                    <div className={styles.label}>Status</div>
                    <div className={styles.flexer}>
                        <Logo
                            className={styles.currency}
                            logo={token.icon}
                            subLogo={token.sub}
                            width={38}
                            height={38}
                        />
                        <div className={styles.title}>
                            {token.title}
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.label}>Supplied</div>
                    <ValueDisplay>
                        <div className={cn(
                            styles.numberish,
                            "status-green-dark"
                        )}>
                            {formatMoneyDashed(suppliedUSD, true)}
                        </div>
                    </ValueDisplay>
                    {/*<Currency className={styles.balance} value={item.balance} />*/}
                </div>
                <div className={styles.col}>
                    <div className={styles.label}>Borrowed</div>
                    <ValueDisplay>
                        <div className={cn(
                            styles.numberish,
                            "status-purple-dark"
                        )}>
                            {formatMoneyDashed(borrowedUSD, true)}
                        </div>
                    </ValueDisplay>
                    {/*<Currency className={styles.balance} value={item.balance} />*/}
                </div>
                <div className={styles.col}>
                    <div className={styles.label}>Available</div>
                    <ValueDisplay>
                        <div className={styles.sales}>
                            <div className={styles.number}>
                                {formatMoneyDashed(availableUSD, true)}
                            </div>
                            {/*<Currency className={styles.balance} value={item.balance} />*/}
                        </div>
                    </ValueDisplay>
                </div>
                <div className={styles.col}>
                    <div className={styles.label}>Utilized</div>
                    <div className={styles.box}>
                        <ValueDisplay>
                            <div className={styles.number}>
                                {utilizationPercentageFormatted}
                            </div>
                        </ValueDisplay>
                        <div className={styles.line}>
                            <div
                                className={styles.progress}
                                style={{
                                    backgroundColor: "#00b0aa",
                                    width: computedWidth,
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <button
                        // to={`/lending/pool/${token.name.toLowerCase()}`}
                        // disabled={_no(tokenBalance.repayBalance)}
                        className={cn(
                            "button-small",
                            styles.button,
                            styles.infos
                        )}
                    // onClick={() => showInfoDefiLama(tokenBalance.name)}
                    >
                        <Icon name="info" /> Pool Details
                    </button>
                </div>
                {/*<div className={styles.col}>
                    <div className={styles.label}>Supply APY</div>
                    <div className={styles.sales}>
                        <div className={cn(
                            styles.numberish,
                            "status-green-dark"
                        )}>10.01%</div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.label}>Borrow APY</div>
                    <div className={styles.sales}>
                        <div className={cn(
                            styles.numberish,
                            "status-blue"
                        )}>12.02%</div>
                    </div>
                </div>*/}
            </Link>
            <ModalProduct
                visible={visibleModalProduct}
                onClose={() => setVisibleModalProduct(false)}
            />
        </>
    );
};

export default Row;
