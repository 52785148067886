import { useMemo } from "react";
import {
    getConfig,
    CONTRACT_VERSIONS
} from "../utils/config";
import { useSelector } from "react-redux";

const useConfig = (version = CONTRACT_VERSIONS.default) => {

    const chainId = useSelector(
        (state) => state.token.chainId
    );

    const config = useMemo(() => {
        return getConfig(
            chainId,
            version[chainId]
        );
    }, [chainId, version]);

    return config;
};

export default useConfig;
