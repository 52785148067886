import { formatMoneyDashed } from "../../utils";
import styles from "./CustomChartTooltip.module.sass";

const CustomChartTooltip = ({ active, payload, label }) => {
    if (active && payload && payload?.length) {
        return (
            <div className={styles.tooltip}>
                <div className={styles.title}>{label}</div>
                {
                    payload?.map((details, index) => {
                        const name = details.payload.label ?? details.name;
                        const value = details.value || details.payload.value;
                        return (
                            <div className={styles.label} key={index}>
                                <div className={styles.item}>
                                    <div>
                                        {name}:
                                    </div>
                                    <div style={{ color: details.fill }}>
                                        {
                                            details.payload?.formatter
                                                ? details.payload?.formatter(value)
                                                : formatMoneyDashed(
                                                    value,
                                                    true
                                                )
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
    return null;
};

export default CustomChartTooltip;