import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useAccount, useEnsName } from "wagmi";
import useConfig from "../../../customHooks/useConfig";
import usePositionNFT from "../../../customHooks/usePositionNFT";
import useSupportedTokenPrices from "../../../customHooks/useSupportedTokenPrices";

import {
    setAddress,
    setEnsName,
    setNftId,
} from "../../../redux/settingsSlice";

import {
    setAPY,
    setPricingFeeds,
    setWalletBalance
} from "../../../redux/tokenSlice";

// import { CONTRACT_VERSIONS } from "../../../utils/config";
import { arbitrum } from "wagmi/chains";
import useWalletBalance from "../../../customHooks/useWalletBalance";
import { powerFarms } from "../../../ethers/farms";
import {
    _no,
    _notEmpty,
    // fetchAPR,
    fetchPendleAPY,
    fetchPendleV2APY
} from "../../../utils";
import {
    ARBITRUM_EXCHANGE_INCENTIVE,
    ETHEREUM_EXCHANGE_INCENTIVE
} from "../../../utils/config";

const StateUpdater = ({ params, title }) => {

    const isFarms = title && title.includes("Farms");

    const { address: userAddress, isConnected } = useAccount();
    const data = params?.nftId ?? params?.id ?? userAddress;

    const isNftId = _notEmpty(
        params?.nftId
    );

    const config = useConfig();
    const dispatch = useDispatch();
    const { chainId, hasEnsName } = config;

    const { result } = usePositionNFT({
        config,
        isNftId,
        userAddress
    });

    const address = useMemo(() => {
        if (_no(isConnected)) return null;

        return isNftId
            ? result
            : data;
    }, [
        result,
        isNftId,
        data,
        isConnected
    ]);

    const nftId = useMemo(() => {
        if (_no(isConnected)) return null;

        return isNftId
            ? data
            : result;
    }, [
        result,
        isNftId,
        data,
        isConnected
    ]);

    const { data: ensName } = useEnsName({
        address,
        enabled: hasEnsName ?? false,
        chainId: 1 // chainId
    });

    const { supportedTokens, isFetching } = useSupportedTokenPrices(
        chainId
    );

    const { walletBalance } = useWalletBalance({
        chainId,
        userAccount: address
    });

    useEffect(() => {
        dispatch(
            setAddress(
                address
            )
        );
        // eslint-disable-line react-hooks/exhaustive-deps
    }, [address, dispatch]);

    useEffect(() => {
        dispatch(
            setAddress(
                address
            )
        );
        // eslint-disable-line react-hooks/exhaustive-deps
    }, [address, dispatch]);

    useEffect(() => {
        dispatch(
            setEnsName(
                ensName
            )
        );
        // eslint-disable-line react-hooks/exhaustive-deps
    }, [ensName, dispatch]);

    useEffect(() => {
        if (!isFetching) {
            dispatch(
                setPricingFeeds(
                    supportedTokens
                )
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, dispatch]);

    useEffect(() => {
        dispatch(
            setWalletBalance(
                walletBalance
            )
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletBalance, dispatch]);

    useEffect(() => {
        dispatch(
            setNftId(
                nftId
            )
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nftId, dispatch]);

    const getAPYs = async () => {

        // const aprs = await fetchAPR();

        const pendleFarms = powerFarms.filter((farm) => {
            return farm.active
                && _no(farm.disabled)
                && _notEmpty(farm.pendleAPYMarket);
        });
        const pendleAPYs = {};

        for (const farm of pendleFarms) {
            const pendleAPY = await fetchPendleAPY(
                farm.pendleAPYChainId,
                farm.pendleAPYMarket
            );

            const whiteList = [
                // "0x434df135FE3B2c58D3BDf16F61D8675A22a4333f"
            ];

            let baseAPY = pendleAPY?.aggregatedApy * 100 ?? 0;


            if (whiteList.includes(userAddress)) {
                baseAPY = pendleAPY?.maxBoostedApy * 100 ?? 0;
            }

            const pendleV2APY = await fetchPendleV2APY(
                farm.pendleAPYChainId,
                farm.pendleAPYMarket
            );

            const exchangeIncentive = farm.pendleAPYChainId === arbitrum.id
                ? ARBITRUM_EXCHANGE_INCENTIVE
                : ETHEREUM_EXCHANGE_INCENTIVE;


            pendleAPYs[farm.pendleAPYMarket] = baseAPY - (pendleV2APY?.pendleApy * exchangeIncentive ?? 0);

            // console.log('farm.pendleAPYChainId', farm.pendleAPYChainId, baseAPY, pendleV2APY?.pendleApy * exchangeIncentive ?? 0);
        }

        dispatch(
            setAPY({
                // smaApr: aprs?.smaApr ?? 0,
                smaApr: 0,
                pendleAPY: pendleAPYs
            })
        );
    };

    useEffect(() => {
        // TODO: move to the page level
        if (isFarms === true) {
            getAPYs();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isFarms]);

    return null;
};

export default StateUpdater;
