import { useAccount } from "wagmi";
import { useSelector, useDispatch } from "react-redux";
// import { Link } from "react-router-dom";
import cn from "classnames";
// import Icon from "../../Icon";
import { setShowNetAPY } from "../../../redux/settingsSlice";
import { appLocalStorage } from "../../../utils/localstorage";
import { _no, formatDecimals } from "../../../utils";
import styles from "./NetAPY.module.sass";
import { HOME_TITLE } from "../../../utils/config";
// import useReferees from "../../../customHooks/useReferees";

const NetAPY = ({ title }) => {

    const { isConnected } = useAccount();
    const dispatch = useDispatch();

    const {
        netAPY,
        showNetAPY,
        // earnPoints
    } = useSelector(
        (state) => state.settings
    );
    /*

    const {
        data: refereesData,
    } = useReferees({
        address,
        pricingFeeds
    });
    */

    const handleToggleClick = () => {
        appLocalStorage.NETAPY.update(
            !showNetAPY
        );
        dispatch(
            setShowNetAPY(
                !showNetAPY
            )
        );
    };

    /*
    const totalEarnedPoints = _notEmpty(refereesData) && _notEmpty(earnPoints)
        ? _sum(
            refereesData?.combined?.totalReferralPoints,
            earnPoints
        )
        : null;
    */

    /*
    const PointsText = (
        <span className={styles.bigger}>
            Earned Points: {' '}
            <span className={cn({
                [styles.textPurple]: true
            })}>
                {
                    formatMoneyDashed(
                        totalEarnedPoints
                    )
                } PT
            </span>
        </span>
    );
    */

    const NetAPYText = (
        <span className={styles.bigger}>
            APY{' '}
            <span className={cn({
                [styles.textGreen]: netAPY > 0,
                [styles.textBlue]: netAPY < 0
            })}>
                {netAPY > 0 ? '+' : ''}
                {formatDecimals(netAPY)}%
            </span>
        </span>
    );

    const NetAPYStatus = _no(showNetAPY)
        ? NetAPYText
        : "Compact View";

    return (
        <>
            {title && title === HOME_TITLE && isConnected &&
                (
                    <>
                        {/*<Link
                            className={cn(
                                "button-stroke",
                                styles.netAPY,
                                styles.rainbowBox,
                                {
                                    [styles.active]: _no(showNetAPY)
                                }
                            )}
                            // onClick={handleToggleClick}
                            to="/points"
                        >
                            {PointsText}
                        </Link>*/}
                        <button
                            className={cn(
                                "button-stroke",
                                styles.netAPY,
                                {
                                    [styles.active]: _no(showNetAPY)
                                }
                            )}
                            onClick={() => {
                                handleToggleClick();
                            }}
                        >
                            <div className={styles.flexHolder}>
                                {/*<Icon className={styles.m0} name={"close"} size="24" />*/}
                                <span>{netAPY === null
                                    ? "Loading..."
                                    : NetAPYStatus
                                }
                                </span>
                            </div>
                        </button>
                    </>
                )
            }
        </>
    );
};
export default NetAPY;
