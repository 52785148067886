import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import TooltipGlobal from "../../../TooltipGlobal";
import styles from "./ViewFarmDetails.module.sass";
import Parameter from "../Parameter";
import Product from "../Product";
import RiskLevel from "../RiskLevel";
import Icon from "../../../Icon";
import {
    _no,
    _notEmpty,
    showToast,
    computeUSDAmount,
    displayWithLoaderObj,
    formatMoney,
    formatUSD,
    removeNonNumeric,
    removePendleLiquiditySingleToken
} from "../../../../utils";
import useConfig from "../../../../customHooks/useConfig";
import { usePrivy } from "@privy-io/react-auth";
import { useCloseFarmPosition } from "../../../../customHooks/useCloseFarmPosition";
import {
    CLOSE_FAILED_MESSAGE,
    CLOSE_SUCCESS,
    CLOSING_TEXT,
    CONFIRM_TEXT,
    DONE_TEXT,
} from "../../../../utils/config";
import Loader from "../../../Loader";
import SuccessPanel from "../../../Modal/SuccessPanel";
// import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useSelector } from "react-redux";
import { POOL_NAMES } from "../../../../ethers/tokens";

const ViewFarmDetails = ({
    token,
    nftId,
    item,
    doRefetch,
    onDone,
    // onConnect,
    amount
}) => {
    const { isConnected, address } = useAccount();
    const config = useConfig();
    const { linkBase } = config;
    // const { open } = useWeb3Modal();

    const {
        login,
    } = usePrivy();

    const { pricingFeeds } = useSelector(
        (state) => state.token
    );

    const DEFAULT_TEXT = "Close Position";

    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [isLoading, setIsLoading] = useState(false);
    const [callData, setCallData] = useState(null);
    const [successfullClose, setSuccessfullClose] = useState();
    const [finishClose, setFinishClose] = useState(false);
    const [transactionHashLink, setTransactionHashLink] = useState("");
    const [estimatedReward, setEstimatedReward] = useState(null);

    const farmContract = config[item.contract];

    const closeFarmPosition = useCloseFarmPosition({
        config,
        nftId,
        amount,
        farmContract,
        callData,
        farmItem: item
    });

    const tokenPriceData = pricingFeeds.find(
        item => item.name === token.name
    );

    const CLOSE_SUCCESS_MESSAGE = `${CLOSE_SUCCESS}`;

    const disableClose = closeFarmPosition.isLoading
        || isLoading
        || !isConnected
        || !callData;

    const handleError = (err) => {
        showToast(
            err
        );
    };

    const fetchCallData = async () => {
        if (item.fetchCallData) {
            const pendleAPI = await removePendleLiquiditySingleToken({
                chainId: item.pendleAPYChainId,
                receiverAddress: farmContract?.address,
                marketAddress: item.pendleAPYMarket,
                token: token.address,
                amount: closeFarmPosition.amountLP,
                slippage: 0.5 / 100,
            });
            if (pendleAPI?.tx?.data) {
                setCallData(() => pendleAPI?.tx?.data);
            }
            if (pendleAPI?.transaction?.data) {
                setCallData(() => pendleAPI?.transaction?.data);
            }
        } else {
            setCallData(null);
        }
    };

    const handleClose = async () => {

        if (buttonText === DONE_TEXT) {
            onDone && onDone();
            return;
        };

        closeFarmPosition.closePosition({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonText(
                    CONFIRM_TEXT
                );
            },
        });
    };

    useEffect(() => {

        if (closeFarmPosition.hasError && isLoading) {
            setIsLoading(false);
            handleError(
                closeFarmPosition.hasError
            );
            setTransactionHashLink("");
            setButtonText(
                DEFAULT_TEXT
            );
        }

        if (closeFarmPosition.error && isLoading) {
            setIsLoading(false);
            handleError(
                closeFarmPosition.error
            );
            setTransactionHashLink("");
            setButtonText(
                DEFAULT_TEXT
            );
        }

        if (closeFarmPosition.isConfirming && _no(transactionHashLink)) {
            setButtonText(
                CLOSING_TEXT
            );

            setTransactionHashLink(
                `${linkBase}/tx/${closeFarmPosition.hash}`
            );
        }

        if (closeFarmPosition.isConfirmed && isLoading) {

            setIsLoading(false);
            setFinishClose(true);

            showToast(
                CLOSE_SUCCESS_MESSAGE
            );

            setSuccessfullClose(
                true
            );

            setButtonText(
                DONE_TEXT
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeFarmPosition]);

    useEffect(() => {
        if (closeFarmPosition.amountLP) {
            fetchCallData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeFarmPosition.amountLP]);

    useEffect(() => {
        if (address && callData) {
            fetchEstimatedReward();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, callData])

    const fetchEstimatedReward = async () => {

        const estimatedReward = await closeFarmPosition.simulate(
            address
        );

        if (_notEmpty(estimatedReward)) {
            // const rewardValue = Number(estimatedReward) - Number(amount);

            const rewardValue = Number(
                estimatedReward
            );

            setEstimatedReward(
                Math.max(
                    rewardValue,
                    0
                )
            );
        }
    }

    const getPriceDescription = (price) => {
        const value = _no([POOL_NAMES.USDC, POOL_NAMES.USDT].includes(token.poolName))
            ? computeUSDAmount(
                tokenPriceData?.decimals,
                removeNonNumeric(price || 0),
                tokenPriceData?.price
            )
            : null;

        return _notEmpty(parseFloat(value))
            ? `(${formatUSD(value)})`
            : null;
    }

    const parameters = item.details.map((param) => {

        if (param.title === "Closing Transaction Cost")
            return {
                ...param,
                price: displayWithLoaderObj({
                    isLoading: _no(closeFarmPosition?.estimatesGasFormatted),
                    data: `${formatMoney(closeFarmPosition?.estimatesGasFormatted)} ${token.name}`,
                    skeletonWidth: 50
                }),
                priceDescription: getPriceDescription(
                    closeFarmPosition?.estimatesGasFormatted
                )
            };

        if (param.title === "Expected Payout")
            return {
                ...param,
                price: displayWithLoaderObj({
                    isLoading: estimatedReward === null,
                    data: `${formatMoney(estimatedReward)} ${token.name}`,
                    skeletonWidth: 50
                }),
                priceDescription: getPriceDescription(
                    estimatedReward
                )
            };

        return param;
    });

    return (
        <>
            <div className={styles.details}>
                <div className={cn("title-green", styles.title)}>
                    Active Position
                </div>
                <div className={styles.row}>
                    {finishClose &&
                        <div className={styles.successWrapper}>
                            <div className={styles.iconWrapper}>
                                <SuccessPanel />
                            </div>
                            <div style={{ visibility: "hidden" }}>12</div>
                            <div className={styles.text}>
                                {successfullClose
                                    ? CLOSE_SUCCESS_MESSAGE
                                    : CLOSE_FAILED_MESSAGE
                                }
                            </div>
                        </div>
                    }
                    {_no(finishClose) &&
                        <div className={styles.col}>
                            <Product className={styles.product} item={item} />
                            <div className={styles.parameters}>
                                {parameters.map((x, index) => (
                                    <Parameter item={x} key={index} />
                                ))}
                            </div>
                        </div>
                    }
                    {_no(finishClose) && 1 === 2 &&
                        <div style={{ marginTop: "20px" }}>
                            <RiskLevel leverage={
                                // @TODO: pass value more gracefully
                                parseInt(parameters[1].content)
                            } />
                        </div>
                    }
                    <div className={styles.btnsWrapper}>
                        {isConnected && (
                            <>
                                <div className={styles.btns}>
                                    <button
                                        onClick={handleClose}
                                        className={cn(
                                            "button",
                                            styles.button,
                                            styles.full,
                                            {
                                                disabled: disableClose,
                                                [styles.disabled]: disableClose,
                                            }
                                        )}>
                                        {(buttonText === CLOSING_TEXT)
                                            &&
                                            <Loader className={styles.loader} />}
                                        <span>{ !callData ?
                                            'Loading...'
                                            : buttonText
                                        }</span>
                                    </button>
                                </div>
                                {transactionHashLink && finishClose &&
                                    <a
                                        className={cn(
                                            "button",
                                            "button-stroke",
                                            styles.button,
                                            styles.transaction
                                        )}
                                        href={transactionHashLink}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        View Transaction Details
                                    </a>
                                }
                            </>
                        )}
                        {_no(isConnected) &&
                            <div className={styles.btns}>
                                <button
                                    className={cn(
                                        "button",
                                        styles.button,
                                        styles.full
                                    )}
                                    onClick={() => {
                                        login();
                                        // open()
                                    }}
                                >
                                    <Icon name="profile-circle" size="20" />
                                    <span>Connect Account</span>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <TooltipGlobal />
        </>
    );
};

export default ViewFarmDetails;
