import { useEffect } from "react";
import { erc20Abi } from "viem";
import {
    useReadContract,
    useWriteContract,
    useWalletClient,
    useBlockNumber,
    useWaitForTransactionReceipt
} from "wagmi";
import { useQueryClient } from '@tanstack/react-query'
import { getLendingContract } from "../ethers/contracts";
import {
    APPROVE_FUNCTION_NAME,
    // CONFIRMATIONS_COUNT,
    // APPROVE_MAX_VALUE,
    ERROR_ALLOWANCE_APPROVE,
    // ERROR_ALLOWANCE_FETCH,
    // ERROR_ALLOWANCE_WRITE,
    INFLATE_PERCENT,
    USDT_TOKEN_NAME
} from "../utils/config";

import {
    USDT_ERC20
} from '../ethers/abis';
import { useGasEstimate } from "./useGasEstimate";

export const useTokenAllowance = ({
    config,
    // nftId,
    token,
    userAccount,
    amount,
    customContract,
    isReset = false,
}) => {

    const { data: signer } = useWalletClient();
    const { chainId } = config;

    let contract = getLendingContract(
        config,
        token.isAavePool
    );

    if (customContract) {
        contract = customContract;
    }

    const { data: allowance, isLoading, queryKey } = useReadContract({
        chainId,
        address: token?.address,
        abi: erc20Abi,
        functionName: "allowance",
        args: [
            userAccount,
            contract?.address
        ],
        query: {
            enabled: true
        }
    });

    const queryClient = useQueryClient();
    const { data: blockNumber } = useBlockNumber({ watch: true });

    useEffect(() => {
        queryClient.invalidateQueries({
            queryKey
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        blockNumber,
        queryClient,
    ]);

    const approveContract = {
        address: token?.address,
        abi: token.name === USDT_TOKEN_NAME
            ? USDT_ERC20
            : erc20Abi
    };

    const approveArgs = [
        contract?.address,
        isReset
            ? 0
            : token.balance || amount
    ];

    const {
        gasLimit,
        estimatedGas,
        estimatesGasFormatted,
        estimatedGasUSD,
        isError
    } = useGasEstimate({
        contract: approveContract,
        functionName: APPROVE_FUNCTION_NAME,
        args: approveArgs,
        account: signer?.account,
        amount
    });

    let gasLimitFormatted = parseInt(
        parseInt(gasLimit) * INFLATE_PERCENT
    ).toString();

    const {
        error,
        data: hash,
        writeContract,
        isPending,
    } = useWriteContract();

    const approve = async ({
        onConfirmation,
        // onApprovalComplete,
        // onApproving,
        onError
    }) => {
        try {

            onConfirmation?.();

            writeContract({
                chainId,
                address: approveContract.address,
                abi: approveContract.abi,
                signerOrProvider: signer,
                functionName: APPROVE_FUNCTION_NAME,
                args: approveArgs,
                gas: gasLimitFormatted,
            });

            /*
            const approveTransaction = await tokenContract.writeAsync();
            const { hash } = approveTransaction;

            onApproving?.(
                hash
            );

            const approve = await waitForTransaction({
                hash,
                confirmations: CONFIRMATIONS_COUNT
            });
            const { logs } = approve;

            const spendingCap = fromHex(
                logs[0].data,
                "bigint"
            );

            setAllowance(
                spendingCap
            );

            onApprovalComplete?.(
                approve,
                spendingCap,
                isReset
            );
            */

            return true;

        } catch (error) {

            console.error(
                ERROR_ALLOWANCE_APPROVE,
                error
            );

            onError?.(error);
            return false;
        }
    };

    const {
        data: transactionData,
        isError: hasError,
        isLoading: isConfirming,
        isSuccess: isConfirmed
    } = useWaitForTransactionReceipt({
        hash,
    });

    return {
        hasError,
        error,
        hash,
        approve,
        allowance,
        isFetching: isLoading,
        isPending,
        isConfirming,
        isConfirmed,
        estimatedGas,
        estimatesGasFormatted,
        estimatedGasUSD,
        transactionData,
        estimateHasError: isError
    };
};
