import React, { useMemo } from "react";
import DataSource from "../../components/DataSource";
import TooltipGlobal from "../../components/TooltipGlobal";
import useConfig from "../../customHooks/useConfig";
import MoreWidgets from "../Earning/MoreWidgets";
import styles from "./PoolCenter.module.sass";
// import TooltipGlobal from "../../components/TooltipGlobal";
import Snapshot from "./Snapshot";
// import PopularProducts from "../../components/PopularProducts";
import { useHistory, useParams } from 'react-router-dom';
import GlobalUtilization from "../Earning/GlobalUtilization";
import PerformanceByDay from "./PerformanceByDay";
// import CreateLink from "./CreateLink";
import { useSelector } from "react-redux";
import usePoolsChartData from "../../customHooks/usePoolsChartData";
import useTokenUtilization from "../../customHooks/useTokenUtilitzation";

import Logo from "../../components/Logo";
import { useGetTransactions } from "../../customHooks/useGetTransactions";
import useTokenBalance from "../../customHooks/useTokenBalance";
import { getSupportedTokens } from "../../ethers/tokens";
import { _no } from "../../utils";
import PoolRates from "../Earning/PoolRates";
import ActivityDetailsTable from "../Earning/Table";

const PoolCenter = () => {

    const history = useHistory();
    const config = useConfig();
    const { chainId, availablePools } = config;

    const { pricingFeeds, walletBalance } = useSelector(
        (state) => state.token
    );

    const { nftId, address } = useSelector(
        (state) => state.settings
    );

    const params = useParams();
    const tokenName = params.pool?.toUpperCase().trim();

    const token = useMemo(
        () => {
            const supportedTokens = getSupportedTokens(
                chainId,
                availablePools
            );
            return supportedTokens?.find((supportedToken) => {
                return supportedToken.name.toUpperCase() === tokenName;
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [chainId, availablePools]
    );

    const { tokenData: tokensUtilization, isLoading } = useTokenUtilization({
        tokens: [token],
        config,
        tokenPrices: pricingFeeds,
        skip: _no(token)
    });

    const {
        chartData: poolChartData,
        isLoading: isPoolChartDataLoading,
        error
    } = usePoolsChartData({
        tokens: [tokenName],
        chainId
    });

    const { tokenBalance } = useTokenBalance({
        config,
        nftId,
        token,
        userAccount: address,
        walletBalance
    });

    const tokenUtilization = tokensUtilization?.find((token) => {
        return token.name.toUpperCase() === tokenName;
    });

    if (_no(token)) {
        history.push('/details');
    }

    const chartData = useMemo(() => {
        if (isPoolChartDataLoading) return [];

        if (error || _no(poolChartData?.[tokenName])) {
            return [{
                timestamp: new Date(),
                tvlUsd: tokenUtilization?.totalFormatted,
                apyBase: tokenUtilization?.supplyRateFormatted,
            }];
        }

        return poolChartData[tokenName];
    },
        [
            isPoolChartDataLoading,
            error,
            poolChartData,
            tokenName,
            tokenUtilization
        ]);

    const tokenAddress = token.isAavePool
        ? token.aaveAddress
        : token.address;

    const { transactions, isFetching, refetch, isRefetching } = useGetTransactions({
        config,
        tokenAddresses: [tokenAddress],
        skip: _no(token)
    });

    const logo = <Logo
        className={styles.currency}
        logo={token.icon}
        subLogo={token.sub}
        width={38}
        height={38}
    />;

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Snapshot
                        poolName={tokenName}
                        className={styles.card}
                        tokenUtilization={tokenUtilization}
                        chartData={chartData}
                        loading={isPoolChartDataLoading}
                        logo={logo}
                    />
                    <PerformanceByDay
                        poolName={tokenName}
                        className={styles.card}
                        type={"Supply"}
                        chartData={chartData}
                        loading={isPoolChartDataLoading}
                        logo={logo}
                    />
                    <ActivityDetailsTable
                        title={`${tokenName} Pool Activity`}
                        className={styles.smallCard}
                        transactions={transactions}
                        isFetching={isFetching}
                        onRefreshClick={refetch}
                        isRefetching={isRefetching}
                        logo={logo}
                    />
                </div>
                <div className={styles.col}>
                    <DataSource tokenName={tokenName} className={styles.card} />
                    <GlobalUtilization
                        tokensUtilization={[tokenUtilization]}
                        isLoading={isLoading}
                        widgetTitle={`${tokenName} Pool Utilization`}
                        className={styles.card}
                        logo={logo}
                    />
                    <PoolRates
                        tokenBalance={tokenBalance}
                        isLoading={isLoading}
                        widgetTitle={`${tokenName} Pool Rates`}
                        className={styles.card}
                        logo={logo}
                    />
                    <MoreWidgets />
                </div>
            </div>
            <TooltipGlobal />
        </>
    );
};

export default PoolCenter;
