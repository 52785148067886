import { useState, useMemo, useEffect } from "react";
import { getSupportedTokens } from "../ethers/tokens";
import {
    BACKEND_ORACLE_LINK,
    ERROR_PRICING_FEEDS,
    // PRICE_FEED_PREFIX
} from "../utils/config";
import axios from "axios";
// import { parseUnits } from "viem";

const useSupportedTokenPrices = (chainId) => {

    const tokens = useMemo(
        () => {
            const supportedTokens = getSupportedTokens(
                chainId
            );

            return supportedTokens;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [chainId]
    );

    const [supportedTokens, setSupportedTokens] = useState(tokens);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {

        let mounted = true;
        const fetchPrices = () => {
            setIsFetching(true);
            axios
                .get(
                    BACKEND_ORACLE_LINK
                )
                .then(response => {
                    const data = response.data;
                    const tokenPrices = tokens.map((token) => {
                        // const prefix = PRICE_FEED_PREFIX;
                        // const price = data[`${prefix}_${token.name}`];
                        const price = data[`${token.name}`];
                        return {
                            ...token,
                            price,
                        };
                    });

                    setSupportedTokens(
                        tokenPrices
                    );

                    setIsFetching(false);
                })
                .catch(error => {
                    console.error(
                        ERROR_PRICING_FEEDS,
                        error
                    );
                });
        };

        if (mounted) {
            fetchPrices();
        }

        return () => (mounted = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId]);

    return { supportedTokens, isFetching };
};

export default useSupportedTokenPrices;
