import { _no } from "../utils";
import {
    NETAPY_STORAGE_KEY,
    PREFERRED_NFT_KEY,
    PREFERRED_HOME_TAB,
    SHOW_MANY_STAKES,
    SHOW_MANY_REFERRALS,
    INTERFACE_PREFERNCE,
    HIDE_STAKE_TYPE
} from "../utils/config";

// returns parsed value
const get = (storageKey, item) => {

    let storageValue = localStorage.getItem(
        storageKey
    );

    if (item) {
        storageValue = JSON.parse(storageValue);
    }

    const value = item
        ? storageValue?.[item]
        : storageValue;

    return value
        ? JSON.parse(value)
        : null;
};

const update = (
    storage,
    value,
    item,
) => {

    let newConfig;

    if (item?.length) {

        newConfig = get(
            storage,
            item
        ) ?? {};

        newConfig[item] = value;

    } else {
        newConfig = value;
    }

    localStorage.setItem(
        storage,
        JSON.stringify(
            newConfig
        )
    );
};

const clear = (storage, item) => {

    if (_no(item)) {
        localStorage.removeItem(storage);
        return;
    }

    const newConfig = get(
        storage,
        item
    ) || {};

    delete newConfig[item];

    localStorage.setItem(
        storage,
        JSON.stringify(
            newConfig
        )
    );
};

export const appLocalStorage = {
    NETAPY: {
        update: (value) => update(
            NETAPY_STORAGE_KEY,
            value
        ),
        clear: () => clear(
            NETAPY_STORAGE_KEY
        ),
        get: () => get(
            NETAPY_STORAGE_KEY
        )
    },
    preferredNFT: {
        update: (value) => update(
            PREFERRED_NFT_KEY,
            value
        ),
        clear: () => clear(
            PREFERRED_NFT_KEY
        ),
        get: () => get(
            PREFERRED_NFT_KEY
        )
    },
    preferredHomeTab: {
        update: (value) => update(
            PREFERRED_HOME_TAB,
            value
        ),
        clear: () => clear(
            PREFERRED_HOME_TAB
        ),
        get: () => get(
            PREFERRED_HOME_TAB
        )
    },
    showHowManyStakes: {
        update: (value) => update(
            SHOW_MANY_STAKES,
            value
        ),
        clear: () => clear(
            SHOW_MANY_STAKES
        ),
        get: () => get(
            SHOW_MANY_STAKES
        )
    },
    hideStakeType: {
        update: (value) => update(
            HIDE_STAKE_TYPE,
            value
        ),
        clear: () => clear(
            HIDE_STAKE_TYPE
        ),
        get: () => get(
            HIDE_STAKE_TYPE
        )
    },
    interfacePreferences: {
        update: (value) => update(
            {INTERFACE_PREFERNCE},
            value
        ),
        clear: () => clear(
            INTERFACE_PREFERNCE
        ),
        get: () => get(
            INTERFACE_PREFERNCE
        )
    },
    showHowManyReferrals: {
        update: (value) => update(
            SHOW_MANY_REFERRALS,
            value
        ),
        clear: () => clear(
            SHOW_MANY_REFERRALS
        ),
        get: () => get(
            SHOW_MANY_REFERRALS
        )
    }
};
