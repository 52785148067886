import React, { useState } from "react";
import styles from "./Referrals.module.sass";
import TooltipGlobal from "../../components/TooltipGlobal";
import useConfig from "../../customHooks/useConfig";
import useWiseReferrals from "../../customHooksWise/useWiseReferrals";
import useWiseGlobals from "../../customHooksWise/useWiseGlobals";
import { useSelector } from "react-redux";
import { appLocalStorage } from "../../utils/localstorage";
// import Overview from "./Overview";
import Transactions from "./Transactions";
// from src/screens/Referrals/Panel/index.js

const Referrals = ({fullScreen, onClose}) => {

    const {
        address,
    } = useSelector(
        (state) => state.settings
    );

    const config = useConfig();
    const chainId = config.chainId;

    const { data: globalData } = useWiseGlobals({
        config
    });

    const DEFAULT_VALUE = 10;

    const howMany = appLocalStorage.showHowManyReferrals.get() || DEFAULT_VALUE;

    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(howMany);
    const [doRefetch, setDoRefetch] = useState(false);

    const offset = 0;

    const { referrals, referralCountFormatted, isFetching } = useWiseReferrals({
        config,
        address,
        page: currentPage,
        perPage,
        offset,
        doRefetch
    });

    const toggleRefetch = () => {
        setDoRefetch(!doRefetch);
    }

    return (
        <>
            <div className={styles.section}>
                {/*<Overview className={styles.card} />*/}
                <Transactions
                    onClose={onClose}
                    fullScreen={fullScreen}
                    isFetching={isFetching}
                    perPage={perPage}
                    chainId={chainId}
                    setPerPage={setPerPage}
                    className={styles.card}
                    referralCountFormatted={referralCountFormatted}
                    setCurrentPage={setCurrentPage}
                    currentWiseDay={globalData ? parseInt(globalData.currentWiseDay) : 0}
                    address={address}
                    referrals={referrals}
                    doRefetch={toggleRefetch}
                />
            </div>
            <TooltipGlobal />
        </>
    );
};

export default Referrals;
