import React from "react";
// import cn from "classnames";
import styles from "./Profile.module.sass";
// import Card from "../../components/Card";

const Stats = () => {

    return (
        <>
            <div className={styles}>
                <div>
                    Coming soon: collaboration with https://wisestats.org/
                </div>
            </div>
        </>
    );
};

export default Stats;
