import cn from "classnames";
import styles from "./Row.module.sass";
import { formatUnits } from "viem";
import { useSelector } from "react-redux";
import useConfig from "../../../../customHooks/useConfig";
import {
    shortenAddress,
    formatDate,
    formatMoney,
    computeUSDAmount
} from "../../../../utils";
import Logo from "../../../../components/Logo";

const Row = ({ transaction, rowStyles }) => {

    const config = useConfig();
    const { linkBase } = config;

    const pricingFeeds = useSelector(
        (state) => state.token.pricingFeeds
    );

    const tokenPrice = pricingFeeds.find((item) => {
        return item.name === transaction.token?.name;
    });

    const formattedAmount = tokenPrice
        ? formatUnits(
            transaction.amount,
            transaction.token?.decimals
        )
        : 0;

    const amountInUSD = computeUSDAmount(
        transaction.token?.decimals,
        formattedAmount,
        tokenPrice?.price
    );

    if (!transaction.token?.name) {
        return null;
    }

    return (
        <div className={rowStyles.row}>
            <div className={rowStyles.col}>
                <div className={styles.item}>
                    <div className={styles.label}>Date</div>
                    <div className={styles.value} style={{ paddingLeft: "10px" }}>
                        {
                            formatDate(
                                Number(transaction?.timestamp?.toString()) * 1000,
                                "MMM D H:mm",
                                true
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={rowStyles.col}>
                <div className={styles.item}>
                    <div className={styles.label}>User</div>
                    <div className={styles.value}>
                        <a
                            className={cn(
                                styles.user
                            )}
                            href={`${linkBase}/address/${transaction.userAccount}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {shortenAddress(transaction.userAccount)}
                        </a>
                    </div>
                </div>
            </div>
            <div className={rowStyles.col}>
                <div className={styles.item}>
                    <div className={styles.label}>Action</div>
                    <div className={styles.value}>
                        <div
                            style={{ width: "88px" }}
                            className={cn(
                                { "status-green-dark": transaction.label === "Lend" },
                                { "status-yellow": transaction.label === "Redeem" },
                                { "status-purple-dark": transaction.label === "Borrow" },
                                { "status-green": transaction.label === "Repaid" },
                                styles.status
                            )}
                        >
                            {transaction.label}
                        </div>
                    </div>
                </div>
            </div>
            <div className={rowStyles.col}>
                <div className={styles.item}>
                    <div className={styles.label}>Token</div>
                    <div className={styles.value}>
                        <Logo
                            className={styles.currency}
                            logo={transaction?.token?.icon}
                            subLogo={transaction?.token?.sub}
                            width={36}
                            height={36}
                        />
                    </div>
                </div>
            </div>
            <div className={rowStyles.col}>
                <div className={styles.item}>
                    <div className={styles.label}>Amount</div>
                    <div className={styles.value}>
                        <div
                            className={styles.token}>
                            {
                                formatMoney(
                                    formattedAmount
                                )
                            }
                            <div className={cn(
                                styles.text
                            )}>
                                {transaction?.token?.name}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={rowStyles.col}>
                <div className={styles.item}>
                    <div className={styles.label}>USD Value</div>
                    <div className={styles.value}>
                        {
                            formatMoney(
                                amountInUSD,
                                true
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={rowStyles.col}>
                <div className={styles.buttons}>
                    <a
                        className={cn(
                            "button-small",
                            "button-stroke",
                            styles.button
                        )}
                        href={`${linkBase}/tx/${transaction.transactionHash}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Details
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Row;
