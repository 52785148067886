import cn from "classnames";
import React, { useMemo, useState } from "react";
import {
    // _no,
    _notEmpty,
    // computeUSDAmount,
    formatDecimals,
    formatMoney,
    formatMoneyDashed,
    // getRemainingDaysAndNextClaimDate
} from "../../../../utils";

import { useSelector } from "react-redux";
import { formatUnits } from "viem";
import Modal from "../../../../components/Modal";
import useTokenBalance from "../../../../customHooks/useTokenBalance";
import { ALL_SUPPORTED_POOLS, getSupportedTokens } from "../../../../ethers/tokens";
import { MAX_RWA_VAULT_AMOUNT_USDC } from "../../../../utils/config";
import OpenRwaFarmDetails from "../OpenRwaFarmDetails";
import ProductLogo from "../Row/ProductLogo";
import styles from "./Row.module.sass";

const Row = ({
    item,
    chainId,
    config,
    totalDepositedData,
    toggleRefetch,
    sum
}) => {

    const [modalType, setModalType] = useState();
    // const farmingContext = useFarmingContext();
    // const farmContext = useContext(FarmingContext);

    const supportedTokens = useMemo(
        () => {
            return getSupportedTokens(
                chainId,
                ALL_SUPPORTED_POOLS
            );
        },
        [chainId]
    );

    const token = supportedTokens.find(
        (token) => {
            return token.name === item.defaultToken
                && token?.isAavePool === (item.tokens?.[item.defaultToken]?.isAave ?? false);
        }
    );

    const {
        address,
        nftId
    } = useSelector(
        (state) => state.settings
    );

    const {
        pricingFeeds,
        walletBalance,
    } = useSelector(
        (state) => state.token
    );

    const tokenPriceData = pricingFeeds.find(
        item => item.name === token.name
    );

    const tokenPrice = tokenPriceData?.price ?? 0;

    const { tokenBalance } = useTokenBalance({
        config,
        nftId,
        token,
        userAccount: address,
        price: tokenPrice,
        walletBalance
    });

    const handleCloseModal = () => setModalType(null);
    const handleOpenModal = (modal) => {
        setModalType(modal);
    };

    /*
    const formattedPositionAmount = formatUnits(
        rwaPosition?.positionData?.[0] ?? 0,
        token.decimals
    );

    const formattedRewardsAmount = formatUnits(
        rwaPosition?.rewardsData?.[2] ?? 0,
        token.decimals
    );

    const hasNFT = rwaPosition?.RWANftID !== null;
    */

    /*
    const remainingDaysAndNextDate = getRemainingDaysAndNextClaimDate(
        Number(rwaPosition?.rewardsData?.[1])
    );
    */

    /*
    const remainingDays = hasNFT
        ? remainingDaysAndNextDate.remainingDays
        : 30;
    */

    const totalDepositedUSDC = formatUnits(
        totalDepositedData ?? 0,
        0
    );

    /*
    const amountInUSD = useMemo(() => {
        return rwaPosition ?
            computeUSDAmount(
                token.decimals,
                formattedPositionAmount.toString(),
                tokenPrice
            ) : 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        formattedPositionAmount
    ]);
    */

    /*
    const rewardsInUSD = useMemo(() => {
        return rwaPosition ?
            computeUSDAmount(
                token.decimals,
                formattedRewardsAmount.toString(),
                tokenPrice
            ) : 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        rwaPosition,
        rwaPosition?.RWANftID,
        formattedRewardsAmount
    ]);

    useEffect(() => {
        if (farmingContext) {
            farmingContext.addUpdateActiveRWA({
                id: item.id,
                rewardsValue:
                    hasNFT && item.active && rwaPosition
                        ? Number(amountInUSD) + Number(rewardsInUSD)
                        : 0,
                amountInUSD: Number(amountInUSD),
                isLoading: rwaPosition?.isFetching,
                farmNftId: hasNFT
                    ? parseUnits(rwaPosition?.RWANftID?.toString(), 0)
                    : undefined,
                poolName: token.poolName,
                hasNFT,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        amountInUSD,
        rewardsInUSD,
        rwaPosition?.RWANftID,
        hasNFT
    ]);
    */

    /*
    useEffect(() => {
        if (hasNFT && item.APY) {
            farmContext.addOpenFarmAPY(
                token.poolName,
                item.APY
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.APY, hasNFT]);
    */

    const data = {
        ...item,
        totalDepositedUSDC,
        capacity: MAX_RWA_VAULT_AMOUNT_USDC - totalDepositedUSDC,
        details: [
            {
                title: "Fixed APY",
                content: `20%`,
            },
        ]
    }

    /*
    const data = {
        ...item,
        totalDepositedUSDC,
        capacity: MAX_RWA_VAULT_AMOUNT_USDC - totalDepositedUSDC,
        details: [
            {
                title: "Position Amount",
                content: `${formatMoney(formattedPositionAmount)} ${token.name}`,
            },
            {
                title: "Fixed APY",
                content: `20%`,
            },
            {
                title: "Next Payout In",
                tooltip: `${dayjs(
                    remainingDaysAndNextDate.nextClaimDate
                ).format("DD.MM.YYYY")
                    }`,
                content: `${remainingDays} Days`,
            },
            {
                title: "Available Interest",
                content: `${formattedRewardsAmount} ${token.name}`,
            },
        ],
    };
    */

    return (
        <>
            <div
                style={{ cursor: "pointer" }}
                className={cn(
                    styles.row,
                )}
                onClick={() => handleOpenModal("open-position")}
            >
                <div className={styles.col}>
                    <div
                        className={styles.productItem}
                    >
                        <ProductLogo item={item} />
                        <div className={styles.details}>
                            <div>
                                <div className={styles.product}>{item.source}</div>
                                <div className={styles.link}>{item.description}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.label}>Filled Capacity</div>
                        {formatMoney(totalDepositedUSDC)} / {formatMoney(MAX_RWA_VAULT_AMOUNT_USDC)} {token.name}
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.label}>APY</div>
                        <div className={styles.apyValue}>
                            <div className={"status-green-dark"}
                            >
                                {
                                    `${formatDecimals(
                                        item.APY
                                    )}%`
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.label}>Reward Cycle</div>
                        <div className={styles.value}>
                            30 Days
                            {/*<Tooltip
                                className={cn(
                                    styles.tooltip,
                                )}
                                title={"tooltip text"}
                                icon="info-stroke"
                                place="right"
                            />*/}
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.label}>Deposited By You</div>
                        <div className={styles.value}>
                            {
                                formatMoneyDashed(sum)
                            } {sum > 0 && (`USDC`)}
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.buttons}>
                        <button
                            className={cn(
                                "button",
                                styles.button,
                                {
                                    // disabled: isDeprecated,
                                    // [styles.disabled]: isDeprecated
                                    // disabled: true,
                                    // [styles.disabled]: true
                                }
                            )}
                            onClick={() => handleOpenModal("open-position")}
                        >
                            Create Position
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                outerClassName={cn({
                    [styles.openOuter]: modalType === "open-position",
                    [styles.viewOuter]: modalType === "view-position"
                })}
                visible={_notEmpty(modalType)}
                onClose={handleCloseModal}
            >
                {
                    modalType === "open-position" && (
                        <OpenRwaFarmDetails
                            item={data}
                            toggleRefetch={toggleRefetch}
                            onClose={handleCloseModal}
                            token={tokenBalance}
                            onDone={handleCloseModal}
                            // doRefetch={() => setRefetch(!refetch)}
                        />
                    )
                }
                {/*
                    modalType === "view-position" && (
                        <ViewRWAFarmDetails
                            // nftId={rwaPosition?.RWANftID}
                            item={data}
                            token={token}
                            onClose={handleCloseModal}
                            onDone={handleCloseModal}
                            amount={formattedRewardsAmount}
                            doRefetch={() => setRefetch(!refetch)}
                            // amount={positionData.amount}
                        />
                    )*/}
            </Modal>
        </>
    );
};

export default Row;
