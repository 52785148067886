import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import Icon from "../../../components/Icon";
import Tooltip from "../../../components/Tooltip";
import LinearBar from "../../../components/LinearBar";
import {
    _no,
    formatDecimals,
    formatMoney,
    toPrecision,
    displayWithLoader,
    // scientificToDecimal
} from "../../../utils";
import { setNetAPY } from "../../../redux/settingsSlice";

import styles from "./HealthFactors.module.sass";
import NetAPY from "./NetAPY";

const HealthFactors = ({ show, summary }) => {
    const dispatch = useDispatch();

    const {
        supplyFormatted,
        limitFormatted,
        borrowFormatted,
        safeLimit,
        lendingRateFormatted,
        borrowRateFormatted,
        percentageCap,
        borrowAPYFormatted,
        // borrowAPY,
        lendingAPYFormatted,
        // lendingAPY,
        overallNetAPYFormatted,
        overallNetAPY,
        isNegativeApy
    } = summary;

    const isFetching = _no(supplyFormatted)
        && _no(borrowFormatted);

    const supplyNum = useMemo(() => (
        toPrecision(
            supplyFormatted
        ).split(".")
    ), [supplyFormatted]);

    const borrowNum = useMemo(() => (
        toPrecision(
            borrowFormatted
        ).split(".")
    ), [borrowFormatted]);

    const parsedBorrowFormatted = parseFloat(
        borrowFormatted ?? 0
    );

    const parsedLimitFormatted = parseFloat(
        limitFormatted ?? 0
    );

    const borrowUsed = parsedBorrowFormatted
        / parsedLimitFormatted
        * 100;

    const parsedLendingRateFormatted = parseFloat(
        lendingAPYFormatted ?? lendingRateFormatted
    );

    const parsedBorrowRateFormatted = parseFloat(
        borrowAPYFormatted ?? borrowRateFormatted
    );

    const APYPrefix = isNegativeApy
        ? '-'
        : '';

    const netAPYValue = parseFloat(
        `${APYPrefix}${overallNetAPYFormatted}`
    );

    const netAPYComputedValue = parsedLendingRateFormatted
        - parsedBorrowRateFormatted;

    useEffect(() => {
        dispatch(
            setNetAPY(
                overallNetAPY === undefined
                    ? netAPYComputedValue
                    : netAPYValue
            )
        );
    }, [
        netAPYValue,
        dispatch,
        netAPYComputedValue,
        overallNetAPY
    ]);

    return (
        <div className={cn(
            styles.wrapper,
            {
                [styles.show]: show
            })}
        >
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={cn(
                        styles.label,
                        styles.supply
                    )}>
                        Lend Balance
                    </div>
                    <div className={styles.value}>
                        $
                        {displayWithLoader(
                            isFetching,
                            <>
                                {supplyNum[0]}
                                <span className={styles.decimal}>
                                    .
                                    {supplyNum[1]}
                                </span>
                            </>
                        )}
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.pie}>
                        <NetAPY
                            netAPY={netAPYValue}
                            lendingAPY={parsedLendingRateFormatted}
                            borrowAPY={parsedBorrowRateFormatted}
                            isFetching={isFetching}
                        />
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={cn(
                        styles.label,
                        styles.borrow
                    )}>
                        Borrow Balance
                    </div>
                    <div className={styles.value}>
                        $
                        {displayWithLoader(
                            isFetching,
                            <>
                                {borrowNum[0]}
                                <span className={styles.decimal}>
                                    .
                                    {borrowNum[1]}
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.section}>
                <div className={cn(
                    styles.item,
                    styles.limit
                )}>
                    <div className={styles.span}>
                        Borrow Limit Used:
                        <span className={styles.value}>
                            {" "}
                            {displayWithLoader(
                                isFetching,
                                `${formatDecimals(borrowUsed)}%`
                            )}
                            <Tooltip
                                className={styles.tooltip}
                                title={"To avoid liquidation keep it below 90%"}
                                icon="info-stroke"
                                place="right"
                            />
                        </span>
                    </div>
                    <div className={styles.span}>
                        Borrow Limit:{" "}
                        <span className={styles.value}>
                            {displayWithLoader(
                                isFetching,
                                formatMoney(
                                    limitFormatted < 1
                                    ? 0
                                    : limitFormatted,
                                    true
                                )
                            )}
                        </span>
                        <Tooltip
                            className={styles.tooltip}
                            title={"USD equivalent you can borrow as maximum"}
                            icon="info-stroke"
                            place="top-end"
                        />
                    </div>
                </div>
                <LinearBar
                    height={7}
                    width={borrowUsed}
                    fill="#00b0aa"
                    limit={percentageCap}
                    limitColor="#FF6A55"
                />
                <div style={{ display: "none" }} className={cn(
                    styles.item,
                    styles.safeLimit
                )}>
                    <div>
                        Safe Limit:{" "}
                        <span className={styles.value}>
                            {
                                formatMoney(
                                    safeLimit,
                                    true
                                )
                            }
                        </span>
                        <Icon name="info-stroke" size="20" />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default HealthFactors;
