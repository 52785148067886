import React from "react";
// import useDarkMode from "use-dark-mode";

const Image = ({ className, src, srcDark, srcSet, srcSetDark, alt }) => {

    const darkMode = true;
    // const darkMode = useDarkMode(false);

    const srcSetFinal = darkMode
        ? srcSetDark
        : srcDark;

    const srcFinal = darkMode
        ? srcDark
        : src;

    return (
        <img
            className={className}
            srcSet={srcSetFinal}
            src={srcFinal}
            alt={alt}
        />
    );
};

export default Image;
