import { progress } from "../utils";
import { MAIN_COLOR_A } from "../utils/config";

export const viewers = [
    {
        id: 0,
        product: "vitally.eth",
        date: "0x123...321",
        image: "/images/content/product-pic-1.jpg",
        image2x: "/images/content/product-pic-1@2x.jpg",
        progress: progress(),
        items: [
            {
                title: "Followers",
                color: MAIN_COLOR_A,
                counter: 550000,
                width: "30%",
            },
            {
                title: "Others",
                color: "#f99191",
                counter: 854350,
                width: "70%",
            },
        ],
    },
    {
        id: 1,
        product: "vitally.eth",
        date: "0x123...321",
        image: "/images/content/product-pic-2.jpg",
        image2x: "/images/content/product-pic-2@2x.jpg",
        progress: progress(),
        items: [
            {
                title: "Followers",
                color: MAIN_COLOR_A,
                counter: 550000,
                width: "60%",
            },
            {
                title: "Others",
                color: "#f99191",
                counter: 412350,
                width: "40%",
            },
        ],
    },
];
