import axios from "axios";
import { useEffect, useState } from "react";
import { DEFI_LLAMA_POOL_IDS, DEFI_LLAMA_YIELDS_LINK } from "../utils/config";

export const usePoolsChartData = ({ tokens = [], chainId, skip = false } = {}) => {

    const [chartData, setChartData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const getChartLink = (tokenName) => {
        // return `${DEFI_LLAMA_YIELDS_LINK}/chart/${DEFI_LLAMA_POOL_IDS[chainId][tokenName]}`;
        return `${DEFI_LLAMA_YIELDS_LINK}/chartLendBorrow/${DEFI_LLAMA_POOL_IDS[chainId][tokenName]}`;
    };

    const fetchChartData = async () => {

        if (skip || tokens.length === 0) {
            return;
        };

        setIsLoading(true);
        setError(null);

        try {
            const chartDataPromises = tokens.filter((tokenName) => {
                return getChartLink(tokenName);
            }).map((tokenName) => {
                return axios.get(getChartLink(tokenName)).then((response) => {
                    if (response.data.status === "success") {
                        return {
                            token: tokenName,
                            data: response.data.data.map((dataPoint) => ({
                                timestamp: new Date(dataPoint.timestamp),
                                tvlUsd: dataPoint.totalSupplyUsd - dataPoint.totalBorrowUsd,
                                ...dataPoint
                            })),
                        };
                    } else {
                        throw new Error(
                            `Failed to fetch chart data for token ${tokenName}`
                        );
                    }
                });
            });

            const results = await Promise.all(
                chartDataPromises
            );

            const newChartData = results.reduce((updatedChartData, chartResult) => {
                updatedChartData[chartResult.token] = chartResult.data;
                return updatedChartData;
            }, {});

            setChartData(newChartData);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchChartData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(tokens), skip, chainId]);

    return { chartData, isLoading, error };
};

export default usePoolsChartData;
