import {
    useWriteContract,
    useWalletClient,
    useWaitForTransactionReceipt
} from "wagmi";
import { _notEmpty } from "../utils";
import { useGasEstimate } from "./useGasEstimate";

export const useOpenRwaDeposit = ({ amount, config, showApproval }) => {

    const { data: signer } = useWalletClient();
    const { ForwardingDepositUSDCContract } = config;

    const {
        error,
        data: hash,
        writeContract,
        // writeContractAsync,
        isPending,
    } = useWriteContract();

    const enabled = _notEmpty(
        ForwardingDepositUSDCContract?.address
    );

    const DEPOSIT_FUNCTION_NAME = "deposit";

    const {
        estimatedGas,
        estimatesGasFormatted,
        estimatedGasUSD,
        isError
    } = useGasEstimate({
        contract: ForwardingDepositUSDCContract,
        functionName: DEPOSIT_FUNCTION_NAME,
        args: [amount],
        account: signer?.account,
        amount,
        showApproval,
    });

    const deposit = async ({
        onConfirmation,
        onDepositComplete,
        onDepositing,
        onError,
    }) => {
        onConfirmation?.();

        writeContract({
            chainId: config.chainId,
            address: ForwardingDepositUSDCContract?.address,
            abi: ForwardingDepositUSDCContract?.abi,
            functionName: DEPOSIT_FUNCTION_NAME,
            args: [
                amount
            ],
            query: {
                enabled
            }
        });

        /*
        const depositTransaction = await depositContract.writeAsync();
        const { hash } = depositTransaction;

        onDepositing?.(hash);

        const depositResult = await waitForTransaction({
            hash,
            confirmations: CONFIRMATIONS_COUNT,
        });

        onDepositComplete?.(depositResult);
        */
    };

    const {
        isError: hasError,
        isLoading: isConfirming,
        isSuccess: isConfirmed
    } = useWaitForTransactionReceipt({
        hash,
    });

    return {
        hash,
        error,
        deposit,
        hasError,
        isPending,
        isConfirming,
        isConfirmed,
        estimatedGas,
        estimatesGasFormatted,
        estimatedGasUSD,
        estimateHasError: isError,
        isLoading: isConfirming,
    };
};
