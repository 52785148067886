import React, { useEffect } from "react";
import { useState } from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import Icon from "../../../components/Icon";
import { toPrecision, formatMoney } from "../../../utils";
import { useScrapeReferral } from "../../../customHooksWise/useScrapeReferral";
import useConfig from "../../../customHooks/useConfig";
import Loader from "../../../components/Loader";
import { showToast } from "../../../utils";

const Panel = ({
    selected,
    totalWise,
    wiseCost,
    setSelected,
    setTotalWise,
    setDoRefetch
}) => {

    const selectedCount = selected.length;
    const totalWiseFormatted = toPrecision(totalWise);
    const cost = wiseCost * totalWise;
    const costFormatted = formatMoney(cost);
    const [isLoading, setIsLoading] = useState(false);
    const [transactionHashLink, setTransactionHashLink] = useState("");
    const [isDone, setIsDone] = useState(null);

    const config = useConfig();

    const clearAll = () => {
        setSelected([]);
        setTotalWise(0);
        setDoRefetch();
    }

    const scrapeReferrals = useScrapeReferral({
        config: config,
        referrals: selected,
    });

    const handleScrape = async () => {
        await scrapeReferrals.scrapeReferrals({
            onConfirmation: () => {
                setIsLoading(true);
            },
        });
    }

    const handleScrapeError = (err) => {
        const error = err?.message || err;
        showToast(
            error,
            {
                type: "warning"
            }
        );
    }

    useEffect(() => {
        if (scrapeReferrals.error && isLoading) {
            setIsLoading(false);
            handleScrapeError(scrapeReferrals.error);
        }
        if (scrapeReferrals.hasError && isLoading) {
            setIsLoading(false);
            handleScrapeError(scrapeReferrals.hasError);
            setIsDone("Try Again");
        }

        if (scrapeReferrals.isConfirming && isLoading) {
            setTransactionHashLink(
                `https://etherscan.io/tx/${scrapeReferrals.hash}`
            );
        }

        if (scrapeReferrals.isConfirmed && isLoading) {
            setIsLoading(false);
            showToast(
                "Referral rewards collected successfully",
                {
                    type: "success"
                }
            );
            setIsDone("All Done");
            setDoRefetch();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrapeReferrals]);

    const handleDone = () => {
        clearAll();
        setIsDone(null);
        setTransactionHashLink("");
    }

    if (selectedCount === 0) {
        return (<div className={cn(styles.panel, styles.inactive)}></div>)
    }

    return (
        <div className={cn(styles.panel)}>
            <div onClick={() => {clearAll()}} className={styles.info}>
                <Icon name="trash" size="24" />Deselect All ({selectedCount} Referral{selectedCount > 1 ? "s" : ""})
            </div>
            {!transactionHashLink && !isLoading && (
                <div onClick={() => {handleScrape()}} className={styles.info}>
                    <Icon name="multiselect" size="24" />
                    <div>Collect {totalWiseFormatted} WISE (${costFormatted})</div>
                </div>
            )}
            {!transactionHashLink && isLoading && (
                <div className={styles.info}>
                    <Icon name="edit" size="24" />
                    <div>Confirm Transaction In Wallet</div>
                </div>
            )}
            { transactionHashLink && !isDone &&
                <a
                    className={cn(styles.transaction)}
                    href={transactionHashLink}
                    target="_blank"
                    rel="noreferrer"
                >
                    <div className={styles.info}>
                        { isLoading && <Loader className={styles.loader}></Loader> }
                        { isLoading && (
                            <div>Collecting Rewards</div>
                        )}
                    </div>
                </a>
            }
            { transactionHashLink && isDone &&
                <div onClick={() => {handleDone()}} className={styles.info}>
                    <Icon name="check-all" size="24" />
                    <div>{isDone}</div>
                </div>
            }
            {/* transactionHashLink &&
                <div className={styles.info}>
                <a
                    className={cn(styles.transaction)}
                    href={transactionHashLink}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Icon name="link" size="24" />
                    View Transaction
                </a>
                </div>
            */}
        </div>
    );
};

export default Panel;
