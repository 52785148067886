import {
    useWriteContract,
    useWalletClient,
    useWaitForTransactionReceipt
} from "wagmi";

import {
    // _no,
    _notEmpty
} from "../utils";

import {
    // CONFIRMATIONS_COUNT,
} from "../utils/config";

export const useCloseStake = ({
    config,
    nftId,
    stakeId,
}) => {

    const { data: signer } = useWalletClient();
    const {
        chainId,
        WiseTokenContract: contract,
        wiseNftStakes: nftContract,
    } = config;

    const enabled = _notEmpty(
        contract?.address
    ) && stakeId;

    const {
        error,
        data: hash,
        writeContract,
        // writeContractAsync,
        isPending,
    } = useWriteContract();

    const closeStake = ({
        onConfirmation,
        // onError
    }) => {
        onConfirmation?.();

        const args = [
            nftId
                ? nftId
                : stakeId
        ];

        const functionName = nftId
            ? "burn"
            : "endStake";

        writeContract({
            chainId,
            abi: nftId
                ? nftContract?.abi
                : contract?.abi,
            address: nftId
                ? nftContract?.address
                : contract?.address,
            signerOrProvider: signer,
            // gas: gasLimitFormatted,
            functionName: functionName,
            args: args,
            query: {
                enabled: enabled,
            }
        });
    };

    const {
        // data,
        isError: hasError,
        isLoading: isConfirming,
        isSuccess: isConfirmed,
    } = useWaitForTransactionReceipt({
        hash,
    });

    return {
        hash,
        error,
        hasError,
        isPending,
        isConfirming,
        isConfirmed,
        closeStake,
        isLoading: isConfirming,
    };
};
