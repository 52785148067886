import React from "react";
import cn from "classnames";
import styles from "./Table.module.sass";
import Scrollbar from "../../../components/Scrollbar";
import Loader from "../../../components/Loader";
import Card from "../../../components/Card";
import Row from "./Row";
import Icon from "../../../components/Icon";

const ActivityDetailsTable = ({
    title = "Latest Transactions",
    transactions,
    isFetching,
    className,
    onRefreshClick,
    isRefetching,
    logo
}) => {
    return (
        <Card
            className={cn(styles.card, className)}
            title={title}
            classTitle={logo ? "title" : "title-green"}
            icon={logo}
            head={
                <button
                    style={{display: "none"}}
                    disabled={isFetching || isRefetching}
                    onClick={onRefreshClick}
                    className={cn(styles.refreshBtn, {
                        [styles.active]: isRefetching
                    })}>
                    <Icon name="repeat" size="24" />
                </button>
            }
        >
            <div className={styles.wrapper}>
                <Scrollbar className={styles.scroll}>
                    {isFetching ? (
                        <div className={styles.foot}>
                            <Loader className={styles.loader} />
                            <span>Loading Data</span>
                        </div>
                    ) : (
                        <>
                            <div className={styles.table}>
                                <div className={cn(
                                    styles.row,
                                    styles.head
                                )}>
                                    <div className={styles.col}>Date</div>
                                    <div className={styles.col}>User</div>
                                    <div className={styles.col}>Action</div>
                                    <div className={styles.col}>Pool</div>
                                    <div className={styles.col}>Amount</div>
                                    <div className={styles.col}>Value</div>
                                    <div className={styles.col}>Transaction</div>
                                </div>
                                {transactions.map((x, index) => (
                                    <Row transaction={x} key={index} rowStyles={styles} />
                                ))}
                            </div>
                        </>
                    )}
                </Scrollbar>
            </div >
        </Card>
    );
};

export default ActivityDetailsTable;
