import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    nftId: null,
    address: null,
    ensName: null,
    showNetAPY: true,
    showSwitchNetwork: false,
    netAPY: null,
    interfacePreference: [],
    earnPoints: null
};

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setNftId: (state, action) => {
            state.nftId = action.payload;
        },
        setEnsName: (state, action) => {
            state.ensName = action.payload;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
        },
        setShowNetAPY: (state, action) => {
            state.showNetAPY = action.payload;
        },
        setShowSwitchNetwork: (state, action) => {
            state.showSwitchNetwork = action.payload;
        },
        setNetAPY: (state, action) => {
            state.netAPY = action.payload;
        },
        setEarnPoints: (state, action) => {
            state.earnPoints = action.payload;
        },
        setInterfacePreference: (state, action) => {
            state.interfacePreference = action.payload;
        }
    },
});

export const {
    setNftId,
    setEnsName,
    setShowNetAPY,
    setNetAPY,
    setShowSwitchNetwork,
    setAddress,
    setEarnPoints,
    setInterfacePreference
} = settingsSlice.actions;

export default settingsSlice.reducer;
