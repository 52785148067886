import { mainnet, arbitrum } from "wagmi/chains";
import { _notEmpty, _no, _isNotEmptyNftId } from "../utils";

const aaveLogo = "/images/aave.svg";
// const beefyLogo = "/images/beefy.png";

export const WETHToken = {
    name: "WETH",
    title: "Wrapped ETH"
};

export const POOL_NAMES = {
    ETH: "ETH-AAVE",
    USDC: "USDC-AAVE",
    USDT: "USDT-AAVE",
    DAI: "DAI-AAVE",
    WBTC: "WBTC-AAVE",
    ETH_NATIVE: "ETH",
    USDC_NATIVE: "USDC",
    USDT_NATIVE: "USDT",
    DAI_NATIVE: "DAI",
    sDAI_NATIVE: "sDAI",
    WBTC_NATIVE: "WBTC",
    LINK_NATIVE: "LINK",
    wstETH_NATIVE: "wstETH",
    // moo3USDC: "moo3USDC",
    // moo3USDT: "moo3USDT",
    // moo3MIM: "moo3MIM",
};

export const supportedTokens = [
    {
        name: "ETH",
        title: "Ethereum",
        poolName: POOL_NAMES.ETH,
        icon: "/images/icons/eth.svg",
        sub: aaveLogo,
        decimals: 18,
        poolCollateralFactor: 0.805,
        isAavePool: true,
        isEnabled: true,
        canBorrow: true
    },
    {
        name: "USDC",
        title: "USDC Token",
        poolName: POOL_NAMES.USDC,
        icon: "/images/icons/usdc.svg",
        sub: aaveLogo,
        decimals: 6,
        poolCollateralFactor: 0.77,
        isAavePool: true,
        isEnabled: true,
        canBorrow: true
    },
    {
        name: "USDT",
        title: "USDT Token",
        poolName: POOL_NAMES.USDT,
        icon: "/images/icons/usdt.svg",
        sub: aaveLogo,
        decimals: 6,
        poolCollateralFactor: 0.77,
        isAavePool: true,
        isEnabled: true,
        canBorrow: true
    },
    {
        name: "DAI",
        title: "Dai Token",
        poolName: POOL_NAMES.DAI,
        icon: "/images/icons/dai.svg",
        sub: aaveLogo,
        decimals: 18,
        poolCollateralFactor: 0.67,
        isAavePool: true,
        isEnabled: true,
        canBorrow: true
    },
    {
        name: "WBTC",
        title: "Wrapped Bitcoin",
        poolName: POOL_NAMES.WBTC,
        icon: "/images/icons/btc.svg",
        sub: aaveLogo,
        decimals: 8,
        poolCollateralFactor: 0.73,
        isAavePool: true,
        isEnabled: true,
        canBorrow: true
    },
    {
        name: "ETH",
        title: "Ethereum",
        poolName: POOL_NAMES.ETH_NATIVE,
        icon: "/images/icons/eth.svg",
        sub: undefined,
        decimals: 18,
        poolCollateralFactor: 0.805,
        isAavePool: false,
        isEnabled: true,
        canBorrow: true
    },
    {
        name: "USDC",
        title: "USDC Token",
        poolName: POOL_NAMES.USDC_NATIVE,
        icon: "/images/icons/usdc.svg",
        sub: undefined,
        decimals: 6,
        poolCollateralFactor: 0.77,
        isAavePool: false,
        isEnabled: true,
        canBorrow: true
    },
    {
        name: "USDT",
        title: "USDT Token",
        poolName: POOL_NAMES.USDT_NATIVE,
        icon: "/images/icons/usdt.svg",
        sub: undefined,
        decimals: 6,
        poolCollateralFactor: 0.74,
        isAavePool: false,
        isEnabled: true,
        canBorrow: true
    },
    {
        name: "DAI",
        title: "Dai Token",
        poolName: POOL_NAMES.DAI_NATIVE,
        icon: "/images/icons/dai.svg",
        sub: undefined,
        decimals: 18,
        poolCollateralFactor: 0.67,
        isAavePool: false,
        isEnabled: true,
        canBorrow: true
    },
    {
        name: "sDAI",
        title: "sDai Token",
        poolName: POOL_NAMES.sDAI_NATIVE,
        icon: "/images/icons/sdai.svg",
        sub: undefined,
        decimals: 18,
        poolCollateralFactor: 0.77,
        isAavePool: false,
        isEnabled: true,
        canBorrow: true
    },
    {
        name: "WBTC",
        title: "Wrapped Bitcoin",
        poolName: POOL_NAMES.WBTC_NATIVE,
        icon: "/images/icons/btc.svg",
        sub: undefined,
        decimals: 8,
        poolCollateralFactor: 0.60, // 0.73
        isAavePool: false,
        isEnabled: true,
        canBorrow: true
    },
    /*{
        name: "LINK",
        title: "Chainlink Token",
        poolName: POOL_NAMES.LINK_NATIVE,
        icon: "/images/icons/link.svg",
        sub: undefined,
        decimals: 18,
        poolCollateralFactor: 0.67,
        isAavePool: false,
        isEnabled: true,
        canBorrow: true
    },*/
    {
        name: "wstETH",
        title: "wstETH Token",
        poolName: POOL_NAMES.wstETH_NATIVE,
        icon: "/images/icons/wsteth.svg",
        sub: undefined,
        decimals: 18,
        // @TODO: find a way to set per chain
        // because we have different collateral factors per chain
        // on mainnet should be 0.805 and on Arbitrum 0.70
        poolCollateralFactor: 0.70,
        isAavePool: false,
        isEnabled: true,
        canBorrow: true
    },
    /*{
        name: "moo3USDC",
        title: "Moo Tricrypto USDC",
        poolName: POOL_NAMES.moo3USDC,
        sub: beefyLogo,
        icon: "/images/icons/moo3USDC.svg",
        decimals: 18,
        poolCollateralFactor: 0.73,
        isAavePool: false,
        isEnabled: true,
        canBorrow: false
    },
    {
        name: "moo3USDT",
        title: "Moo Tricrypto USDT",
        poolName: POOL_NAMES.moo3USDT,
        sub: beefyLogo,
        icon: "/images/icons/moo3USDT.svg",
        decimals: 18,
        poolCollateralFactor: 0.73,
        isAavePool: false,
        isEnabled: true,
        canBorrow: false
    },
    {
        name: "moo3MIM",
        title: "Moo Tricrypto MIM",
        poolName: POOL_NAMES.moo3MIM,
        sub: beefyLogo,
        icon: "/images/icons/moo3MIM.svg",
        decimals: 18,
        poolCollateralFactor: 0.73,
        isAavePool: false,
        isEnabled: true,
        canBorrow: false
    },*/
];

const priceFeedLinks = {
    ETH: "https://data.chain.link/ethereum/mainnet/crypto-usd/eth-usd",
    USDT: "https://data.chain.link/ethereum/mainnet/stablecoins/usdt-usd",
    USDC: "https://data.chain.link/ethereum/mainnet/stablecoins/usdc-usd",
    WBTC: "https://data.chain.link/ethereum/mainnet/crypto-usd/btc-usd",
    DAI: "https://data.chain.link/ethereum/mainnet/stablecoins/dai-usd",
    sDAI: "https://etherscan.io/address/0x9a8eaa4097102d3dd86f4b66904674f82522768e#readContract#F8",
    wstETH: "https://etherscan.io/address/0xC42e9F1Aa22f78bC585e6911424c6B4936674e08#readContract#F8",
    // LINK: "https://data.chain.link/ethereum/mainnet/crypto-usd/link-usd"
};

const tokenAddress = [
    {
        addressChainId: mainnet.id,
        address: {
            ETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
            USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
            USDC: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
            WBTC: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
            DAI: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
            wstETH: "0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0",
            // sDAI: "0x83F20F44975D03b1b09e64809B757c47f942BEeA",
            // WISE: "0x66a0f676479Cee1d7373f3DC2e2952778BfF5bd6",
            // moo3USDC: "0xD1BeaD7CadcCC6b6a715A6272c39F1EC54F6EC99",
            // moo3USDT: "0xe50e2fe90745A8510491F89113959a1EF01AD400",
            // moo3MIM: "0xd5bAd7c89028B3F7094e40DcCe83D4e6b3Fd9AA4",
            // LINK: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
        },
        aaveAddress: {
            ETH: "0x4d5F47FA6A74757f35C14fD3a6Ef8E3C9BC514E8",
            USDT: "0x23878914EFE38d27C4D67Ab83ed1b93A74D4086a",
            USDC: "0x98C23E9d8f34FEFb1B7BD6a91B7FF122F4e16F5c",
            WBTC: "0x5Ee5bf7ae06D1Be5997A1A72006FE6C607eC6DE8",
            DAI: "0x018008bfb33d285247A21d44E50697654f754e63",
            // wstETH: undefined,
        },
    },
    /*{
        addressChainId: goerli.id,
        address: {
            USDT: "0xc2c527c0cacf457746bd31b2a698fe89de2b6d49",
            USDC: "0x65aFADD39029741B3b8f0756952C74678c9cEC93",
            ETH: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
            WBTC: "0xC04B0d3107736C32e19F1c62b2aF67BE61d63a05",
            DAI: "0x11fe4b6ae13d2a6055c8d9cf65c55bac32b5d844",
            wstETH: "0x6320cD32aA674d2898A68ec82e869385Fc5f7E2f",
            LINK: "0x326C977E6efc84E512bB9C30f76E30c160eD06FB"
        },
        aaveAddress: {
            ETH: "0x7649e0d153752c556b8b23DB1f1D3d42993E83a5",
            USDT: undefined,
            USDC: "0x8Be59D90A7Dc679C5cE5a7963cD1082dAB499918",
            WBTC: undefined,
            DAI: undefined,
            wstETH: undefined,
            LINK: undefined
        },
    },*/
    {
        addressChainId: arbitrum.id,
        address: {
            USDT: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
            USDC: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
            ETH: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
            // WBTC: undefined,
            DAI: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
            wstETH: "0x5979D7b546E38E414F7E9822514be443A4800529",
            // LINK: undefined
        },
        aaveAddress: {
            ETH: "0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8",
            USDT: "0x6ab707Aca953eDAeFBc4fD23bA73294241490620",
            USDC: "0x724dc807b04555b71ed48a6896b6F41593b8C637",
            // WBTC: undefined,
            DAI: "0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE",
            // wstETH: undefined,
            // LINK: undefined
        },
    }
];

const defaultPools = [
    "ETH",
    "USDC",
    "USDT",
    "DAI",
    "sDAI",
    "WBTC",
    "wstETH",
    "LINK",
    // "moo3USDC",
    // "moo3USDT",
    // "moo3MIM",
];

export const ALL_SUPPORTED_POOLS = supportedTokens.map((token) => {
    return token.poolName
});

export const getSupportedTokens = (
    chainId,
    availableTokens = defaultPools
) => {

    const filteredTokens = supportedTokens.filter(
        token => availableTokens.includes(token.poolName)
    );

    return filteredTokens.map(token => {
        const addresses = tokenAddress.find(
            item => item.addressChainId === chainId
        );
        if (addresses) {
            const {
                addressChainId,
                address,
                aaveAddress
            } = addresses;
            return {
                ...token,
                addressChainId,
                address: address[token.name],
                aaveAddress: aaveAddress[token.name],
                priceFeedLink: priceFeedLinks[token.name]
            };
        }

        return token;
    });
};

export const isCorrectTokenAddress = (chainId, name, address) => {

    const haveData = _notEmpty(chainId)
        && _notEmpty(name)
        && _notEmpty(address);

    const addresses = tokenAddress.find(item => item.addressChainId === chainId);

    if (haveData && addresses) {
        return addresses.address[name] === address;
    }

    return false;
};

export const isEnabled = ({
    config,
    nftId,
    token,
    allowNullNftId = false
}) => {
    const {
        chainId,
        wiseLendingContract,
        wiseSecurityContract,
        positionNFTContract
    } = config;

    const isCorrectAddress = isCorrectTokenAddress(
        chainId,
        token.name,
        token.address
    );

    const isValidContract = _notEmpty(wiseLendingContract?.address)
        && _notEmpty(wiseSecurityContract?.address)
        && _notEmpty(positionNFTContract?.address);

    const validNftId = _no(allowNullNftId)
        ? _isNotEmptyNftId(nftId)
        : true;

    return isCorrectAddress
        && isValidContract
        && validNftId
        && _notEmpty(chainId)
        && token.isEnabled;
};

export const getLendingTokenAddress = (token, isAavePool) => {
    return isAavePool
        ? token.aaveAddress
        : token.address;
};
