import React, { useState, useEffect } from "react";
import styles from "./CreateReferral.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import { showToast } from "../../../utils";
import { isWalletAddressFormat, getEnsNameForAddress } from "../../../utils";
import { getEnsAddressAcrossNetworks } from "../../../utils";
import { shortenAddress } from "../../../utils";
import useTransferNft from "../../../customHooksWise/useTransferNft";
import Loader from "../../../components/Loader";
import { useAccount } from "wagmi";

const SendNFT = ({
    title,
    className,
    linkBase,
    nftId,
    onClose,
    contract,
    onSent,
    // doRefetchStakes,
}) => {

    const { address } = useAccount();

    const SENDING_TEXT = "Sending...";
    const DEFAULT_TEXT = title;
    const DONE_TEXT = "Done";
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [transferAddress, setTransferAddress] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [transactionHashLink, setTransactionHashLink] = useState("");

    const transferNft = useTransferNft({
        contract
    });

    const handleTransfer = async () => {

        if (buttonText === DONE_TEXT) {
            onClose();
            return;
        }

        let resolvedAddress;

        if (isWalletAddressFormat(transferAddress)) {
            const res = {
                address: transferAddress,
                ensName: await getEnsNameForAddress(transferAddress),
            };
            if (res.address) {
                resolvedAddress = res.address;
            } else {
                showToast(
                    "Invalid ENS name or wallet address provided",
                    {
                        type: "warning",
                    }
                );
                return;
            }
        } else {
            const res = await getEnsAddressAcrossNetworks(
                transferAddress
            );

            if (res) {
                resolvedAddress = res;
                // setTransferAddress(res);
            } else {
                showToast(
                    "Invalid ENS name or wallet address provided",
                    {
                        type: "warning",
                    }
                );
                return;
            }
        }

        // If we have a valid address, proceed with the transfer
        if (resolvedAddress) {
            transferNft.transferNft({
                fromAddress: address,
                toAddress: resolvedAddress,
                tokenId: nftId,
                onConfirmation: () => {
                    setIsLoading(true);
                    setButtonText("Confirm In Wallet");
                }
            });
        }
    };

    useEffect(() => {
        if (transferNft.error && isLoading) {
            setIsLoading(false);
            showToast(
                "Failed to transfer NFT: " + (transferNft.error?.message || "Unknown error"),
                {
                    type: "info",
                }
            );
            setButtonText(DEFAULT_TEXT);
        }

        if (transferNft.hasError && isLoading) {
            setIsLoading(false);
            showToast(
                "Failed to transfer NFT: " + (transferNft.error?.message || "Unknown error"),
                {
                    type: "info",
                }
            );
            setButtonText(DEFAULT_TEXT);
        }

        if (transferNft.isConfirming && isLoading) {
            setButtonText(SENDING_TEXT);
            setTransactionHashLink(
                `${linkBase}/tx/${transferNft.hash}`
            );
        }

        if (transferNft.isConfirmed && isLoading) {
            const displayAddress = transferAddress.length === 42
                ? shortenAddress(transferAddress)
                : transferAddress;
            setIsLoading(false);
            showToast(
                `Successful transfer to ${displayAddress}`,
                {
                    type: "success",
                }
            );
            setButtonText(DONE_TEXT);

            if (onSent) {
                onSent();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transferNft]);

    return (
        <Card
            className={cn(styles.card, className)}
            title={title}
            classTitle="title-green"
        >
            <div className={styles.create}>
                <div className={styles.box}>
                    <div className={styles.label}>
                        Input valid ENS name or wallet address for transfer to the recipient.
                    </div>
                    <TextInput
                        className={styles.field}
                        name="link-promote"
                        type="text"
                        value={transferAddress}
                        onChange={(e) => setTransferAddress(e.target.value)}
                        placeholder={"ENS name or wallet address"}
                        required
                        icon="profile-circle"
                    />
                    <button
                        disabled={isLoading}
                        style={{ width: "100%", marginTop: "16px" }}
                        className={cn("button",
                            styles.button,
                            {
                                [styles.disabled]: isLoading
                            }
                        )}
                        onClick={handleTransfer}
                    >
                        {buttonText === SENDING_TEXT && (<Loader className={styles.loader} />)} {buttonText}
                    </button>
                    {transactionHashLink &&
                        <a
                            className={cn(
                                "button",
                                "button-stroke",
                                styles.button,
                                styles.transaction
                            )}
                            href={transactionHashLink}
                            target="_blank"
                            rel="noreferrer"
                        >
                            View Transaction Details
                        </a>
                    }
                </div>
            </div>
        </Card>
    );
};

export default SendNFT;