import React from "react";
import styles from "./TopPools.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import { MAIN_COLOR_A } from "../../../utils/config";

//import useDarkMode from "use-dark-mode";

const data = [
    {
        name: "WETH Pool",
        views: 1823,
    },
    {
        name: "USDC Pool",
        views: 1498,
    },
    {
        name: "WBTC Pool",
        views: 1123,
    },
    {
        name: "USDT Pool",
        views: 432,
    },
];

const TopPools = ({ className }) => {

    //const darkMode = useDarkMode(false);
    const darkMode = false;

    return (
        <Card
            className={cn(styles.card, className)}
            title="Top Pools"
            classTitle="title-purple"
        >
            <div className={styles.chart}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={data}
                        layout="vertical"
                        margin={{
                            top: 0,
                            right: 0,
                            left: 10,
                            bottom: 0,
                        }}
                        barSize={24}
                        barGap={23}
                    >
                        <CartesianGrid
                            strokeDasharray="none"
                            stroke={darkMode.value ? "#272B30" : "#EFEFEF"}
                            horizontal={false}
                        />
                        <XAxis
                            type="number"
                            axisLine={false}
                            tickLine={false}
                            tick={{ fontSize: 12, width: "130", whiteSpace: "nowrap", fontWeight: "500", fill: "#6F767E" }}
                            padding={{ left: 0 }}
                        />
                        <YAxis
                            type="category"
                            dataKey="name"
                            axisLine={false}
                            tickLine={false}
                            tick={{ fontSize: 12, width: "130", whiteSpace: "nowrap", fontWeight: "500", fill: "#6F767E" }}
                        />
                        <Tooltip
                            contentStyle={{
                                backgroundColor: "#272B30",
                                borderColor: "rgba(255, 255, 255, 0.12)",
                                borderRadius: 8,
                                boxShadow:
                                    "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
                            }}
                            labelStyle={{ fontSize: 12, fontWeight: "500", color: "#fff" }}
                            itemStyle={{
                                padding: 0,
                                textTransform: "capitalize",
                                fontSize: 12,
                                fontWeight: "600",
                                color: "#fff",
                            }}
                            cursor={{ fill: "#f3f2f3" }}
                        />
                        <Bar dataKey="views" fill={MAIN_COLOR_A} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </Card>
    );
};

export default TopPools;
