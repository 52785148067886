import React, { useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
// import Icon from "../../../../Icon";
import ModalPreview from "../../../../ModalPreview";

const gallery = [
    "/images/content/photo-1.jpg",
    "/images/content/photo-2.jpg",
    "/images/content/photo-1.jpg",
    "/images/content/photo-2.jpg",
];

// const features = [
//     "128 prebuilt screens",
//     "SaaS landing page ready",
//     "Global styleguide",
//     "Dark + light more ready",
// ];

const Overview = () => {
    const [visibleModalPreview, setVisibleModalPreview] = useState(false);

    return (
        <>
            <div className={styles.overview}>
                <div className={cn("h4", styles.title)}>
                    Borrowing Funds...
                </div>
                <div className={styles.info}>
                    Wait till your transaction confirms
                </div>
                <div className={styles.line}>
                    <div className={styles.author}>
                        <div className={styles.avatar}>
                            <img src="/images/content/shadow-owl.jpg" alt="Avatar" />
                        </div>
                        by <span>vitally.eth</span>
                    </div>
                </div>
            </div>
            <ModalPreview
                visible={visibleModalPreview}
                onClose={() => setVisibleModalPreview(false)}
                gallery={gallery}
                title="Fleet - Travel shopping UI design kit"
                figcaption="Elegant product mockup for your next project"
                download
            />
        </>
    );
};

export default Overview;
