import React, { useState } from "react";
import styles from "./NewProduct.module.sass";
import TooltipGlobal from "../../components/TooltipGlobal";
import Modal from "../../components/Modal";
// import Schedule from "../../components/Schedule";
import NameAndDescription from "./NameAndDescription";
import ImagesAndCTA from "./ImagesAndCTA";
import Price from "./Price";
import CategoryAndAttibutes from "./CategoryAndAttibutes";
import ProductFiles from "./ProductFiles";
import Discussion from "./Discussion";
import Preview from "./Preview";
import Panel from "./Panel";

const NewProduct = () => {

    const [visiblePreview, setVisiblePreview] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <NameAndDescription className={styles.card} />
                    <ImagesAndCTA className={styles.card} />
                    <Price className={styles.card} />
                    <CategoryAndAttibutes className={styles.card} />
                    <ProductFiles className={styles.card} />
                    <Discussion className={styles.card} />
                </div>
                <div className={styles.col}>
                    <Preview
                        visible={visiblePreview}
                        onClose={() => setVisiblePreview(false)}
                    />
                </div>
            </div>
            <Panel
                setVisiblePreview={setVisiblePreview}
                setVisibleSchedule={setVisibleModal}
            />
            <TooltipGlobal />
            <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
            </Modal>
        </>
    );
};

export default NewProduct;
