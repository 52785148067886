import {
    useWriteContract,
    useWalletClient,
    useWaitForTransactionReceipt
} from "wagmi";
import { getLendingContract } from "../ethers/contracts";
import { isEnabled } from "../ethers/tokens";
import {
    // CONFIRMATIONS_COUNT,
    // ERROR_WITHDRAW,
    // ERROR_WITHDRAW_WRITE,
    INFLATE_PERCENT,
    NATIVE_TOKENS
} from "../utils/config";
import { useGasEstimate } from "./useGasEstimate";
// import { parseUnits } from "viem";

export const useWithdrawToken = ({
    config,
    nftId,
    token,
    amount,
    isMax,
    isAll,
    isPrivate,
    hasEnough,
    showApproval
}) => {

    const isETH = NATIVE_TOKENS.includes(
        token.name
    );

    const { data: signer } = useWalletClient();
    const { chainId } = config;

    const contract = getLendingContract(
        config,
        token.isAavePool
    );

    /*if (isMax && isAll === false) {
        amount = amount * 1000n / 1001n;
    }*/

    let functionName = isETH === false
        ? "withdrawExactAmount"
        : "withdrawExactAmountETH";

    let args = isETH
        ? [
            nftId,
            amount
        ]
        : [
            nftId,
            token?.address,
            amount
        ];

    const enabled = isEnabled({
        config,
        nftId,
        token
    });

    if (isPrivate) {
        functionName = isETH === false
            ? "solelyWithdraw"
            : "solelyWithdrawETH";
    }

    if (isMax && hasEnough && isAll && isETH === false) {
        functionName = "withdrawExactShares";

        args = [
            nftId,
            token?.address,
            token.withdrawShareBalance
        ];
    }

    if (isMax && hasEnough && isAll && isETH === true) {
        functionName = "withdrawExactSharesETH";

        args = [
            nftId,
            token.withdrawShareBalance
        ];
    }

    const {
        gasLimit,
        estimatedGas,
        estimatesGasFormatted,
        estimatedGasUSD,
        estimateHasError: isError
    } = useGasEstimate({
        contract,
        functionName,
        args,
        account: signer?.account,
        amount,
        showApproval
    });

    let gasLimitFormatted = parseInt(
        parseInt(gasLimit) * INFLATE_PERCENT
    ).toString();

    const {
        error,
        data: hash,
        writeContract,
        isPending,
    } = useWriteContract();

    const withdraw = async ({
        onConfirmation,
        onWithdrawComplete,
        onWithdrawing,
        onError
    }) => {
        onConfirmation?.();

        writeContract({
            chainId,
            abi: contract?.abi,
            address: contract?.address,
            signerOrProvider: signer,
            gas: gasLimitFormatted,
            functionName,
            args,
            query: {
                enabled
            }
        });
    };

    const {
        isLoading: isConfirming,
        isSuccess: isConfirmed,
        isError: hasError
    } = useWaitForTransactionReceipt({
        hash,
    });

    return {
        hash,
        error,
        hasError,
        withdraw,
        isPending,
        isConfirming,
        isConfirmed,
        estimatedGas,
        estimatesGasFormatted,
        estimatedGasUSD,
        estimateHasError: isError
    };
};
