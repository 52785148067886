import React, {
    // useState,
    // useEffect,
    // useMemo
} from "react";
// import * as cn from "classnames";

// import loaderImage from "../../assets/images/loaders/golden.svg";
// import StatusBadge from "../../assets/images/icons/StatusBadge";
// import { getOwlImage } from "../../utils";

// import styles from "./Avatar.module.sass";

const Avatar = (
    // {
    // className,
    // address,
    // showBadge,
    // onClick
    // }
) => {

    // const [avatar, setAvatar] = useState(
        // loaderImage
    // );

    // const [isVerified, setIsVerified] = useState(
        // false
    // );

    // const [isLoading, setIsLoading] = useState(
        // true
    // );

    /*
    useEffect(() => {

        const checkAvatar = async (address) => {

            const result = await getOwlImage(
                address
            );

            if (result) {
                setAvatar(
                    result.image
                );

                setIsVerified(
                    result.isOwned
                );

                setIsLoading(
                    false
                );
            }
        };

        setIsLoading(true);
        setAvatar(loaderImage);
        checkAvatar(address);

    }, [address]);
    */

    /*(const statusTooltipText = useMemo(() => {
        return isVerified
            ? `🟣 Verified NFT Status ${avatar}`
            : `⚪ Unverified NFT Status ${avatar}`;
    }, [isVerified, avatar]);
    */

    return (<div></div>);
    /*
    return (
        <div
            className={cn(className,
                {
                    [styles.loaded]: isLoading === false,
                    [styles.loading]: isLoading === true,
                    [styles.badgeContainer]: showBadge,
                    [styles.tooltip]: showBadge
                }
            )}
            data-text={statusTooltipText}
            onClick={onClick}
        >
            <img src={avatar} alt="Avatar" />
            {showBadge && (
                <div
                    className={cn(
                        styles.verifiedBadge,
                        {
                            [styles.verified]: isVerified
                        }
                    )}
                >
                    <StatusBadge width={30} height={30} />
                </div>
            )}
        </div>
    );
    */
};

export default Avatar;
