import React, { useState, useEffect, useMemo } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { useSelector } from "react-redux";
import Control from "./Control";
import Tooltip from "../../Tooltip";
import { useAPYEstimate } from "../../../customHooks/useAPYEstimate";
import useConfig from "../../../customHooks/useConfig";
import useTokenBalance from "../../../customHooks/useTokenBalance";
import { ALL_SUPPORTED_POOLS, getSupportedTokens } from "../../../ethers/tokens";
import {
    _no,
    calculateAvailablePoolFunds,
    // _intPrefix,
    computeUSDAmount,
    displayWithLoaderObj,
    formatDecimals,
    formatMoney,
    formatMoneyDashed,
} from "../../../utils";
import { formatUnits } from "viem";
import TooltipGlobal from "../../TooltipGlobal";
import useLendingSummary from "../../../customHooks/useLendingSummary";
import useGetFarmPosition from "../../../customHooks/useGetFarmPosition";
import useGetFarmPositionData from "../../../customHooks/useGetFarmPositionData";
// import { DEFAULT_DECIMALS } from "../../../utils/config";
import { getFarmAPR } from "../../../ethers/farms";
import ProductLogo from "./ProductLogo";

import dayjs from 'dayjs';
// import ReactTooltip from "react-tooltip";
// import { FarmingContext, useFarmingContext } from "../../../screens/Farming/context";

const Row = ({
    item,
    index,
    isExpired,
    isFetching,
    doRefetch,
    defaultChain,
    handleChildValueChange,
    tokenUtilization,
    isEmpty
}) => {

    // const config = useConfig();
    // const farmContext = useContext(FarmingContext);
    // const { chainId } = config;
    // const farmingContext = useFarmingContext();
    const chainId = defaultChain;
    // const { farmNftId } = item;

    // console.log(item, 'item');
    // console.log(item, 'item');
    const config = useConfig();

    const {
        nftId,
        address
    } = useSelector(
        (state) => state.settings
    );

    const {
        pricingFeeds,
        walletBalance,
        APY
    } = useSelector(
        (state) => state.token
    );

    const { farmNftId } = useGetFarmPosition({
        address,
        farm: item,
        chainId
        // refetch
    });

    let APR = getFarmAPR(
        item,
        APY
    );

    const supportedTokens = useMemo(
        () => {
            return getSupportedTokens(
                chainId,
                ALL_SUPPORTED_POOLS
            );
        },
        [chainId]
    );

    const getDefaultToken = () => {
        return supportedTokens.find(
            (token) => {
                return token.name === item.defaultToken
                    && token.isAavePool === (item.tokens[item.defaultToken].isAave ?? false);
            }
        );
    };

    const [token, setToken] = useState(getDefaultToken());

    /*
    useEffect(() => {
        setToken(getDefaultToken());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportedTokens]);
    */

    const tokenPriceData = pricingFeeds.find(
        item => item.name === token.name
    );

    const tokenPrice = tokenPriceData?.price ?? 0;

    const { tokenBalance } = useTokenBalance({
        config,
        nftId,
        token,
        userAccount: address,
        price: tokenPrice,
        walletBalance
    });

    const { data } = useGetFarmPositionData({
        chainId,
        farmNftId,
        farm: item,
    });

    let availableFunds = useMemo(() =>
        calculateAvailablePoolFunds({
            poolTotalBalance: tokenBalance.totalFormatted,
            poolUtilizationRate: tokenUtilization?.utilizationPercentage,
            poolUtilizationThreshold: "0.9"
        })
        ?? 0
        , [
            tokenBalance.totalFormatted,
            tokenUtilization?.utilizationPercentage,
        ]);

    /*
    if (item.expiring) {
        availableFunds = "-";
    }
    */

    const wiseLendingNft = data && data[0]
        ? Number(data[0])
        : Number(-1);

    const summary = useLendingSummary({
        config,
        nftId: wiseLendingNft,
        userAccount: address,
    });

    const nftSupply = !!farmNftId ? parseFloat(
        summary.supplyFormatted
    ) : "0";

    const nftBorrow = !!farmNftId ? parseFloat(
        summary.borrowFormatted
    ) : "0";

    const leverageFormatted = farmNftId
        ? parseFloat(
            data && parseInt(data[1]) / 1E18
        ) : 3; // 14;

    const amountInUSD = useMemo(() => {
        return farmNftId ?
            computeUSDAmount(
                token.decimals,
                data && parseInt(data[2]).toString(),
                tokenPrice
            ) : 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        tokenPrice,
        token.decimals,
        data,
        // transaction,
        farmNftId
    ]);

    const netAmount = (nftSupply - nftBorrow);

    // TODO: to be deleted
    // const netUSD = netAmount - amountInUSD;
    // let reward = 0;

    // if (tokenPrice > 0 && netUSD > 0) {
    //     reward = netUSD / tokenPrice;
    // }

    // if (reward < 0) {
    //     reward = 0;
    // }

    const apyEstimate = useAPYEstimate({
        config,
        amount: "0.1",
        leverage: leverageFormatted + 1,
        token: tokenBalance,
        apr: APR
    });

    /*
    const apyEstimate = {
        isLoading: false,
        isPositive: true,
        netAPY: 0,
        supplyAPY: 0,
    }
    */

    const supplyAPY = farmNftId
        ? parseFloat(APR) * (leverageFormatted + 1)
        : 2;

    const borrowAPY = parseFloat(tokenBalance.borrowRateFormatted)
        * leverageFormatted;

    const APYValueCalculated = farmNftId
        ? (supplyAPY - borrowAPY).toFixed(2)
        : apyEstimate.netAPY;

    const estimatedAPYValue = item.active && _no(isNaN(APYValueCalculated))
        ? `${formatDecimals(APYValueCalculated)}`
        : "0.00";

    const maturityDaysDuration = dayjs(item.maturityDate).diff(new Date(), 'd');
    const maturityDateDurationPrivate = dayjs(item.maturityDatePrivate).diff(new Date(), 'd');

    const daysOfMaturity = maturityDaysDuration > 0
        ? maturityDaysDuration
        : 0;

    const daysOfMaturityPrivate = maturityDateDurationPrivate > 0
        ? maturityDateDurationPrivate
        : 0;

    let amountDaysValue = `${daysOfMaturity} ${daysOfMaturity > 1
        ? " Days"
        : " Day"}`;

    if (daysOfMaturity <= 0) {
        amountDaysValue = `Expired`;
    }

    const amountDaysValuePrivate = `${daysOfMaturityPrivate} ${daysOfMaturityPrivate > 1
        ? " Days"
        : " Day"}`;

    const amountValue = amountDaysValue;
    // item.active && transaction?.amount
    //     // ? `${formatMoney(amountFormatted)} ${token.name}`
    //     ? `${amountDaysValue}`
    //     : `${amountDaysValue}`;

    const amountValuePrivate = amountDaysValuePrivate;
    // item.active && transaction?.amount
    //     ? `${amountDaysValuePrivate}`
    //     : `${amountDaysValuePrivate}`;


    // const rewardsValue = item.active && transaction?.amount ? formatMoney(estimatedRewards) : `0.00`;
    // const rewardsValue = `0.00`;

    // const healthValue = item.active && transaction?.amount
    //  ? `100.00%`
    // : `0.00%`;

    const rewardsValue = netAmount
        ? `$${formatMoneyDashed(netAmount)}`
        // ? `${parseFloat(reward).toFixed(2)} ${token.name}`
        : `—`;

    const APYValueReal = farmNftId
        ? (supplyAPY - borrowAPY).toFixed(2)
        : 0;

    const positionData = {
        // ...transaction,
        ...apyEstimate,
        // TODO: to be deleted
        // reward,
        estimatedAPYValue,
        amountFormatted: data && (parseInt(data[2]) / 10 ** token.decimals),
        leverageFormatted
    };

    useEffect(() => {
        handleChildValueChange(
            index,
            {
                apyReal: APYValueReal,
                amountFormatted: amountInUSD && formatUnits(amountInUSD, token.decimals),
                netAmount: netAmount
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amountInUSD, APYValueReal, netAmount]);

    const handleRowClick = (event) => {
        if (event?.target?.className?.includes?.("Row_")) {
            const rowClickEvent = new CustomEvent("farmRowClick" + item.id, {
                detail: {
                    key: item.id,
                },
            });

            window.dispatchEvent(
                rowClickEvent
            );
        }
    };

    /*
    const isLoading = useMemo(() => {
        return isFetching || _no(summary.successDataFetching);
    }, [isFetching, summary.successDataFetching]);
    */


    /*
    useEffect(() => {
        if (farmNftId && item.active && _no(isNaN(APYValueCalculated))) {
            farmContext.addOpenFarmAPY(
                token.poolName,
                estimatedAPYValue
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estimatedAPYValue]);
    */

    /*
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [leverageFormatted, item.maturityDate, isLoading]);
    */

    if (isExpired && _no(farmNftId)) {
        return null;
    }

    let apyStyle = APYValueCalculated >= 0
        ? "status-green-dark"
        : "status-red";

    if (!APYValueCalculated || netAmount <= 0) {
        apyStyle = "status-yellow";
    }

    if (APYValueCalculated && parseFloat(APYValueCalculated) < 0) {
        apyStyle = "status-red";
    }

    let apyStyle2 = !farmNftId
        ? "status-yellow"
        : "status-green-dark"

    if (daysOfMaturity <= 0 && !isExpired && item.id !== 10) {
        return null;
    }

    if (!farmNftId && item.expiring === true) {
        return null;
    }

    return (
        <>
            <div
                onClick={handleRowClick}
                className={cn(
                    styles.row,
                    {
                        [styles.disabled]: item.active === false,
                    }
                )}
            >
                <div className={styles.col}></div>
                <div className={styles.col}>
                    <div
                        className={styles.productItem}
                    >
                        <ProductLogo item={item} />
                        <div className={styles.details}>
                            <div>
                                <div className={styles.product}>{item.product}</div>
                                {item.expiring ? (
                                    <div className={styles.linker}>
                                        Old Farm: Expires Soon
                                    </div>
                                ) : (
                                    <div className={styles.link}>{item.link}</div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.label}>Borrowing Asset</div>
                        <div className={cn(
                            styles.value,
                            styles.icons
                        )}>
                            <img
                                style={{opacity: item.expiring ? 0.2 : 1}}
                                className={cn(styles.data)}
                                width={"35px"}
                                alt={`token${item.defaultToken}`}
                                src={`/images/icons/${item.defaultToken.toLowerCase()}.svg`}>
                            </img>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.label}>Available To Borrow</div>
                        {item.expiring ? (
                            <div className={styles.value}>
                                Only Closing
                            </div>
                        ): (
                            <div className={styles.value}>
                                {displayWithLoaderObj({
                                    isLoading: !availableFunds,
                                    data: `${formatMoney(availableFunds)} ${token.name}`,
                                    skeletonWidth: 50,
                                    isEmpty
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.label}>Estimated APY</div>
                        <div className={styles.value}>
                            {displayWithLoaderObj({
                                isLoading: !estimatedAPYValue || estimatedAPYValue === "0.00",
                                data: <div
                                    className={cn(
                                        `${apyStyle}`,
                                        styles.data,
                                        styles.lbl
                                    )}>
                                    {estimatedAPYValue}%
                                </div>,
                                skeletonWidth: 50,
                                isEmpty
                            })}
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.label}>Maturity</div>
                        <div className={styles.value}>
                            {displayWithLoaderObj({
                                isLoading: !estimatedAPYValue,
                                data: <>
                                    {
                                        isExpired
                                            ? amountValuePrivate
                                            : amountValue
                                    }
                                    <Tooltip
                                        className={cn(
                                            styles.tooltip,
                                        )}
                                        title={dayjs(
                                            new Date(
                                                isExpired
                                                    ? "2024-06-27"
                                                    : item.maturityDate
                                            ),
                                            'YYYY-MM-DD'
                                        ).format('MMMM D, YYYY')}
                                        icon="info-stroke"
                                        place="right"
                                    />
                                </>,
                                skeletonWidth: 50,
                                isEmpty
                            })}
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.label}>Your Position</div>
                        <div className={styles.value}>
                        <div
                            className={cn(
                                `${apyStyle2}`,
                                styles.data,
                                styles.lbl
                            )}>
                            {displayWithLoaderObj({
                                isLoading: !rewardsValue,
                                data: rewardsValue,
                                skeletonWidth: 50,
                                isEmpty
                            })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cn(
                    styles.col
                )}>
                {positionData && token && item && tokenUtilization &&  (
                    <Control
                        item={item}
                        amountDaysValue={amountDaysValue}
                        availableFunds={availableFunds}
                        token={tokenBalance ?? token}
                        tokenBalance={tokenBalance}
                        isActive={item.active}
                        doRefetch={doRefetch}
                        nftId={farmNftId}
                        isFetching={isFetching}
                        className={styles.control}
                        positionData={positionData}
                        tokenUtilization={tokenUtilization}
                        setToken={setToken}
                    />
                )}
                </div>
            </div>
            <TooltipGlobal />
        </>
    );
};

export default Row;
