import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SHOW_NETAPY } from "../utils/config";
import { appLocalStorage } from "../utils/localstorage";
import { setShowNetAPY } from "../redux/settingsSlice";

export const useLocalStorage = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        // NetAPY preference
        const showNetAPY = appLocalStorage.NETAPY.get();
        if (showNetAPY !== null) {
            dispatch(
                setShowNetAPY(
                    showNetAPY
                )
            );
        } else {
            appLocalStorage.NETAPY.update(
                SHOW_NETAPY
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
