import React, { useMemo } from "react";
import cn from "classnames";
import {
    // POOL_NAMES,
    getSupportedTokens
} from "../../../ethers/tokens";
import Card from "../../../components/Card";
import Tooltip from "../../../components/Tooltip";
import styles from "./AssetsToSupply.module.sass";
import Row from "./Row";

// import { PENDLE_MARKET_CONTRACT_ADDRESSES } from "../../../ethers/farms";
// import useEthenaExtraApy from "../../../customHooks/useEthenaExtraApy";
// import useEtherfiExtraApy from "../../../customHooks/useEtherfiExtraApy";
// import { _defaultArray } from "../../../utils";

const AssetsToSupply = ({ config, nftId, summary, expand }) => {

    const { chainId, availablePools } = config;

    const tokens = useMemo(
        () => {
            return getSupportedTokens(
                chainId,
                availablePools
            );
        },
        [chainId, availablePools]
    );

    /*
    const stableEthenaPools = useMemo(() =>
        tokens
            .filter(token => [
                POOL_NAMES.USDC,
                POOL_NAMES.USDT,
                POOL_NAMES.DAI
            ].includes(token.poolName))
        , [tokens]);

    const stableEtherfiPool = useMemo(() =>
        tokens
            .find(token => token.poolName === POOL_NAMES.ETH)
        , [tokens]);
    */

    /*
    const { tokenWithExtraApy } = useEtherfiExtraApy({
        config,
        token: stableEtherfiPool,
        powerFarmAddress: PENDLE_MARKET_CONTRACT_ADDRESSES.Etherfi,
        skip: !stableEtherfiPool
    });

    const { tokensWithExtraApy } = useEthenaExtraApy({
        config,
        tokens: stableEthenaPools,
        powerFarmAddress: PENDLE_MARKET_CONTRACT_ADDRESSES.Ethena,
        skip: !stableEthenaPools.length
    });
    */

    /*
    const tokenList = useMemo(() =>
        _defaultArray(tokensWithExtraApy)
        .concat(_defaultArray([tokenWithExtraApy]))
        .filter(Boolean)
    , [
        tokenWithExtraApy,
        tokensWithExtraApy
    ]);
    */

    return (
        <Card
            className={cn(styles.card)}
            title="Funds to Lend"
            classTitle="title-green"
        >
            {/*<span className={cn(styles.hodler)}>
                <img className={cn(styles.pointA)} src="https://www.ether.fi/_next/image?url=%2Fimages%2Fgovernance%2Fliquid.webp&w=750&q=75"></img>
                <img className={cn(styles.pointB)} src="https://www.stakingrewards.com/_next/image?url=https%3A%2F%2Fstorage.googleapis.com%2Fstakingrewards-static%2Fimages%2Fproviders%2Fproduction%2Fethena_logo.png%3Fv%3D1713525380019&w=3840&q=75"></img>
            </span>*/}
            <div className={styles.wrapper}>
                <div className={styles.table}>
                    <div className={cn(styles.row, styles.head)}>
                        <div className={styles.col}>Pool Type</div>
                        <div className={styles.col}>Provided</div>
                        <div className={styles.col}>
                            <Tooltip
                                className={styles.tooltip}
                                title={"Variable APY based on supply and demand"}
                                place="right"
                            >
                                <b className={styles.tilt}>APY</b>
                            </Tooltip>
                        </div>
                        <div className={styles.col}>Your Fund</div>
                        <div className={styles.col}>Actions / Info</div>
                    </div>
                    {tokens.map((x, index) => {
                        /*
                        const extraApy =
                            tokenList
                                ?.find(token => token.poolName === x.poolName)
                                ?.extraApy;
                        */
                        return (
                            <Row
                                expand={expand}
                                token={x}
                                key={`aave_${index}`}
                                nftId={nftId}
                                config={config}
                                summary={summary}
                                // extraApy={extraApy}
                            />
                        )
                    })}
                </div>
            </div>
        </Card>
    );
};

export default AssetsToSupply;
