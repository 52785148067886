import React, { useEffect, useMemo, useState } from "react";
import styles from "./ReferralNetwork.module.sass";
import cn from "classnames";
import { WriteReferralCookie } from "../../../../utils/cookie";
import { useSelector } from "react-redux";
import { useAccount, useSignMessage } from "wagmi";
import { _no, _notEmpty, displayWithLoader, isAddressSignerOfMessage, isWalletAddressFormat, setPromoterAddress, shortenAddress } from "../../../../utils";
import SuccessPanel from "../../../../components/Modal/SuccessPanel";
import { DONE_TEXT, JOIN_REFERRAL_NETWORK_SUCCESS, SIGNING_TEXT } from "../../../../utils/config";
import Loader from "../../../../components/Loader";
import Comment from "../../../../components/Comment";
import useReferralNetworkUsers from "../../../../customHooks/useReferralNetworkUsers";
import { showToast } from "../../../../utils/";
// import { useWeb3Modal } from "@web3modal/wagmi/react";
import useMediaQuery from "../../../../customHooks/useMediaQuery";

const SIGN_TOAST_MESSAGES = {
    notConnected: "Please connect account to join referral network",
    invalidReferralLink: "Please enter a valid referral network link to use",
    success: "Successfully joined referral network",
    failed: "Failed to join referral network"
}

const INVALID_LINK_LABELS = {
    TITLE: "Invalid Link Provided",
    REFERRER_LABEL: "Invalid link provided...",
    USER_LABEL: "...",
    USER_DESCRIPTION: "Please use valid link"
}

const BUTTON_TEXT = {
    DEFAULT_TEXT: "Sign Confirmation",
    SIGNING_TEXT,
    LEAVE_NETWORK: "Go to Lending",
    FETCHING_DATA: "Fetching Data...",
    INVALID_ADDRESS: INVALID_LINK_LABELS.TITLE,
}


const ReferralNetwork = ({
    title = "Join Referral Network",
    referrerAddress,
    showFullAddress,
    onSuccessConfirm,
    onSuccess,
    hasPromoter,
    shouldDisplayLoading,
    hideAddressTooltips,
    isStaking
}) => {
    const { ensName } = useSelector(state =>
        state.settings
    );

    const NETWORK_LABELS = {
        CONNECT_WALLET: !isStaking
            ? "If wallet is connected you can join referral network"
            : "You are all good to go, staking referral activated",
        LEAVE_NETWORK: !isStaking
            ? "Leaving referral network may result in points loss"
            : "Simply visit other links if you want to switch referral",
        EXISTING_NETWORK: !isStaking
            ? "Your are part of this referral network earning +10% for lending"
            : "Your are part of this referral network getting +10% for staking",
        INVITE: !isStaking
            ? "Is inviting you to join their referral network"
            : "Is giving you extra earning +10% rewards for new stakes",
        JOIN: !isStaking
            ? "Join to start earning +10% points"
            : "Start earning +10% rewards for your new stakes"
    }

    const { isConnected, address } = useAccount();
    // const { open } = useWeb3Modal();

    const isMobile = useMediaQuery("(max-width: 639px)");

    const [isJoinNetworkLoading, setIsJoinNetworkLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const [buttonText, setButtonText] = useState(
        BUTTON_TEXT.DEFAULT_TEXT
    );

    const { signMessageAsync } = useSignMessage();

    const handleJoinReferralClick = async () => {
        if (shouldDisplayLoading) {
            return;
        }

        if (hasPromoter) {
            window.location.href = '/lending/hub';
            return;
        }

        if (isSuccess) {
            onSuccessConfirm?.();
            return;
        }

        if (_no(address)) {
            showToast(
                SIGN_TOAST_MESSAGES.notConnected,
                {
                    type: "error",
                }
            );
            return;
        }

        if (_no(referrerAddress)) {
            showToast(
                SIGN_TOAST_MESSAGES.invalidReferralLink,
                {
                    type: "error",
                }
            );
            return;
        }

        setIsJoinNetworkLoading(true);
        setButtonText(BUTTON_TEXT.SIGNING_TEXT);

        try {
            const signedMessage = await signMessageAsync({
                message
            });

            const isUserSignerOfMessage = await isAddressSignerOfMessage({
                address,
                message,
                signature: signedMessage
            });

            if (isUserSignerOfMessage) {
                const setPromoterAddressResult = await setPromoterAddress({
                    walletAddress: address,
                    promoterAddress: referrerDetails?.address,
                    signedMessage
                });

                if (_notEmpty(setPromoterAddressResult?.message)) {
                    setIsSuccess(true);
                    setButtonText(DONE_TEXT);
                    showToast(
                        SIGN_TOAST_MESSAGES.success,
                        {
                            type: "success",
                        }
                    );
                    onSuccess?.();
                } else {
                    throw new Error(SIGN_TOAST_MESSAGES.failed);
                }
            } else {
                throw new Error(SIGN_TOAST_MESSAGES.failed);
            }
        } catch (error) {
            showToast(
                error.message,
                {
                    type: "error",
                }
            );
            setButtonText(
                BUTTON_TEXT.DEFAULT_TEXT
            );
        } finally {
            setIsJoinNetworkLoading(false);
        }
    };

    useEffect(() => {
        return () => {
            setButtonText(
                BUTTON_TEXT.DEFAULT_TEXT
            );
        }
    }, []);

    const { loading: isNetworkUsersDetailsLoading, addressLabels, referrerDetails } = useReferralNetworkUsers({
        userAddress: address,
        userEnsName: ensName,
        referrerAddress,
        showFullAddress: showFullAddress && !isMobile,
    });

    const message = useMemo(() => {

        WriteReferralCookie(
            referrerDetails?.address
        );

        if (_no(referrerDetails?.address) || _no(address)) {
            return null;
        }
        return `I want to join ${referrerDetails?.address} referral network with my wallet ${address}`;

    }, [referrerDetails?.address, address]);



    const loading = shouldDisplayLoading || isNetworkUsersDetailsLoading;
    const invalidReferrer = !loading && !referrerDetails?.address;

    const promoterDescription = displayWithLoader(
        loading || invalidReferrer,
        hasPromoter
            ? NETWORK_LABELS.EXISTING_NETWORK
            : NETWORK_LABELS.INVITE,
            ""
    );

    const defaultUserAddressDisplay = useMemo(() => {
        if (showFullAddress) {
            return address;
        } else {
            return address
                ? shortenAddress(address)
                : null;
        }
    }, [showFullAddress, address]);

    const defaultReferrerAddressDisplay = useMemo(() => {
        if (showFullAddress) {
            return referrerAddress;
        } else {
            return referrerAddress && isWalletAddressFormat(referrerAddress)
                ? shortenAddress(referrerAddress)
                : null;
        }
    }, [
        showFullAddress,
        referrerAddress
    ]);

    const userAddressLabel = (addressLabels?.user ?? defaultUserAddressDisplay)
        || NETWORK_LABELS.CONNECT_WALLET;

    const userAddressDisplay = displayWithLoader(
        loading,
        invalidReferrer
        ? INVALID_LINK_LABELS.USER_LABEL
        : userAddressLabel,
        " "
    );

    const referrerAddressDisplay = displayWithLoader(
        loading,
        invalidReferrer
            ? INVALID_LINK_LABELS.REFERRER_LABEL
            : addressLabels?.referrer || defaultReferrerAddressDisplay,
        " "
    );

    const userDescription = displayWithLoader(
        loading,
        invalidReferrer
            ? INVALID_LINK_LABELS.USER_DESCRIPTION
            : hasPromoter
                ? NETWORK_LABELS.LEAVE_NETWORK
                : NETWORK_LABELS.JOIN,
        " "
    );

    useEffect(() => {
        if (invalidReferrer) {
            setButtonText(BUTTON_TEXT.INVALID_ADDRESS);
        }
    }, [invalidReferrer]);

    return (
        <>
            {isSuccess ? (
                <div className={styles.successWrapper}>
                    <SuccessPanel />
                    <div className={styles.text}>
                        {JOIN_REFERRAL_NETWORK_SUCCESS} {referrerDetails?.address}
                    </div>
                </div>
            ) : (
                <>
                    <div className={styles.head}>
                        <div className={cn("title-green", styles.title)}>
                            {
                                invalidReferrer
                                    ? INVALID_LINK_LABELS.TITLE
                                    : title
                            }
                        </div>
                    </div>
                    <div className={styles.wrapper}>
                        <Comment
                            avatar={'/images/content/shadow-owl.jpg'}
                            author={referrerAddressDisplay}
                            comment={promoterDescription}
                            authorClassName={cn({
                                [styles.referrerAddress]: _no(loading)
                            })}
                            tooltip={
                                _no(hideAddressTooltips)
                                    ? referrerAddress
                                    : null
                            }
                            className={cn(
                                styles.comments,
                                {
                                    [styles.disabled]: loading
                                }
                            )}
                        >
                            <Comment
                                avatar={'/images/content/shadow-owl.jpg'}
                                author={userAddressDisplay}
                                comment={userDescription}
                                isAnswer
                            />
                        </Comment>
                    </div>
                </>
            )}
            {!isStaking && (
                <div className={styles.foot}>
                {
                    _no(isConnected) ? (
                        <button
                            className={cn('button', styles.button)}
                            onClick={() => {
                                // open()
                            }}
                        >
                            Connect Wallet
                        </button>
                    ) : (
                        <button
                            className={cn(
                                "button",
                                styles.button,
                                {
                                    disabled: loading || isJoinNetworkLoading || _no(message) || _no(address) || invalidReferrer,
                                    [styles.invalidReferrer]: invalidReferrer
                                }
                            )}
                            onClick={handleJoinReferralClick}
                        >
                            {(loading || isJoinNetworkLoading) && <Loader className={styles.loader} />}
                            {
                                displayWithLoader(
                                    loading,
                                    <span>
                                        {hasPromoter
                                            ? BUTTON_TEXT.LEAVE_NETWORK
                                            : buttonText
                                        }
                                    </span>,
                                    BUTTON_TEXT.FETCHING_DATA
                                )
                            }
                        </button>
                    )
                }
            </div>
            )}
            {isStaking && (
                <div className={styles.foot}>
                    <button
                        className={cn('button', styles.button)}
                        onClick={() => {
                            window.location.href = '/staking/hub';
                        }}
                    >
                        Go to Staking
                    </button>
                </div>
            )}
        </>
    )
}

export default ReferralNetwork;
