import React from 'react';
import { useState } from 'react';
import cn from 'classnames';
import styles from "./TransactionRow.module.sass";
// import useConfig from "../../../../customHooks/useConfig";
import useWiseStakeData from "../../../../customHooksWise/useWiseStakeData";
import LinearBar from "../../../../components/LinearBar";
import Modal from "../../../../components/Modal";
// import Positions from "../../../../screens/ProductsDashboard/Positions";
import Icon from "../../../../components/Icon";
import Details from "./Details";
import Tooltip from "../../../../components/Tooltip";
import {
    formatAPY,
    daysAgoToTitle,
    numberWithCommasForDays,
    numberWithCommasForFutureDays,
} from "../../../../utils/";
import TooltipGlobal from '../../../../components/TooltipGlobal';
import useWiseStakeNft from '../../../../customHooksWise/useWiseStakeNft';
import SendNFT from '../../../AffiliateCenter/SendNFT';

const TransactionRow = ({
    // transaction,
    index,
    config,
    hidden,
    stakeId,
    address,
    wiseCost,
    transferable,
    doRefetch,
    currentWiseDay
}) => {

    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleModalSend, setVisibleModalSend] = useState(false);

	const { stakeData, refetchStakeData } = useWiseStakeData({
        config,
        address,
        stakeId,
        currentWiseDay,
        wiseCost
    });

    const staked = stakeData.stakedAmountFormatted;

    // const pendingStake = stakeData && stakeData.isPending === 1;

    const daysAgo = daysAgoToTitle(currentWiseDay - stakeData.startDay + 1);

    const progress = stakeData.stakeProgress;
    // @TODO... loading
    if (!progress)  {
        return <div className={cn(styles.row)} key={index}>
            <div></div>
        </div>;
    }

    return (
        <>
        <div
            className={cn(
                styles.row,
                {
                    [styles.hider]: hidden,
                }
            )}
            key={index}
        >
            <div
                onClick={() => setVisibleModal(true)}
                className={styles.colmobile}
            >
            <div
                onClick={() => setVisibleModal(true)}
                className={styles.liner}>
                    <LinearBar
                        height={8}
                        width={"100"}
                        backColor="#333333"
                        customFill={stakeData.stakeColor}
                        limit={progress}
                        limitColor="#333333"
                    />
                </div>
                <div
                    onClick={() => setVisibleModal(true)}
                >
                    <div className={styles.invoice}>
                        <div className={styles.static}>
                            <span>{parseFloat(progress).toFixed(2)}% Matured</span>
                            <span>{stakeData && numberWithCommasForDays(stakeData.lockDays)} Total</span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                onClick={() => setVisibleModal(true)}
                className={styles.col}
            >
                <div className={styles.label}>Stake Start</div>
                <div className={styles.holduris}>
                    <TooltipGlobal />
                    <div>
                        <Tooltip
                            title={stakeData.tooltipText}
                            place="right"
                            children={<span className={styles.tooltip}>{stakeData.tooltipText}</span>}
                        >
                            <Icon className={cn(
                                styles.icon,
                                {
                                    [styles.active]: stakeData.matureStake,
                                    [styles.closed]: stakeData.closedStake,
                                    [styles.pending]: stakeData.pendingStake,
                                }
                            )} size="24" name={stakeData.stakeIcon} />
                        </Tooltip>
                    </div>
                    <div>
                        <div className={styles.product}>
                            {stakeData && stakeData.pendingStake ? (
                                <>Pending Today</>
                            ) : (
                                <>{stakeData && daysAgo}</>
                            )}
                        </div>
                    <div className={styles.invoice}>{stakeData.pendingStake ? 'On' : 'On'} {stakeData && stakeData.opensDayDate}</div>
                    </div>
                </div>
            </div>
            <div
                onClick={() => setVisibleModal(true)}
                className={styles.col}
            >
                <div className={styles.label}>Stake Progress</div>
                <div className={styles.liner}
                    style={{
                        opacity: stakeData && stakeData.closedStake ? 0.2 : 1,
                        filter: stakeData && stakeData.closedStake ? "grayscale(0.8)" : "grayscale(0)",
                    }}
                >
                    <LinearBar
                        height={8}
                        width={"100"}
                        backColor="#333333"
                        customFill={stakeData.stakeColor}
                        limit={progress}
                        limitColor="#333333"
                    />
                </div>
                <div>
                    <div className={styles.invoice}>
                        <div className={styles.static}>
                            <span>{parseFloat(progress).toFixed(2)}% Matured</span>
                            <span>{stakeData && stakeData.lockDaysFormatted} Total</span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                onClick={() => setVisibleModal(true)}
                className={styles.col}
            >
                <div className={styles.label}>Stake Status</div>
                {stakeData.matureStake && !stakeData.closedStake && (
                    <>
                        <div className={styles.product}>Stake Matured</div>
                        <div className={styles.invoice}>On {stakeData && stakeData.finalDayDate}</div>
                    </>
                )}
                {stakeData.onGoingStake && !stakeData.pendingStake && (
                    <>
                        <div className={styles.product}>{stakeData && numberWithCommasForFutureDays(stakeData.daysLeft, "Wait ")}</div>
                        <div className={styles.invoice}>Till {stakeData && stakeData.finalDayDate}</div>
                    </>

                )}
                {stakeData.pendingStake && (
                    <>
                        <div className={styles.product}>Starts Tomorrow</div>
                        <div className={styles.invoice}>Till {stakeData && stakeData.finalDayDate}</div>
                    </>

                )}
                {stakeData.closedStake && (
                    <>
                        <div className={styles.product}>{
                            stakeData && stakeData.closedPrematurely
                                ? "Terminated"
                                : "Stake Closed"
                        }</div>
                        <div className={styles.invoice}>On {stakeData && stakeData.closeDayDate}</div>
                    </>
                )}
            </div>
            {/*<div className={styles.col}>
                <div className={styles.product}>{shortenAddress(stakeId)}</div>
                <div className={styles.invoice}><div className={styles.stat}></div>
                    <span className={styles.statname}>
                        Matured Stake
                    </span>
                </div>
            </div>*/}
            <div
                onClick={() => setVisibleModal(true)}
                className={styles.col}
            >
                <div className={styles.label}>Stake Amount</div>
                <div className={styles.product}>{stakeData && staked}</div>
                <div className={styles.invoice}>{stakeData && stakeData.stakedAmountInUSDWithPrecision}</div>
            </div>
            <div
                onClick={() => setVisibleModal(true)}
                className={styles.col}
            >
                <div className={styles.label}>Stake APY</div>
                <>
                    <div
                        className={cn(
                            styles.productor,
                            {
                                [styles.striked]: stakeData.isMature,
                                [styles.strikedGray]: stakeData && stakeData.closedStake
                            }
                        )}
                    >
                        {stakeData && formatAPY(stakeData)}
                    </div>
                    <div className={styles.invoice}>
                        {stakeData && stakeData.servedDaysFormatted}
                    </div>
                </>
            </div>
            <div
                onClick={() => setVisibleModal(true)}
                className={styles.col}
            >
                <div className={styles.label}>Stake Rewards</div>
                <div
                    style={{color: "#1bc943"}}
                    className={cn(
                        styles.product,
                        {
                            [styles.strikedGray]: stakeData && stakeData.closedStake
                        }
                    )}
                >
                    +{stakeData && stakeData.stakeRewards}
                </div>
                <div
                    className={styles.invoice}
                >
                    +{stakeData && stakeData.stakeRewardsInUSD}
                </div>
            </div>
            <div className={styles.col}>
                <div className={styles.label}>Actions</div>
                <div className={styles.holderis}>
                    <button
                        // disabled={closedStake}
                        onClick={() => setVisibleModal(true)}
                        className={cn(
                            "button-stroke button-small",
                            styles.button,
                            {
                                // ["disabled"]: closedStake
                            }
                        )}>
                        <span>
                            {stakeData.closedStake ? "Check Summary" : "Check Rewards"}
                        </span>
                    </button>
                    {transferable && (
                        <button
                            // disabled={closedStake}
                            onClick={() => setVisibleModalSend(true)}
                            className={cn(
                                "button-stroke button-small",
                                styles.button,
                                {
                                    // ["disabled"]: closedStake
                                }
                            )}>
                            Send
                        </button>
                    )}
                </div>
            </div>
        </div>
        <Modal
            outerClassName={cn(styles.sender)}
            visible={visibleModalSend}
            onClose={() => {
                setVisibleModalSend(false);
                doRefetch && doRefetch();
            }}
        >
            <SendNFT
                onClose={() => {
                    setVisibleModalSend(false);
                    doRefetch && doRefetch();
                }}
                nftId={transferable}
                stakeId={stakeId}
                linkBase={config.linkBase}
                chainId={config.chainId}
                stakeData={stakeData}
                contract={config.wiseNftStakes}
                // doRefetchStakes={doRefetch}
                title={"Send NFT Stake"}
            />
        </Modal>
        <Modal
            outerClassName={cn(
                {
                    [styles.outer]: !stakeData.closedStake,
                    [styles.closed]: stakeData.closedStake
                }
            )}
            visible={visibleModal}
            onClose={() => {
                setVisibleModal(false);
            }}
        >
            <Details
                onClose={() => {
                    setVisibleModal(false);
                }}
                nftId={transferable}
                stakeId={stakeId}
                stakeData={stakeData}
                currentWiseDay={currentWiseDay}
                currentAPY={formatAPY(stakeData)}
                doRefetchStakes={refetchStakeData}
            />
        </Modal>
        </>
    );
};

export const TransactionRowWrapperNft = ({
    // transaction,
    index,
    config,
    hidden,
    nftId,
    address,
    wiseCost,
    doRefetch,
    currentWiseDay
}) => {

    const { stakeId } = useWiseStakeNft({
        config,
        nftId
    });

    return (
        <TransactionRow
            index={index}
            config={config}
            hidden={hidden}
            doRefetch={doRefetch}
            transferable={nftId}
            stakeId={stakeId}
            address={address}
            wiseCost={wiseCost}
            currentWiseDay={currentWiseDay}
        />
    );
}

export default TransactionRow;