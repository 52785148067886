import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { formatUnits } from "viem";
import { usePublicClient } from "wagmi";
import { computeUSDAmount } from "../utils";
import {
    DEFAULT_DECIMALS,
    ERROR_GAS_ESTIMATE,
    ETH_TOKEN_NAME
} from "../utils/config";

export const useGasEstimate = ({
    contract,
    account,
    functionName,
    args,
    value = undefined,
    otherData = undefined,
    amount,
    showApproval = false,
    enabled = true,
}) => {

    const publicClient = usePublicClient();
    const pricingFeeds = useSelector((state) => state.token.pricingFeeds);

    const [isFetching, setIsFetching] = useState(true);
    const [isError, setIsError] = useState(true);
    const [estimatedGas, setEstimatedGas] = useState([]);
    const [gasPrice, setGasPrice] = useState(0);

    useEffect(() => {
        const getGasPrice = async () => {
            const gasPrice = await publicClient.getGasPrice();
            setGasPrice(gasPrice);
        };

        getGasPrice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount, showApproval]);

    useEffect(() => {
        let mounted = true;
        const fetch = async () => {
            try {
                if (account && contract?.address) {

                    setIsFetching(true);

                    const {
                        address,
                        abi
                    } = contract;

                    let estimatedGas = 0;

                    if (functionName && amount) {
                        estimatedGas = await publicClient.estimateContractGas({
                            address,
                            abi,
                            functionName,
                            account,
                            args,
                            value,
                        });
                    }

                    const formattedEstimatedGas = parseInt(
                        estimatedGas
                    );

                    const totalGas = formattedEstimatedGas > 0
                        ? gasPrice * estimatedGas
                        : gasPrice;

                    const formattedTotalGas = formatUnits(
                        totalGas,
                        DEFAULT_DECIMALS
                    );

                    const ethTokenPrice = pricingFeeds.find(
                        item => item.name === ETH_TOKEN_NAME
                    );

                    const USDPrice = computeUSDAmount(
                        DEFAULT_DECIMALS,
                        formattedTotalGas,
                        ethTokenPrice?.price
                    );

                    setEstimatedGas({
                        gasLimit: estimatedGas,
                        estimatedGas: totalGas,
                        estimatesGasFormatted: formattedTotalGas,
                        estimatedGasUSD: USDPrice,
                    });

                    setIsFetching(false);
                }
            } catch (ex) {
                if (ex.message.includes(ERROR_GAS_ESTIMATE)) setIsError(true);
            }
        };

        if (mounted && enabled) {
            fetch();
        }

        return () => (mounted = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount, enabled, otherData, gasPrice, showApproval]);

    return { ...estimatedGas, isFetching, isError };
};
